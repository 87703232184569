import { object, z } from "zod";
import { AutoRtaAnalysis, autoRtaAnalysisScheme } from "./Analysis";
import { autoRtaForecastSchemeV2, AutoRtaForecastV2 } from "./Forecast";
import { AutoRtaInputGrid, autoRtaInputGridScheme } from "./InputGrid";

export const autoRtaScheme = object({
  inputs: autoRtaInputGridScheme,
  analysis: autoRtaAnalysisScheme,
  forecast: autoRtaForecastSchemeV2,
});

export type AutoRtaState = z.infer<typeof autoRtaScheme>;

export type AnalysisPayload = {
  data_set_ids: string[];
  analysis: AutoRtaAnalysis;
  inputs: AutoRtaInputGrid;
};

export type AutoRtaForecastPayload = {
  data_set_ids: string[];
  inputs: AutoRtaInputGrid;
  analysis: AutoRtaAnalysis;
  forecast: AutoRtaForecastV2;
};
