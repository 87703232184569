import { useEffect } from "react";
import { Amplify } from "aws-amplify";
import { shallow } from "zustand/shallow";
import { QueryClient, QueryClientProvider, QueryCache } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

import { ThemeProvider as MuiThemeProvider } from "@mui/material";

import { useAppStore } from "@/features/app";
import Router from "./Router";
import SignIn from "./SignIn";
import { TenantConfigs } from "./tenants";
import { SettingsProvider } from "./SettingsState";
import { ProjectSettingsProviderV3 } from "./ProjectSettingsContextV3";
import { CustomError } from "./utils/apiFetcher";
import TreeViewProvider from "./components/TreeView/hooks/TreeViewContextV2";
import ErrorBoundaryWrapper from "./components/ErrorBoundary";
import { captureException } from "./utils/errorMonitoring";
import dictionary from "./constants/dictionary";

import { useUserPreference } from "./features/settings/UserPreference/context";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.extraErrorDataIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^predico/],
  // Session Replay
  replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you'res not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
  environment: process.env.REACT_APP_ENV_SPACE,
  release: process.env.REACT_APP_VERSION + "." + process.env.REACT_APP_BUILD_NUMBER,
  beforeSend: (event) => {
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
});

const App = () => {
  const { user, tenant, title, setApiError, setLoadingBlocker } = useAppStore(
    (state) => ({
      user: state.user,
      tenant: state.tenant,
      title: state.title,
      setApiError: state.setApiError,
      setLoadingBlocker: state.setLoadingBlocker,
    }),
    shallow
  );

  // Configure Amplify authentication
  useEffect(() => {
    let region = process.env.REACT_APP_AMPLIFY_REGION;

    let idpEnabled = false;
    let idpDomain = undefined;
    let userPoolId = process.env.REACT_APP_AMPLIFY_USER_POOL_ID;
    let userPoolWebClientId = process.env.REACT_APP_AMPLIFY_APP_CLIENT_ID;

    if (tenant && TenantConfigs[tenant]) {
      idpEnabled = TenantConfigs[tenant].idpEnabled;
      idpDomain = TenantConfigs[tenant].idpDomain;
      userPoolId = TenantConfigs[tenant].userPoolId;
      userPoolWebClientId = TenantConfigs[tenant].clientId;
    }

    Amplify.configure({
      Auth: {
        region,
        userPoolId,
        userPoolWebClientId,
        oauth: idpEnabled
          ? {
              domain: idpDomain,
              scope: ["email", "openid"],
              redirectSignIn: window.location.origin,
              redirectSignOut: window.location.origin,
              responseType: "code",
            }
          : undefined,
      },
    });
  }, [tenant]);

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        username: user?.username,
        email: user?.attributes?.email ?? user?.username,
      });
    }
  }, [user]);

  useEffect(() => {
    document.title = title ? `${title} - Predico` : "Predico";
  }, [title]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0,
        gcTime: 0,
      },
    },
    queryCache: new QueryCache({
      onError(error) {
        setLoadingBlocker(false);
        const customErr = error as CustomError;
        setApiError({
          code: customErr.code ?? 0,
          detail: customErr.detail,
          message: customErr.code === 422 ? dictionary.errorMessage.validationGeneric : customErr.message,
          severity: customErr.severity,
        });
        setLoadingBlocker(false);
        captureException(error);
      },
      onSuccess(data: any) {
        setApiError(undefined);
        if (data?.data) setLoadingBlocker(false);
      },
    }),
  });

  const { theme } = useUserPreference();

  return (
    <MuiThemeProvider theme={theme}>
      <ErrorBoundaryWrapper>
        <QueryClientProvider client={queryClient}>
          {!user || user.challengeName ? (
            <SignIn />
          ) : (
            <SettingsProvider>
              <ProjectSettingsProviderV3>
                <TreeViewProvider>
                  <Router />
                </TreeViewProvider>
              </ProjectSettingsProviderV3>
            </SettingsProvider>
          )}
        </QueryClientProvider>
      </ErrorBoundaryWrapper>
    </MuiThemeProvider>
  );
};

export default App;
