import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import InputField from "@/components/fields/InputField";
import DropdownField from "@/components/fields/DropdownField";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

import { mapEnumToDropdown } from "@/utils/general";
import { GasPvt, GasViscosityCorrelation, ZCorrelation } from "@/models/InputGeneric";
import dictionary from "@/constants/dictionary";
import helpLinkUrl from "@/constants/helpLinkUrl";

export type GasPvtInputFieldProps = {
  gasPvtInput?: GasPvt;
  isLoading: boolean;
  updateInputValue: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
};

const GasPvtInputField = ({ gasPvtInput, isLoading, updateInputValue, validationError }: GasPvtInputFieldProps) => {
  const gasRecombination = gasPvtInput?.section_gas_recombination.use_gas_recombination;

  return (
    <div>
      <InputField
        label={dictionary.inputPage.specificGravity}
        suffix="dim"
        debounceDelay={1000}
        value={gasPvtInput?.specific_gravity}
        onChange={(v) => updateInputValue(v, "specific_gravity", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField={"inputs.gas_pvt.specific_gravity"}
        helpUrl={helpLinkUrl.gasAutoRta.modelComponents["specificGravity"]}
      />
      <InputField
        label={dictionary.inputPage.n2}
        suffix="%"
        min={0}
        max={100}
        debounceDelay={1000}
        value={gasPvtInput?.nitrogen}
        onChange={(v) => updateInputValue(v, "nitrogen", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField={"inputs.gas_pvt.nitrogen"}
      />
      <InputField
        label={dictionary.inputPage.co2}
        suffix="%"
        min={0}
        max={100}
        debounceDelay={1000}
        value={gasPvtInput?.carbon_dioxide}
        onChange={(v) => updateInputValue(v, "carbon_dioxide", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField={"inputs.gas_pvt.carbon_dioxide"}
      />
      <InputField
        label={dictionary.inputPage.h2s}
        suffix="%"
        min={0}
        max={100}
        debounceDelay={1000}
        value={gasPvtInput?.hydrogen_sulphide}
        onChange={(v) => updateInputValue(v, "hydrogen_sulphide", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField={"inputs.gas_pvt.hydrogen_sulphide"}
      />

      <DropdownField
        label={dictionary.inputPage.gasViscosityCorrelation}
        options={mapEnumToDropdown(GasViscosityCorrelation)}
        selectedKey={gasPvtInput?.selected_gas_viscosity_correlation}
        onChange={(v) => updateInputValue(v, "selected_gas_viscosity_correlation", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField="inputs.gas_pvt.selected_gas_viscosity_correlation"
        helpUrl={helpLinkUrl.gasAutoRta.modelComponents["gasViscosityCorrelation"]}
      />
      <DropdownField
        label={dictionary.inputPage.zCorrelation}
        options={mapEnumToDropdown(ZCorrelation)}
        selectedKey={gasPvtInput?.selected_z_correlation}
        onChange={(v) => updateInputValue(v, "selected_z_correlation", "gas_pvt")}
        disabled={isLoading}
        errors={validationError}
        keyField="inputs.gas_pvt.selected_z_correlation"
        helpUrl={helpLinkUrl.gasAutoRta.modelComponents["zCorrelation"]}
      />

      <div style={{ marginTop: 30 }}>
        {gasPvtInput && (
          <FormControlLabel
            control={
              <Checkbox
                id="gas-recombination-autorta"
                onChange={(e) => {
                  updateInputValue(
                    {
                      ...gasPvtInput?.section_gas_recombination,
                      use_gas_recombination: e.target.checked,
                    },
                    "section_gas_recombination",
                    "gas_pvt"
                  );
                  updateInputValue(e.target.checked, "use_wellbore_model", "wellbore_model");
                }}
                defaultChecked
                size="small"
                checked={gasRecombination}
                disabled={isLoading}
              />
            }
            label="Gas Recombination"
          />
        )}

        {gasRecombination && gasPvtInput?.section_gas_recombination && (
          <div>
            <div className="equal-size">
              <InputField
                label={dictionary.inputPage.condensateGasRatio}
                suffix="stb/MMscf"
                debounceDelay={1000}
                value={gasPvtInput.section_gas_recombination.condensate_gas_ratio}
                onChange={(v) =>
                  updateInputValue({ ...gasPvtInput.section_gas_recombination, condensate_gas_ratio: v }, "section_gas_recombination", "gas_pvt")
                }
                disabled={isLoading}
                errors={validationError}
                keyField={"inputs.gas_pvt.section_gas_recombination.condensate_gas_ratio"}
              />
              <InputField
                label={dictionary.inputPage.gasGravitySeparator}
                suffix="dim"
                debounceDelay={1000}
                value={gasPvtInput.section_gas_recombination.specific_gravity_separator}
                disabled={isLoading}
                onChange={(v) =>
                  updateInputValue(
                    { ...gasPvtInput.section_gas_recombination, specific_gravity_separator: v },
                    "section_gas_recombination",
                    "gas_pvt"
                  )
                }
                errors={validationError}
                keyField={"inputs.gas_pvt.section_gas_recombination.specific_gravity_separator"}
              />
              <InputField
                label={dictionary.inputPage.condensateApi}
                suffix="API"
                debounceDelay={1000}
                value={gasPvtInput.section_gas_recombination.condensate_API}
                disabled={isLoading}
                onChange={(v) =>
                  updateInputValue({ ...gasPvtInput.section_gas_recombination, condensate_API: v }, "section_gas_recombination", "gas_pvt")
                }
                errors={validationError}
                keyField={"inputs.gas_pvt.section_gas_recombination.specific_gravity_separator"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GasPvtInputField;
