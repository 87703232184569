import ForecastChartSection from "./ForecastChartSection";
import ForecastInput from "./ForecastInput";

const KoldunCSGForecastPage = () => {
  return (
    <div
      style={{
        padding: 10,

        display: "grid",
        gridTemplateColumns: "450px 1fr",
        gridGap: 25,
        height: "98%",
        overflow: "auto",
      }}
    >
      <ForecastInput />
      <ForecastChartSection />
    </div>
  );
};

export default KoldunCSGForecastPage;
