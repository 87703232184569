import { Link } from "react-router-dom";
import useThemeStyling from "@/utils/useThemeStyling";

import "./ModuleLink.css";

interface ModuleLinkProps {
  to: string;
  text: string;
  secondaryText: string;
  topText?: string;
  disabled: boolean;
  onClickModule: () => void;
}

function ModuleLink({ to, text, secondaryText, topText, disabled, onClickModule }: Readonly<ModuleLinkProps>) {
  const { palette } = useThemeStyling();

  let pathFill = disabled ? palette.customColor.neutralTertiary : palette.primary.main;

  return (
    <Link
      id={`${text}-${secondaryText?.split(" ")?.join("-")}`}
      className={disabled ? "ModuleLink disabled" : "ModuleLink"}
      to={to}
      onClick={onClickModule}
      aria-disabled={disabled}
    >
      <div className="ModuleLink-hex-outer">
        <svg style={{ zIndex: -1 }} version="1.1" xmlns="http://www.w3.org/2000/svg" width="105" height="122" viewBox="0 0 173.20508075688772 200">
          <path fill={pathFill} d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"></path>
        </svg>
        <div className="ModuleLink-hex-inner" style={{ color: disabled ? palette.customColor.neutralTertiary : palette.primary.main }}>
          <div style={{ color: "black", fontWeight: "bold" }}>{topText}</div>
          <h3 style={{ color: "white", fontWeight: "bold", marginTop: 0, marginBottom: 0 }}>{text}</h3>
          <div style={{ color: "white", fontSize: 14 }}>{secondaryText}</div>
        </div>
      </div>
    </Link>
  );
}

export default ModuleLink;
