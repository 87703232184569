import { useState } from "react";

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import DialogMui from "@mui/material/Dialog";

import TreeViewCreateGroupsInput from "../../TreeViewCreateGroupsInputV2";
import { Group } from "../../../model";

import { getIdFromActiveKey } from "../utils";
import { DialogEnum } from "../types";
import InputField from "@/components/fields/InputField";

interface GroupDialogProps {
  isLoading: boolean;
  createNewGroups: (val: string) => void;
  editExistingGroup: (name: string) => void;
  setNewGroupsNames: (names: string[]) => void;
  newGroupsNames: string[];
  activeDialog?: DialogEnum;
  setActiveDialog: (val: DialogEnum | undefined) => void;
  selectedKey: string;
  deleteGroup: () => void;
  selectedGroup?: Group;
}

const GroupDialog = ({
  editExistingGroup,
  setNewGroupsNames,
  createNewGroups,
  newGroupsNames,
  activeDialog,
  setActiveDialog,
  selectedKey,
  isLoading,
  selectedGroup,
  deleteGroup,
}: GroupDialogProps) => {
  const { groupIds } = getIdFromActiveKey(selectedKey);
  const [name, setName] = useState("");

  return (
    <>
      {/* new  group */}
      <DialogMui
        open={activeDialog === DialogEnum.NEW_GROUP}
        keepMounted
        onClose={() => {
          setNewGroupsNames([]);
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{`New ${groupIds.length > 0 ? "Subgroups" : "Groups"}`}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>{`Please enter a name for the new ${groupIds.length > 0 ? "subgroups" : "groups"}.`}</DialogContentText>
          <TreeViewCreateGroupsInput name={name} setName={setName} parentGroup={selectedGroup} value={newGroupsNames} onChange={setNewGroupsNames} />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="create-group"
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={isLoading || (newGroupsNames.length < 1 && name.length < 1)}
            onClick={() => {
              createNewGroups(name);
              setName("");
            }}
          >
            Create
          </Button>

          <Button
            onClick={() => {
              setNewGroupsNames([]);
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>

      {/* Edit group dialog */}
      <DialogMui
        open={activeDialog === DialogEnum.EDIT_GROUP}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{`Edit Group`}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>{`You can update the group name below. Click save when you are done with this group.`}</DialogContentText>

          <InputField
            label="New group name"
            placeholder="Enter a group name..."
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                setActiveDialog(undefined);
                editExistingGroup(name);
                setName("");
              }
            }}
            value={name}
            type="text"
            onChange={(v) => setName(String(v ?? ""))}
          />
          <span
            style={{
              fontSize: 10,
            }}
          >
            Tip: Use the Enter key to save changes.
          </span>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={!name}
            onClick={() => {
              setActiveDialog(undefined);
              editExistingGroup(name);
              setName("");
            }}
          >
            Save
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
              setName("");
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>

      {/* Delete group dialog */}
      <DialogMui
        open={activeDialog === DialogEnum.DELETE_GROUP}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{`Delete Group`}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>{`Warning: This operation cannot be undone!`}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              deleteGroup();
            }}
          >
            Delete
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>
    </>
  );
};

export default GroupDialog;
