import React from "react";
import { Link } from "react-router-dom";

import Page from "./Page";
import useThemeStyling from "./utils/useThemeStyling";

function NotFound() {
  const { palette } = useThemeStyling();

  return (
    <Page title="Not found">
      <div style={{ height: "100%", padding: 20, justifyContent: "center", alignItems: "center", flexDirection: "column", display: "flex" }}>
        <div style={{ fontSize: 18 }}>Sorry, you found a</div>
        <h1 style={{ marginTop: 0, marginBottom: 0 }}>
          <span style={{ color: palette.primary.main }}>blank</span> page.
        </h1>
        <br />
        <div>
          <Link style={{ color: palette.primary.main }} to="/">
            Click here to go home and try again.
          </Link>
        </div>
      </div>
    </Page>
  );
}

export default NotFound;
