import { postGroupEditInitializeApi, postGroupEditApi } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { GroupEditItem } from "../groupEdit";
import { ModuleId } from "@/model";

export type GroupEditPayload = {
  projectId: string;
  datasetIds: string[];
  moduleId: ModuleId;
};
export const postGroupEditInitialize = ({ datasetIds, moduleId, projectId }: GroupEditPayload) => {
  return fetchApi<GroupEditItem[]>({
    path: postGroupEditInitializeApi(projectId, moduleId),
    body: {
      data_set_ids: datasetIds,
    },
    type: "post",
  });
};

export const postGroupEdit = ({ body, moduleId, projectId }: { projectId: string; body: GroupEditItem[]; moduleId: ModuleId }) => {
  return fetchApi({
    path: postGroupEditApi(projectId, moduleId),
    body,
    type: "post",
  });
};
