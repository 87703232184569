import { TahkCsgInputLayerCharts, TahkCsgInputState, TahkCsgSelectedGeometryEnum, TahkCsgStateResponse, TahkCsgWellInputBase } from "@/models/tahk";
import { dimensionWellInputHeader, areaWellInputHeader, tableHeaderStyle, tableHeaderNotationStyle, tableCellStyle } from "../../../constants/grid";
import { camelToSnakeCase, formatToScientific, mapEnumToDropdownReactGrid } from "@/utils/general";
import { useCallback, useMemo, useState } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import _ from "lodash";
import dictionary from "@/constants/dictionary";
import { SkinModelEnum, WellInputEnum } from "@/models/InputGeneric";

export type TahkCsgWellInputProps = {
  inputs?: TahkCsgInputState;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  loadingState: boolean;
  layers: TahkCsgInputLayerCharts[];
  isTest?: boolean;
};

const useTahkCsgWellInput = ({ inputs, setTahkCsgState, loadingState, isTest }: TahkCsgWellInputProps) => {
  const [activeDropdown, setActiveDropdown] = useState<{ columnId: string; row: number } | null>(null);

  const showPowerLawTable = useMemo(() => {
    return (
      Object.keys(inputs?.power_law_skin ?? {}).length > 0 &&
      inputs?.layers[0].well_inputs?.well_parameters?.selected_skin_model === SkinModelEnum.PowerLaw
    );
  }, [inputs?.layers, inputs?.power_law_skin]);

  const selectedHeader = useMemo(() => {
    return inputs?.selected_geometry === TahkCsgSelectedGeometryEnum.SpecifyArea ? areaWellInputHeader : dimensionWellInputHeader;
  }, [inputs?.selected_geometry]);

  const inputColumns = useMemo(() => {
    return Object.keys(selectedHeader).map((columnId, index) => {
      let width = 110;
      if (index === 0) width = 50;
      if (index === 2) width = 135;
      return { columnId: camelToSnakeCase(columnId), width };
    });
  }, [selectedHeader]);

  const inputRows: Row<any>[] = useMemo(() => {
    if (!inputs) return [];
    const input = _.cloneDeep(inputs);
    const headerKeys = Object.keys(selectedHeader);
    return [
      {
        rowId: "header",
        cells: Object.values(selectedHeader).map((header) => {
          return {
            type: "header",
            text: header,
            style: tableHeaderStyle,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: headerKeys.map((header: string) => {
          return {
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...input.layers.map((layer, index) => {
        const wellInput: TahkCsgWellInputBase = layer.well_inputs;
        const wellParam = layer.well_inputs.well_parameters;

        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "number",
              value: index + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...headerKeys.slice(1, headerKeys.length).map((header) => {
              const val = wellInput.hasOwnProperty(camelToSnakeCase(header))
                ? wellInput[camelToSnakeCase(header) as keyof TahkCsgWellInputBase]
                : wellParam[camelToSnakeCase(header) as keyof TahkCsgWellInputBase["well_parameters"]];
              const nonEditable =
                (showPowerLawTable && header === "skin" && wellParam?.selected_skin_model === SkinModelEnum.PowerLaw) || loadingState;

              if (isNaN(Number(val)) && val) {
                return {
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: mapEnumToDropdownReactGrid(header === "selectedWellType" ? WellInputEnum : SkinModelEnum),
                  style: tableCellStyle,
                  isOpen: !!(activeDropdown && activeDropdown.columnId === camelToSnakeCase(header) && index === activeDropdown.row),
                  nonEditable: loadingState,
                };
              } else if (isNaN(Number(val)) && String(val) === "undefined") {
                return {
                  type: "text",
                  text: "-",
                  style: tableCellStyle,
                  nonEditable: true,
                };
              }
              return {
                type: "text",
                text: formatToScientific(Number(val)),
                style: {
                  ...tableCellStyle,
                  background: nonEditable ? "#f3f2f1" : "#fff",
                },
                nonEditable,
                validator: isTest
                  ? null
                  : (text: string) => {
                      return !isNaN(Number(text));
                    },
              };
            }),
          ],
        };
      }),
    ];
  }, [inputs, selectedHeader, showPowerLawTable, loadingState, isTest, activeDropdown]);

  const onChangeCells = useCallback(
    (changes: CellChange[]) => {
      if (!inputs) return;
      const updatedRows = [...inputs.layers];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        const wellInput: any = updatedRows[rowId].well_inputs;
        const wellParam: { [key: string]: any } = updatedRows[rowId].well_inputs.well_parameters;

        if (type === "dropdown") {
          const newDropdown = {
            row: rowId,
            columnId: columnId,
          };
          if (prevCell.isOpen !== dropDownNewCell.isOpen || !_.isEqual(newDropdown, activeDropdown)) {
            if (dropDownNewCell.isOpen) {
              setActiveDropdown(newDropdown);
            } else {
              setActiveDropdown(null);
            }
          }
          if (dropDownNewCell.isOpen) return;
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
            if (wellInput.hasOwnProperty(columnId)) {
              wellInput[columnId] = dropDownNewCell.selectedValue;
            } else {
              wellParam[columnId] = dropDownNewCell.selectedValue;
            }
          }
        } else {
          if (wellInput.hasOwnProperty(columnId)) {
            wellInput[columnId] = newCell.value ?? newCell.text ?? 0;
          } else {
            wellParam[columnId] = newCell.value ?? newCell.text ?? 0;
          }
        }
      }

      setTahkCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            layers: updatedRows,
          },
        };
      });
    },
    [inputs, setTahkCsgState, activeDropdown]
  );

  const powerLawColumns = [
    { columnId: "permeability", width: 150 },
    { columnId: "skin", width: 150 },
  ];

  const powerLawRows = [
    {
      rowId: "header",
      cells: powerLawColumns.map((header) => {
        return {
          type: "header",
          text: (dictionary.tahk as { [key: string]: string })[header.columnId],
          style: tableHeaderStyle,
        };
      }),
      height: 50,
    },
    {
      rowId: "notation",
      cells: powerLawColumns.map((header) => {
        return {
          type: "header",
          text: (dictionary.tableUnits as { [key: string]: string })[header.columnId],
          style: tableHeaderNotationStyle,
        };
      }),
    },
    {
      rowId: "a",
      cells: [
        {
          type: "text",
          text: formatToScientific(Number(inputs?.power_law_skin?.permeability_a)) ?? "",
          style: tableCellStyle,
          nonEditable: loadingState,
        },
        {
          type: "text",
          text: formatToScientific(Number(inputs?.power_law_skin?.skin_a)) ?? "",
          style: tableCellStyle,
          nonEditable: loadingState,
        },
      ],
    },
    {
      rowId: "b",
      cells: [
        {
          type: "text",
          text: formatToScientific(Number(inputs?.power_law_skin?.permeability_b)) ?? "",
          style: tableCellStyle,
          nonEditable: loadingState,
        },
        {
          type: "text",
          text: formatToScientific(Number(inputs?.power_law_skin?.skin_b)) ?? "",
          style: tableCellStyle,
          nonEditable: loadingState,
        },
      ],
    },
  ];

  const onChangePowerLawSkin = useCallback(
    (changes: CellChange[]) => {
      if (!inputs) return;
      const updatedPowerLaw = { ...inputs };

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell } = change as CellChange<any>;

        rowId = rowId as number;
        columnId = columnId as string;

        const powerLaw: { [key: string]: any } = updatedPowerLaw.power_law_skin;

        const key = `${columnId}_${rowId}`;

        powerLaw[key] = newCell.value ?? newCell.text ?? 0;
      }

      setTahkCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            ...updatedPowerLaw,
          },
        };
      });
    },
    [inputs, setTahkCsgState]
  );

  return {
    inputColumns,
    inputRows,
    onChangeCells,
    onChangePowerLawSkin,
    powerLawColumns,
    powerLawRows,
    showPowerLawTable,
  };
};

export default useTahkCsgWellInput;
