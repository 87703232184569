import React, { useEffect, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import { FormControlLabel } from "@mui/material";

import { ModuleGildroHydrateOptions } from "./model";
import { GasViscosityMethod, OilDensityMethod, SolGasMethod, WaterViscosityMethod, ZMethod } from "@/model";

import InputField from "@/components/fields/InputField";
import CustomCard from "@/components/Card";
import DropdownField from "@/components/fields/DropdownField";

interface ModuleGildroHydrateInputGridTabProps {
  options: ModuleGildroHydrateOptions | undefined;
  onChange: (options: ModuleGildroHydrateOptions | undefined) => void;
}

const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
];

const oilDensityMethodOptions = [{ key: OilDensityMethod.EOS, text: "EOS" }];

const solGasMethodOptions = [
  { key: SolGasMethod.VASQUEZ_BEGGS, text: "Vasquez-Beggs" },
  { key: SolGasMethod.STANDING, text: "Standing" },
  { key: SolGasMethod.GLASO, text: "Glaso" },
  { key: SolGasMethod.LASATER, text: "Lasater" },
  { key: SolGasMethod.PETROSKY, text: "Petrosky" },
];

const wtrViscMethodOptions = [
  { key: WaterViscosityMethod.MCCAIN, text: "McCain" },
  { key: WaterViscosityMethod.LOUISIANA_STATE_UNI, text: "Louisiana State University" },
  { key: WaterViscosityMethod.BRILL_BEGGS, text: "Brill-Beggs" },
];

const gasViscMethodOptions = [
  { key: GasViscosityMethod.GONZALES_EAKIN, text: "Gonzales-Eakin" },
  { key: GasViscosityMethod.LONDONO, text: "Londono" },
  { key: GasViscosityMethod.STANDING, text: "Standing" },
];

const OIL_PVT_DEFAULT_VALUES = { oil_api_psia: 30, oil_pb_psia: 800 };
const WATER_PVT_DEFAULT_VALUES = { water_salinity_ppm: 0 };

const ModuleGildroHydrateInputGridTab = ({ options, onChange }: ModuleGildroHydrateInputGridTabProps) => {
  const [includeOil, setIncludeOil] = useState(false);
  const [includeWater, setIncludeWater] = useState(false);

  // Set defaults values
  useEffect(() => {
    if (options) return;

    onChange({
      formation_temp_of: 180,
      gas_gravity: 0.65,
      n2_perc: 0,
      co2_perc: 0,
      h2s_perc: 0,
      z_method: ZMethod.DPR,

      oil_api_psia: 30,
      oil_pb_psia: 800,
      oil_density_method: OilDensityMethod.EOS,
      sol_gas_method: SolGasMethod.VASQUEZ_BEGGS,
      water_salinity_ppm: 0,
      water_viscosity_method: WaterViscosityMethod.MCCAIN,
      tubing_diameter_in: 2.4,
      tubing_angle_deg: 90,
      tubing_depth_ft: 8900,
      wellhead_temp_of: 90,
      num_inc: 30,
      gas_viscosity_method: GasViscosityMethod.GONZALES_EAKIN,

      cases: [],
    });
  }, [options, onChange]);

  // Reset defaults when not selected
  useEffect(() => {
    if (
      options &&
      !includeOil &&
      (options.oil_api_psia !== OIL_PVT_DEFAULT_VALUES.oil_api_psia || options.oil_pb_psia !== OIL_PVT_DEFAULT_VALUES.oil_pb_psia)
    ) {
      onChange({ ...options, ...OIL_PVT_DEFAULT_VALUES });
    }
  }, [includeOil, onChange, options]);

  useEffect(() => {
    if (options && !includeWater && options.water_salinity_ppm !== WATER_PVT_DEFAULT_VALUES.water_salinity_ppm) {
      onChange({ ...options, ...WATER_PVT_DEFAULT_VALUES });
    }
  }, [includeWater, onChange, options]);

  if (!options) {
    return null;
  }

  return (
    <div
      style={{
        padding: "1em",
        gridGap: 20,
        display: "grid",
      }}
    >
      <CustomCard>
        <h4 className="primaryColor noMarginVer">Inputs</h4>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: 20,
          }}
        >
          <div>
            <InputField
              label="Formation temperature"
              suffix="°F"
              value={options.formation_temp_of}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      formation_temp_of: Number(v),
                    })
                  : null
              }
            />
          </div>

          <div>
            <InputField
              label="Gas gravity"
              suffix="g"
              value={options.gas_gravity}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      gas_gravity: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label="N₂"
              suffix="%"
              min={0}
              max={100}
              value={options.n2_perc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      n2_perc: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label="CO₂"
              suffix="%"
              min={0}
              max={100}
              value={options.co2_perc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      co2_perc: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label="H₂S"
              suffix="%"
              min={0}
              max={100}
              value={options.h2s_perc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      h2s_perc: Number(v),
                    })
                  : null
              }
            />
          </div>

          <div>
            <DropdownField
              style={{
                marginTop: 0,
              }}
              label="Z Method"
              options={zMethodOptions}
              selectedKey={options.z_method}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      z_method: v as ZMethod,
                    })
                  : null
              }
            />
          </div>
        </div>
      </CustomCard>

      <CustomCard>
        <h4 className="primaryColro noMarginVer">Wellbore</h4>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: 20,
          }}
        >
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setIncludeOil(!!e.target.checked);
                  }}
                  checked={includeOil}
                  size="small"
                />
              }
              label="Include Oil PVT"
            />
          </div>

          <div>
            <InputField
              label="Oil API"
              suffix="psia"
              disabled={!includeOil}
              value={options.oil_api_psia}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      oil_api_psia: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label="Oil Pb"
              suffix="psia"
              disabled={!includeOil}
              value={options.oil_pb_psia}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      oil_pb_psia: Number(v),
                    })
                  : null
              }
            />
          </div>

          <div>
            <DropdownField
              style={{
                marginTop: 0,
              }}
              label="Oil density method"
              options={oilDensityMethodOptions}
              selectedKey={options.oil_density_method}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      oil_density_method: v as OilDensityMethod,
                    })
                  : null
              }
            />
            <DropdownField
              label="Sol. gas method"
              options={solGasMethodOptions}
              selectedKey={options.sol_gas_method}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      sol_gas_method: v as SolGasMethod,
                    })
                  : null
              }
            />
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: 20,
          }}
        >
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setIncludeWater(!!e.target.checked);
                  }}
                  checked={includeWater}
                  size="small"
                />
              }
              label="Include Water PVT"
            />
          </div>

          <div>
            <InputField
              label="Water salinity"
              suffix="ppm"
              disabled={!includeWater}
              value={options.water_salinity_ppm}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      water_salinity_ppm: Number(v),
                    })
                  : null
              }
            />
          </div>

          <div>
            <DropdownField
              style={{
                marginTop: 0,
              }}
              label="Water viscosity method"
              options={wtrViscMethodOptions}
              selectedKey={options.water_viscosity_method}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      water_viscosity_method: v as WaterViscosityMethod,
                    })
                  : null
              }
            />
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: 20,
          }}
        >
          <div>
            <InputField
              label="Tubing diameter"
              suffix="in"
              value={options.tubing_diameter_in}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      tubing_diameter_in: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label="Tubing angle"
              suffix="°"
              value={options.tubing_angle_deg}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      tubing_angle_deg: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label="Tubing depth"
              suffix="ft"
              value={options.tubing_depth_ft}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      tubing_depth_ft: Number(v),
                    })
                  : null
              }
            />
          </div>

          <div>
            <InputField
              label="Wellhead temperature"
              suffix="°F"
              value={options.wellhead_temp_of}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      wellhead_temp_of: Number(v),
                    })
                  : null
              }
            />
            <InputField
              type="int"
              label="Number of increments"
              value={options.num_inc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      num_inc: Number(v),
                    })
                  : null
              }
            />
          </div>

          <div>
            <DropdownField
              style={{
                marginTop: 0,
              }}
              label="Gas viscosity method"
              options={gasViscMethodOptions}
              selectedKey={options.gas_viscosity_method}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...options,
                      gas_viscosity_method: v as GasViscosityMethod,
                    })
                  : null
              }
            />
          </div>
        </div>
      </CustomCard>
    </div>
  );
};

export default ModuleGildroHydrateInputGridTab;
