import { useMemo } from "react";
import { Column, Row } from "@silevis/reactgrid";

import { CustomCell, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { PvtTableHeaderMap } from "@/models/Generic";
import { OilPvtDataTable } from "@/models/InputGeneric";
import { formatToScientific } from "@/utils/general";
import useChartInfo from "@/utils/useChartInfo";
import dictionary from "@/constants/dictionary";
import useThemeStyling from "@/utils/useThemeStyling";

type UseOilPvtProps = {
  oilPvtCalculation?: OilPvtDataTable;
};

const oilPvtHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  viscosity: { name: "μ", unit: "(cP)", sub: "o" },
  density: { name: "p", unit: "(lbm/ft³)", sub: "o" },
  form_vol_factor: { name: "B", unit: "(rb/stb)", sub: "o" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "o" },
  solution_gas_oil_ratio: { name: "R", unit: "(scf/stb)", sub: "s" },
  superficial_tension: { name: "σ", unit: "(dynes/cm)", sub: "o" },
};

const oilPvtDataTableColumns = [
  { columnId: "pressure", width: 80 },
  { columnId: "viscosity", width: 80 },
  { columnId: "density", width: 80 },
  { columnId: "form_vol_factor", width: 80 },
  { columnId: "compressibility", width: 100 },
  { columnId: "solution_gas_oil_ratio", width: 80 },
  { columnId: "superficial_tension", width: 80 },
] as Column[];

const useOilPvt = ({ oilPvtCalculation }: UseOilPvtProps) => {
  const { palette } = useThemeStyling();

  const oilPvtDataTableRows = useMemo<Row[]>(() => {
    if (!oilPvtCalculation) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(oilPvtHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: tableHeaderStyle,
        sub,
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(oilPvtHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: tableHeaderNotationStyle,
      })),
    };

    return [
      headerRow,
      unitsRow,
      ...oilPvtCalculation.density.map((_, j) => ({
        rowId: j,
        cells: Object.keys(oilPvtHeaderUnitMapping).map((key) => {
          return {
            type: "text",
            text: formatToScientific(oilPvtCalculation[key as keyof OilPvtDataTable][j]),
            style: { display: "flex", justifyContent: "center" },
            nonEditable: true,
          };
        }),
      })),
    ] as Row[];
  }, [oilPvtCalculation]);

  const oilPvtBo = useChartInfo({
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.formVolFactorLiquid,
    seriesName: dictionary.inputPage.oilBo,
    chartPlot: oilPvtCalculation ? oilPvtCalculation.pressure.map((p, j) => [p, oilPvtCalculation.form_vol_factor[j]]) : [],
    seriesColor: palette.customColor.green,
  });

  const oilPvtMu = useChartInfo({
    seriesColor: palette.customColor.green,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.viscosity,
    seriesName: dictionary.inputPage.oilMu,
    chartPlot: oilPvtCalculation ? oilPvtCalculation.pressure.map((p, j) => [p, oilPvtCalculation.viscosity[j]]) : [],
  });

  const oilPvtpo = useChartInfo({
    seriesColor: palette.customColor.green,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.density,
    seriesName: dictionary.inputPage.oilPo,
    chartPlot: oilPvtCalculation ? oilPvtCalculation.pressure.map((p, j) => [p, oilPvtCalculation.density[j]]) : [],
  });

  const oilPvtRs = useChartInfo({
    seriesColor: palette.customColor.green,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.solutionGasOilRatio,
    seriesName: dictionary.inputPage.oilRs,
    chartPlot: oilPvtCalculation ? oilPvtCalculation.pressure.map((p, j) => [p, oilPvtCalculation.solution_gas_oil_ratio[j]]) : [],
  });

  const oilPvtSigma = useChartInfo({
    seriesColor: palette.customColor.green,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.superficialTension,
    seriesName: dictionary.inputPage.oilSigma,
    chartPlot: oilPvtCalculation ? oilPvtCalculation.pressure.map((p, j) => [p, oilPvtCalculation.superficial_tension[j]]) : [],
  });

  const oilPvtCo = useChartInfo({
    seriesColor: palette.customColor.green,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.compressibility,
    seriesName: dictionary.inputPage.oilCo,
    chartPlot: oilPvtCalculation ? oilPvtCalculation.pressure.map((p, j) => [p, oilPvtCalculation.compressibility[j]]) : [],
  });

  return { oilPvtDataTableRows, oilPvtDataTableColumns, oilPvtBo, oilPvtMu, oilPvtpo, oilPvtRs, oilPvtSigma, oilPvtCo };
};

export default useOilPvt;
