import React, { useMemo } from "react";
import dictionary from "@/constants/dictionary";
import CustomCard from "@/components/Card";
import Tabs from "@/components/Tabs";

import GroupEdit from "./component/GroupEdit";
import GroupRun from "./component/GroupRun";

const ModuleRulonGroupRun = () => {
  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.rulon.groupEdit}</span>,
        key: "Group Edit",
        content: <GroupEdit />,
      },
      {
        label: <span>{dictionary.rulon.groupRun}</span>,
        key: "Group Run",
        content: <GroupRun />,
      },
    ];
  }, []);

  return (
    <CustomCard
      style={{
        margin: "1em",
      }}
    >
      <Tabs tabList={tabList} />
    </CustomCard>
  );
};

export default ModuleRulonGroupRun;
