import React from "react";
import { Outlet } from "react-router-dom";

// components
import ApiErrorMessageBar from "./components/APIErrorMessageBar";
import ProjectNav from "./components/TreeView/ProjectNav";
import ErrorAlert from "./components/Error/Alert";
import { useTreeViewState } from "./components/TreeView/hooks/TreeViewContextV2";

function Layout() {
  const { errorWarning } = useTreeViewState();

  return (
    <div style={{ minHeight: 0, display: "flex", flexDirection: "row", flexGrow: 1 }}>
      <ProjectNav />
      <div style={{ flexGrow: 1 }}>
        <ErrorAlert message={errorWarning} />
        <ApiErrorMessageBar />
        <Outlet />
      </div>
    </div>
  );
}

export default Layout;
