import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import styled from "@emotion/styled";

import InputErrorWrapper, { InputErrorWrapperProps } from "../Error/InputErrorWrapper/InputErrorWrapper";
import FieldLabel, { FieldLabelProps } from "./FieldLabel";
import { convertDateToUtcTimeZoneIsoString, parseISOToDate } from "@/utils/dateTime";

type InputFieldProps = {
  label?: string;
  tinyLabel?: string; // For small lable show on the border of inputfield and place holder
  type?: "int" | "float" | "date" | "text" | "password";
  disabled?: boolean;
  required?: boolean;
  styles?: any;
  min?: number;
  max?: number;
  debounceDelay?: number;
  value?: number | null | string;
  onChange?: (value: number | undefined | string) => void;
  customValue?: boolean;
  ariaLabel?: string;
  suffix?: string | ReactNode;
  helpUrl?: string;
  isHorizontal?: boolean;
  dataTestId?: string;
  id?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  preffix?: string | ReactNode;
  placeholder?: string;
  outerStyle?: any;
} & FieldLabelProps &
  Omit<InputErrorWrapperProps, "children">;

const StyledTextField = styled(TextField)`
  margin-bottom: 8px;

  .MuiFormControl-root .MuiTextField-root {
    margin-top: 8px;
  }

  input {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  p {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const Container = styled.div<{ isHorizontal: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isHorizontal ? "row" : "column")};
`;

const getNumValue = (rawValue: string | undefined) => {
  let numValue = Number(rawValue);
  let zeroCoerceNumValue = Number(rawValue + "0");

  if (rawValue === "") {
    return NaN;
  } else if (isNaN(numValue) && !isNaN(zeroCoerceNumValue)) {
    // Check for special cases
    return zeroCoerceNumValue;
  } else {
    return numValue;
  }
};

const InputField = ({
  ariaLabel,
  label,
  tinyLabel,
  suffix,
  type = "float",
  disabled,
  required = false,
  styles,
  min,
  max,
  debounceDelay = 1000,
  value,
  onChange,
  calloutContent,
  errors,
  keyField,
  helpUrl,
  dataTestId,
  id,
  isHorizontal = false,
  preffix,
  onKeyDown,
  placeholder,
  outerStyle,
  isV2,
}: Readonly<InputFieldProps>) => {
  const [strValue, setStrValue] = useState<string | undefined>(value ? String(value) : "");
  const [errorMessage, setErrorMessage] = useState<string | undefined>("");
  const [showPassword, setShowPassword] = useState(false);

  const ref = useRef<any>(null);

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    setStrValue((prevStrValue) => {
      if (type === "date") {
        if (value === undefined || value === null || value === "") return "";
        return parseISOToDate(String(value));
      } else if (value === undefined || value === null || value === "") {
        return "";
      } else if (type === "text" || type === "password") {
        return prevStrValue;
      } else if (isNaN(Number(value))) {
        return "";
      } else if (Number(value) !== getNumValue(prevStrValue)) {
        return String(value);
      } else {
        return prevStrValue;
      }
    });
  }, [value, setStrValue, type]);

  const validateInput = useCallback(
    (newStrValue: string) => {
      let numValue = getNumValue(newStrValue);
      let strVal = newStrValue;

      if (newStrValue === "") {
        if (required) {
          return {
            errorMessage: "Required",
          };
        } else {
          return {
            strVal: "",
            emittedValue: undefined,
          };
        }
      }
      if (type === "date") {
        return {
          strVal: newStrValue,
          emittedValue: convertDateToUtcTimeZoneIsoString(new Date(newStrValue)),
        };
      }
      if (type === "text" || type === "password") {
        return {
          strVal: newStrValue,
          emittedValue: newStrValue,
        };
      }
      if (!isNaN(numValue)) {
        if (type === "int" && !Number.isInteger(numValue)) {
          numValue = Math.trunc(numValue);
          strVal = String(numValue);
        }

        if (min !== undefined && numValue < min) {
          return {
            errorMessage: `Minimum value is ${min}.`,
          };
        } else if (max !== undefined && numValue > max) {
          return {
            errorMessage: `Maximum value is ${max}.`,
          };
        }
        return {
          strVal,
          emittedValue: numValue,
        };
      }

      return {
        errorMessage: "Input is not a valid number.",
      };
    },
    [max, min, required, type]
  );

  const onChangeWrapper = useCallback(
    (event: any) => {
      let newStrValue = event.target.value;

      setErrorMessage(undefined);

      const validated = validateInput(newStrValue);
      if (validated.errorMessage) {
        setErrorMessage(validated.errorMessage);
      }
      if (onChange) {
        if (debounceDelay) {
          if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
          }

          debounceTimerRef.current = setTimeout(() => onChange(validated.emittedValue), debounceDelay);
        } else {
          onChange(validated.emittedValue);
        }
      }

      setStrValue(validated.strVal ?? newStrValue);
    },
    [debounceDelay, onChange, validateInput]
  );

  const parsedType = useMemo(() => {
    if (type === "password") {
      return showPassword ? "text" : "password";
    }
    return type ?? "number";
  }, [showPassword, type]);

  const inputPropsObj = useMemo(() => {
    if (suffix)
      return {
        endAdornment: <InputAdornment position="end">{suffix}</InputAdornment>,
      };

    if (type === "password") {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={(event) => event.preventDefault()}
              onMouseUp={(event) => event.preventDefault()}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      };
    }

    if (preffix)
      return {
        startAdornment: <InputAdornment position="start">{preffix}</InputAdornment>,
      };
  }, [preffix, showPassword, suffix, type]);

  return (
    <InputErrorWrapper errors={errors} keyField={keyField} isV2={isV2}>
      {(errorValidation) => {
        const errMsg = errorValidation ?? errorMessage;
        return (
          <Container style={outerStyle} isHorizontal={isHorizontal}>
            <FieldLabel
              width={isHorizontal ? "40%" : "unset"}
              label={label}
              ariaLabel={ariaLabel}
              calloutContent={calloutContent}
              helpUrl={helpUrl}
            />
            <StyledTextField
              label={tinyLabel} // For small lable show on the border of inputfield and place holder
              onKeyDown={onKeyDown}
              placeholder={placeholder}
              aria-label={label}
              id={id ?? label}
              type={parsedType}
              size="small"
              value={strValue}
              required={required}
              sx={{
                marginTop: 0,
                width: isHorizontal ? "60%" : "unset",
              }}
              disabled={disabled}
              ref={ref}
              onChange={(event) => {
                onChangeWrapper(event);
              }}
              inputProps={{
                "data-testid": dataTestId ?? id ?? label,
              }}
              InputProps={inputPropsObj}
              error={!!errMsg}
              helperText={errMsg}
              style={styles}
              onWheel={(e: any) => e.target.blur()}
            />
          </Container>
        );
      }}
    </InputErrorWrapper>
  );
};

export default InputField;
