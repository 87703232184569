import { ReactNode, useMemo } from "react";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

export interface InputErrorWrapperProps {
  // keyField to match if this is the exact input that will display the error message
  keyField?: string;
  children: (errorMessage: string | undefined) => ReactNode;
  errors?: ErrorValidationDetail[];
  isV2?: boolean; //V2 is the exact match of error loc, V1 is partial match
}

// This component will wrap every input and display error message from BE
// For easier mapping, I prefer to send the error object as a whole here and maps the error message using the keyFields
// Since there won't be as many error messages, as user changes input 1 by one and fetch to API one by one too
const InputErrorWrapper = ({ children, keyField, errors, isV2 = false }: InputErrorWrapperProps) => {
  // get error message, matching key field and location array
  const errorMessage = useMemo(() => {
    // loop through the array of error
    if (!errors || !keyField) return undefined;

    let errorMsg;
    errors.forEach((error: ErrorValidationDetail) => {
      const errorLocation = error?.loc?.slice(1)?.join(".");

      const isExactMatch = errorLocation === keyField;
      const isPartialMatch = errorLocation?.includes(keyField) && keyField.split(".").length > 1;
      const isNotExtraForbidden = error.type !== "extra_forbidden";

      if ((isV2 && isExactMatch && isNotExtraForbidden) || (!isV2 && (isExactMatch || isPartialMatch) && isNotExtraForbidden)) {
        errorMsg = error.msg;
      }
    });
    return errorMsg;
  }, [keyField, errors, isV2]);

  return <>{children(errorMessage)}</>;
};

export default InputErrorWrapper;
