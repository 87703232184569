import React, { useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { AFA_WELL_NAV_WIDTH, AFA_PROJECT_NAV_WIDTH } from "./constants";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import StorageIcon from "@mui/icons-material/Storage";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import WebIcon from "@mui/icons-material/Web";
import { useTreeViewState } from "./hooks/TreeViewContextV2";
import { Container, ExpandableContainer, IconContainer, LightTooltip, SectionHeader } from "./style";
import DialogSection from "./Components/DialogSectionV2";
import DnDProjectTree from "./Components/DndProjectTree";
import DataSetList from "./Components/DataSetList/DataSetList";
import { useResizableSidebar } from "./hooks/useResizableSidebar";

const ProjectNav = () => {
  const { palette } = useTheme();
  let wellTooltipTitle;

  const {
    rootId,
    isDataSetsLoading,
    isProjectEmpty,
    setActiveDialog,
    hideProjects,
    mappedItemKeyTotal,
    onDragEnd,
    selectedItems,
    showAll,
    setShowAll,
    setHideProjects,
    dataSets,
    onSelectItem,
    selectedKeys,
    onClickPreview,
    onDragWellList,
    setWellWidth,
    setNavWidth,
    missingDataset,
  } = useTreeViewState();

  const wellMaxWidthFunc = useCallback((sectionRect: DOMRect) => {
    return window.innerWidth - sectionRect.left - window.innerWidth / 2;
  }, []);

  const projectMaxWidthFunc = useCallback((sectionRect: DOMRect) => {
    return window.innerWidth - sectionRect.left - window.innerWidth / 4;
  }, []);

  const handleWellCollapse = useCallback(() => {
    setShowAll(false);
  }, [setShowAll]);

  // well list nav
  const {
    width: wellWidth,
    onMouseDown: onMouseDownWell,
    sectionRef: wellSectionRef,
  } = useResizableSidebar({
    initialWidth: AFA_WELL_NAV_WIDTH,
    minWidth: AFA_WELL_NAV_WIDTH,
    maxWidthFunc: wellMaxWidthFunc,
    setWidthSetter: setWellWidth,
    onCollapse: handleWellCollapse,
  });

  // project nav
  const {
    width: navWidth,
    onMouseDown: onMouseDownNav,
    sectionRef: navSectionRef,
  } = useResizableSidebar({
    initialWidth: AFA_PROJECT_NAV_WIDTH,
    minWidth: AFA_PROJECT_NAV_WIDTH,
    maxWidthFunc: projectMaxWidthFunc,
    setWidthSetter: setNavWidth,
  });

  if (hideProjects) {
    wellTooltipTitle = "Show Projects";
  } else if (showAll) {
    wellTooltipTitle = "Hide Wells";
  } else {
    wellTooltipTitle = "Show Wells";
  }

  const renderEmptyProject = () => {
    if (!isProjectEmpty)
      return (
        <div className="spinner-container">
          <CircularProgress size={20} color="primary" />
        </div>
      );
    return <Typography variant="subtitle2">Start by creating new Projects and Groups</Typography>;
  };

  return (
    <Container id={rootId}>
      <div className="header-container">
        {/* Well list */}
        <div
          ref={wellSectionRef}
          className="nav-section"
          style={{
            width: showAll ? `${wellWidth}px` : "",
          }}
        >
          <SectionHeader>
            <IconContainer
              id="well-list-icon"
              onClick={(e) => {
                e.preventDefault();
                if (!hideProjects) setShowAll((prev) => !prev);
                setHideProjects(false);
              }}
              bgColor={showAll ? "white" : palette.primary.main}
            >
              <LightTooltip title={wellTooltipTitle}>
                {showAll ? <MenuOpenIcon sx={{ color: palette.primary.main }} /> : <StorageIcon sx={{ color: "white" }} />}
              </LightTooltip>
            </IconContainer>
            <ExpandableContainer showAll={showAll}>
              <span>Wells</span>
            </ExpandableContainer>
          </SectionHeader>
          <ExpandableContainer maxWidth={wellWidth} showAll={showAll}>
            {isDataSetsLoading ? (
              <div className="spinner-container">
                <CircularProgress size={20} color="primary" />
              </div>
            ) : (
              <DataSetList />
            )}
          </ExpandableContainer>
        </div>

        {/* Drag handle between well list and project nav */}
        {!hideProjects && (
          <div
            className="drag-handle"
            onMouseDown={onMouseDownWell}
            style={{
              cursor: !showAll ? "not-allowed" : "col-resize",
            }}
          ></div>
        )}

        {/* Project nav list */}
        <div
          ref={navSectionRef}
          className="nav-section"
          style={{
            width: !hideProjects ? `${navWidth}px` : "0px",
          }}
        >
          <SectionHeader>
            <IconContainer
              id="project-list-icon"
              onClick={(e) => {
                e.preventDefault();
                setShowAll(false);
                setHideProjects((prev) => !prev);
              }}
              bgColor={!hideProjects ? "white" : palette.primary.main}
            >
              {!hideProjects ? (
                <LightTooltip title="Hide All">
                  <MenuOpenIcon sx={{ color: palette.primary.main }} />
                </LightTooltip>
              ) : (
                <WebIcon sx={{ color: "white" }} />
              )}
            </IconContainer>
            <ExpandableContainer showAll={!hideProjects}>
              <span>Projects</span>
            </ExpandableContainer>
          </SectionHeader>
          <ExpandableContainer maxWidth={navWidth} showAll={!hideProjects}>
            <div className="list-container project-list">
              {selectedItems.length === 0 ? (
                <>{renderEmptyProject()}</>
              ) : (
                <DnDProjectTree
                  onClickPreview={onClickPreview}
                  onDragWellList={onDragWellList}
                  onDragEnd={onDragEnd}
                  onSelectItem={onSelectItem}
                  selectedKeys={selectedKeys}
                  setActiveDialog={setActiveDialog}
                  dataSets={dataSets}
                  projects={selectedItems}
                  mappedItemKeyTotal={mappedItemKeyTotal}
                  missingDataset={missingDataset}
                />
              )}
            </div>
          </ExpandableContainer>
        </div>

        {/* Drag handle between project nav and content */}
        {!hideProjects && <div className="drag-handle" onMouseDown={onMouseDownNav}></div>}
      </div>
      <DialogSection />
    </Container>
  );
};

export default ProjectNav;
