import React, { useCallback, useEffect, useMemo } from "react";
import { CellChange, Column, NumberCell, ReactGrid, Row } from "@silevis/reactgrid";
import _ from "lodash";

import InputField from "@/components/fields/InputField";
import CustomCard from "@/components/Card";

import BaseInputs from "./components/BaseInputs";
import HorizontalSelectInputs from "./components/HorizontalSelectInputs";
import GasOilUncertainParameterInputs from "./components/GasOilUncertainParameterInputs";

import { TankType } from "@/model";
import { ModuleTahkForecastGasOilOptions, ModuleTahkForecastUncertainParamDistribution } from "../model";
import { getLastValidDataIndex } from "../ModuleTahkForecastGasOilContent";

import { handleKeyDown } from "@/util";
import useThemeStyling from "@/utils/useThemeStyling";

const inputsCols = [
  { columnId: 0, width: 125 },
  { columnId: 1, width: 200 },
  { columnId: 2, width: 200 },
  { columnId: 3, width: 200 },
] as Column[];

interface ModuleTahkForecastGasOilInputGridTabProps {
  value: ModuleTahkForecastGasOilOptions | undefined;
  onChange: (val: ModuleTahkForecastGasOilOptions) => void;
  module: string;
  isLoading: boolean;

  handleInputNotChanged: () => void;
}

function ModuleTahkForecastGasOilInputGridTab({
  value,
  onChange,
  handleInputNotChanged,
  module,
  isLoading,
}: Readonly<ModuleTahkForecastGasOilInputGridTabProps>) {
  useEffect(() => {
    if (!value?.drainage_radius_ft) return;
    onChange({
      ...value,
      ogip_mmscf: { distribution: ModuleTahkForecastUncertainParamDistribution.fixed, value: 0 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.drainage_radius_ft]);

  // Calculate last valid data index in data table
  const lastValidDataIndex = useMemo(() => {
    return getLastValidDataIndex(value?.inputs, value?.num_days);
  }, [value]);

  const { palette } = useThemeStyling();

  // Parse data from spreadsheet to input array
  const inputsRows = useMemo(() => {
    if (!value || isLoading) return [];
    const input = _.cloneDeep(value.inputs);
    return [
      {
        rowId: "header",
        cells: [
          { type: "header", text: "Days" },
          { type: "header", text: "Flowing BH pressure (psia)" },
          { type: "header", text: "Skin" },
          {
            type: "header",
            text: "Contract rate(MMscf/d)",
          },
        ],
      },
      ...input.map((p, i) => {
        const background = i === 0 ? palette.customColor.neutralLight : i > lastValidDataIndex ? palette.primary.light : undefined;
        return {
          rowId: i,
          cells: [
            {
              type: "number",
              value: p[0] < 0 ? 0 : p[0],
              nonEditable: i === 0,
              hideZero: true,
              style: {
                background,
              },
              handleKeyDown,
            },
            {
              type: "number",
              value: p[1] < 0 ? 0 : p[1],
              hideZero: true,
              handleKeyDown,
            },
            {
              type: "number",
              value: p[2] < 0 ? null : p[2],
              hideZero: false,
              handleKeyDown,
            },
            {
              type: "number",
              value: p[3] < 0 ? 0 : p[3],
              hideZero: true,
              handleKeyDown,
            },
          ],
        };
      }),
    ] as Row[];
  }, [isLoading, lastValidDataIndex, palette.customColor.neutralLight, palette.primary.light, value]);

  const handleChanges = useCallback(
    (changes: CellChange[]) => {
      if (value) {
        const newInputs = JSON.parse(JSON.stringify(value.inputs));
        changes.forEach((change) => {
          const numberCell = change.newCell as NumberCell;
          const idx = change.rowId as number;
          const colIdx = change.columnId as number;
          if (isNaN(numberCell.value) || Number(numberCell.value) < 0) newInputs[idx][colIdx] = -1;
          else if (numberCell.value === 0) newInputs[idx][colIdx] = 0;
          else newInputs[idx][colIdx] = numberCell.value;
        });

        onChange({ ...value, inputs: newInputs });
        handleInputNotChanged();
      }
    },
    [handleInputNotChanged, onChange, value]
  );

  if (!value) {
    return <></>;
  }

  return (
    <div style={{ padding: 20, display: "grid", gridGap: 20 }}>
      <CustomCard>
        <h4 className="primaryColor noMarginVer">Inputs</h4>

        <BaseInputs value={value} onChange={onChange} module={module} />
      </CustomCard>

      {value.tank_type === TankType.Horizontal ? <HorizontalSelectInputs value={value} onChange={onChange} /> : null}

      <CustomCard>
        <h4 className="primaryColor noMarginVer">Uncertain Parameters</h4>

        <div>
          {module !== "tahk_gas" && (
            <InputField
              type="int"
              label="Number of simulations"
              suffix="simulations"
              max={200}
              styles={{ width: 250 }}
              value={value.num_simulations}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      num_simulations: Number(v),
                    })
                  : null
              }
            />
          )}

          <GasOilUncertainParameterInputs value={value} onChange={onChange} tahkMode />
        </div>
      </CustomCard>

      <CustomCard>
        <h4 className="primaryColor noMarginVer">Data</h4>

        <div>
          <InputField
            type="int"
            label="Number of days"
            suffix="days"
            styles={{
              width: 385,
            }}
            value={value.num_days}
            onChange={(v) => {
              const newInputs = [...value.inputs];
              const safeNum = Number(v ?? 0);
              if (newInputs.length > safeNum) {
                // Ensure we don't lose inputted data
                const lastValidIndex = newInputs.reduce((lastValidIndex, row, i) => {
                  if (row.some((v) => v > 0)) return i;
                  return lastValidIndex;
                }, 0);
                newInputs.splice(Math.max(safeNum, lastValidIndex + 1));
              } else if (newInputs.length < safeNum) {
                const a = Array.from(Array(safeNum - newInputs.length).keys());
                newInputs.push(...a.map(() => [0, 0, 0, 0] as [number, number, number, number]));
              }
              onChange({ ...value, inputs: newInputs, num_days: safeNum });
            }}
          />
        </div>
        <span className="primaryColor">Note: Flowing BH pressure & Contract rate should be greater than 0 </span>
        <div style={{ blockSize: 300, overflowY: "auto", marginBlockStart: 5, width: 750 }}>
          <ReactGrid
            columns={inputsCols}
            rows={inputsRows}
            enableRangeSelection
            enableColumnSelection
            enableFullWidthHeader
            stickyTopRows={1}
            onCellsChanged={handleChanges}
          />
        </div>
        <span>Tip: You can copy and paste cells from a spreadsheet here.</span>
      </CustomCard>
    </div>
  );
}

export default ModuleTahkForecastGasOilInputGridTab;
