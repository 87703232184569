import React, { useMemo } from "react";

import InputField from "@/components/fields/InputField";
import FossilyticsChart from "@/components/FossilyticsChart";
import CustomDataTable from "@/components/CustomTable";
import Tabs from "@/components/Tabs";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { LangmuirIsotherm, LangmuirIsothermDataTable } from "@/models/InputGeneric";
import dictionary from "@/constants/dictionary";
import useLangmuirIsotherm from "./hooks/useLangmuirIsotherm";
import helpLinkUrl from "@/constants/helpLinkUrl";

type InputLangmuirIsothermProps = {
  langmuirIsothermInput?: LangmuirIsotherm;
  isLoading: boolean;
  updateInputValue: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
  langmuirIsothermCalculation?: LangmuirIsothermDataTable;
};

const InputLangmuirIsotherm = ({
  isLoading,
  updateInputValue,
  validationError,
  langmuirIsothermCalculation,
  langmuirIsothermInput,
}: InputLangmuirIsothermProps) => {
  const { langmuirIsothermGasContent, langmuirIsothermCd, langmuirIsothermDataTableCols, langmuirIsothermDataTableRows } = useLangmuirIsotherm({
    langmuirIsothermCalculation,
    langmuirIsothermInput,
  });

  const tabList = useMemo(() => {
    return [
      {
        label: <span>Gas Content</span>,
        key: "Gas Content",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="langmuir_isotherm_gas_content"
              isLoading={isLoading}
              xAxes={langmuirIsothermGasContent.xAxes}
              yAxes={langmuirIsothermGasContent.yAxes}
              series={langmuirIsothermGasContent.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            c<sub>{"d"}</sub>
          </span>
        ),
        key: "Cd",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="langmuir_isotherm_cg"
              isLoading={false}
              xAxes={langmuirIsothermCd.xAxes}
              yAxes={langmuirIsothermCd.yAxes}
              series={langmuirIsothermCd.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: <span>Data Table</span>,
        key: "Langmuir Data table",
        content: (
          <div className="chartWrapper">
            <CustomDataTable rows={langmuirIsothermDataTableRows} columns={langmuirIsothermDataTableCols} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [isLoading, langmuirIsothermDataTableCols, langmuirIsothermDataTableRows, langmuirIsothermGasContent, langmuirIsothermCd]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 3fr",
        gridGap: 20,
      }}
    >
      <div>
        <InputField
          label={dictionary.inputPage.desorptionPressure}
          suffix="psia"
          debounceDelay={1000}
          value={langmuirIsothermInput?.desorption_pressure}
          onChange={(v) => updateInputValue(v, "desorption_pressure", "langmuir_isotherm")}
          disabled={isLoading}
          errors={validationError}
          keyField={"inputs.langmuir_isotherm.desorption_pressure"}
          helpUrl={helpLinkUrl.gasAutoRta.modelComponents["desorptionPressure"]}
        />

        <InputField
          label={dictionary.inputPage.vi}
          suffix="scf/ton"
          debounceDelay={1000}
          value={langmuirIsothermInput?.langmuir_volume}
          onChange={(v) => updateInputValue(v, "langmuir_volume", "langmuir_isotherm")}
          disabled={isLoading}
          errors={validationError}
          keyField={"inputs.langmuir_isotherm.langmuir_volume"}
          helpUrl={helpLinkUrl.gasAutoRta.modelComponents["VI"]}
        />

        <InputField
          label={dictionary.inputPage.pi}
          suffix="psia"
          debounceDelay={1000}
          value={langmuirIsothermInput?.langmuir_pressure}
          onChange={(v) => updateInputValue(v, "langmuir_pressure", "langmuir_isotherm")}
          disabled={isLoading}
          errors={validationError}
          keyField={"inputs.langmuir_isotherm.langmuir_pressure"}
          helpUrl={helpLinkUrl.gasAutoRta.modelComponents["PI"]}
        />

        <InputField
          label={dictionary.inputPage.rockDensity}
          suffix="g/cc"
          debounceDelay={1000}
          value={langmuirIsothermInput?.rock_density}
          onChange={(v) => updateInputValue(v, "rock_density", "langmuir_isotherm")}
          disabled={isLoading}
          errors={validationError}
          keyField={"inputs.langmuir_isotherm.rock_density"}
          helpUrl={helpLinkUrl.gasAutoRta.modelComponents["rockDensity"]}
        />
      </div>
      <Tabs tabList={tabList} centered />
    </div>
  );
};

export default InputLangmuirIsotherm;
