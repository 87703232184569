import {
  FormationCompressibility,
  GasViscosityMethod,
  GeomechanicsType,
  RockType,
  TankType,
  WaterViscosityMethod,
  ZMethod,
} from "../../../../../../../../model";

export interface ModuleKoldunMcsimMatrixShrinkage {
  shrinkage_factor_dim: number;
  max_pim: number;
  perm_modulus: number;
  poissons_ratio_dim: number;
  grain_compres_psi: number;
  axial_modulus_psi: number;
  lang_vol_strain: number;
  n_exponent: number;
}

export interface FieldMapping {
  label: string;
  suffix: string;
  min: number;
  valueKey: keyof ModuleKoldunMcsimMatrixShrinkage;
  onChangeKey: keyof ModuleKoldunMcsimMatrixShrinkage;
}

export const formationCompressibilityOptions = [
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.SANDSTONE}`,
    text: "Newman Sandstone",
    data: {
      form_compressibility_method: FormationCompressibility.NEWMAN,
      rock_type: RockType.SANDSTONE,
    },
  },
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.LIMESTONE}`,
    text: "Newman Limestone",
    data: {
      form_compressibility_method: FormationCompressibility.NEWMAN,
      rock_type: RockType.LIMESTONE,
    },
  },
  {
    key: `${FormationCompressibility.HALL}-${RockType.SANDSTONE}`,
    text: "Hall General",
    data: {
      form_compressibility_method: FormationCompressibility.HALL,
      rock_type: RockType.SANDSTONE,
    },
  },
];

export const GeomechanicsOptions = [
  { key: GeomechanicsType.Geo_None, text: "None" },
  { key: GeomechanicsType.Geo_Burgoyne, text: "Burgoyne-Shrivastava" },
  { key: GeomechanicsType.Geo_Yilmaz, text: "Yilmaz-Nur" },
  { key: GeomechanicsType.Geo_Palmer, text: "Palmer-Mansoori" },
];
export const TankTypeOptions = [
  { key: TankType.Vertical, text: "Vertical" },
  { key: TankType.Horizontal, text: "Horizontal" },
];

export const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
];

export const wtrViscMethodOptions = [
  { key: WaterViscosityMethod.MCCAIN, text: "McCain" },
  { key: WaterViscosityMethod.LOUISIANA_STATE_UNI, text: "Louisiana State University" },
  { key: WaterViscosityMethod.BRILL_BEGGS, text: "Brill-Beggs" },
];

export const gasViscMethodOptions = [
  { key: GasViscosityMethod.GONZALES_EAKIN, text: "Gonzales-Eakin" },
  { key: GasViscosityMethod.LONDONO, text: "Londono" },
  { key: GasViscosityMethod.STANDING, text: "Standing" },
];

export const koldunCsgUncertainParameterMappingField = [
  {
    name: "Water saturation",
    units: "%",
    valueKey: "water_sat_perc",
  },
  {
    name: "Porosity",
    units: "%",
    valueKey: "porosity_perc",
  },
  {
    name: "Permeability",
    units: "md",
    valueKey: "permeability_md",
  },
  {
    name: "Net pay",
    units: "ft",
    valueKey: "net_pay_ft",
  },
  {
    name: "Initial pressure",
    units: "psia",
    valueKey: "initial_pressure_psia",
  },
  {
    name: "Langmuir volume",
    units: "scf/ton",
    valueKey: "langmuir_v",
  },
  {
    name: "Langmuir pressure",
    units: "psia",
    valueKey: "langmuir_p",
  },
  {
    name: "Coal Density",
    units: "g/cc",
    valueKey: "coal_density",
  },
  {
    name: "Desorbtion pressure",
    units: "psia",
    valueKey: "desorbtion_press",
  },
];

export const koldunGasUncertainParameterMappingField = [
  {
    name: "OGIP",
    units: "MMscf",
    valueKey: "ogip_mmscf",
  },
  {
    name: "Water saturation",
    units: "%",
    valueKey: "water_sat_perc",
  },
  {
    name: "Porosity",
    units: "%",
    valueKey: "porosity_perc",
  },
  {
    name: "Permeability",
    units: "md",
    valueKey: "permeability_md",
  },
  {
    name: "Net pay",
    units: "ft",
    valueKey: "net_pay_ft",
  },
  {
    name: "Initial pressure",
    units: "psia",
    valueKey: "initial_pressure_psia",
  },
];

export const koldunCsgRelPermParameterMappingField = [
  {
    name: "krg_max_csg",
    label: "KrgMax",
    suffix: "md",
  },
  {
    name: "krw_max_csg",
    label: "KrwMax",
    suffix: "md",
  },
  {
    name: "ng",
    label: "ng",
    suffix: "Dim",
  },
  {
    name: "nw",
    label: "nw",
    suffix: "Dim",
  },
  {
    name: "connate_gas_saturation",
    label: "Connate gas saturation",
    suffix: "frac",
  },
  {
    name: "connate_water_saturation",
    label: "Connate water saturation",
    suffix: "frac",
  },
];
