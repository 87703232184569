import { autoFmbInitializeApi } from "@/constants/apiUrl";
import { fetchApi } from "@/utils/apiFetcher";

import { FluidType } from "@/models/Generic";

import { AutoFmbState } from "../state";

type InitializeFmbPayload = {
  data_set_ids: string[];
};

export const postInitializeFmb = (projectId: string, fluidType: FluidType, body: InitializeFmbPayload) => {
  return fetchApi<AutoFmbState, InitializeFmbPayload>({
    path: autoFmbInitializeApi(projectId),
    type: "post",
    body,
    queryStringParameters: {
      fluid: fluidType,
    },
  });
};
