import styled from "@emotion/styled";

export const Container = styled.div<{}>`
  margin-left: 8px;

  &:focus {
    outline: none;
  }

  .project-name {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px;

    .name-text {
      padding-left: 8px;
    }

    .project-title-item {
      color: #ff8200;
      font-weight: 600;
    }

    .radio-container {
      .MuiRadio-sizeSmall {
        padding: 5px;
      }

      span {
        visibility: hidden;
        padding: 0px;
      }

      span input[type="radio"]:checked + span {
        visibility: visible;
      }

      &:hover span {
        visibility: visible;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;

      .expanded {
        font-weight: 600;
      }
      .first-half {
        display: flex;
        align-items: center;
      }
    }
  }

  .project-name:hover {
    background-color: rgb(243, 242, 241);
  }

  .onDragBg {
    background-color: #fbdcb9;
  }
`;

export const PaddingContainer = styled.div<{ level: number }>`
  padding-left: ${(props) => {
    if (props.level === 0) return 5;
    return props.level * 20;
  }}px !important;
`;
