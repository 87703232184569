import { DimensionlessPressureArraysModel, DimensionlessRateArrays } from "@/models/gaz/autoRta";

export interface ModuleAutoRTAYaxisField {
  yAxis: string;
  defaultDisabled?: boolean;
  color: string;
}

export interface ModuleAutoRTAXaxisField {
  xAxis: string;
  defaultDisabled?: boolean;
  color: string;
}

export function DimensionlessPressureFieldName(f: keyof DimensionlessPressureArraysModel) {
  switch (f) {
    case "normalized_pressure":
      return "pD";
    case "bourdet_derivative":
      return "pD'";
    case "normalized_pressure_integral":
      return "pDi";
    case "normalized_pressure_integral_derivative":
      return "pDid";
    case "beta_derivative":
      return "pβd";
    case "chow_pressure_group":
      return "CPG";
  }
}

export interface ModuleAutoRTADataViewField {
  yAxis: string;
  defaultDisabled?: boolean;
  color: string;
}

export function DimensionlessRateFieldName(f: keyof DimensionlessRateArrays) {
  switch (f) {
    case "normalized_rate":
      return "qD";
    case "decline_rate":
      return "qDd";
    case "decline_rate_integral":
      return "qDdi";
    case "decline_rate_integral_derivative":
      return "qDdid";
  }
}
