import { autoFmbInputCalcApi } from "@/constants/apiUrl";
import { fetchApi } from "@/utils/apiFetcher";

import { FluidType } from "@/models/Generic";
import { InputGridCalculation } from "@/models/InputGeneric";

import { FmbInputState } from "../input";

type CalculateInputFmbPayload = {
  data_set_ids: string[];
  inputs: FmbInputState;
};

export const postCalculateInputFmb = (projectId: string, fluidType: FluidType, body: CalculateInputFmbPayload) => {
  return fetchApi<InputGridCalculation, CalculateInputFmbPayload>({
    path: autoFmbInputCalcApi(projectId),
    type: "post",
    body,
    queryStringParameters: {
      fluid: fluidType,
    },
  });
};
