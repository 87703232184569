import { z } from "zod";
import { FmbInputState, fmbInputStateScheme } from "./input";
import { AnalysisFmbState, analysisFmbStateScheme } from "./analysis";
import { ForecastFmbState, forecastFmbStateScheme } from "./forecast";
import { HandlebarCoordinateItem } from "../Generic";

export const autoFmbStateScheme = z.object({
  inputs: fmbInputStateScheme,
  analysis: analysisFmbStateScheme,
  forecast: forecastFmbStateScheme,
  symbols_key: z.record(z.string(), z.string()),
  units_key: z.record(z.string(), z.string()),
});

export type AutoFmbState = z.infer<typeof autoFmbStateScheme>;

// putting request type here to prevent tangled circular import
// analysis calc request type
export type AnalysisFmbRequest = {
  data_set_ids: string[];
  inputs: FmbInputState;
  analysis: AnalysisFmbState;
};

// analysis chart handlebar request
export type AnalysisFmbChartRequest = {
  handlebar: HandlebarCoordinateItem;
} & AnalysisFmbRequest;

// forecast calc request type
export type ForecastFmbRequest = {
  data_set_ids: string[];
  inputs: FmbInputState;
  analysis: AnalysisFmbState;
  forecast: ForecastFmbState;
};
