import { useEffect } from "react";

type UseArrowNavigationProps = {
  onArrowUp: () => void;
  onArrowDown: () => void;
  nodeRef: React.RefObject<HTMLElement>;
};

const useArrowNavigation = ({ onArrowUp, onArrowDown, nodeRef }: UseArrowNavigationProps) => {
  useEffect(() => {
    const currentNode = nodeRef.current;
    if (!currentNode) return;

    const handler = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown") {
        onArrowDown();
      } else if (event.key === "ArrowUp") {
        onArrowUp();
      }
    };

    currentNode.addEventListener("keydown", handler);

    return () => {
      currentNode.removeEventListener("keydown", handler);
    };
  }, [onArrowDown, onArrowUp, nodeRef]);
};

export default useArrowNavigation;
