import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";

import ModulePage from "../../ModulePage";
import { DataSet, isDataSet } from "../../../model";
import { ModuleGildroHydrateCase, ModuleGildroHydrateOptions, ModuleGildroHydrateOutput } from "./model";
import ModuleGildroHydrateInputGridTab from "./ModuleGildroHydrateInputGridTab";
import ModuleGildroHydrateDataGrid from "./ModuleGildroHydrateDataGrid";
import ModuleGildroHydrateOutputView from "./ModuleGildroHydrateOutputView";
import { useAppStore } from "@/features/app";

import useThemeStyling from "@/utils/useThemeStyling";
import CustomCard from "@/components/Card";

const ModuleGildroHydrate = () => {
  const { selectedDataSets, isLoading, postRequest } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      postRequest: state.postRequest,
    }),
    shallow
  );

  const [dataSet, setDataSet] = useState<DataSet>();
  const [options, setOptions] = useState<ModuleGildroHydrateOptions>();
  const [output, setOutput] = useState<ModuleGildroHydrateOutput>();

  const onCasesChange = useCallback(
    (cases: ModuleGildroHydrateCase[]) => setOptions((prevOptions) => (prevOptions ? { ...prevOptions, cases } : undefined)),
    []
  );

  useEffect(() => {
    setDataSet(isDataSet(selectedDataSets) ? selectedDataSets : undefined);
  }, [selectedDataSets]);

  useEffect(() => {
    setOutput(undefined);
    if (!dataSet || !options || options.cases?.length < 2) return;

    (async () => {
      try {
        const response = await postRequest(
          "/modules/gildro/hydrate/calculate",
          {
            data_set_id: dataSet.id,
          },
          options
        );
        setOutput(response);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [dataSet, options, postRequest]);

  const { palette, elevatedCard } = useThemeStyling();

  return (
    <ModulePage
      title="GILDRO: Hydrate"
      tabs={
        dataSet && [
          {
            headerText: "Input Grid",
            itemIcon: "InputField",
            disabled: isLoading,
            disableHideSidebar: true,
            content: <ModuleGildroHydrateInputGridTab options={options} onChange={setOptions} />,
          },
          {
            headerText: "Output",
            itemIcon: "LineChart",
            disabled: isLoading,
            canSaveAsImg: true,
            content: (
              <div style={{ height: "100%", gridGap: 20, display: "grid", gridTemplateColumns: "430px 1fr", padding: "1em" }}>
                <div>
                  <div style={elevatedCard}>
                    <span style={{ color: palette.primary.main }}>Case data</span>

                    <div
                      style={{
                        marginTop: "1em",
                        height: "70vh",
                        overflow: "auto",
                      }}
                    >
                      <ModuleGildroHydrateDataGrid onCasesChange={onCasesChange} output={output} />
                    </div>
                    <span>Tip: You can copy and paste cells from a spreadsheet here.</span>
                  </div>
                </div>

                <CustomCard>
                  <ModuleGildroHydrateOutputView isLoading={isLoading} dataSet={dataSet} output={output} />
                </CustomCard>
              </div>
            ),
          },
        ]
      }
    />
  );
};

export default ModuleGildroHydrate;
