import { FormationCompressibility, RockType, TankType, ZMethod, WaterViscosityMethod, GasViscosityMethod } from "../../../../../model";

export enum ModuleKoldunMcsimUncertainParamDistribution {
  fixed = "fixed",
  uniform = "uniform",
  triangular = "triangular",
  lognormal = "lognormal",
  normal = "normal",
}

export interface ModuleKoldunMcsimUncertainParam {
  distribution: ModuleKoldunMcsimUncertainParamDistribution;
}

export interface ModuleKoldunMcsimUncertainParamFixed extends ModuleKoldunMcsimUncertainParam {
  distribution: ModuleKoldunMcsimUncertainParamDistribution.fixed;
  value: number;
}

export interface ModuleKoldunMcsimUncertainParamTriangular extends ModuleKoldunMcsimUncertainParam {
  distribution: ModuleKoldunMcsimUncertainParamDistribution.triangular;
  left: number;
  mode: number;
  right: number;
}

export interface ModuleKoldunMcsimUncertainParamUniform extends ModuleKoldunMcsimUncertainParam {
  distribution: ModuleKoldunMcsimUncertainParamDistribution.uniform;
  low: number;
  high: number;
}

export interface ModuleKoldunMcsimUncertainParamLogNorm extends ModuleKoldunMcsimUncertainParam {
  distribution: ModuleKoldunMcsimUncertainParamDistribution.lognormal;
  mean: number;
  sigma: number;
}

export interface ModuleKoldunMcsimUncertainParamNorm extends ModuleKoldunMcsimUncertainParam {
  distribution: ModuleKoldunMcsimUncertainParamDistribution.normal;
  mean: number;
  std: number;
}

export type ModuleKoldunMcsimUncertainParamType =
  | ModuleKoldunMcsimUncertainParamFixed
  | ModuleKoldunMcsimUncertainParamTriangular
  | ModuleKoldunMcsimUncertainParamUniform
  | ModuleKoldunMcsimUncertainParamLogNorm
  | ModuleKoldunMcsimUncertainParamNorm;

export type ModuleKoldunMcsimGasOilOptions = {
  num_days: number;
  tank_type: TankType;

  form_temp_of: number;
  gas_gravity: number;
  n2_perc: number;
  co2_perc: number;
  h2s_perc: number;
  z_method: ZMethod;
  form_compressibility_method: FormationCompressibility;
  rock_type: RockType;

  area_acres: number;
  salinity_ppm: number;
  drainage_radius_ft?: number;
  wellbore_radius_ft: number;
  z_correlation?: string;

  num_simulations: number;
  ogip_mmscf: ModuleKoldunMcsimUncertainParamType;
  water_sat_perc: ModuleKoldunMcsimUncertainParamType;
  porosity_perc: ModuleKoldunMcsimUncertainParamType;
  permeability_md: ModuleKoldunMcsimUncertainParamType;
  net_pay_ft: ModuleKoldunMcsimUncertainParamType;
  initial_pressure_psia: ModuleKoldunMcsimUncertainParamType;
  langmuir_v: ModuleKoldunMcsimUncertainParamType;
  langmuir_p: ModuleKoldunMcsimUncertainParamType;
  coal_density: ModuleKoldunMcsimUncertainParamType;
  desorbtion_press: ModuleKoldunMcsimUncertainParamType;
  res_prop: ModuleKoldunMcsimResProp;

  water_viscosity_method: WaterViscosityMethod;
  gas_viscosity_method: GasViscosityMethod;

  type_flag: string;
  contract_rate_type_flag: string;
  // Days, Flowing BH pressures, Skins, contract_rate_mmscf_d

  inputs: [number, number, number, number][];
  temp_inputs: [number, number, number, number][];
};

export type ModuleKoldunMcsimCase = {
  ogip_mmscf: number;
  water_sat_perc: number;
  porosity_perc: number;
  permeability_md: number;
  net_pay_ft: number;
  initial_pressure_psia: number;
};

export type ModuleKoldunMcsimOutput = {
  days: number[];
  rates_mmscf_d: number[][];
  cumes_mmscf: number[][];
  cases: ModuleKoldunMcsimCase[];
  indices?: [number, number, number];
  synthetic_dist?: number[];
  krg: number[];
  krw: number[];
  sw: number[];
};

export type ModuleKoldunMcsimTahkUncertainOutput = {
  press_arr: number[];
  gcontent_arr: number[];
  gcontent_param: number;
};

export type ModuleKoldunMcsimCsgOutput = {
  days: number[];
  rates_mmscf_d: number[][];
  cumes_mmscf: number[][];
  cases: ModuleKoldunMcsimCase[];
  qw: number[][];
  diagnostic: CsgOutPutDiagnostic;
};

export type CsgOutPutDiagnostic = {
  Bg: number[];
  Bw: number[];
  Krg: number[];
  Krw: number[];
  Mug: number[];
  Pr: number[];
  Pwf: number[];
  Qg: number[];
  Qw: number[];
  Sw: number[];
  Uw: number[];
  days: number[];
  k_ki_ratio: number[];
  pim: number[];
  pseudo_Pr: number[];
  pseudo_Pwf: number[];
  qg: number[];
  qw: number[];
  z_factor: number[];
};

export type ModuleKoldunMcsimMatrixShrinkage = {
  method: string;
  perm_modulus: number;
  shrinkage_factor_dim: number;
  max_pim: number;
  poissons_ratio_dim: number;
  axial_modulus_psi: number;
  grain_compres_psi: number;
  lang_vol_strain: number;
  n_exponent: number;
};

export type ModuleKoldunMcsimResProp = {
  permeability_xaxis_md: number;
  permeability_yaxis_md: number;
  permeability_zaxis_md: number;
  well_position_xaxis_ft: number;
  well_position_yaxis_ft: number;
  well_position_zaxis_ft: number;
  res_width_xaxis_ft: number;
  res_length_yaxis_ft: number;
  res_height_zaxis_ft: number;
  horizontal_well_length_yaxis_ft: number;
};

export type ModuleKoldunMcsiCsgOutputResult = {
  days: number[];
  rates_mmscf_d: number[][];
  cumes_mmscf: number[][];
  cases: ModuleKoldunMcsimCase[];
  qw: number[][];
};

export type ModuleKoldunMcsiCsgCase = {
  ogip_mmscf?: number;
  water_sat_perc?: number;
  porosity_perc?: number;
  permeability_md?: number;
  net_pay_ft?: number;
  initial_pressure_psia?: number;
  langmuir_v?: number;
  langmuir_p?: number;
  coal_density?: number;
  desorbtion_pressure?: number;
};

export const headerUnitMapping: Record<string, { property: keyof CsgOutPutDiagnostic; name: string; unit: string }> = {
  days: { property: "days", name: "Days", unit: "" },
  Pr: { property: "Pr", name: "Pr", unit: "psia" },
  Pwf: { property: "Pwf", name: "Pwf", unit: "psia" },
  pseudo_Pr: { property: "pseudo_Pr", name: "pseudo_Pr", unit: "Dim" },
  pseudo_Pwf: { property: "pseudo_Pwf", name: "pseudo_Pwf", unit: "Dim" },
  qg: { property: "qg", name: "qg", unit: "MMscf" },
  Qg: { property: "Qg", name: "Qg", unit: "MMscf" },
  qw: { property: "qw", name: "qw", unit: "bbl" },
  Qw: { property: "Qw", name: "Qw", unit: "bbl" },
  Bw: { property: "Bw", name: "Bw", unit: "Dim" },
  Uw: { property: "Uw", name: "Uw", unit: "cP" },
  Sw: { property: "Sw", name: "Sw", unit: "fraction" },
  Krg: { property: "Krg", name: "Krg", unit: "mD" },
  Krw: { property: "Krw", name: "Krw", unit: "mD" },
  pim: { property: "pim", name: "Pim", unit: "Dim" },
  k_kl_ratio: { property: "k_ki_ratio", name: "k/ki ratio", unit: "Dim" },
  Bg: { property: "Bg", name: "Bg", unit: "Dim" },
  Mug: { property: "Mug", name: "Ug", unit: "cP" },
  z_factor: { property: "z_factor", name: "z_factor", unit: "Dim" },
};

export type CSGOGIPOutPut = {
  ogip: number;
  free_gas: number;
  adsorbed_gas: number;
  init_gas_content: number;
};
