import { useState, useMemo } from "react";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

import useThemeStyling from "@/utils/useThemeStyling";

import { AnalysisCalculation, DimensionlessPressureArraysModel, DimensionlessTimeArrayModel } from "@/models/gaz/autoRta";
import { ModuleAutoRTAYaxisField, DimensionlessPressureFieldName } from "../../model";
import { normalizedPressureXaxisOptions } from "../../constants";

type NormalizedPressureProps = {
  analysisCalculation?: AnalysisCalculation;
};

const useAutoRtaAnalysisNormalizedPressure = ({ analysisCalculation }: NormalizedPressureProps) => {
  const { palette } = useThemeStyling();
  const [selectedXaxis, setSelectedXaxis] = useState<string>("wellbore_radius_normalized_time");

  const xAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: normalizedPressureXaxisOptions.filter((opt) => opt.key === selectedXaxis)[0].text,
        type: "log",
        color: palette.customColor.black,
      },
    ],
    [palette, selectedXaxis]
  );

  const yAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: "pD",
        type: "log",
        color: palette.customColor.black,
        show: true,
        gridIndex: 0,
      },
    ],
    [palette]
  );

  const moduleDimensionlessPressureField = useMemo<{ [k in keyof DimensionlessPressureArraysModel]?: ModuleAutoRTAYaxisField }>(
    () => ({
      normalized_pressure: {
        name: "pD",
        yAxis: "pD",
        color: palette.customColor.red,
        defaultDisabled: false,
      },
      bourdet_derivative: {
        name: "pD'",
        yAxis: "pD'",
        color: palette.customColor.blueMid,
        defaultDisabled: false,
      },
      normalized_pressure_integral: {
        name: "pDi",
        yAxis: "pDi",
        color: palette.customColor.green,
        defaultDisabled: true,
      },
      normalized_pressure_integral_derivative: {
        name: "pDid",
        yAxis: "pDid",
        color: palette.customColor.orangeLighter,
        defaultDisabled: true,
      },
      beta_derivative: {
        name: "pβd",
        yAxis: "pβd",
        color: palette.customColor.yellow,
        defaultDisabled: true,
      },
      chow_pressure_group: {
        name: "CPG",
        yAxis: "CPG",
        color: palette.customColor.purple,
        defaultDisabled: true,
      },
    }),
    [palette]
  );

  const dotPressureSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleDimensionlessPressureField)
        .map((v) => v as keyof DimensionlessPressureArraysModel)
        .map((f) => ({
          name: `${DimensionlessPressureFieldName(f)}`,
          type: "scatter",
          symbolSize: 5,
          lineWidth: 4,
          color: moduleDimensionlessPressureField[f]!.color,
          data: analysisCalculation?.transient_analysis_curves_data.dimensionless_time_arrays
            ? analysisCalculation?.transient_analysis_curves_data.dimensionless_time_arrays[selectedXaxis as keyof DimensionlessTimeArrayModel].map(
                (d, i) => [d, analysisCalculation.transient_analysis_curves_data.dimensionless_pressure_arrays[f][i]]
              )
            : [],
          defaultDisabled: !analysisCalculation ? true : moduleDimensionlessPressureField[f]?.defaultDisabled,
        })),
    [moduleDimensionlessPressureField, analysisCalculation, selectedXaxis]
  );

  const linePressureSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      Object.keys(moduleDimensionlessPressureField)
        .map((v) => v as keyof DimensionlessPressureArraysModel)
        .map((f) => ({
          name: `${DimensionlessPressureFieldName(f)} Line`,
          type: "line",
          lineWidth: 2,
          symbolSize: 0.1,
          color: moduleDimensionlessPressureField[f]!.color,
          data: analysisCalculation?.transient_analysis_curves_model.dimensionless_time_arrays
            ? analysisCalculation?.transient_analysis_curves_model.dimensionless_time_arrays[selectedXaxis as keyof DimensionlessTimeArrayModel].map(
                (d, i) => [d, analysisCalculation?.transient_analysis_curves_model.dimensionless_pressure_arrays[f][i]]
              )
            : [],
          defaultDisabled: !analysisCalculation ? true : moduleDimensionlessPressureField[f]?.defaultDisabled,
        })),
    [moduleDimensionlessPressureField, analysisCalculation, selectedXaxis]
  );

  const logPessureFlowSeries = useMemo<FossilyticsChartSeries[]>(
    () => [...dotPressureSeries, ...linePressureSeries],
    [dotPressureSeries, linePressureSeries]
  );

  return {
    id: "normalized_pressure",
    xAxes,
    yAxes,
    series: logPessureFlowSeries,
    onChangeDropdownOption: (val: string) => setSelectedXaxis(val),
    selectedOption: selectedXaxis,
  };
};

export default useAutoRtaAnalysisNormalizedPressure;
