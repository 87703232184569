import { useCallback, useMemo } from "react";
import { shallow } from "zustand/shallow";

import { Button } from "@mui/material";

import { useAppStore } from "@/features/app";

import Tabs from "@/components/Tabs";
import dictionary from "@/constants/dictionary";
import { ModuleId } from "@/model";

import SpadDeclineEdit from "./SpadDeclineEdit";
import useGroupEdit from "../hooks/useGroupEdit";

const availableGroupEdit: ModuleId[] = [ModuleId.SPAD_DECLINE_GAS, ModuleId.SPAD_DECLINE_OIL];

const safeDictionaryModule: { [key: string]: string } = dictionary.moduleName;

const GroupEditItem = ({ moduleId }: { moduleId: ModuleId }) => {
  const { selectedDataSets, isLoading, project, setApiError, setIsLoading, apiError } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      project: state.project,
      setApiError: state.setApiError,
      setIsLoading: state.setIsLoading,
      apiError: state.apiError,
    }),
    shallow
  );

  const {
    isLoading: loadingState,
    groupEditState,
    setGroupEditState,
    onClickUpdate,
  } = useGroupEdit({
    moduleId,
    isLoading,
    selectedDataSets,
    setApiError,
    setIsLoading,
    apiError,
    project,
  });

  const getComponent = useCallback(() => {
    // always return spad decline edit, because its the only available item right now
    return <SpadDeclineEdit key={moduleId} loadingState={loadingState} setGroupEditState={setGroupEditState} groupEditState={groupEditState} />;
  }, [groupEditState, loadingState, setGroupEditState, moduleId]);

  return (
    <div>
      {getComponent()}
      <Button
        style={{ color: "white", marginTop: 10, marginBottom: 10, width: "100%" }}
        variant="contained"
        disabled={loadingState || isLoading}
        onClick={onClickUpdate}
      >
        {dictionary.genericButton.applyUpdates}
      </Button>
    </div>
  );
};

const GroupEdit = () => {
  const tabList = useMemo(() => {
    return availableGroupEdit.map((module: ModuleId) => {
      return {
        label: <span>{safeDictionaryModule[module]}</span>,
        key: module,
        content: <GroupEditItem moduleId={module} />,
      };
    });
  }, []);

  return (
    <div>
      <Tabs tabList={tabList} />
    </div>
  );
};

export default GroupEdit;
