import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { DataSet, isDataSet, Project } from "@/model";
import { ApiError } from "@/models/APIGeneric";
import { ChartData, mlForecastScheme, MLForecastState } from "@/models/machineLearning";
import { ProgressStatus, usePolling } from "@/utils/apiFetcher/pollRequest";

import useThemeStyling from "@/utils/useThemeStyling";
import { pollMachineLearning } from "@/constants/apiUrl";

import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";

type UseMachineLearningProps = {
  setApiError: (error?: ApiError) => void;
  apiError?: ApiError;
  selectedDataSets: DataSet | DataSet[] | undefined;
  project?: Project;
};

const useMachineLearning = ({ setApiError, project, apiError, selectedDataSets }: UseMachineLearningProps) => {
  const { palette } = useThemeStyling();
  const [loading, setLoading] = useState(false);
  const [calculation, setCalculation] = useState<MLForecastState | null>(null);
  const haveInitialize = useRef(false);
  const [progress, setProgress] = useState<ProgressStatus>();

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets];
    return selectedDataSets ?? [];
  }, [selectedDataSets]);

  const { createPoll } = usePolling({
    setApiError,
    setLoadingState: setLoading,
    apiError,
    setProgressStatus: (val) => {
      setProgress(val);
    },
  });

  const getColor = useCallback(
    (name: string) => {
      const lowercaseName = name.toLocaleLowerCase();
      if (lowercaseName.includes("oil")) return palette.customColor.green;
      if (lowercaseName.includes("water")) return palette.customColor.blue;
      if (lowercaseName.includes("pressure")) return palette.common.black;
      if (lowercaseName.includes("gas")) return palette.customColor.red;

      if (dataSets[0].type === "gas") return palette.customColor.red;
      return palette.customColor.green;
    },
    [dataSets, palette.common.black, palette.customColor.blue, palette.customColor.green, palette.customColor.red]
  );

  const calculateMachineLearning = useCallback(async () => {
    if (project?.id && dataSets.length > 0) {
      try {
        haveInitialize.current = true;
        setProgress(undefined);
        const calculation = await createPoll<MLForecastState>({
          path: pollMachineLearning(project?.id),
          type: "post",
          body: { data_set_ids: dataSets.map((data) => data.id) },
        });

        if (calculation.task_result) {
          const parsed = mlForecastScheme.parse(calculation.task_result);
          setCalculation(parsed);
        }
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }, [createPoll, dataSets, project?.id]);

  useEffect(() => {
    if (!calculation && !haveInitialize.current) calculateMachineLearning();
  }, [calculateMachineLearning, calculation]);

  const yAxes = useMemo<FossilyticsChartAxis[]>(() => {
    // group yaxis by unit
    let result: FossilyticsChartAxis[] = [];
    if (!calculation?.analysed_data.series || !calculation?.forecast_result.series) return result;

    [...calculation.analysed_data.series, ...calculation.forecast_result.series].forEach((serie) => {
      // only take unique unit
      const sameUnitAxisIndex = result.findIndex((axis) => axis.name === serie.unit);
      if (sameUnitAxisIndex === -1) {
        const position = Number(result.length) > 1 ? "right" : "left";
        const currentSerieUnit = calculation?.analysed_data.series.filter((item) => item.label === serie.label)[0];

        let axis = result.length - 1;
        if (axis < 0) axis = 0;
        result.push({
          name: serie.unit ?? "",
          type: "value",
          color: getColor(currentSerieUnit.label),
          position,
          nameGap: 20,
          offset: 0,
          axis,
        });
      }
    });

    const totalPosLeft = result.filter((res) => res.position === "left").length;
    const totalPosRight = result.length - totalPosLeft;
    if (totalPosLeft > 1) {
      result[result.findIndex((res) => res.position === "left")].offset = 85;
    }
    if (totalPosRight > 1) {
      // @ts-ignore
      result[result?.findLastIndex((res: FossilyticsChartAxis) => res.position === "right")].offset = 85;
    }
    return result;
  }, [calculation?.analysed_data.series, calculation?.forecast_result.series, getColor]);

  const xAxes = useMemo<FossilyticsChartAxis[]>(() => {
    if (!calculation) return [];
    return [{ name: dictionary.genericChart.date, type: "time", color: palette.customColor.black }];
  }, [calculation, palette.customColor.black]);

  const convertSerieAxis = useCallback(
    (type: "scatter" | "line", chartData?: ChartData) => {
      const result: FossilyticsChartSeries[] = [];

      chartData?.series.forEach((dataSerie) => {
        const defaultUnit = dataSerie.unit ?? "";
        const chartAxis = yAxes.findIndex((axis) => axis.name === defaultUnit);
        if (chartAxis !== -1) {
          const unitLabel = defaultUnit ? `(${defaultUnit})` : "";
          result.push({
            name: `${dataSerie.label} ${unitLabel}`,
            type,
            color: getColor(dataSerie.label),
            z: 0,
            data: chartData?.dates.map((date, index) => [new Date(date), dataSerie.data[index]]),
            yAxisIndex: chartAxis,
          });
        }
      });

      return result;
    },
    [getColor, yAxes]
  );

  const series = useMemo<FossilyticsChartSeries[]>(() => {
    return [...convertSerieAxis("scatter", calculation?.analysed_data), ...convertSerieAxis("line", calculation?.forecast_result)];
  }, [convertSerieAxis, calculation?.analysed_data, calculation?.forecast_result]);

  return {
    loading,
    xAxes,
    yAxes,
    series,
    progress,
  };
};

export default useMachineLearning;
