import { DefaultTheme } from "@mui/system";
import { PaletteMode } from "@mui/material";

export type UnitSelection = {
  [moduleType: string]: {
    [unitItem: string]: string;
  };
};

export type UserPreferenceState = {
  unitPreference: UnitSelection;
  setUnitPreference: React.Dispatch<React.SetStateAction<UnitSelection>>;
  language: Language.English;
  setLanguage: React.Dispatch<React.SetStateAction<Language>>;
  theme: DefaultTheme;
  setMode: React.Dispatch<React.SetStateAction<PaletteMode>>;
  mode: PaletteMode;
};

export type UserPreferenceProviderProps = {
  children: React.ReactNode;
};

export enum Language {
  English = "English",
}

export enum ThemeMode {
  light = "light",
  dark = "dark",
}
