import { useCallback } from "react";

import InputField from "@/components/fields/InputField";
import CustomCard from "@/components/Card";

import { ModuleTahkForecastGasOilOptions } from "../../model";

interface HorizontalSelectInputsProps {
  value: ModuleTahkForecastGasOilOptions;
  onChange: (val: ModuleTahkForecastGasOilOptions) => void;
}

const HorizontalSelectInputs = ({ value, onChange }: HorizontalSelectInputsProps) => {
  const handleChange = useCallback(
    (input: { [key: string]: any }) => {
      if (!value) return;
      onChange({
        ...value,
        res_prop: {
          ...value.res_prop,
          ...input,
        },
      });
    },
    [onChange, value]
  );

  return (
    <CustomCard>
      <h4 className="primaryColor noMarginVer">Areas and Dimensions</h4>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "250px 250px",
          gridGap: 20,
        }}
      >
        <div>
          <InputField
            label="Permeability(X-axis)"
            suffix="md"
            value={value.res_prop.permeability_xaxis_md}
            onChange={(v) => (v !== undefined ? handleChange({ permeability_xaxis_md: v }) : null)}
          />
          <InputField
            label="Permeability(Y-axis)"
            suffix="md"
            value={value.res_prop.permeability_yaxis_md}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    permeability_yaxis_md: v,
                  })
                : null
            }
          />
          <InputField
            label="Permeability(Z-axis)"
            suffix="md"
            value={value.res_prop.permeability_zaxis_md}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    permeability_zaxis_md: v,
                  })
                : null
            }
          />
          <InputField
            label="Horizontal well center position (X-axis)"
            suffix="ft"
            value={value.res_prop.well_position_xaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    well_position_xaxis_ft: v,
                  })
                : null
            }
          />
          <InputField
            label="Horizontal well center position (Y-axis)"
            suffix="ft"
            value={value.res_prop.well_position_yaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    well_position_yaxis_ft: v,
                  })
                : null
            }
          />
          <InputField
            label="Horizontal well center position (Z-axis)"
            suffix="ft"
            value={value.res_prop.well_position_zaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    well_position_zaxis_ft: v,
                  })
                : null
            }
          />
        </div>
        <div>
          <InputField
            label="Reservoir Width (X-axis)"
            suffix="ft"
            value={value.res_prop.res_width_xaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    res_width_xaxis_ft: v,
                  })
                : null
            }
          />
          <InputField
            label="Reservoir Length (Y-axis)"
            suffix="ft"
            value={value.res_prop.res_length_yaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    res_length_yaxis_ft: v,
                  })
                : null
            }
          />
          <InputField
            label="Net Pay"
            suffix="ft"
            value={value.res_prop.res_height_zaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    res_height_zaxis_ft: v,
                  })
                : null
            }
          />
          <InputField
            label="Horizontal Section Length (Y-axis)"
            suffix="ft"
            value={value.res_prop.horizontal_well_length_yaxis_ft}
            onChange={(v) =>
              v !== undefined
                ? handleChange({
                    horizontal_well_length_yaxis_ft: v,
                  })
                : null
            }
          />
        </div>
      </div>
    </CustomCard>
  );
};

export default HorizontalSelectInputs;
