import React, { useState, ReactNode, CSSProperties, useEffect, useRef } from "react";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import styled from "@emotion/styled";

const StyledTab = styled(Tab)`
  text-transform: none;
`;

const StyledTabContainer = styled(Tabs)`
  .Mui-selected {
    color: black !important;
    font-weight: 600;
  }
  .MuiTabs-indicator {
    background-color: rgb(242, 143, 29);
  }
  button {
    min-width: ${(props) => (props.centered ? "unset" : "90px")};
  }
`;

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export enum IconPosition {
  bottom = "bottom",
  top = "top",
  start = "start",
  end = "end",
}

type TabItemProps = {
  label: ReactNode;
  content: ReactNode;
  key: string;
  icon?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  iconPosition?: IconPosition;
  disabled?: boolean;
};

type CustomTabsProps = {
  tabList: TabItemProps[];
  centered?: boolean;
  onClickItem?: (tabIndex: number) => void;
  id?: string;
  customActiveTab?: number;
  style?: CSSProperties;
  headerText?: string;
  // this is to mimic old behaviour where all tabs are prerendered
  alwaysRender?: boolean;
  tabContainerStyle?: CSSProperties;
};

const CustomTabs = ({
  tabList,
  centered = false,
  onClickItem,
  id,
  customActiveTab,
  style,
  headerText,
  alwaysRender,
  tabContainerStyle,
}: CustomTabsProps) => {
  const [activeTab, setActiveTab] = useState(customActiveTab ?? 0);
  const haveInitialize = useRef(false);

  useEffect(() => {
    // this is to make sure if we read from query, will have time to initialize outer state form url
    if (!isNaN(Number(customActiveTab)) && customActiveTab !== activeTab && !haveInitialize.current && Number(customActiveTab) > -1) {
      setActiveTab(Number(customActiveTab));
      haveInitialize.current = true;
    }
  }, [activeTab, customActiveTab]);

  if (tabList.length === 0) return <></>;

  return (
    <StyledContainer style={style}>
      <div style={{ position: "relative" }}>
        <StyledTabContainer
          centered={centered}
          value={activeTab}
          onChange={(_, indexTab) => {
            setActiveTab(indexTab);
            onClickItem?.(indexTab);
          }}
          aria-label={id}
          id={id}
        >
          {tabList.map((tab, indexTab) => {
            return (
              <StyledTab
                data-testid={`${tab.key}-tab-label`}
                icon={tab.icon}
                iconPosition={tab.iconPosition}
                className={tab.key}
                key={tab.key}
                label={tab.label}
                value={indexTab}
              />
            );
          })}
          {headerText && (
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  fontSize: 24,
                  display: "inline-block",
                  textOverflow: "ellipsis",
                  maxWidth: "50vw",
                  overflow: "hidden",
                }}
                className="noMarginVer"
              >
                {headerText}
              </h1>
            </div>
          )}
        </StyledTabContainer>
      </div>

      {alwaysRender ? (
        tabList.map((tab, index) => {
          return (
            <div role="tabpanel" key={tab.key} style={{ flex: 1, display: index === activeTab ? "unset" : "none", height: "100%", overflow: "auto" }}>
              {tab?.content}
            </div>
          );
        })
      ) : (
        <div style={{ flex: 1, height: "100%", overflow: "auto", ...tabContainerStyle }}>{tabList[activeTab]?.content}</div>
      )}
    </StyledContainer>
  );
};

export default CustomTabs;
