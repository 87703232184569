import { useMemo } from "react";
import LoadingIndicator from "@/components/LoadingIndicator";

import { DistributionTypeEnum, InputType } from "@/models/koldunV2";

import { useKoldunCsgState } from "../../../hooks/KoldunCsgContext";
import StandardizedMonteCarloInputField from "./StandardizedMonteCarloInputField";
import dictionary from "@/constants/dictionary";

const InputList = () => {
  const { selectedLayer, state, setKoldunCsgState, validationError, setValidationError } = useKoldunCsgState();

  const type = useMemo(() => {
    return state?.inputs?.specify_inputs ?? InputType.Volumetric;
  }, [state?.inputs?.specify_inputs]);

  const measures = useMemo(() => {
    return state?.inputs.measures ?? [];
  }, [state]);

  const modelType = useMemo(() => {
    if (type === InputType.Volumetric) return "MeasureInputsVolumetricValidate";
    return "MeasureInputsOgipValidate";
  }, [type]);

  return (
    <div>
      {measures.length === 0 ? (
        <LoadingIndicator />
      ) : (
        <>
          <StandardizedMonteCarloInputField
            setKoldunCsgState={setKoldunCsgState}
            currentItemKey={"permeability_horizontal_distribution"}
            measures={measures}
            selectedLayer={selectedLayer}
            errorValidation={validationError}
            modelType={modelType}
            setValidationError={setValidationError}
          />
          <StandardizedMonteCarloInputField
            setKoldunCsgState={setKoldunCsgState}
            currentItemKey={"net_pay_distribution"}
            measures={measures}
            customOptions={[
              { key: DistributionTypeEnum.Fixed, text: dictionary.koldunCsg.fixed },
              { key: DistributionTypeEnum.Lorenz, text: dictionary.koldunCsg.lorenz },
            ]}
            selectedLayer={selectedLayer}
            errorValidation={validationError}
            modelType={modelType}
            setValidationError={setValidationError}
          />
          <StandardizedMonteCarloInputField
            setKoldunCsgState={setKoldunCsgState}
            currentItemKey={"initial_water_saturation_distribution"}
            measures={measures}
            selectedLayer={selectedLayer}
            errorValidation={validationError}
            modelType={modelType}
            setValidationError={setValidationError}
          />
          <StandardizedMonteCarloInputField
            setKoldunCsgState={setKoldunCsgState}
            currentItemKey={"initial_pressure_distribution"}
            measures={measures}
            selectedLayer={selectedLayer}
            errorValidation={validationError}
            modelType={modelType}
            setValidationError={setValidationError}
          />
          <StandardizedMonteCarloInputField
            selectedLayer={selectedLayer}
            setKoldunCsgState={setKoldunCsgState}
            currentItemKey={"porosity_distribution"}
            measures={measures}
            errorValidation={validationError}
            modelType={modelType}
            setValidationError={setValidationError}
          />
          <StandardizedMonteCarloInputField
            setKoldunCsgState={setKoldunCsgState}
            currentItemKey={"langmuir_pressure_distribution"}
            measures={measures}
            selectedLayer={selectedLayer}
            errorValidation={validationError}
            modelType={modelType}
            setValidationError={setValidationError}
          />
          {type === InputType.Volumetric ? (
            <>
              <StandardizedMonteCarloInputField
                selectedLayer={selectedLayer}
                setKoldunCsgState={setKoldunCsgState}
                currentItemKey={"langmuir_volume_distribution"}
                measures={measures}
                errorValidation={validationError}
                modelType={modelType}
                setValidationError={setValidationError}
              />
              <StandardizedMonteCarloInputField
                selectedLayer={selectedLayer}
                setKoldunCsgState={setKoldunCsgState}
                currentItemKey={"desorption_pressure_distribution"}
                measures={measures}
                errorValidation={validationError}
                modelType={modelType}
                setValidationError={setValidationError}
              />
              <StandardizedMonteCarloInputField
                selectedLayer={selectedLayer}
                setKoldunCsgState={setKoldunCsgState}
                currentItemKey={"rock_density_distribution"}
                measures={measures}
                errorValidation={validationError}
                modelType={modelType}
                setValidationError={setValidationError}
              />
            </>
          ) : (
            <>
              <StandardizedMonteCarloInputField
                selectedLayer={selectedLayer}
                setKoldunCsgState={setKoldunCsgState}
                currentItemKey={"ogip_distribution"}
                measures={measures}
                errorValidation={validationError}
                modelType={modelType}
                setValidationError={setValidationError}
              />
              <StandardizedMonteCarloInputField
                selectedLayer={selectedLayer}
                setKoldunCsgState={setKoldunCsgState}
                currentItemKey={"gas_content_saturation_distribution"}
                measures={measures}
                errorValidation={validationError}
                modelType={modelType}
                setValidationError={setValidationError}
              />
              <StandardizedMonteCarloInputField
                selectedLayer={selectedLayer}
                setKoldunCsgState={setKoldunCsgState}
                currentItemKey={"initial_gas_content_distribution"}
                measures={measures}
                errorValidation={validationError}
                modelType={modelType}
                setValidationError={setValidationError}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default InputList;
