import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";

import { dataviewInitializeScheme, DataviewInitializeState, postCalculateDataview, postInitializeDataview } from "@/models/dataview";
import { DataSet, isDataSet, ModuleId, Project } from "@/model";
import { chartSerieScheme } from "@/models/Generic";
import { ApiError } from "@/models/APIGeneric";

import { parseErrorThrown } from "@/utils/errorHandling";
import useThemeStyling from "@/utils/useThemeStyling";

import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";

type UseDataviewProps = {
  selectedDataSets?: DataSet | DataSet[];
  project?: Project;
  currentModule?: ModuleId;

  setApiError: (err?: ApiError) => void;
  apiError?: ApiError;
};

const useDataview = ({ selectedDataSets, project, currentModule, apiError, setApiError }: UseDataviewProps) => {
  const [stateOption, setStateOption] = useState<DataviewInitializeState>();
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);

  const latestDataSets = useRef<string[]>([]);

  const { palette } = useThemeStyling();

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets];
    return selectedDataSets ?? [];
  }, [selectedDataSets]);

  const dataSetIds = useMemo(() => dataSets.map((dataSet) => dataSet.id), [dataSets]);

  const { isLoading: isLoadingInitialize, isFetching: isFetchingInitialize } = useQuery({
    queryKey: ["initialize-dataview", project?.id, dataSetIds],
    queryFn: async () => {
      return postInitializeDataview(project?.id ?? "", currentModule ?? "", { data_set_ids: dataSetIds });
    },
    select(data) {
      try {
        if (data?.data && !stateOption) {
          const parsed = dataviewInitializeScheme.parse(data.data);
          setStateOption(parsed);
          latestDataSets.current = dataSetIds;
        }
        return data?.data;
      } catch (error: any) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!project?.id && !!currentModule,
  });

  const {
    isLoading: isLoadingCalculate,
    isFetching: isFetchingCalculate,
    data,
  } = useQuery({
    queryKey: ["calculate-dataview", project?.id, dataSetIds, currentModule, stateOption?.data_option],
    queryFn: async () => {
      const payload = {
        chart_option: stateOption!.chart_option,
        data_option: stateOption!.data_option,
        data_set_ids: dataSetIds,
      };

      return postCalculateDataview(project?.id ?? "", currentModule ?? "", payload);
    },
    select(res) {
      try {
        if (res?.data) {
          const parsed = chartSerieScheme.parse(res.data);
          return parsed;
        }
      } catch (error: any) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!project?.id && !!stateOption && !!currentModule,
  });

  const client = useQueryClient();

  useEffect(() => {
    if (latestDataSets.current.length > 0 && _.isEqual(latestDataSets.current, dataSets)) {
      setStateOption(undefined);
      client?.invalidateQueries();
    }
  }, [client, dataSets, latestDataSets]);

  const loadingState = isLoadingInitialize || isFetchingInitialize || isFetchingCalculate || isLoadingCalculate;

  const dataOptionColumn = useMemo(() => {
    return [
      { columnId: "axis", width: 150 },
      { columnId: "data", width: 180 },
    ];
  }, []);

  const dataOptionRows: Row<any>[] = useMemo(() => {
    const header = [
      {
        rowId: "header",
        cells: [
          {
            type: "custom",
            text: dictionary.dataView.axis,
            style: tableHeaderStyle,
          },
          {
            type: "custom",
            text: dictionary.dataView.data,
            style: tableHeaderStyle,
          },
        ],
        height: 50,
      },
    ];
    if (!data || !stateOption) return header;

    const chartRows = stateOption?.chart_option.map((option, index) => {
      return {
        rowId: index,
        height: 30,
        cells: [
          {
            type: "dropdown",
            selectedValue: String(option.axis),
            values: Array.from(Array(4).keys()).map((index) => ({
              label: String(index + 1),
              value: String(index + 1),
            })),
            style: tableCellStyle,
            isOpen: !!(activeDropdown && index + 1 === activeDropdown),
            nonEditable: loadingState,
          },
          {
            type: "text",
            text: option.label,
            nonEditable: true,
            style: tableCellStyle,
          },
        ],
      };
    });

    return [...header, ...chartRows];
  }, [activeDropdown, data, loadingState, stateOption]);

  const onCellsChanged = useCallback(
    (changes: CellChange[]) => {
      if (!data || !stateOption?.chart_option) return;
      const updatedDataOption: any = [...stateOption.chart_option];
      let haveChanged = false;
      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;
        if (prevCell.isOpen !== dropDownNewCell.isOpen || rowId !== Number(activeDropdown) + 1) {
          if (dropDownNewCell.isOpen) {
            setActiveDropdown(rowId + 1);
          } else {
            setActiveDropdown(null);
          }
        }
        if (dropDownNewCell.isOpen) return;
        if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
          updatedDataOption[rowId].axis = dropDownNewCell.selectedValue;
          haveChanged = true;
        }
      }
      if (haveChanged) {
        setStateOption((prev) => {
          if (!prev?.chart_option) return prev;
          return {
            ...prev,
            chart_option: updatedDataOption,
          };
        });
      }
    },
    [activeDropdown, data, stateOption?.chart_option]
  );

  const getColor = useCallback(
    (name: string) => {
      const lowercaseName = name.toLocaleLowerCase();
      if (lowercaseName.includes("oil")) return palette.customColor.green;
      if (lowercaseName.includes("water")) return palette.customColor.blue;
      if (lowercaseName.includes("pressure")) return palette.common.black;
      return palette.customColor.red;
    },
    [palette.common.black, palette.customColor.blue, palette.customColor.green, palette.customColor.red]
  );

  const yAxes = useMemo<FossilyticsChartAxis[]>(() => {
    let result: FossilyticsChartAxis[] = [];

    _.cloneDeep(stateOption?.chart_option ?? [])
      .sort((a, b) => Number(a.axis) - Number(b.axis))
      .forEach((item) => {
        const currentSerieUnit = data?.series.filter((serie) => serie.label === item.label)[0];

        if (currentSerieUnit) {
          const position = Number(item.axis) > 2 ? "right" : "left";

          if (result[item.axis - 1]) {
            const prevName = result[item.axis - 1].name.split(", ");
            result[item.axis - 1] = {
              ...result[item.axis - 1],
              name: prevName[prevName.length - 1] === currentSerieUnit.unit ? currentSerieUnit.unit : "",
              color: getColor(currentSerieUnit.label),
            };
          } else {
            result[item.axis - 1] = {
              name: currentSerieUnit.unit ?? "",
              type: "value",
              color: getColor(currentSerieUnit.label),
              position,
              nameGap: 30,
              offset: 0,
              axis: item.axis ?? 0,
              data: item.axis,
            };
          }
        }
      });
    result = result.filter((res) => !!res);

    const totalPosLeft = result.filter((res) => res.position === "left").length;
    const totalPosRight = result.length - totalPosLeft;

    if (totalPosLeft > 1) {
      result[result.findIndex((res) => res.position === "left")].offset = 75;
    }
    if (totalPosRight > 1) {
      // @ts-ignore
      result[result?.findLastIndex((res: FossilyticsChartAxis) => res.position === "right")].offset = 75;
    }
    return result;
  }, [data?.series, getColor, stateOption?.chart_option]);

  const xAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Date`, type: "time", color: palette.customColor.black }],
    [palette.customColor.black]
  );

  const series = useMemo<FossilyticsChartSeries[]>(() => {
    if (!data || yAxes.length === 0 || !stateOption?.chart_option) return [];
    return data?.series.map((serie) => {
      const chartAxis = stateOption.chart_option.find((option) => option.label === serie.label);
      const axis = yAxes.findIndex((axes) => axes.data === chartAxis?.axis);

      return {
        name: serie.label,
        type: "line",
        color: getColor(serie.label),
        z: 0,
        data: data.dates.map((date, index) => [new Date(date), serie.data[index]]),
        yAxisIndex: axis < 0 ? 0 : axis,
      };
    });
  }, [data, getColor, stateOption?.chart_option, yAxes]);

  const dataColumns = useMemo(() => {
    if (!data?.series) return [];
    return [
      { columnId: "date", width: 200 },
      ...data.series.map((item) => {
        return {
          columnId: item.label,
          width: 200,
        };
      }),
    ];
  }, [data]);

  const dataRows: Row<any>[] = useMemo(() => {
    if (!data?.series) return [];

    return [
      {
        rowId: "header",
        cells: [
          {
            type: "text",
            text: `${dictionary.dataView.date}`,
            style: tableHeaderStyle,
            nonEditable: true,
          },
          ...data.series.map((item) => {
            return {
              type: "text",
              text: `${item.label} (${item.unit})`,
              style: tableHeaderStyle,
            };
          }),
        ],
        height: 50,
      },
      ...data.dates.map((date, index) => {
        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "text",
              text: `${new Date(date).toLocaleDateString()}`,
              style: tableCellStyle,
            },
            ...data.series.map((item) => {
              return {
                type: "text",
                text: `${item.data[index]}`,
                style: tableCellStyle,
              };
            }),
          ],
        };
      }),
    ];
  }, [data]);

  return {
    loadingState,
    stateOption,
    setStateOption,
    dataOptionColumn,
    dataOptionRows,
    onCellsChanged,
    yAxes,
    xAxes,
    series,
    dataRows,
    dataColumns,
  };
};

export default useDataview;
