import React, { ReactNode, useCallback, useMemo } from "react";

import { useTheme } from "@mui/material/styles";
import useThemeStyling from "@/utils/useThemeStyling";

import dictionary from "@/constants/dictionary";
import Tabs from "@/components/Tabs";

import { TahkCsgInputLayerCharts, TahkCsgInputState, TahkCsgStateResponse } from "@/models/tahk";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";

// components
import GasPvt from "./GasPvt";
import LoadingIndicator from "@/components/LoadingIndicator";
import WaterPvt from "./WaterPvt";
import RelativePermeability from "./RelativePermeability";
import WellInputs from "./WellInput";
import WellboreInput from "./WellboreInput";
import LangmuirIsotherm from "./LangmuirIsotherm";
import Geomechanics from "./Geomechanics";

type ModelComponentsProps = {
  inputs?: TahkCsgInputState;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  loadingState: boolean;
  layers?: TahkCsgInputLayerCharts[];
  errorInputValidation: ErrorValidationDetail[];
  tabIndex: number;
};

const ModelComponents = ({ inputs, setTahkCsgState, loadingState, layers, errorInputValidation, tabIndex }: ModelComponentsProps) => {
  const { palette } = useTheme();
  const { elevatedCard } = useThemeStyling();

  const renderLoadingIndicator = useCallback(
    (component: ReactNode) => {
      if (loadingState || !layers || tabIndex !== 1) {
        return (
          <div className="center-flex h100">
            <LoadingIndicator />
          </div>
        );
      }
      return component;
    },
    [layers, loadingState, tabIndex]
  );
  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.tahk.gasPvt}</span>,
        key: dictionary.tahk.gasPvt,
        content: renderLoadingIndicator(
          <GasPvt layers={layers ?? []} loadingState={loadingState} inputs={inputs} setTahkCsgState={setTahkCsgState} />
        ),
      },
      {
        label: <span>{dictionary.tahk.waterPvt}</span>,
        key: dictionary.tahk.waterPvt,
        content: renderLoadingIndicator(
          <WaterPvt layers={layers ?? []} loadingState={loadingState} inputs={inputs} setTahkCsgState={setTahkCsgState} />
        ),
      },
      {
        label: <span>{dictionary.tahk.relativePermeability}</span>,
        key: dictionary.tahk.relativePermeability,
        content: renderLoadingIndicator(
          <RelativePermeability layers={layers ?? []} loadingState={loadingState} inputs={inputs} setTahkCsgState={setTahkCsgState} />
        ),
      },
      {
        label: <span>{dictionary.tahk.langmuirIsotherm}</span>,
        key: dictionary.tahk.langmuirIsotherm,
        content: renderLoadingIndicator(
          <LangmuirIsotherm layers={layers ?? []} loadingState={loadingState} inputs={inputs} setTahkCsgState={setTahkCsgState} />
        ),
      },
      {
        label: <span>{dictionary.tahk.wellInputs}</span>,
        key: dictionary.tahk.wellInputs,
        content: renderLoadingIndicator(
          <WellInputs layers={layers ?? []} loadingState={loadingState} inputs={inputs} setTahkCsgState={setTahkCsgState} />
        ),
      },
      {
        label: <span>{dictionary.tahk.wellboreModel}</span>,
        key: dictionary.tahk.wellboreModel,
        content: renderLoadingIndicator(
          <WellboreInput
            errorInputValidation={errorInputValidation}
            layers={layers ?? []}
            loadingState={loadingState}
            inputs={inputs}
            setTahkCsgState={setTahkCsgState}
          />
        ),
      },
      {
        label: <span>{dictionary.tahk.geomechanic}</span>,
        key: dictionary.tahk.geomechanic,
        content: renderLoadingIndicator(<Geomechanics loadingState={loadingState} inputs={inputs} setTahkCsgState={setTahkCsgState} />),
      },
    ];
  }, [renderLoadingIndicator, layers, loadingState, inputs, setTahkCsgState, errorInputValidation]);

  return (
    <div style={{ ...elevatedCard, marginBottom: 20 }}>
      <h4
        style={{
          color: palette.primary.main,
          marginBottom: 10,
        }}
      >
        {dictionary.tahk.modelComponentsInputs}
      </h4>
      <Tabs tabList={tabList} />
    </div>
  );
};

export default ModelComponents;
