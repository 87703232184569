import { useMemo, useState } from "react";
import { Row } from "@silevis/reactgrid";

import {
  AnalysisFmbDataTable,
  AnalysisFmbResult,
  AutoFmbState,
  CaseResult,
  FmbChartAnalysis,
  FmbPiChartAnalysis,
  FmbPzChartAnalysis,
  ManualPzDataTable,
} from "@/models/autoFmb";
import useThemeStyling from "@/utils/useThemeStyling";

import { FossilyticsChartLine, FossilyticsChartLineConstraint, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";
import { caseKey } from "../constants";
import { capitalizeFirstLetter, formatToScientific } from "@/utils/general";
import { tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import symbolUnits from "@/constants/symbolUnit";
import { transformHeaderOrNotation } from "@/utils/cellRendering";

type UseAutoFmbAnalysisChartProps = {
  analysisResult?: AnalysisFmbResult;
  autoFmbState?: AutoFmbState;
};

export const pointManualDataTableColumn = [
  {
    columnId: "cumulative_production",
    width: 150,
    label: dictionary.fmb.cumulativeProduction,
    notation: dictionary.suffix.cumulativeProduction,
  },
  {
    columnId: "static_pressure",
    width: 150,
    label: dictionary.fmb.staticPressure,
    notation: dictionary.suffix.staticPressure,
  },
  {
    columnId: "z",
    width: 150,
    label: dictionary.fmb.z,
    notation: "",
  },
  {
    columnId: "z_star",
    width: 150,
    label: dictionary.fmb.zStar,
    notation: "",
  },
  {
    columnId: "p_on_z_star",
    width: 150,
    label: dictionary.fmb.pzStar,
    notation: dictionary.suffix.pz,
  },
];

const safeFmbDictionary: { [key: string]: string } = dictionary.fmb;

const generateLineSeries = ({
  caseObject,
  colorList,
  prefixName = "",
}: {
  caseObject: CaseResult<any>;
  colorList: string[];
  prefixName?: string;
}) => {
  const generatedSeries: FossilyticsChartSeries[] = [];
  const generatedLines: FossilyticsChartLine[] = [];

  caseKey.forEach((key, index) => {
    const handle = caseObject[key as keyof CaseResult<FmbChartAnalysis>].handle_bar;

    const nameKey = `fmb${capitalizeFirstLetter(key)}Case`;
    const name = `${prefixName}${safeFmbDictionary[nameKey]}`;

    // this is for the legend display
    generatedSeries.push({
      name,
      type: "line",
      color: colorList[index],
      data: [],
    });

    const draggableHead: FossilyticsChartLine = {
      id: handle.identity,
      name,
      linked: name,
      color: colorList[index],
      handleColor: colorList[index],
      line: [
        [handle.coordinates[0].x, handle.coordinates[0].y],
        [handle.coordinates[1].x, handle.coordinates[1].y],
      ],
      yMin: 0,
      xMin: 0,
    };

    if (handle.identity.includes("productivity_index_chart")) {
      draggableHead.xConstraint = FossilyticsChartLineConstraint.FIXED_VALUE;
      draggableHead.xConstraintValue = [handle.coordinates[0].x, handle.coordinates[1].x];
    } else {
      draggableHead.xConstraint = FossilyticsChartLineConstraint.FIXED_VALUE;
      draggableHead.xConstraintValue = [0, undefined];

      draggableHead.yConstraint = FossilyticsChartLineConstraint.FIXED_VALUE;
      draggableHead.yConstraintValue = [undefined, 0];
    }
    generatedLines.push(draggableHead);
  });

  return {
    generatedSeries,
    generatedLines,
  };
};

const useAutoFmbAnalysisChart = ({ analysisResult, autoFmbState }: UseAutoFmbAnalysisChartProps) => {
  const { palette } = useThemeStyling();

  const [selectedCase, setSelectedCase] = useState<string>(caseKey[0]);

  const chartResult = useMemo(() => {
    return analysisResult?.analysis_results;
  }, [analysisResult]);

  const colorScheme = useMemo(() => {
    const seriesColorScheme = [palette.customColor.pinkLight, palette.customColor.red, palette.customColor.redDark];
    return seriesColorScheme;
  }, [palette.customColor.pinkLight, palette.customColor.red, palette.customColor.redDark]);

  const fmbChartLineSeries = useMemo(() => {
    let series: FossilyticsChartSeries[] = [];
    let lines: FossilyticsChartLine[] = [];

    if (chartResult) {
      caseKey.forEach((key, index) => {
        const currentItem = chartResult.fmb_chart[key as keyof CaseResult<FmbChartAnalysis>];
        series.push({
          name: `${dictionary.fmb.flowingMaterialBalance} ${safeFmbDictionary[key]}`,
          type: "scatter",
          defaultDisabled: key !== "mid",
          color: colorScheme[index],
          data: currentItem.normalized_cumulative_production.map((point, index) => [point, currentItem.normalized_rates[index]]),
        });
      });

      const { generatedLines, generatedSeries } = generateLineSeries({
        caseObject: chartResult.fmb_chart,
        colorList: colorScheme,
      });

      lines = generatedLines;
      series = series.concat(generatedSeries);
    }

    return {
      series,
      lines,
    };
  }, [chartResult, colorScheme]);

  const fmbChartAxes = useMemo(() => {
    return {
      xAxis: { name: dictionary.fmb.normalizeCumulativeProduction, type: "value", color: palette.customColor.black, min: 0 },
      yAxis: { name: dictionary.fmb.normalizeRate, type: "value", color: palette.customColor.black, min: 0, nameGap: 40, offset: 0 },
    };
  }, [palette]);

  const pzLineSeries = useMemo(() => {
    let series: FossilyticsChartSeries[] = [];
    let lines: FossilyticsChartLine[] = [];

    if (chartResult) {
      caseKey.forEach((key, index) => {
        const currentItem = chartResult.p_on_z_chart[key as keyof CaseResult<FmbPzChartAnalysis>];
        series.push({
          name: `${dictionary.fmb.flowingPzStar} ${safeFmbDictionary[key]}`,
          type: "scatter",
          defaultDisabled: key !== "mid",
          color: colorScheme[index],
          data: currentItem.cumulative_production.map((point, index) => [point, currentItem.flowing_p_star_on_z_double_star[index]]),
        });
      });

      const { generatedLines, generatedSeries } = generateLineSeries({
        caseObject: chartResult.p_on_z_chart,
        colorList: colorScheme,
        prefixName: "P/Z ",
      });

      lines = generatedLines;
      series = series.concat(generatedSeries);
      if (chartResult.p_on_z_chart.manual_p_on_z) {
        // add manual point
        series.push({
          name: `${dictionary.fmb.manualPoint}`,
          type: "scatter",
          defaultDisabled: false,
          color: palette.common.black,
          data: chartResult.p_on_z_chart.manual_p_on_z?.p_on_z_chart_manually_add.map((point) => [point.x, point.y]),
        });

        // add manual line
        // legend
        series.push({
          name: dictionary.fmb.manualLine,
          type: "line",
          color: palette.common.black,
          defaultDisabled: true,
          data: [],
        });

        const line = chartResult.p_on_z_chart.manual_p_on_z?.p_on_z_chart_manual_line;
        // the line itself
        lines.push({
          controllable: false,
          id: "line-id",
          name: dictionary.fmb.manualLine,
          linked: dictionary.fmb.manualLine,
          color: palette.common.black,
          handleColor: palette.common.black,
          line: [
            [line.x1, line.y1],
            [line.x2, line.y2],
          ],
        });
      }
    }

    return {
      series,
      lines,
    };
  }, [chartResult, colorScheme, palette.common.black]);

  const pzAxes = useMemo(() => {
    return {
      xAxis: {
        name: `${dictionary.fmb.cumulativeProduction} ${dictionary.suffix.cumulativeProduction}`,
        type: "value",
        color: palette.customColor.black,
        min: 0,
      },
      yAxis: {
        name: dictionary.fmb.pzAxis,
        type: "value",
        color: palette.customColor.black,
        min: 0,
        nameGap: 40,
        offset: 0,
      },
    };
  }, [palette]);

  const piLineSeries = useMemo(() => {
    let series: FossilyticsChartSeries[] = [];
    let lines: FossilyticsChartLine[] = [];

    if (chartResult) {
      caseKey.forEach((key, index) => {
        const currentItem = chartResult.productivity_index_chart[key as keyof CaseResult<FmbPiChartAnalysis>];
        series.push({
          name: `${dictionary.fmb.productivityIndexLegend} ${safeFmbDictionary[key]}`,
          type: "scatter",
          defaultDisabled: key !== "mid",
          color: colorScheme[index],
          data: currentItem.cumulative_production.map((point, index) => [point, currentItem.productivity_index[index]]),
        });
      });

      const { generatedLines, generatedSeries } = generateLineSeries({
        caseObject: chartResult.productivity_index_chart,
        colorList: colorScheme,
        prefixName: "PI ",
      });

      lines = generatedLines;
      series = series.concat(generatedSeries);
    }

    return {
      series,
      lines,
    };
  }, [chartResult, colorScheme]);

  const piAxes = useMemo(() => {
    return {
      xAxis: {
        name: `${dictionary.fmb.cumulativeProduction} ${dictionary.suffix.cumulativeProduction}`,
        type: "value",
        color: palette.customColor.black,
        min: 0,
      },
      yAxis: { name: dictionary.fmb.productivityIndex, type: "value", color: palette.customColor.black, min: 0, nameGap: 40 },
    };
  }, [palette.customColor.black]);

  // data table
  const dataTableColumn = useMemo(() => {
    if (!chartResult) return [];

    return Object.keys(chartResult.data_tables[selectedCase as keyof CaseResult<AnalysisFmbDataTable>]).map((item) => {
      const wordCount = item.split("_").length;
      return {
        columnId: item,
        width: wordCount > 2 ? 180 : 140,
      };
    });
  }, [chartResult, selectedCase]);

  const dataTableRow: Row<any>[] = useMemo(() => {
    if (!chartResult) return [];
    return [
      {
        rowId: "header",
        cells: dataTableColumn.map((column) => {
          const item = autoFmbState?.symbols_key[column.columnId] ?? "";
          return {
            type: "custom",
            isLatex: item,
            text: symbolUnits?.[item] ?? capitalizeFirstLetter(transformHeaderOrNotation(column.columnId)),
            style: tableHeaderStyle,
            nonEditable: true,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: dataTableColumn.map((column) => ({
          type: "text",
          text: transformHeaderOrNotation(autoFmbState?.units_key[column.columnId]) ?? dictionary.suffix[column.columnId] ?? "",
          style: tableHeaderNotationStyle,
          nonEditable: true,
        })),
        height: 35,
      },
      ...chartResult.data_tables[selectedCase as keyof CaseResult<AnalysisFmbDataTable>].flowing_pressure.map((_, index) => {
        return {
          rowId: index,
          cells: dataTableColumn.map((column) => {
            const val =
              chartResult.data_tables[selectedCase as keyof CaseResult<AnalysisFmbDataTable>][column.columnId as keyof AnalysisFmbDataTable][index];

            return {
              type: "text",
              text: formatToScientific(val ?? ""),
              style: tableCellStyle,
              nonEditable: true,
            };
          }),
        };
      }),
    ];
  }, [autoFmbState?.symbols_key, autoFmbState?.units_key, chartResult, dataTableColumn, selectedCase]);

  // pz point data table
  const pzPointDataTableRow: Row<any>[] = useMemo(() => {
    if (!chartResult) return [];
    return [
      {
        rowId: "header",
        cells: pointManualDataTableColumn.map((column) => {
          const item = autoFmbState?.symbols_key[column.columnId] ?? "";

          return {
            type: "custom",
            isLatex: item,
            text: item ? symbolUnits[item] : capitalizeFirstLetter(transformHeaderOrNotation(column.columnId)),
            style: tableHeaderStyle,
            nonEditable: true,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: pointManualDataTableColumn.map((column) => {
          const item = autoFmbState?.units_key[column.columnId] ?? "";

          return {
            type: "custom",
            text: symbolUnits[item] ? transformHeaderOrNotation(symbolUnits[item]) : column.notation,
            style: tableHeaderNotationStyle,
            nonEditable: true,
          };
        }),
        height: 35,
      },
      ...(chartResult.data_tables?.p_on_z_manual_table?.cumulative_production.map((_, index) => {
        return {
          rowId: index,
          cells: pointManualDataTableColumn.map((column) => {
            const val = chartResult.data_tables?.p_on_z_manual_table?.[column.columnId as keyof ManualPzDataTable][index];

            return {
              type: "text",
              text: formatToScientific(val ?? ""),
              style: tableCellStyle,
              nonEditable: true,
            };
          }),
        };
      }) ?? []),
    ];
  }, [autoFmbState?.symbols_key, autoFmbState?.units_key, chartResult]);

  return {
    fmbChartLineSeries,
    fmbChartAxes,
    pzAxes,
    piAxes,
    pzLineSeries,
    piLineSeries,
    dataTableRow,
    dataTableColumn,
    setSelectedCase,
    selectedCase,
    pzPointDataTableRow,
  };
};

export default useAutoFmbAnalysisChart;
