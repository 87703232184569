import InputField from "@/components/fields/InputField";
import DropdownField from "@/components/fields/DropdownField";

import { ModuleTahkForecastGasOilOptions } from "@/modules/tahk/forecast/gasoil/model";
import { TankType, GasViscosityMethod, WaterViscosityMethod, ZMethod } from "@/model";

import { TankTypeOptions, formationCompressibilityOptions, gasViscMethodOptions, wtrViscMethodOptions, zMethodOptions } from "./model";
import { THEORETICAL_HELP_LINKS } from "@/constants/helpLinkUrl";
import { generateWikiLink } from "@/features/help-link/help-link.utils";

interface BaseInputsProps {
  value: ModuleTahkForecastGasOilOptions;
  onChange: (val: ModuleTahkForecastGasOilOptions) => void;
  module?: string;
}

const BaseInputs = ({ value, onChange, module }: BaseInputsProps) => {
  if (!value) return <></>;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: 20,
      }}
    >
      <div>
        <DropdownField
          label="Tank type"
          helpUrl={generateWikiLink(THEORETICAL_HELP_LINKS.pssModels)}
          options={TankTypeOptions}
          selectedKey={value.tank_type}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  tank_type: v as TankType,
                })
              : null
          }
        />
        {module !== "tahk_gas" && (
          <InputField
            label="Area"
            suffix="acre"
            value={value.area_acres}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    area_acres: Number(v),
                  })
                : null
            }
          />
        )}
        <InputField
          label="Wellbore radius"
          suffix="ft"
          value={value.wellbore_radius_ft}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  wellbore_radius_ft: Number(v),
                })
              : null
          }
        />
      </div>

      <div>
        <InputField
          label="Gas gravity"
          suffix="dim"
          value={value.gas_gravity}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  gas_gravity: Number(v),
                })
              : null
          }
        />
        <InputField
          label="N₂"
          suffix="%"
          min={0}
          max={100}
          value={value.n2_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  n2_perc: Number(v),
                })
              : null
          }
        />
        <InputField
          label="CO₂"
          suffix="%"
          min={0}
          max={100}
          value={value.co2_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  co2_perc: Number(v),
                })
              : null
          }
        />
        <InputField
          label="H₂S"
          suffix="%"
          min={0}
          max={100}
          value={value.h2s_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  h2s_perc: Number(v),
                })
              : null
          }
        />
      </div>

      <div>
        <DropdownField
          label="Formation compressibility method"
          options={formationCompressibilityOptions}
          selectedKey={`${value.form_compressibility_method}-${value.rock_type}`}
          onChange={(v) => {
            const data = formationCompressibilityOptions.filter((key) => key.key === v);
            if (data?.[0]?.data) {
              onChange({
                ...value,
                ...data[0].data,
              });
            }
          }}
        />
        <DropdownField
          label="Z Method"
          helpUrl={generateWikiLink(THEORETICAL_HELP_LINKS.gasDeviationFactor)}
          options={zMethodOptions}
          selectedKey={value.z_method}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  z_method: v as ZMethod,
                })
              : null
          }
        />

        <DropdownField
          label="Water viscosity method"
          options={wtrViscMethodOptions}
          selectedKey={value.water_viscosity_method}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  water_viscosity_method: v as WaterViscosityMethod,
                })
              : null
          }
        />
        <DropdownField
          label="Gas viscosity method"
          helpUrl={generateWikiLink(THEORETICAL_HELP_LINKS.gasViscosity)}
          options={gasViscMethodOptions}
          selectedKey={value.gas_viscosity_method}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  gas_viscosity_method: v as GasViscosityMethod,
                })
              : null
          }
        />
        <InputField
          label="Formation temperature"
          suffix="°F"
          value={value.form_temp_of}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  form_temp_of: Number(v),
                })
              : null
          }
        />

        <InputField
          label="Water Salinity "
          suffix="PPM"
          value={value.salinity_ppm}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  salinity_ppm: Number(v),
                })
              : null
          }
        />
      </div>
    </div>
  );
};

export default BaseInputs;
