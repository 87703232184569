import { useMemo } from "react";
import { Column, Row } from "@silevis/reactgrid";

import { PvtTableHeaderMap } from "@/models/Generic";
import { GasPvtDataTable } from "@/models/InputGeneric";
import { CustomCell, tableHeaderStyle, tableHeaderNotationStyle } from "@/components/CustomTable";
import { formatToScientific } from "@/utils/general";

import dictionary from "@/constants/dictionary";
import useChartInfo from "@/utils/useChartInfo";
import useThemeStyling from "@/utils/useThemeStyling";

export const gasPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
] as Column[];

export const gasPvtHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  compressibility_factor: { name: "Z", unit: "(Dim)" },
  viscosity: { name: "μ", unit: "cP", sub: "g" },
  density: { name: "p", unit: "lbm/ft³", sub: "g" },
  form_vol_factor: { name: "B", unit: "(ft³/scf)", sub: "g" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "g" },
};

export type UseGasPvtProps = {
  gasPvtCalculation?: GasPvtDataTable;
};

const useGasPvt = ({ gasPvtCalculation }: UseGasPvtProps) => {
  const { palette } = useThemeStyling();
  const gasPvtDataTableRows = useMemo<Row[]>(() => {
    if (!gasPvtCalculation) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(gasPvtHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: tableHeaderStyle,
        sub,
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(gasPvtHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: tableHeaderNotationStyle,
      })),
    };

    return [
      headerRow,
      unitsRow,
      ...gasPvtCalculation.density.map((_, j) => ({
        rowId: j,
        cells: Object.keys(gasPvtHeaderUnitMapping).map((key) => {
          return {
            type: "text",
            text: formatToScientific(gasPvtCalculation[key as keyof GasPvtDataTable][j]),
            style: { display: "flex", justifyContent: "center" },
            nonEditable: true,
          };
        }),
      })),
    ] as Row[];
  }, [gasPvtCalculation]);

  const gasPvtZ = useChartInfo({
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.compresibilityFactor,
    seriesName: dictionary.inputPage.gasZ,
    chartPlot: gasPvtCalculation ? gasPvtCalculation.pressure.map((p, j) => [p, gasPvtCalculation.compressibility_factor[j]]) : [],
    seriesColor: palette.customColor.red,
  });
  const gasPvtMu = useChartInfo({
    seriesColor: palette.customColor.red,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.viscosity,
    seriesName: dictionary.inputPage.gasMu,
    chartPlot: gasPvtCalculation ? gasPvtCalculation.pressure.map((p, j) => [p, gasPvtCalculation.viscosity[j]]) : [],
  });
  const gasPvtPg = useChartInfo({
    seriesColor: palette.customColor.red,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.density,
    seriesName: dictionary.inputPage.gasPg,
    chartPlot: gasPvtCalculation ? gasPvtCalculation.pressure.map((p, j) => [p, gasPvtCalculation.density[j]]) : [],
  });
  const gasPvtBg = useChartInfo({
    seriesColor: palette.customColor.red,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.formVolFactor,
    seriesName: dictionary.inputPage.gasBg,
    chartPlot: gasPvtCalculation ? gasPvtCalculation.pressure.map((p, j) => [p, gasPvtCalculation.form_vol_factor[j]]) : [],
  });
  const gasPvtCg = useChartInfo({
    seriesColor: palette.customColor.red,
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.compressibility,
    seriesName: dictionary.inputPage.gasCg,
    chartPlot: gasPvtCalculation ? gasPvtCalculation.pressure.map((p, j) => [p, gasPvtCalculation.compressibility[j]]) : [],
  });

  return {
    gasPvtDataTableRows,
    gasPvtDataTableCols,
    gasPvtZ,
    gasPvtMu,
    gasPvtPg,
    gasPvtBg,
    gasPvtCg,
  };
};

export default useGasPvt;
