import React, { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import ReactMarkdown from "react-markdown";

import { AnnouncementResponse } from "../model";
import { fetchApi } from "@/utils/apiFetcher";

const Announcement = () => {
  const [announcements, setAnnouncements] = useState<AnnouncementResponse[]>([]);
  const haveInitialize = useRef(false);

  // announcement doesn't require loading & error since its not stoping user from anything
  useEffect(() => {
    if (!haveInitialize.current) {
      fetchApi<AnnouncementResponse[]>({
        path: "/announcements",
      })
        .then((res) => {
          if (res.data) {
            setAnnouncements(res.data);
            haveInitialize.current = true;
          }
        })
        .catch((e) => {
          // no-op
        });
    }
  }, []);

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ position: "absolute", top: "10vh", left: "3vh", right: "5vh", zIndex: 1 }}>
        {announcements.map((announcement) => (
          <Alert key={announcement.id} style={{ marginTop: "1vh" }} severity={announcement.maintenance_mode ? "error" : "info"}>
            <ReactMarkdown components={{ p: "span" }}>{announcement.content}</ReactMarkdown>
          </Alert>
        ))}
      </Box>
    </Box>
  );
};

export default Announcement;
