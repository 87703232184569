import { useMemo, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import CustomCard from "@/components/Card";
import Tabs from "@/components/Tabs";
import FossilyticsChart, { FossilyticsChartLine } from "@/components/FossilyticsChart";
import CustomTable from "@/components/CustomTable";

import dictionary from "@/constants/dictionary";
import useAutoFmbAnalysisChart, { pointManualDataTableColumn } from "../../hooks/useAutoFmbAnalysisChart";
import { AnalysisFmbResult, AutoFmbState } from "@/models/autoFmb";
import DropdownField from "@/components/fields/DropdownField";
import { capitalizeFirstLetter } from "@/utils/general";
import { caseKey } from "../../constants";

type AnalysisChartFmbProps = {
  isLoading: boolean;
  analysisResult?: AnalysisFmbResult;
  onDragChartHandlebar: (l: number[][] | [string, number][], _i: number, _isEnd?: boolean, line?: FossilyticsChartLine) => void;
  autoFmbState?: AutoFmbState;
  tabIndex: number;
};

const AnalysisChartFmb = ({ isLoading, analysisResult, onDragChartHandlebar, autoFmbState, tabIndex }: AnalysisChartFmbProps) => {
  const [isExpand, setIsExpand] = useState(true);

  const {
    fmbChartAxes,
    fmbChartLineSeries,
    piAxes,
    piLineSeries,
    pzAxes,
    pzLineSeries,
    dataTableRow,
    dataTableColumn,
    setSelectedCase,
    selectedCase,
    pzPointDataTableRow,
  } = useAutoFmbAnalysisChart({
    analysisResult,
    autoFmbState,
  });

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.fmb.fmb}</span>,
        key: dictionary.fmb.fmb,
        content: (
          <div
            style={{
              height: 600,
            }}
          >
            <FossilyticsChart
              id={"fmb_chart"}
              key={"fmb_chart"}
              isLoading={isLoading}
              xAxes={[fmbChartAxes.xAxis]}
              yAxes={[fmbChartAxes.yAxis]}
              series={fmbChartLineSeries.series}
              lines={fmbChartLineSeries.lines}
              onLineChange={onDragChartHandlebar}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.fmb.pz}</span>,
        key: dictionary.fmb.pz,
        content: (
          <div
            style={{
              height: 600,
            }}
          >
            <FossilyticsChart
              id={"pz_chart"}
              key={"pz_chart"}
              isLoading={isLoading}
              xAxes={[pzAxes.xAxis]}
              yAxes={[pzAxes.yAxis]}
              series={pzLineSeries.series}
              lines={pzLineSeries.lines}
              onLineChange={onDragChartHandlebar}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.fmb.pi}</span>,
        key: dictionary.fmb.pi,
        content: (
          <div
            style={{
              height: 600,
            }}
          >
            <FossilyticsChart
              id={"pi_chart"}
              key={"pi_chart"}
              isLoading={isLoading}
              xAxes={[piAxes.xAxis]}
              yAxes={[piAxes.yAxis]}
              series={piLineSeries.series}
              lines={piLineSeries.lines}
              onLineChange={onDragChartHandlebar}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataTable}</span>,
        key: " Data table",
        content: (
          <div>
            {analysisResult?.analysis_results?.data_tables?.p_on_z_manual_table && (
              <div>
                <h4
                  onKeyDown={() => setIsExpand(!isExpand)}
                  onClick={() => setIsExpand(!isExpand)}
                  style={{ margin: 0, display: "flex", justifyContent: "space-between", marginTop: 20, cursor: "pointer" }}
                >
                  {dictionary.fmb.pzManualPoint} Data {isExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </h4>

                {isExpand && (
                  <CustomTable
                    columns={pointManualDataTableColumn}
                    rows={pzPointDataTableRow}
                    enableRangeSelection
                    enableColumnSelection
                    stickyTopRows={2}
                    isLoading={false}
                    style={{
                      maxHeight: 300,
                    }}
                  />
                )}
              </div>
            )}

            <DropdownField
              label={dictionary.fmb.caseOption}
              options={caseKey.map((key) => ({
                key,
                text: capitalizeFirstLetter(key),
              }))}
              selectedKey={selectedCase}
              onChange={(v) => setSelectedCase(v)}
              disabled={isLoading}
            />
            {tabIndex === 2 && <CustomTable stickyTopRows={1} rows={dataTableRow} columns={dataTableColumn} isLoading={isLoading} />}
          </div>
        ),
      },
    ];
  }, [
    isLoading,
    fmbChartAxes,
    fmbChartLineSeries,
    onDragChartHandlebar,
    pzAxes,
    pzLineSeries,
    piAxes,
    piLineSeries,
    analysisResult?.analysis_results?.data_tables?.p_on_z_manual_table,
    isExpand,
    pzPointDataTableRow,
    selectedCase,
    tabIndex,
    dataTableRow,
    dataTableColumn,
    setSelectedCase,
  ]);

  return (
    <CustomCard
      style={{
        opacity: isLoading || fmbChartLineSeries.series.length === 0 ? 0.5 : 1,
        position: "relative",
        display: "flex",
        height: "100%",
      }}
    >
      {fmbChartLineSeries.series.length === 0 && <div className="overlay-section" />}

      <Tabs tabList={tabList} />
    </CustomCard>
  );
};

export default AnalysisChartFmb;
