import { nativeEnum, number, object, z } from "zod";
import { defineParametersScheme, gasPvtScheme, langmuirIsothermScheme, oilPvtScheme, waterPvtScheme } from "../InputGeneric";

export enum PressureFmbDataEnum {
  TubingHeadPressure = "TubingHeadPressure",
  BottomHolePressure = "BottomHolePressure",
  CasingPressure = "CasingPressure",
  ConstantPressure = "ConstantPressure",
}

export enum WellboreFmbModelEnum {
  BeggsBrill = "BeggsBrill",
  NoModel = "NoModel",
}

const wellboreFmbParam = object({
  tubing_diameter: number(),
  tubing_roughness: number(),
  tubing_depth: number(),
  well_angle: number(),
  wellhead_temperature: number(),
});

const wellboreFmbScheme = object({
  selected_pressure_data: nativeEnum(PressureFmbDataEnum),
  constant_flowing_pressure: number(),
  selected_wellbore_model: nativeEnum(WellboreFmbModelEnum),
  parameters: wellboreFmbParam.nullable(),
});

export type WellboreFmbInput = z.infer<typeof wellboreFmbScheme>;
export const fmbInputStateScheme = object({
  defined_parameters: defineParametersScheme,
  gas_pvt: gasPvtScheme,
  water_pvt: waterPvtScheme,
  oil_pvt: oilPvtScheme,
  langmuir_isotherm: langmuirIsothermScheme,
  wellbore_model: wellboreFmbScheme,
});

export type FmbInputState = z.infer<typeof fmbInputStateScheme>;
