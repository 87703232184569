import { dataPreview } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { DataviewInitializeState } from "..";

export const getDataPreview = (dataSetId: string) => {
  return fetchApi<DataviewInitializeState>({
    path: dataPreview(dataSetId),
  });
};
