import { useTreeViewState } from "@/components/TreeView/hooks/TreeViewContextV2";
import useSpadDeclineEdit, { editTableColumn, SpadDelineEditProps } from "../hooks/useSpadDeclineEdit";
import CustomTable from "@/components/CustomTable";

const SpadDeclineEdit = ({ groupEditState, loadingState, setGroupEditState }: SpadDelineEditProps) => {
  const { treeViewWidth } = useTreeViewState();

  const { editTableRow, onCellsChanged } = useSpadDeclineEdit({
    groupEditState,
    loadingState,
    setGroupEditState,
  });

  const getMaxWidthRightSide = window.innerWidth - treeViewWidth - 20;

  return (
    <div
      style={{
        marginTop: 20,
        height: 500,
        width: getMaxWidthRightSide,
        overflow: "auto",
      }}
    >
      <CustomTable
        style={{
          display: "flex",
          overflow: "auto",
          height: "100%",
          width: "fit-content",
        }}
        noScrollable
        columns={editTableColumn}
        rows={editTableRow}
        enableRangeSelection
        enableColumnSelection
        isLoading={loadingState}
        stickyTopRows={1}
        onCellsChanged={onCellsChanged}
      />
    </div>
  );
};

export default SpadDeclineEdit;
