import { useEffect, useMemo, useRef, useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import BookOutlinedIcon from "@mui/icons-material/BookOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import Page from "@/Page";
import Tabs from "@/components/Tabs";
import { ReleaseDetailsList } from "@/features/settings/release-details-list";
import UserPreference from "@/features/settings/UserPreference";
import { isDevelopment } from "@/utils/general";
import CustomCard from "@/components/Card";
import dictionary from "@/constants/dictionary";

export const SettingsPage = ({ buildNumberText }: { buildNumberText: string | null }) => {
  const [tabIndex, setTabIndex] = useState(-1);
  const haveInitialize = useRef(false);

  useEffect(() => {
    const queryTab = window.location.href.split("?");
    if (queryTab?.[1]) {
      setTabIndex(Number(queryTab[1].split("=")[1]));
    }
    haveInitialize.current = true;
  }, []);

  const tabList = useMemo(() => {
    return [
      {
        label: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoOutlinedIcon style={{ marginRight: 5 }} fontSize="small" />
            {dictionary.settings.about}
          </span>
        ),
        content: (
          <div
            style={{
              padding: "1em",
            }}
          >
            <CustomCard>
              <h2 style={{ margin: 0 }}>AFA - Advanced Flow Analytics</h2>
              <div>
                Version: {buildNumberText}
                <br />
                Email: support@predico.com.au
                <br />
                <br />
                autoRTA and AFA are Trade Marked and <br /> Copyright 2024 Predico Pty Ltd. All rights reserved.
              </div>
            </CustomCard>
          </div>
        ),
        key: "about",
      },
      {
        label: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SettingsOutlinedIcon style={{ marginRight: 5 }} fontSize="small" />
            {dictionary.settings.preference}
          </span>
        ),
        content: (
          <div
            style={{
              paddingTop: "1em",
              paddingBottom: "1em",
            }}
          >
            {isDevelopment && <UserPreference />}
          </div>
        ),
        key: "preference",
      },
      {
        label: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <BookOutlinedIcon style={{ marginRight: 5 }} fontSize="small" />
            {dictionary.settings.releaseNotes}
          </span>
        ),
        content: <ReleaseDetailsList />,
        key: "releaseNotes",
      },
      {
        label: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <DescriptionOutlinedIcon style={{ marginRight: 5 }} fontSize="small" />
            {dictionary.settings.terms}
          </span>
        ),
        content: (
          <div
            style={{
              paddingTop: "1em",
            }}
          >
            <iframe
              style={{
                height: "77vh",
                width: "97vw",
              }}
              title="Predico Terms"
              src="https://www.predico.com.au/terms"
              name="myiFrame"
            ></iframe>
          </div>
        ),
        key: "terms",
      },
    ];
  }, [buildNumberText]);

  if (!haveInitialize.current) return <div />;

  return (
    <Page title="Settings">
      <div style={{ padding: "40px", height: "100%", overflow: "auto" }}>
        <Tabs tabContainerStyle={{ flex: "unset" }} customActiveTab={tabIndex} onClickItem={(item) => setTabIndex(item)} tabList={tabList} />
      </div>
    </Page>
  );
};
