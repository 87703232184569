import { Group, DataSet } from "@/model";
import { Tags } from "./types";
import { AFA_WELL_TYPE } from "./constants";

export function getProjectIdFromKey(key: string): string {
  return key.split(";").shift()!.split(":")[1];
}

export function getGroupIdFromKey(key: string): string {
  const keyParts = key.split(";");
  if (!keyParts[keyParts.length - 1].startsWith("group")) keyParts.pop();
  return keyParts.pop()!.split(":")[1];
}

// NEW UTILS

// recursive to get key pair from group id to total data set item
export const loopGroup = (
  group: Group,
  isChild: boolean = false
): {
  [groupId: string]: {
    total: number;
    isChild?: boolean;
    ids: string[];
    name: string;
  };
} => {
  let res: {
    [groupId: string]: {
      total: number;
      isChild?: boolean;
      ids: string[];
      name: string;
    };
  } = {};

  let totalWell = 0;
  group.groups.forEach((group) => {
    res = { ...res, ...loopGroup(group, true) };
  });

  totalWell += group.data_set_ids ? group.data_set_ids.length : 0;

  const testTotal = Object.values(res).reduce((total, curr) => {
    total += curr.total;
    return total;
  }, 0);

  res[group.id] = {
    total: totalWell + testTotal,
    isChild,
    ids: group.data_set_ids ?? [],
    name: group.name,
  };

  return res;
};

// recursive function that return selected group
// handle multi level find even though only provide the lowest level group id, for current user task functionality
export const findGroupRecursive = (groupIds: string[], groups: Group[]): Group | undefined => {
  if (groupIds.length === 0) return undefined;

  const currGroupId = groupIds[0];

  for (const group of groups) {
    if (group.id === currGroupId) {
      if (groupIds.length === 1) {
        return group;
      } else {
        return findGroupRecursive(groupIds.slice(1), group.groups);
      }
    }

    const foundGroup = findGroupRecursive(groupIds, group.groups);
    if (foundGroup) {
      return foundGroup;
    }
  }

  return undefined;
};

export const getIdFromActiveKey = (activeKey: string) => {
  const [projectId, groupIds, dataSet, isCollapsible] = activeKey.split(";");
  return {
    projectId,
    groupIds: groupIds ? groupIds.split(",") : [],
    dataSet: dataSet ? dataSet.split(",") : [],
    isCollapsible: !!isCollapsible,
  };
};

// -----------------For filtering utils----------------------

// **Determines if the default tag selection has been modified**
export const hasModifiedDefaultSelectionUtils = (selectedTags: Record<string, string[]>, tags: Record<string, Record<string, any>>): boolean => {
  return (
    (selectedTags[AFA_WELL_TYPE]?.length ?? 0) !== Object.keys(tags[AFA_WELL_TYPE] || {}).length ||
    Object.keys(selectedTags).some((tagKey) => tagKey !== AFA_WELL_TYPE && (selectedTags[tagKey]?.length ?? 0) > 0)
  );
};

export function buildTagDictionary(dataSets: DataSet[]): Tags {
  const tagDict: Tags = {};

  dataSets.forEach((dataSet) => {
    dataSet.tags.forEach((tagStr) => {
      const [key, value] = tagStr.split(":");
      if (!tagDict[key]) {
        tagDict[key] = {};
      }
      if (!tagDict[key][value]) {
        tagDict[key][value] = [];
      }
      tagDict[key][value].push(dataSet.id);
    });
  });

  return tagDict;
}
