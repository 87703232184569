import React, { useMemo } from "react";
import { Row, CellChange } from "@silevis/reactgrid";

// utils
import useThemeStyling from "@/utils/useThemeStyling";
import { formatNumber } from "@/util";

// models
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { AutoRtaForecastV2 } from "@/models/gaz/autoRta";

//component
import InputField from "@/components/fields/InputField";

// constant
import { dataTableColumns, forecastEventColumn } from "../../constants";

import LoadingIndicator from "@/components/LoadingIndicator";
import { FluidType, SummaryCard } from "@/models/Generic";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import SummaryTable from "@/components/SummaryTable";
import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import CustomCard from "@/components/Card";
import dictionary from "@/constants/dictionary";

export type AutoRtaForecastSettingProps = {
  onChangeInput: (payload: { [key: string]: any }) => void;
  forecastInput: AutoRtaForecastV2;
  onChangeTableInput: (changes: CellChange[]) => void;
  errorInputValidation?: ErrorValidationDetail[];
  forecastEventsRow: Row[];
  autoRtaForecastParameter?: SummaryCard[];
  isLoading: boolean;
  onClickValidate: () => void;

  fluidType: FluidType;
  canCancelPoll: boolean;
  onCancelPoll: () => {};
};

const AutoRtaForecastSetting = ({
  onChangeInput,
  forecastInput,
  onChangeTableInput,
  errorInputValidation,
  forecastEventsRow,
  autoRtaForecastParameter = [],
  isLoading,
  onClickValidate,
  fluidType,
  canCancelPoll,
  onCancelPoll,
}: AutoRtaForecastSettingProps) => {
  const { palette } = useThemeStyling();

  // display error to notify user if there's an error at other page
  const analysisInputPageHaveErr = useMemo(() => {
    const containErr = errorInputValidation?.find((err) => err.loc.includes("analysis") || err.loc.includes("input"));
    return !!containErr;
  }, [errorInputValidation]);

  const forecastEventErrors = useMemo(() => {
    return errorInputValidation?.filter((err) => err.loc.includes("forecast_events")) ?? [];
  }, [errorInputValidation]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <CustomCard style={{ marginBottom: "1em", overflow: "auto" }}>
        <h3 style={{ color: palette.primary.main, margin: 0 }}>Forecast Settings</h3>
        {analysisInputPageHaveErr && <span style={{ color: palette.error.main, fontSize: 14 }}>{dictionary.autoRta.analysisInputError}</span>}

        <div>
          <InputField
            type="float"
            label="Economic Cutoff"
            suffix={fluidType === FluidType.oil ? "STB/D" : "MMscf/D"}
            value={forecastInput.economic_cutoff}
            debounceDelay={1000}
            onChange={(v) => {
              onChangeInput({
                economic_cutoff: v,
              });
            }}
            disabled={isLoading}
            errors={errorInputValidation}
          />

          <FormControlLabel
            control={
              forecastInput ? (
                <Checkbox
                  onChange={(e) => {
                    onChangeInput({
                      smart_fill: e.target.checked,
                    });
                  }}
                  checked={forecastInput.smart_fill}
                  size="small"
                  disabled={isLoading}
                />
              ) : (
                <></>
              )
            }
            label="Smart Fill"
          />

          <div
            style={{
              paddingBottom: 15,
            }}
          >
            {forecastEventErrors.length > 0 && (
              <div style={{ color: palette.error.main }}>
                Forecast Events: <br />
                {forecastEventErrors.map((err) => err.msg)}
              </div>
            )}
            <ReactGridTable
              viewWidth={410}
              viewHeight={360}
              columns={forecastEventColumn}
              rows={forecastEventsRow}
              enableRangeSelection
              enableColumnSelection
              stickyTopRows={2}
              onCellsChanged={onChangeTableInput}
            />
          </div>

          <Button
            onClick={(e) => {
              e.preventDefault();
              canCancelPoll ? onCancelPoll() : onClickValidate();
            }}
            disabled={!canCancelPoll ? isLoading : false}
            style={{ color: "white", width: "100%" }}
            variant="contained"
          >
            {canCancelPoll ? dictionary.genericButton.cancel : "Calculate Forecast"}
          </Button>
        </div>
      </CustomCard>
      <CustomCard style={{ flex: 1, overflow: "auto" }}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <SummaryTable
            rows={autoRtaForecastParameter.map((item: any, i) => {
              return {
                parameter: item.parameter,
                value: formatNumber(Number(Number(item.value).toFixed(2))),
                units: item.unit,
              };
            })}
            headers={dataTableColumns}
          />
        )}
      </CustomCard>
    </div>
  );
};

export default AutoRtaForecastSetting;
