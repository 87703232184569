import React, { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { captureException } from "@/utils/errorMonitoring";

const ErrorBoundaryWrapper = ({ children }: PropsWithChildren) => {
  const handleError = (err: any) => {
    console.log(err);
    captureException(err);
  };

  const defaultError = `Please contact support@predico.com.au for further assistance`;

  return (
    <ErrorBoundary
      FallbackComponent={() => {
        return (
          <div style={{ height: "80vh", padding: 20, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <div
              style={{
                fontSize: 18,
              }}
              id="error-boundary-msg"
            >
              Something went
            </div>
            <h1>wrong.</h1>
            <br />
            <div>{defaultError}</div>
          </div>
        );
      }}
      onError={handleError}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
