import { z, string } from "zod";
import { AnalysisOptionScheme } from "../spad/decline";

export const groupEditItemScheme = z.object({
  data_set_id: string(),
  option: AnalysisOptionScheme,
});

export type GroupEditItem = z.infer<typeof groupEditItemScheme>;
export type GroupEditItemAdditional = z.infer<typeof groupEditItemScheme> & {
  name: string;
};
