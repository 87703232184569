import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

import { UseModelComponentInputProps } from "../../../hooks/KoldunCsgContext";
import useWaterPvt from "../../../hooks/input/useWaterPvt";

const WaterPvt = (props: UseModelComponentInputProps) => {
  const { onChangeWaterPvtCells, waterPvtColumns, waterPvtRows } = useWaterPvt(props);

  return (
    <div
      style={{
        padding: 10,
        marginTop: 20,
      }}
      data-testid="water-pvt-table-container"
    >
      <ReactGridTable
        data-testid="water-pvt-table"
        viewWidth={950}
        viewHeight={360}
        columns={waterPvtColumns}
        rows={waterPvtRows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onChangeWaterPvtCells}
      />
    </div>
  );
};

export default WaterPvt;
