import { exportKoldunCsgForecast } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { ModuleIdentity, Interval } from "@/models/Generic";

type ExportPayload = {
  projectId: string;
  body: ModuleIdentity;
  interval: Interval;
};

// csg doesnt need fluid
export const postExportKoldunCsgForecast = ({ body, interval, projectId }: ExportPayload) => {
  return fetchApi<Blob>({
    path: exportKoldunCsgForecast(projectId),
    body,
    type: "post",
    responseType: "blob",
    queryStringParameters: {
      interval,
    },
  });
};
