import { useEffect, useState } from "react";

import { boldText } from "@/features/text/bold-text";
import { IReleaseDetailLists } from "@/features/settings";
import { replaceAsterisksWithBullets } from "@/features/text/bold-text/bold-text";
import CustomCard from "@/components/Card";

export const ReleaseDetailsList = () => {
  const [releaseDetailsList, setReleaseDetailsList] = useState<IReleaseDetailLists[]>([]);

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/releases.json")
      .then((response) => response.json())
      .then((data) => setReleaseDetailsList(data))
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div
      style={{
        padding: "1em",
      }}
    >
      {releaseDetailsList.map((release) => (
        <CustomCard
          style={{
            marginBottom: 15,
          }}
          key={release.version}
        >
          <h2 style={{ marginBottom: 0 }}> Version: {release.version}</h2>
          <p style={{ marginTop: 0 }}>{release.releasedate}</p>
          <p
            className="br-format"
            style={{ whiteSpace: "pre-wrap" }}
            dangerouslySetInnerHTML={{
              __html: boldText(replaceAsterisksWithBullets(release.summary)).split("\n\n").join("<br>"),
            }}
          />

          {release.issues.map((issue: { [x: string]: any[] }) => (
            <ul key={JSON.stringify(issue)}>
              {Object.keys(issue).map((type) => (
                <div key={type}>
                  <h3>{type}</h3>
                  {issue[type]?.map((item: any) => (
                    <li
                      key={item.key}
                      dangerouslySetInnerHTML={{
                        __html: boldText(`${item.key} - ${item.summary}`),
                      }}
                    />
                  ))}
                </div>
              ))}
            </ul>
          ))}
        </CustomCard>
      ))}
    </div>
  );
};
