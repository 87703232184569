import React, { useCallback, useMemo, useState } from "react";
import Tree from "rc-tree";
import DataSetRadioItem from "../DataSetRadioItem";
import { useTreeViewState } from "../../hooks/TreeViewContextV2";
import { IconButton, Radio } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TagFilterMenu from "../TagFilterMenu";
import { useTheme } from "@mui/material/styles";
import InputField from "@/components/fields/InputField";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { IItem } from "../../types";
import { getTagValue } from "./helper";
import { AFA_DATASOURCE } from "../../constants";
import "./style.css";

interface TreeNode {
  key: string;
  title: string;
  isFolder: boolean;
  isLeaf?: boolean;
  children?: TreeNode[];
  parents?: string[];
  dataSet?: IItem;
  index?: number;
  id?: string;
  totalDatasets?: number;
  filteredDatasets?: number;
  indexInFlatList?: number;
  isSelected?: boolean;
  level: number;
}

const DataSetList: React.FC = () => {
  const {
    dataSetItems,
    setFilter,
    filter,
    selectedDataSet,
    setSelectedDataSet,
    setActiveDialog,
    onDropWell,
    onClickPreview,
    // For filter
    tags,
    selectedTags,
    setSelectedTags,
    hasModifiedDefaultSelection,
    filterTypes,
    shiftHeld,
    commandControlHeld,
  } = useTreeViewState();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openTagKeys, setOpenTagKeys] = useState<string[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  const { palette } = useTheme();

  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setOpenTagKeys([]);
  };

  // Build totalFolderMap
  const totalFolderMap: Record<string, { totalDatasets: number }> = useMemo(() => {
    const map: Record<string, { totalDatasets: number }> = {};
    dataSetItems.forEach((item) => {
      const folderName = getTagValue(item.data.tags, AFA_DATASOURCE) ?? "Unknown";

      if (!map[folderName]) {
        map[folderName] = { totalDatasets: 0 };
      }

      map[folderName].totalDatasets += 1;
    });
    return map;
  }, [dataSetItems]);

  // Apply filters to datasets
  const filteredDataSets = useMemo(() => {
    const filterString = filter ?? "";
    return dataSetItems.filter((item) => {
      const matchesFilter = item.name.toLowerCase().includes(filterString.toLowerCase());

      const matchesTags = Object.entries(selectedTags).every(([key, values]) => {
        if (values.length === 0) return true;
        const tagValue = getTagValue(item.data.tags, key);
        return tagValue && values.includes(tagValue);
      });

      const matchesType = filterTypes.indexOf(item.data.type) >= 0;

      return matchesFilter && matchesTags && matchesType;
    });
  }, [dataSetItems, filter, selectedTags, filterTypes]);

  // Transform datasets into tree nodes
  const transformDataSetsToTreeNodes = useCallback((dataSets: IItem[], totalFolderMap: Record<string, { totalDatasets: number }>): TreeNode[] => {
    const folderMap: Record<string, TreeNode> = {};

    // Initialize folderMap with all folders
    for (const folderName in totalFolderMap) {
      folderMap[folderName] = {
        key: folderName,
        title: folderName,
        isFolder: true,
        isLeaf: false,
        children: [],
        totalDatasets: totalFolderMap[folderName].totalDatasets,
        filteredDatasets: 0,
        level: 0,
      };
    }

    dataSets.forEach((dataSet, index) => {
      const folderName = getTagValue(dataSet.data.tags, AFA_DATASOURCE) ?? "Unknown";
      const folder = folderMap[folderName];

      if (!folder) return;

      const key = dataSet.key ?? dataSet.data.id ?? index.toString();

      folder.children!.push({
        key,
        title: dataSet.name,
        dataSet: dataSet,
        index,
        id: dataSet.data.id,
        isFolder: false,
        isLeaf: true,
        parents: [folderName],
        level: 1,
      });

      folder.filteredDatasets! += 1;
    });

    // Convert folderMap to treeNodes array
    return Object.values(folderMap);
  }, []);

  const treeData = useMemo(
    () => transformDataSetsToTreeNodes(filteredDataSets, totalFolderMap),
    [filteredDataSets, totalFolderMap, transformDataSetsToTreeNodes]
  );

  // Flatten the tree data for multi-select
  const flatDataSetList = useMemo(() => {
    const list: TreeNode[] = [];
    let idx = 0;

    const traverse = (nodes: TreeNode[], parentKeys: string[] = []) => {
      nodes.forEach((node) => {
        const currentParents = [...parentKeys];
        if (node.isFolder) {
          currentParents.push(node.key);
        }
        if (node.isFolder && node.children) {
          traverse(node.children, currentParents);
        } else if (!node.isFolder) {
          node.indexInFlatList = idx++;
          node.parents = currentParents;
          list.push(node);
        }
      });
    };
    traverse(treeData);
    return list;
  }, [treeData]);

  const onExpand = (keys: React.Key[]) => {
    setExpandedKeys(keys as string[]);
  };

  const handleSelectAllInFolder = useCallback(
    (folderKey: string, areAllSelected: boolean) => {
      // Get all dataset IDs
      const folderDatasetIds = flatDataSetList
        .filter((dataset) => dataset.parents?.includes(folderKey))
        .map((dataset) => dataset.id!)
        .filter((id): id is string => id !== undefined);

      let newSelectedDataSet = [...selectedDataSet];

      if (areAllSelected) {
        newSelectedDataSet = newSelectedDataSet.filter((id) => !folderDatasetIds.includes(id));
      } else {
        newSelectedDataSet = Array.from(new Set([...newSelectedDataSet, ...folderDatasetIds]));
      }

      setSelectedDataSet(newSelectedDataSet);
    },
    [flatDataSetList, selectedDataSet, setSelectedDataSet]
  );

  const onClickDataSetWellList = useCallback(
    (clickedIndex: number, id: string, indexOnSelected: number, isChecked: boolean) => {
      const latestElIndex =
        selectedDataSet.length > 0 ? flatDataSetList.findIndex((item) => item.id === selectedDataSet[selectedDataSet.length - 1]) : 0;

      const startIndex = clickedIndex > latestElIndex ? latestElIndex : clickedIndex;
      const endIndex = clickedIndex > latestElIndex ? clickedIndex : latestElIndex;
      const newSelectedArray = flatDataSetList.slice(startIndex, endIndex + 1);

      let newSelectedDataSet = [...selectedDataSet];

      if (isChecked) {
        newSelectedDataSet.splice(indexOnSelected, 1);
      } else if (shiftHeld && newSelectedDataSet.length > 0 && selectedDataSet.length === 1) {
        newSelectedDataSet = newSelectedArray.map((item) => item.id!).filter((id): id is string => id !== undefined);
      } else if (commandControlHeld) {
        newSelectedDataSet.push(id);
      } else {
        newSelectedDataSet = [id];
      }

      setSelectedDataSet(newSelectedDataSet);
    },
    [commandControlHeld, flatDataSetList, selectedDataSet, setSelectedDataSet, shiftHeld]
  );

  const renderDatasetItem = useCallback(
    (item: TreeNode) => {
      const isExpanded = expandedKeys.includes(item.key);

      if (item.isFolder) {
        // if all selected in folder, then the radio button should be checked
        const folderDatasetIds = flatDataSetList
          .filter((dataset) => dataset.parents?.includes(item.key))
          .map((dataset) => dataset.id!)
          .filter((id): id is string => id !== undefined);

        const areAllDatasetsSelected = folderDatasetIds.every((id) => selectedDataSet.includes(id));

        return (
          <div
            key={item.key}
            className="dataset-folder-name"
            style={{ color: palette.primary.main, display: "flex", alignItems: "center", paddingLeft: `${item.level * 12}px` }}
          >
            <Radio
              className="folder-radio"
              size="small"
              checked={areAllDatasetsSelected}
              onClick={(e) => {
                e.stopPropagation();
                handleSelectAllInFolder(item.key, areAllDatasetsSelected);

                // Expand the folder when the Radio button is clicked
                const newExpandedKeys = [...expandedKeys];
                if (!newExpandedKeys.includes(item.key)) {
                  newExpandedKeys.push(item.key);
                  setExpandedKeys(newExpandedKeys);
                }
              }}
              style={{ color: palette.primary.main }}
              disableRipple
              disableFocusRipple
            />
            <button
              onClick={() => {
                const newExpandedKeys = isExpanded ? expandedKeys.filter((k) => k !== item.key) : [...expandedKeys, item.key];
                setExpandedKeys(newExpandedKeys);
              }}
              className="folder-button"
              style={{ color: palette.primary.main }}
            >
              {isExpanded ? <ExpandMoreIcon style={{ color: palette.primary.main }} /> : <ChevronRightIcon style={{ color: palette.primary.main }} />}
              {isExpanded ? (
                <FolderOpenIcon fontSize="small" style={{ color: palette.primary.main, marginLeft: 2 }} />
              ) : (
                <FolderIcon fontSize="small" style={{ color: palette.primary.main, marginLeft: 2 }} />
              )}
              <span style={{ marginLeft: 8 }}>
                {item.title} ({hasModifiedDefaultSelection ? `${item.filteredDatasets}/${item.totalDatasets}` : item.totalDatasets})
              </span>
            </button>
          </div>
        );
      } else {
        const indexOnSelected = selectedDataSet.indexOf(item.id!);
        const isChecked = indexOnSelected > -1;
        const isDataPreviewMode = window.location.href.includes("dataPreview");
        const clickedIndex = item.indexInFlatList!;

        return (
          <DataSetRadioItem
            onClickPreview={onClickPreview}
            key={`${item.key}-data-set-list`}
            onClick={() => {
              if (isDataPreviewMode) onClickPreview(item.id!, true);
              onClickDataSetWellList(clickedIndex, item.id!, indexOnSelected, isChecked);
            }}
            dataSet={item.title}
            currDataSet={item.dataSet!.data}
            setActiveDialog={setActiveDialog}
            checked={isChecked}
            className="noselect"
            disableMenu
          />
        );
      }
    },
    [
      expandedKeys,
      setExpandedKeys,
      onClickDataSetWellList,
      selectedDataSet,
      setActiveDialog,
      onClickPreview,
      palette.primary.main,
      hasModifiedDefaultSelection,
      flatDataSetList,
      handleSelectAllInFolder,
    ]
  );

  const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

  return (
    <div className="well-container">
      <InputField
        data-testid="well-filter-input"
        type="text"
        value={filter ?? ""}
        onChange={(value) => setFilter(typeof value === "string" ? value : String(value ?? ""))}
        styles={{ width: "96%" }}
        tinyLabel="Search..."
        debounceDelay={300}
        suffix={
          <IconButton onClick={handleFilterIconClick} data-testid="well-filter-icon" aria-label="Filter datasets">
            {hasModifiedDefaultSelection ? (
              <FilterAltIcon style={{ width: 25, height: 25, color: palette.primary.main }} />
            ) : (
              <FilterAltOutlinedIcon style={{ width: 25, height: 25 }} />
            )}
          </IconButton>
        }
      />
      <TagFilterMenu
        anchorEl={anchorEl}
        openTagKeys={openTagKeys}
        setOpenTagKeys={setOpenTagKeys}
        handleMenuClose={handleMenuClose}
        tags={tags}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        hasModifiedDefaultSelection={hasModifiedDefaultSelection}
      />
      <Tree
        onDragEnd={() => {
          onDropWell();
        }}
        onDragStart={(info) => {
          const item: any = info.node;
          const indexOnSelected = selectedDataSet.indexOf(item.id);
          const isChecked = indexOnSelected > -1;
          if (selectedDataSet.length === 0) onClickDataSetWellList(item.indexInFlatList, item.id, indexOnSelected, isChecked);
        }}
        draggable
        virtual
        height={height - 220}
        itemHeight={40}
        className="well-list noScrollbar"
        treeData={treeData}
        expandedKeys={expandedKeys}
        onExpand={onExpand}
        titleRender={(item) => renderDatasetItem(item)}
      />
    </div>
  );
};

export default DataSetList;
