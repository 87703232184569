import useAutoRtaAnalysis from "../../hooks/rtaAnalysis/useAutoRtaAnalysis";
import AnalysisSetting from "./AutoRtaAnalysisSetting";
import AnalysisCalculation from "./AnalysisCalculation";
import LoadingIndicator from "@/components/LoadingIndicator";

const AnalysisContainer = () => {
  const {
    analysisInputState,
    analysisCalculation,
    onChangeAnalysisInput,
    loadingState,
    onClickCalculateAnalysis,
    canCancelPoll,
    onCancelPoll,
    validationError,
  } = useAutoRtaAnalysis();

  // don't display anything if no analysisInputState
  if (!analysisInputState && loadingState) return <LoadingIndicator />;

  return (
    <div style={{ height: "93%", display: "grid", gridTemplateColumns: "470px 1fr", gridGap: 20, padding: "1em" }}>
      {analysisInputState && (
        <AnalysisSetting
          onChangeInput={onChangeAnalysisInput}
          analysisInput={analysisInputState}
          isLoading={loadingState}
          canCancelPoll={canCancelPoll}
          onCancelPoll={onCancelPoll}
          onClickCalculateAnalysis={onClickCalculateAnalysis}
          analysisSummaryCardParameter={analysisCalculation?.summary_card}
          errorInputValidation={validationError}
        />
      )}

      <AnalysisCalculation isLoading={loadingState} analysisCalculation={analysisCalculation} />
    </div>
  );
};

export default AnalysisContainer;
