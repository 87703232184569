import React, { PropsWithChildren, useContext, useEffect, useMemo, useState, useCallback } from "react";
import { shallow } from "zustand/shallow";
import _ from "lodash";

import { useAppStore } from "./features/app";
import { SettingStateV2, ProjectSetting, postSaveProjectSetting } from "./models/settings";
import { CustomError } from "./utils/apiFetcher";
import { useSettings } from "./SettingsState";

interface ProjectSettingsContextValue {
  settings: SettingStateV2;
  saveProjectSettings: (projectId: string, updatedSettings: ProjectSetting) => Promise<void>;
}

const ProjectSettingsContext = React.createContext<ProjectSettingsContextValue | undefined>(undefined);

export const useProjectSettings = () => {
  const context = useContext(ProjectSettingsContext);
  if (!context) {
    throw new Error("useProjectSettings must be used within a ProjectSettingsProvider");
  }
  return context;
};

export function useProjectSettingState<T>(key: string, projectId: string | undefined, fallbackValue: T): [T, (value: T) => void] {
  const context = useContext(ProjectSettingsContext);
  if (!context) {
    throw new Error("useProjectSettingState must be used within a ProjectSettingsProvider");
  }

  const { settings } = context;

  const [localValue, setLocalValue] = useState<T>(fallbackValue);

  useEffect(() => {
    if (projectId) {
      const projectSettings = settings[projectId]?.project_setting;
      const value = _.get(projectSettings, key, fallbackValue);
      setLocalValue(value);
    } else {
      setLocalValue(fallbackValue);
    }
  }, [settings, projectId, key, fallbackValue]);

  return [localValue, setLocalValue];
}

// this file is currently use for project setting: - forecast start & end date, spad decline settings
export const ProjectSettingsProviderV3 = ({ children }: PropsWithChildren<{}>) => {
  const { setApiError } = useAppStore(
    (state) => ({
      setApiError: state.setApiError,
    }),
    shallow
  );

  // !TODO! in future after all legacy save module data is gone,
  // move setting local state here
  const { settings, setSettings } = useSettings();

  const saveProjectSettings = useCallback(
    async (projectId: string, updatedSettings: ProjectSetting) => {
      try {
        const payload = {
          project_id: projectId,
          data: updatedSettings,
        };

        await postSaveProjectSetting(payload);

        setSettings((prevSettings) => ({
          ...prevSettings,
          [projectId]: {
            ...prevSettings[projectId],
            project_setting: updatedSettings,
          },
        }));
      } catch (error) {
        setApiError(error as CustomError);
      }
    },
    [setApiError, setSettings]
  );

  const contextValue = useMemo(
    () => ({
      settings,
      saveProjectSettings,
    }),
    [settings, saveProjectSettings]
  );

  return <ProjectSettingsContext.Provider value={contextValue}>{children}</ProjectSettingsContext.Provider>;
};
