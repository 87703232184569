import { useMemo } from "react";
import CustomCard from "@/components/Card";
import Tabs from "@/components/Tabs";

import { normalizedPressureXaxisOptions } from "../../constants";

// components
import NormalizedChart from "./NormalizedChart";
import AutoRtaAnalysisTable from "./AutoRtaAnalysisTable";

// hooks
import useAutoRtaAnalysisNormalizedPressure from "../../hooks/rtaAnalysis/useAutoRtaAnalysisNormalizedPressure";
import useAutoRtaAnalysisNormalizedRate from "../../hooks/rtaAnalysis/useAutoRtaAnalysisNormalizedRate";
import useAutoRtaAnalysisDataTable from "../../hooks/rtaAnalysis/useAutoRtaAnalysisDataTable";

import { AnalysisCalculation } from "@/models/gaz/autoRta";

export type AnalysisCalculationProps = {
  isLoading: boolean;
  analysisCalculation?: AnalysisCalculation;
};

const AnalysisCalculationContainer = ({ isLoading, analysisCalculation }: AnalysisCalculationProps) => {
  const {
    id: normalizedPressureId,
    onChangeDropdownOption: normalizedPressureChangeDropdownOption,
    selectedOption: normalizedPressureSelectedOption,
    series: normalizedPressureSeries,
    xAxes: normalizedPressureXAxes,
    yAxes: normalizedPressureYAxes,
  } = useAutoRtaAnalysisNormalizedPressure({
    analysisCalculation,
  });

  const {
    id: normalizedRateId,
    onChangeDropdownOption: normalizedRateChangeDropdownOption,
    selectedOption: normalizedRateSelectedOption,
    series: normalizedRateSeries,
    xAxes: normalizedRateXAxes,
    yAxes: normalizedRateYAxes,
  } = useAutoRtaAnalysisNormalizedRate({
    analysisCalculation,
  });

  const { columns, dataTableRows, modelCurvesRows } = useAutoRtaAnalysisDataTable(analysisCalculation);

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{"Normalized Pressure"}</span>,
        key: "Normalized Pressure",
        content: (
          <div
            style={{
              height: 620,
            }}
          >
            <NormalizedChart
              id={normalizedPressureId}
              dropDownOption={normalizedPressureXaxisOptions}
              isLoading={isLoading}
              onChangeDropdownOption={normalizedPressureChangeDropdownOption}
              selectedOption={normalizedPressureSelectedOption}
              series={normalizedPressureSeries}
              xAxes={normalizedPressureXAxes}
              yAxes={normalizedPressureYAxes}
            />
          </div>
        ),
      },
      {
        label: <span>{"Normalized Rate"}</span>,
        key: "Normalized Rate",
        content: (
          <div
            style={{
              height: 620,
            }}
          >
            <NormalizedChart
              id={normalizedRateId}
              dropDownOption={normalizedPressureXaxisOptions}
              isLoading={isLoading}
              onChangeDropdownOption={normalizedRateChangeDropdownOption}
              selectedOption={normalizedRateSelectedOption}
              series={normalizedRateSeries}
              xAxes={normalizedRateXAxes}
              yAxes={normalizedRateYAxes}
            />
          </div>
        ),
      },
      {
        label: <span>{"Data Table"}</span>,
        key: "Data Table",
        content: (
          <div style={{ position: "relative", height: "96%" }}>
            <AutoRtaAnalysisTable isLoading={isLoading} rows={dataTableRows} columns={columns} />
          </div>
        ),
      },
      {
        label: <span>{"Model Curves"}</span>,
        key: "Model Curves",
        content: (
          <div style={{ position: "relative", height: "96%" }}>
            <AutoRtaAnalysisTable isLoading={isLoading} rows={modelCurvesRows} columns={columns} />
          </div>
        ),
      },
    ];
  }, [
    columns,
    dataTableRows,
    isLoading,
    modelCurvesRows,
    normalizedPressureChangeDropdownOption,
    normalizedPressureId,
    normalizedPressureSelectedOption,
    normalizedPressureSeries,
    normalizedPressureXAxes,
    normalizedPressureYAxes,
    normalizedRateChangeDropdownOption,
    normalizedRateId,
    normalizedRateSelectedOption,
    normalizedRateSeries,
    normalizedRateXAxes,
    normalizedRateYAxes,
  ]);

  return (
    <CustomCard
      style={{
        display: "flex",
        height: "100%",
        opacity: !analysisCalculation ? 0.5 : 1,
        position: "relative",
      }}
    >
      {!analysisCalculation && <div className="overlay" />}
      <Tabs alwaysRender tabList={tabList} />
    </CustomCard>
  );
};

export default AnalysisCalculationContainer;
