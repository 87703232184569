import { Row } from "@silevis/reactgrid";

export const renderCell = (params: { row: { value: any } }) => {
  const value = params.row.value;
  if (value || String(value) === "0") {
    return value;
  }
  return <span style={{ color: "red" }}>Invalid</span>;
};

export const calculateHeight = (rows: Row<any>[]) => {
  let res = 0;
  rows.forEach((row) => (res += row.height ?? 25));

  return res;
};
export const transformHeaderOrNotation = (text: string = "") =>
  text.replaceAll("double_star", "**").replaceAll("on_", " / ").replaceAll("_", " ").replaceAll("star", "*").replaceAll("^2", "²");
