import { useCallback, useMemo, useState } from "react";
import { UseModelComponentInputProps } from "../KoldunCsgContext";
import { camelToSnakeCase, formatToScientific, mapEnumToDropdownReactGrid } from "@/utils/general";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import helpLinkUrl from "@/constants/helpLinkUrl";
import _ from "lodash";
import { tableCellStyle, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import dictionary from "@/constants/dictionary";
import { PowerLaw, SkinModelEnum, WellInputEnum } from "@/models/InputGeneric";
import { wellInputAreaBase } from "@/constants/gridTable";
import { KoldunCsgWellInput } from "@/models/koldunV2";

const wellInputHeader: { [key: string]: string } = {
  measure: dictionary.koldunCsg.measure,
  ...wellInputAreaBase,
  skinValue: dictionary.tahk.skin,
};

const defaultParam: { [key: string]: KoldunCsgWellInput["well_parameters"] } = {
  [WellInputEnum.Horizontal]: {
    horizontal_length: 100,
  },
  [WellInputEnum.Vertical]: null,
  [WellInputEnum.VerticalFractured]: {
    fracture_conductivity: 0.1,
    fracture_half_length: 10,
  },
};

const defaultPowerLawTable = {
  permeability_a: 1,
  permeability_b: 100,
  skin_a: 0.5,
  skin_b: 2,
};

const useWellInput = ({ loadingState, measures = [], setKoldunCsgState, powerLawSkin }: UseModelComponentInputProps) => {
  const [activeDropdown, setActiveDropdown] = useState<{ columnId: string; row: number } | null>(null);

  const showPowerLawTable = useMemo(() => {
    return Object.values(powerLawSkin ?? {}).filter((val) => !!val).length > 0;
  }, [powerLawSkin]);

  const inputColumns = useMemo(() => {
    return Object.keys(wellInputHeader).map((columnId, index) => {
      let width = 116;
      if (index === 0) width = 70;
      if (index === 2) width = 144;
      return { columnId: camelToSnakeCase(columnId), width };
    });
  }, []);

  const inputRows: Row<any>[] = useMemo(() => {
    if (!measures) return [];
    const clonedMeasures = _.cloneDeep(measures);
    const headerKeys = Object.keys(wellInputHeader);
    return [
      {
        rowId: "header",
        cells: headerKeys.map((header) => {
          return {
            type: "custom",
            text: wellInputHeader[header],
            style: tableHeaderStyle,
            link: helpLinkUrl.koldunCsgHelpLinks.wellInput?.[header] ?? null,
          };
        }),
        height: 50,
      },
      {
        rowId: "notation",
        cells: headerKeys.map((header: string) => {
          return {
            type: "header",
            text: dictionary.tableUnits[header],
            style: tableHeaderNotationStyle,
          };
        }),
      },
      ...clonedMeasures.map((measure, index) => {
        const wellInput: KoldunCsgWellInput = measure.well_inputs;
        const wellParam = measure.well_inputs.well_parameters;
        const measureParam = measure as any;
        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "number",
              value: index + 1,
              nonEditable: true,
              style: tableCellStyle,
            },
            ...headerKeys.slice(1, headerKeys.length).map((header, headerIndex) => {
              let val: any = null;
              if (headerIndex === 0 || headerIndex > 4) {
                val = measureParam[camelToSnakeCase(header)] ?? undefined;
              } else if (wellInput.hasOwnProperty(camelToSnakeCase(header))) {
                val = wellInput[camelToSnakeCase(header) as keyof KoldunCsgWellInput] ?? 0;
              } else {
                val = wellParam?.[camelToSnakeCase(header) as keyof KoldunCsgWellInput["well_parameters"]];
              }
              const nonEditable = (header === "skinValue" && measureParam.selected_skin_model === SkinModelEnum.PowerLaw) || loadingState;

              if (headerIndex === 1 || headerIndex === 5) {
                let wellInputValueDropdown = mapEnumToDropdownReactGrid(WellInputEnum);
                if (measure.number_of_layers > 1) {
                  wellInputValueDropdown = wellInputValueDropdown.filter((item) => item.value !== WellInputEnum.Horizontal);
                }
                return {
                  type: "dropdown",
                  selectedValue: val ?? undefined,
                  values: header === "selectedWellType" ? wellInputValueDropdown : mapEnumToDropdownReactGrid(SkinModelEnum),
                  style: tableCellStyle,
                  isOpen: !!(activeDropdown && activeDropdown.columnId === camelToSnakeCase(header) && index === activeDropdown.row),
                  nonEditable: loadingState,
                };
              } else if (isNaN(Number(val)) && String(val) === "undefined") {
                return {
                  type: "text",
                  text: "-",
                  style: {
                    ...tableCellStyle,
                    background: nonEditable ? "#f3f2f1" : "#fff",
                  },
                  nonEditable: true,
                };
              }

              return {
                type: "text",
                text: formatToScientific(Number(val)),
                style: {
                  ...tableCellStyle,
                  background: nonEditable ? "#f3f2f1" : "#fff",
                },
                nonEditable,
              };
            }),
          ],
        };
      }),
    ];
  }, [measures, loadingState, activeDropdown]);

  const updateDropdownOption = useCallback(
    (change: CellChange<any>) => {
      let { rowId, columnId, newCell, previousCell } = change;
      const prevCell = previousCell as DropdownCell;
      const dropDownNewCell = newCell as DropdownCell;
      rowId = rowId as number;
      columnId = columnId as string;

      const newDropdown = {
        row: rowId,
        columnId: columnId,
      };

      if (prevCell.isOpen !== dropDownNewCell.isOpen || !_.isEqual(newDropdown, activeDropdown)) {
        if (dropDownNewCell.isOpen) {
          setActiveDropdown(newDropdown);
          return false;
        } else {
          setActiveDropdown(null);
          if (prevCell.selectedValue === newCell.selectedValue) return false;
          return true;
        }
      }
    },
    [activeDropdown]
  );

  const onChangeCells = useCallback(
    (changes: CellChange[]) => {
      const updatedRows = [...measures];

      let newPowerLaw: PowerLaw | null = { ...powerLawSkin } as PowerLaw;
      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell, type } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        const wellInput: any = updatedRows[rowId].well_inputs;
        let wellParam: { [key: string]: any } | null = updatedRows[rowId]?.well_inputs?.well_parameters ?? {};
        let newCellVal = newCell.text ?? 0;

        if (type === "dropdown") {
          const update = updateDropdownOption(change);
          if (!update) return;
          const dropdownVal = dropDownNewCell.selectedValue ?? "";

          if (prevCell.selectedValue !== dropdownVal) {
            if (columnId === "selected_well_type") {
              wellInput[columnId] = dropdownVal;
              updatedRows[rowId].well_inputs.well_parameters = _.cloneDeep(defaultParam[dropdownVal]);
            } else {
              updatedRows[rowId].selected_skin_model = dropdownVal as SkinModelEnum;
              if (dropdownVal === SkinModelEnum.PowerLaw) {
                newPowerLaw = _.cloneDeep(defaultPowerLawTable);
                updatedRows[rowId].skin_value = null;
              } else {
                updatedRows[rowId].skin_value = 0;
              }
            }
          }
        }
        if (columnId === "wellbore_radius" || columnId === "skin_value") {
          updatedRows[rowId][columnId] = newCellVal;
        } else if (wellParam) {
          wellParam[columnId] = newCellVal;
        }
      }
      const isAllLayerConstant = updatedRows.filter((row) => row.selected_skin_model === SkinModelEnum.PowerLaw).length === 0;
      if (isAllLayerConstant) {
        newPowerLaw = null;
      }

      setKoldunCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            power_law_skin: newPowerLaw,
            measures: updatedRows,
          },
        };
      });
    },
    [measures, powerLawSkin, setKoldunCsgState, updateDropdownOption]
  );

  const powerLawColumns = [
    { columnId: "permeability", width: 150 },
    { columnId: "skin", width: 150 },
  ];

  const powerLawRows = [
    {
      rowId: "header",
      cells: powerLawColumns.map((header) => {
        return {
          type: "header",
          text: (dictionary.tahk as { [key: string]: string })[header.columnId],
          style: tableHeaderStyle,
        };
      }),
      height: 50,
    },
    {
      rowId: "notation",
      cells: powerLawColumns.map((header) => {
        return {
          type: "header",
          text: (dictionary.tableUnits as { [key: string]: string })[header.columnId],
          style: tableHeaderNotationStyle,
        };
      }),
    },
    {
      rowId: "a",
      cells: [
        {
          type: "text",
          text: formatToScientific(Number(powerLawSkin?.permeability_a)) ?? "",
          style: tableCellStyle,
          nonEditable: loadingState,
        },
        {
          type: "text",
          text: formatToScientific(Number(powerLawSkin?.skin_a)) ?? "",
          style: tableCellStyle,
          nonEditable: loadingState,
        },
      ],
    },
    {
      rowId: "b",
      cells: [
        {
          type: "text",
          text: formatToScientific(Number(powerLawSkin?.permeability_b)) ?? "",
          style: tableCellStyle,
          nonEditable: loadingState,
        },
        {
          type: "text",
          text: formatToScientific(Number(powerLawSkin?.skin_b)) ?? "",
          style: tableCellStyle,
          nonEditable: loadingState,
        },
      ],
    },
  ];

  const onChangePowerLawSkin = useCallback(
    (changes: CellChange[]) => {
      if (!powerLawSkin) return;
      const updatedPowerLaw = { ...powerLawSkin };

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell } = change as CellChange<any>;

        rowId = rowId as number;
        columnId = columnId as string;

        const powerLaw: { [key: string]: any } = updatedPowerLaw;

        const key = `${columnId}_${rowId}`;

        const newVal = newCell.value ?? newCell.text ?? 0;
        powerLaw[key] = newVal <= 0 ? 1 : newVal;
      }

      setKoldunCsgState((prev) => {
        if (!prev) return prev;
        return {
          ...prev,
          inputs: {
            ...prev.inputs,
            power_law_skin: updatedPowerLaw,
          },
        };
      });
    },
    [powerLawSkin, setKoldunCsgState]
  );

  return {
    inputColumns,
    inputRows,
    onChangeCells,
    onChangePowerLawSkin,
    powerLawColumns,
    powerLawRows,
    showPowerLawTable,
  };
};

export default useWellInput;
