import DropdownField from "@/components/fields/DropdownField";
import InputField from "@/components/fields/InputField";
import dictionary from "@/constants/dictionary";
import { PressureFmbDataEnum, WellboreFmbInput, WellboreFmbModelEnum } from "@/models/autoFmb";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { mapEnumToDropdown } from "@/utils/general";

type WellboreInputProps = {
  wellboreInput?: WellboreFmbInput;
  isLoading: boolean;
  updateInputValue: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
};

const WellboreInput = ({ wellboreInput, isLoading, updateInputValue, validationError }: WellboreInputProps) => {
  const displayRestInput = wellboreInput?.selected_wellbore_model !== WellboreFmbModelEnum.NoModel;
  const displayFlowingPressureConstant = wellboreInput?.selected_pressure_data === PressureFmbDataEnum.ConstantPressure;
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 3fr",
        gridGap: 20,
      }}
    >
      <div>
        <DropdownField
          label={dictionary.inputPage.flowPressureType}
          options={mapEnumToDropdown(PressureFmbDataEnum)}
          selectedKey={wellboreInput?.selected_pressure_data}
          onChange={(v) => updateInputValue(v, "selected_pressure_data", "wellbore_model")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.wellbore_model.selected_pressure_data"
        />
        {displayFlowingPressureConstant && (
          <InputField
            label={dictionary.inputPage.flowingPressureConstant}
            suffix="in"
            debounceDelay={1000}
            value={wellboreInput?.constant_flowing_pressure}
            onChange={(v) => updateInputValue(v, "constant_flowing_pressure", "wellbore_model")}
            disabled={isLoading}
            errors={validationError}
            keyField={"inputs.wellbore_model.constant_flowing_pressure"}
          />
        )}
        <DropdownField
          label={dictionary.inputPage.wellboreModel}
          options={mapEnumToDropdown(WellboreFmbModelEnum)}
          selectedKey={wellboreInput?.selected_wellbore_model}
          onChange={(v) => updateInputValue(v, "selected_wellbore_model", "wellbore_model")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.wellbore_model.selected_wellbore_model"
        />
        {displayRestInput && wellboreInput?.parameters && (
          <>
            <InputField
              label={dictionary.inputPage.tubingDiameter}
              suffix="in"
              debounceDelay={1000}
              value={wellboreInput.parameters?.tubing_diameter}
              onChange={(v) =>
                updateInputValue(
                  {
                    ...wellboreInput?.parameters,
                    tubing_diameter: v,
                  },
                  "parameters",
                  "wellbore_model"
                )
              }
              disabled={isLoading}
              errors={validationError}
              keyField={"inputs.wellbore_model.parameters.tubing_diameter"}
            />
            <InputField
              label={dictionary.inputPage.tubingRoughness}
              suffix="in"
              debounceDelay={1000}
              value={wellboreInput.parameters?.tubing_roughness}
              onChange={(v) =>
                updateInputValue(
                  {
                    ...wellboreInput?.parameters,
                    tubing_roughness: v,
                  },
                  "parameters",
                  "wellbore_model"
                )
              }
              disabled={isLoading}
              errors={validationError}
              keyField={"inputs.wellbore_model.parameters.tubing_roughness"}
            />

            <InputField
              label={dictionary.inputPage.desorptionPressure}
              suffix="ft"
              debounceDelay={1000}
              value={wellboreInput.parameters?.tubing_depth}
              onChange={(v) =>
                updateInputValue(
                  {
                    ...wellboreInput?.parameters,
                    tubing_depth: v,
                  },
                  "parameters",
                  "wellbore_model"
                )
              }
              disabled={isLoading}
              errors={validationError}
              keyField={"inputs.wellbore_model.parameters.tubing_depth"}
            />
            <InputField
              label={dictionary.inputPage.wellheadTemperature}
              suffix="°F"
              debounceDelay={1000}
              value={wellboreInput.parameters?.wellhead_temperature}
              onChange={(v) =>
                updateInputValue(
                  {
                    ...wellboreInput?.parameters,
                    wellhead_temperature: v,
                  },
                  "parameters",
                  "wellbore_model"
                )
              }
              disabled={isLoading}
              errors={validationError}
              keyField={"inputs.wellbore_model.parameters.wellhead_temperature"}
            />
            <InputField
              label={dictionary.inputPage.wellAngle}
              suffix="°"
              debounceDelay={1000}
              value={wellboreInput.parameters?.well_angle}
              onChange={(v) =>
                updateInputValue(
                  {
                    ...wellboreInput?.parameters,
                    well_angle: v,
                  },
                  "parameters",
                  "wellbore_model"
                )
              }
              disabled={isLoading}
              errors={validationError}
              keyField={"inputs.wellbore_model.parameters.well_angle"}
            />
          </>
        )}
      </div>
      <div />
    </div>
  );
};

export default WellboreInput;
