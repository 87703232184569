import { useState, useRef, useEffect, useCallback } from "react";

interface UseResizableSidebarProps {
  initialWidth: number;
  minWidth: number;
  maxWidthFunc: (sectionRect: DOMRect) => number;
  setWidthSetter: (width: number) => void;
  onCollapse?: () => void;
}

export function useResizableSidebar({ initialWidth, minWidth, maxWidthFunc, setWidthSetter, onCollapse }: UseResizableSidebarProps) {
  const [width, setWidth] = useState<number>(initialWidth);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  const onMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const section = sectionRef.current;
        if (!section) return;

        const sectionRect = section.getBoundingClientRect();
        const maxWidth = maxWidthFunc(sectionRect);
        const newWidth = e.clientX - sectionRect.left;

        if (newWidth < 150) {
          setIsDragging(false);
          setWidth(initialWidth);
          if (onCollapse) onCollapse();
        } else {
          const adjustedWidth = Math.max(minWidth, Math.min(newWidth, maxWidth));
          setWidth(adjustedWidth);
          setWidthSetter(adjustedWidth);
        }
      }
    };

    const onMouseUp = () => {
      if (isDragging) setIsDragging(false);
    };

    if (isDragging) {
      window.addEventListener("mousemove", onMouseMove);
      window.addEventListener("mouseup", onMouseUp);
    }

    return () => {
      if (isDragging) {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
      }
    };
  }, [isDragging, initialWidth, minWidth, maxWidthFunc, onCollapse, setWidthSetter]);

  return { width, onMouseDown, sectionRef };
}
