import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { CellChange, Row } from "@silevis/reactgrid";
import _ from "lodash";

import { isDataSet } from "@/model";
import { groupRunSchema, postInitializeGroupRun, RulonGroupRunResponse } from "@/models/rulon";
import dictionary from "@/constants/dictionary";

import { tableCellStyle } from "@/components/CustomTable";
import { usePolling } from "@/utils/apiFetcher";
import { calculateRullon } from "@/constants/apiUrl";
import { parseErrorThrown } from "@/utils/errorHandling";
import { GenericPollProps, GroupRunGenericProps } from "./type";

const useGroupRun = ({
  selectedDataSets,
  isLoading,
  project,
  setApiError,
  setIsLoading,
  setPollStatus,
  setProgress,
  apiError,
}: GroupRunGenericProps & GenericPollProps) => {
  const [rulonState, setRulonState] = useState<RulonGroupRunResponse[] | null>();

  const [selectAllState, setSelectAllState] = useState<{ [key: string]: boolean }>({});

  const haveInitialize = useRef(false);

  const { createPoll, canCancelPoll, onCancelPoll } = usePolling({
    setApiError,
    apiError,
    setLoadingState: setIsLoading,
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
  });

  const dataSetsFullInfo = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets];
    return selectedDataSets ?? [];
  }, [selectedDataSets]);

  const dataSets = useMemo(() => {
    return dataSetsFullInfo?.map((dataset) => dataset.id);
  }, [dataSetsFullInfo]);

  const initializeRulonGroupRun = useCallback(async () => {
    try {
      if (!project || !dataSets || dataSets.length === 0) return;
      const res = await postInitializeGroupRun(project?.id ?? "", dataSets);

      if (res.data && !rulonState) {
        const parsed = groupRunSchema.array().parse(res.data);
        const remmaped = parsed.map((item) => ({
          ...item,
          name: dataSetsFullInfo?.find((dataset) => dataset.id === item.data_set_id)?.name ?? "",
        }));

        setRulonState(remmaped);
      }
    } catch (error) {
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
      console.log(error);
    }
  }, [apiError, dataSets, dataSetsFullInfo, project, rulonState, setApiError]);

  useEffect(() => {
    if (haveInitialize.current) return;
    haveInitialize.current = true;
    initializeRulonGroupRun();
  }, [initializeRulonGroupRun]);

  const loading = isLoading;

  const rulonColumns = useMemo(() => {
    if (!rulonState || rulonState?.length === 0) return [];

    return ["data_set_id", ...Object.keys(rulonState[0]).filter((item) => item !== "data_set_id" && item !== "name")].map((columnId, index) => {
      return { columnId: columnId, width: index === 0 ? 230 : 180 };
    });
  }, [rulonState]);

  console.log(rulonColumns);
  const rulonRows: Row<any>[] = useMemo(() => {
    const customHeaderStyle = {
      justifyContent: "center",
      fontWeight: 600,
      textOverflow: "ellipsis",
      whiteSpace: "break-spaces",
      textAlign: "center",
      borderColor: "grey",
    };

    const header = {
      rowId: "header",
      cells: rulonColumns.map((header, index) => {
        const style = {
          ...customHeaderStyle,
          border: {
            bottom: {
              width: "1px",
              color: "black",
            },
            right: {},
          },
        };

        if (index === 0) {
          style.border.right = {
            width: "1px",
            color: "black",
          };
        }
        return {
          type: "text",
          text: index === 0 ? dictionary.rulon.data_set_id : dictionary.moduleName[String(header.columnId)],
          style,
        };
      }),
      height: 55,
    };

    if (!rulonState) return [header];

    const selectAllRow = {
      rowId: "bulkSelect",
      cells: rulonColumns.map((header, index) => {
        const cellStyle: any = {
          ...customHeaderStyle,
          border: {
            bottom: {
              width: "1px",
              color: "black",
            },
            right: {},
          },
          borderColor: "grey",
        };

        if (index === 0) {
          cellStyle.border.right = {
            width: "1px",
            color: "black",
          };
        }
        if (index === 0) {
          return {
            type: "text",
            text: dictionary.rulon.selectDeselect,
            style: cellStyle,
          };
        }

        return {
          type: "checkbox",
          checked: !!selectAllState[header.columnId],
          style: cellStyle,
        };
      }),
      height: 35,
    };

    const modules = _.cloneDeep(rulonState);
    return [
      header,
      selectAllRow,
      ...modules.map((row: any, rowIndex) => {
        return {
          rowId: rowIndex,
          height: 30,
          cells: [
            ...rulonColumns.map((header, index) => {
              if (index === 0) {
                return {
                  type: "text",
                  text: row.name ?? "-",
                  style: {
                    ...tableCellStyle,
                    border: {
                      right: {
                        width: "1px",
                        color: "black",
                      },
                    },
                  },
                  nonEditable: true,
                };
              }
              return {
                type: "checkbox",
                checked: Boolean(row[header.columnId]),
                style: tableCellStyle,
                nonEditable: loading,
              };
            }),
          ],
        };
      }),
    ];
  }, [loading, rulonColumns, rulonState, selectAllState]);

  const onChangeCell = useCallback(
    (changes: CellChange[]) => {
      if (!rulonState) return;
      let updatedRows: any = [...rulonState];

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, previousCell } = change as CellChange<any>;

        rowId = rowId as number;
        columnId = columnId as string;

        if (String(rowId) === "bulkSelect") {
          setSelectAllState((prev) => ({
            ...prev,
            [columnId]: !previousCell.checked,
          }));

          updatedRows = updatedRows.map((row: { [x: string]: boolean }) => {
            row[columnId] = !previousCell.checked;
            return row;
          });
        } else {
          setSelectAllState((prev) => ({
            ...prev,
            [columnId]: false,
          }));
          updatedRows[rowId][columnId] = !previousCell.checked;
        }
      }
      setRulonState(updatedRows);
    },
    [rulonState]
  );

  const onCalculateRulon = useCallback(async () => {
    try {
      if (!project || !rulonState) return;

      const res = await createPoll<
        any,
        {
          selection: RulonGroupRunResponse[];
        }
      >({
        path: calculateRullon(project?.id),
        body: {
          selection: rulonState,
        },
        type: "post",
      });

      if (res.task_result) {
        setApiError({
          severity: "success",
          message: dictionary.rulon.successGroupRun,
        });
        setTimeout(() => {
          setApiError();
        }, 3500);
      }
    } catch (error) {
      parseErrorThrown({
        error,
        setApiError,
        apiError,
      });
      console.log(error);
    }
  }, [project, rulonState, createPoll, setApiError, apiError]);

  return {
    loading,
    rulonRows,
    rulonColumns,
    onChangeCell,
    onCalculateRulon,
    rulonState,
    canCancelPoll,
    onCancelPoll,
  };
};

export default useGroupRun;
