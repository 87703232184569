import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DateTime } from "luxon";

import dictionary from "@/constants/dictionary";
import { getLicense, LicenseInfo, licenseInfoSchema } from "@/models/license";

const useLicenseInfo = () => {
  const haveInitialize = useRef(false);
  const [licenseInfo, setLicenseInfo] = useState<LicenseInfo>();

  const onGetLicense = useCallback(async () => {
    try {
      const res = await getLicense();
      if (res.data) {
        const parsed = licenseInfoSchema.parse(res.data);
        setLicenseInfo(parsed);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  useEffect(() => {
    if (haveInitialize.current) return;
    onGetLicense();
  }, [onGetLicense]);

  const expiryDateMessage: string | null = useMemo(() => {
    if (!licenseInfo) return null;

    const expiryDate = DateTime.fromISO(licenseInfo?.end_date).diff(DateTime.now(), "days").days;

    return expiryDate > 30 ? null : dictionary.license.demoLicenseExpiry(Math.ceil(expiryDate));
  }, [licenseInfo]);

  return { expiryDateMessage };
};

export default useLicenseInfo;
