import { z } from "zod";

export type SettingsKey = string;

const SettingsObjectSchema = z.record(z.any());
// currently shared this state with fe state -> but fe state need to accommodate both legacy and new one
// that is why we still need the legacy structure for now, because the only difference is group id
const GroupItemSchema = z.object({
  project_id: z.string(),
  // legacy still need group id
  group_id: z.string().nullable().optional(),
  data_set_ids: z.array(z.string()),
  module: z.string().nullable(),
  data: SettingsObjectSchema,
  updated: z.boolean().optional(),
});

const SpadDeclineSettingSchema = z.object({
  min_arps_exponent: z.number().min(0).max(1),
  max_arps_exponent: z.number().min(0).max(1),
  low_case_percentile: z.number().min(0).max(100),
  mid_case_percentile: z.number().min(0).max(100),
  high_case_percentile: z.number().min(0).max(100),
});

const ProjectSettingSchema = z
  .object({
    forecast_start_date: z.string().nullable(),
    forecast_end_date: z.string().nullable(),
    spad_decline_setting: SpadDeclineSettingSchema,
  })
  .catchall(z.any());

const ProjectGroupsSchema = z.record(z.string(), z.array(GroupItemSchema));

export const ProjectStateSchema = z.object({
  project_setting: ProjectSettingSchema,
  groups: ProjectGroupsSchema,
});

export const SettingStateV2Schema = z.record(z.string(), ProjectStateSchema);

const AllWellSettingResponseSchema = z.record(
  z.object({
    project_setting: ProjectSettingSchema.nullable(),
    groups: ProjectGroupsSchema,
  })
);

export type WellSettingLegacyProject = {
  project_id: string;
  group_id: string;
  data_set_ids: string;
  data: any;
};

const SaveProjectSettingPayloadSchema = z.object({
  project_id: z.string(),
  data: ProjectSettingSchema,
});

export type SpadDeclineSetting = z.infer<typeof SpadDeclineSettingSchema>;
export type ProjectSetting = z.infer<typeof ProjectSettingSchema>;
export type SettingsObject = z.infer<typeof SettingsObjectSchema>;
export type GroupItem = z.infer<typeof GroupItemSchema>;
export type ProjectGroups = z.infer<typeof ProjectGroupsSchema>;
export type ProjectState = z.infer<typeof ProjectStateSchema>;
export type SettingStateV2 = z.infer<typeof SettingStateV2Schema>;
export type AllWellSettingResponse = z.infer<typeof AllWellSettingResponseSchema>;
export type SaveProjectSettingPayload = z.infer<typeof SaveProjectSettingPayloadSchema>;

// permission

enum MenuActionEnum {
  file = "file",
  builtin = "builtin",
}

const permissionMenuScheme = z
  .object({
    id: z.string(),
    label: z.string().nullable(),
    api_url: z.string().nullable(),
    action: z.nativeEnum(MenuActionEnum).nullable().optional(),
  })
  .strict();

const permissionsScheme = z.record(z.string(), z.record(z.string(), permissionMenuScheme.array()));

export type NavPermissions = z.infer<typeof permissionsScheme>;
