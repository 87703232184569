import React, { useMemo } from "react";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";
import useMachineLearning from "./hooks/useMachineLearning";
import FossilyticsChart from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";
import helpLinkUrl from "@/constants/helpLinkUrl";

const MachineLearningModule = () => {
  const { selectedDataSets, isLoading, project, setApiError, apiError } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      project: state.project,
      setApiError: state.setApiError,
      apiError: state.apiError,
    }),
    shallow
  );

  const { loading, xAxes, yAxes, series, progress } = useMachineLearning({
    selectedDataSets,
    setApiError,
    apiError,
    project,
  });

  const loadingText = useMemo(() => {
    if (!progress) return undefined;
    let progressText = `${progress.pollStatus ?? ""} `;
    if (progress.progress) progressText += `${progress.progress} %`;

    return progressText;
  }, [progress]);

  return (
    <div
      style={{
        height: 700,
        padding: 20,
      }}
    >
      <h3 className="primaryColor">
        <a className="primaryColor" target="_blank" rel="noreferrer" href={helpLinkUrl.machineLearning}>
          {dictionary.machineLearning.title}
        </a>
      </h3>
      <FossilyticsChart
        id={"machine-learning-chart"}
        key={"machine-learning-chart"}
        isLoading={loading || isLoading}
        customLoadingText={loadingText}
        xAxes={xAxes}
        yAxes={yAxes}
        series={series}
      />
    </div>
  );
};

export default MachineLearningModule;
