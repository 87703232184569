import { useCallback } from "react";
import {
  ModuleKoldunMcsimUncertainParamDistribution,
  ModuleKoldunMcsimUncertainParamType,
} from "../modules/koldun/mcsim/gasoil/ModuleKoldunMcsimGasOilContent/model";
import { mapEnumToDropdown } from "@/utils/general";
import DropdownField from "./fields/DropdownField";
import InputField from "./fields/InputField";

interface UncertainParamInputProps {
  name: string;
  units: string;
  value: ModuleKoldunMcsimUncertainParamType;
  onChange: (value: ModuleKoldunMcsimUncertainParamType) => void;
  hideDistributionSelect: boolean;
  disabled?: boolean;
  disableDistributionSelect?: boolean;
  min?: number;
  max?: number;
  customValue?: boolean;
}

const UncertainParamInput = ({
  name,
  units,
  value,
  onChange,
  disabled,
  disableDistributionSelect,
  hideDistributionSelect,
  min,
  max,
  customValue,
}: UncertainParamInputProps) => {
  const onDistributionChange = useCallback(
    (v: any) => {
      switch (v) {
        case ModuleKoldunMcsimUncertainParamDistribution.fixed:
          onChange({ distribution: ModuleKoldunMcsimUncertainParamDistribution.fixed, value: 0 });
          break;
        case ModuleKoldunMcsimUncertainParamDistribution.uniform:
          onChange({ distribution: ModuleKoldunMcsimUncertainParamDistribution.uniform, low: 0, high: 0 });
          break;
        case ModuleKoldunMcsimUncertainParamDistribution.triangular:
          onChange({
            distribution: ModuleKoldunMcsimUncertainParamDistribution.triangular,
            left: 0,
            mode: 0,
            right: 0,
          });
          break;
        case ModuleKoldunMcsimUncertainParamDistribution.lognormal:
          onChange({ distribution: ModuleKoldunMcsimUncertainParamDistribution.lognormal, mean: 0, sigma: 0 });
          break;
        case ModuleKoldunMcsimUncertainParamDistribution.normal:
          onChange({ distribution: ModuleKoldunMcsimUncertainParamDistribution.normal, mean: 0, std: 0 });
          break;
      }
    },
    [onChange]
  );

  const renderInputField = useCallback(() => {
    switch (value.distribution) {
      case ModuleKoldunMcsimUncertainParamDistribution.fixed:
        return (
          <InputField
            label={`${name}`}
            suffix={units}
            styles={{
              width: 250,
            }}
            disabled={disabled}
            min={min}
            max={max}
            value={value.value}
            customValue={customValue}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    value: Number(v),
                  })
                : null
            }
          />
        );
      case ModuleKoldunMcsimUncertainParamDistribution.uniform:
        return (
          <>
            <InputField
              label={`${name} low`}
              suffix={units}
              styles={{ width: 250, marginRight: 20 }}
              disabled={disabled}
              value={value.low}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      low: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label={`${name} high`}
              suffix={units}
              styles={{ width: 250 }}
              disabled={disabled}
              value={value.high}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      high: Number(v),
                    })
                  : null
              }
            />
          </>
        );
      case ModuleKoldunMcsimUncertainParamDistribution.triangular:
        return (
          <>
            <InputField
              label={`${name} left`}
              suffix={units}
              styles={{ width: 250, marginRight: 20 }}
              disabled={disabled}
              value={value.left}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      left: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label={`${name} mode`}
              suffix={units}
              styles={{ width: 250, marginRight: 20 }}
              disabled={disabled}
              value={value.mode}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      mode: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label={`${name} right`}
              suffix={units}
              styles={{ width: 250 }}
              disabled={disabled}
              value={value.right}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      right: Number(v),
                    })
                  : null
              }
            />
          </>
        );
      case ModuleKoldunMcsimUncertainParamDistribution.lognormal:
        return (
          <>
            <InputField
              label={`${name} mean`}
              suffix={units}
              styles={{ width: 250, marginRight: 20 }}
              disabled={disabled}
              value={value.mean}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      mean: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label={`${name} sigma`}
              suffix={units}
              styles={{ width: 250 }}
              disabled={disabled}
              value={value.sigma}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      sigma: Number(v),
                    })
                  : null
              }
            />
          </>
        );
      default:
        return (
          <>
            <InputField
              label={`${name} mean`}
              suffix={units}
              disabled={disabled}
              value={value.mean}
              styles={{ width: 250, marginRight: 20 }}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      mean: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label={`${name} standard deviation`}
              suffix={units}
              disabled={disabled}
              value={value.std}
              styles={{ width: 250 }}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      std: Number(v),
                    })
                  : null
              }
            />
          </>
        );
    }
  }, [customValue, disabled, max, min, name, onChange, units, value]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {!hideDistributionSelect && (
        <DropdownField
          label={`${name} distribution`}
          options={mapEnumToDropdown(ModuleKoldunMcsimUncertainParamDistribution)}
          style={{ width: 250, marginTop: 0, marginRight: 20 }}
          disabled={disabled || disableDistributionSelect}
          selectedKey={value.distribution}
          onChange={(v) => onDistributionChange(v)}
        />
      )}

      {renderInputField()}
    </div>
  );
};

export default UncertainParamInput;
