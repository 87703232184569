import React from "react";
import { shallow } from "zustand/shallow";

import Button from "@mui/material/Button";

import { useAppStore } from "@/features/app";

import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import LoadingIndicator from "@/components/LoadingIndicator";
import dictionary from "@/constants/dictionary";
import CustomCard from "@/components/Card";

import useGroupRun from "../hooks/useGroupRun";

const GroupRun = () => {
  const { selectedDataSets, isLoading, project, setApiError, setIsLoading, setPollStatus, setProgress, apiError } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      project: state.project,
      setApiError: state.setApiError,
      setIsLoading: state.setIsLoading,
      setProgress: state.setProgress,
      setPollStatus: state.setPollStatus,
      apiError: state.apiError,
    }),
    shallow
  );

  const { loading, rulonRows, rulonColumns, onChangeCell, onCalculateRulon, rulonState, canCancelPoll, onCancelPoll } = useGroupRun({
    project,
    isLoading,
    selectedDataSets,
    setApiError,
    setIsLoading,
    setPollStatus,
    setProgress,
    apiError,
  });

  const groupRunButtonStateDisabled = !canCancelPoll ? loading || !rulonState : false;

  return (
    <div
      style={{
        padding: 10,
        overflow: "auto",
      }}
    >
      <CustomCard style={{ width: 1080, margin: "auto" }}>
        {loading && <LoadingIndicator />}

        <ReactGridTable
          className="noScrollbarAll"
          innerStyle={{
            display: "flex",
            marginTop: 23,
          }}
          viewWidth={"100%"}
          viewHeight={600}
          columns={rulonColumns}
          rows={rulonRows}
          enableRangeSelection
          enableColumnSelection
          stickyTopRows={2}
          onCellsChanged={onChangeCell}
        />

        <Button
          onClick={(e) => {
            e.preventDefault();
            canCancelPoll ? onCancelPoll() : onCalculateRulon();
          }}
          style={{ color: "white", marginTop: 20, width: "100%" }}
          variant="contained"
          disabled={groupRunButtonStateDisabled}
        >
          {canCancelPoll ? dictionary.genericButton.cancel : dictionary.rulon.groupRun}
        </Button>
      </CustomCard>
    </div>
  );
};

export default GroupRun;
