import React, { useMemo } from "react";

import InputField from "@/components/fields/InputField";
import Tabs from "@/components/Tabs";
import CustomDataTable from "@/components/CustomTable";
import FossilyticsChart from "@/components/FossilyticsChart";
import DropdownField from "@/components/fields/DropdownField";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { mapEnumToDropdown } from "@/utils/general";
import { WaterPvt, WaterCompressibilityCorrelation, WaterPvtDataTable, WaterViscosityCorrelation } from "@/models/InputGeneric";
import useWaterPvt from "./useWaterPvt";
import dictionary from "@/constants/dictionary";

type InputWaterPvtProps = {
  waterPvtInput?: WaterPvt;
  isLoading: boolean;
  updateInputValue: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
  waterPvtCalculation?: WaterPvtDataTable;
};
const InputWaterPvt = ({ waterPvtInput, waterPvtCalculation, isLoading, updateInputValue, validationError }: InputWaterPvtProps) => {
  const { waterPvtDataTableRows, waterPvtDataTableCols, waterPvtMu, waterPvtPw, waterPvtBw, waterPvtCw } = useWaterPvt({
    waterPvtCalculation,
  });

  const tabList = useMemo(() => {
    return [
      {
        label: (
          <span>
            μ<sub>{"w"}</sub>
          </span>
        ),
        key: "μw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_mw"
              isLoading={isLoading}
              xAxes={waterPvtMu.xAxes}
              yAxes={waterPvtMu.yAxes}
              series={waterPvtMu.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            p<sub>{"w"}</sub>
          </span>
        ),
        key: "pw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_pw"
              isLoading={isLoading}
              xAxes={waterPvtPw.xAxes}
              yAxes={waterPvtPw.yAxes}
              series={waterPvtPw.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            B<sub>{"w"}</sub>
          </span>
        ),
        key: "Bw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_bw"
              isLoading={false}
              xAxes={waterPvtBw.xAxes}
              yAxes={waterPvtBw.yAxes}
              series={waterPvtBw.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            c<sub>{"w"}</sub>
          </span>
        ),
        key: "cw",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="water_pvt_cw"
              isLoading={false}
              xAxes={waterPvtCw.xAxes}
              yAxes={waterPvtCw.yAxes}
              series={waterPvtCw.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: <span>Data table</span>,
        key: "Data table",
        content: (
          <div className="chartWrapper">
            <CustomDataTable rows={waterPvtDataTableRows} columns={waterPvtDataTableCols} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [isLoading, waterPvtBw, waterPvtDataTableCols, waterPvtDataTableRows, waterPvtMu, waterPvtPw, waterPvtCw]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 3fr",
        gridGap: 20,
      }}
    >
      <div>
        <InputField
          label={dictionary.inputPage.salinity}
          suffix="ppm"
          debounceDelay={1000}
          value={waterPvtInput?.salinity}
          disabled={isLoading}
          onChange={(v) => {
            updateInputValue(v, "salinity", "water_pvt");
          }}
          errors={validationError}
          keyField="inputs.water_pvt.salinity"
        />
        <DropdownField
          label={dictionary.inputPage.waterViscosityCorrelation}
          options={mapEnumToDropdown(WaterViscosityCorrelation)}
          selectedKey={waterPvtInput?.selected_water_viscosity_correlation}
          disabled={isLoading}
          onChange={(v) => updateInputValue(v, "selected_water_viscosity_correlation", "water_pvt")}
          errors={validationError}
          keyField="inputs.water_pvt.selected_water_viscosity_correlation"
        />
        <DropdownField
          label={dictionary.inputPage.waterCompressibility}
          options={mapEnumToDropdown(WaterCompressibilityCorrelation)}
          selectedKey={waterPvtInput?.selected_water_compressibility_correlation}
          disabled={isLoading}
          onChange={(v) => updateInputValue(v, "selected_water_compressibility_correlation", "water_pvt")}
          errors={validationError}
          keyField="inputs.water_pvt.selected_water_compressibility_correlation"
        />
      </div>
      <Tabs tabList={tabList} centered />
    </div>
  );
};

export default InputWaterPvt;
