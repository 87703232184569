export const AFA_WELL_TYPE = "AFA-welltype";
export const AFA_DATASOURCE = "AFA-datasource";
export const AFA_WELL_NAV_WIDTH = 260;
export const AFA_PROJECT_NAV_WIDTH = 280;

export const treeViewDefaultState = {
  isLoading: false,
  rootId: "",
  showAll: false,
  setShowAll: () => {},
  setHideProjects: () => {},

  //tags
  tags: {},
  selectedTags: {},
  setSelectedTags: () => {},
  hasModifiedDefaultSelection: false,

  setShowFilters: () => {},
  showFilters: false,
  filterButtonRef: undefined,
  filterTypes: [],
  setFilterTypes: () => {},
  clearFilters: () => {},
  setFilter: () => {},
  isDataSetsLoading: false,
  isProjectEmpty: false,
  dataSetSelection: undefined,
  dataSetItems: [],
  dragDropEvents: undefined,
  projects: [],
  hideProjects: false,
  projectsListWrapper: undefined,
  projectSelection: undefined,
  projectsList: undefined,
  projectItems: [],
  projectGroups: [],
  renderGroupHeader: undefined,
  projectDragDropEvents: undefined,
  projectDragDropHelper: undefined,
  renderGroupCell: undefined,
  showContextMenu: false,
  contextMenuItems: [],
  setShowContextMenu: () => {},
  onDragWellList: () => {},

  refreshDataSets: () => {},
  // dialog props

  copyFromSelected: false,
  setCopyFromSelected: () => {},
  newProjectName: "",
  setNewProjectName: () => {},
  createNewProject: () => {},
  deleteProject: () => {},
  editProjectName: "",
  setEditProjectName: () => {},
  editExistingProject: () => {},

  createNewGroups: () => {},
  editGroupName: "",
  setEditGroupName: () => {},
  editExistingGroup: () => {},
  setNewGroupsNames: () => {},
  newGroupsNames: [],
  refreshProjects: () => {},
  removeDataSets: () => {},
  setActiveDialog: () => {},

  mappedItemKeyTotal: {},
  onDragEnd: () => {},

  onSelectDataSet: () => {},
  onSelectGroup: () => {},
  onSelectProject: () => {},
  setActiveKey: () => {},
  deleteGroup: () => {},
  selectedItems: [],
  selectedDataSet: [],
  setSelectedDataSet: () => {},
  startDrag: false,
  setStartDrag: () => {},
  dataSets: [],
  shiftHeld: false,
  commandControlHeld: false,

  startDraggingWellMove: false,
  setStartDraggingWellMove: () => {},
  onSelectItem: () => {},
  selectedKeys: [],
  setSelectedWellKeys: () => {},
  selectedWellKeys: [],
  onDropWell: () => {},

  onClickPreview: () => {},
  setWellWidth: () => {},
  setNavWidth: () => {},
  treeViewWidth: AFA_WELL_NAV_WIDTH + 10 + 120,

  missingDataset: [],
};
