import { fetchApi } from "@/utils/apiFetcher";
import { license } from "@/constants/apiUrl";
import { z } from "zod";

export const licenseInfoSchema = z.object({
  end_date: z.string().date(),
  level: z.string(),
  start_date: z.string().date(),
});

export type LicenseInfo = z.infer<typeof licenseInfoSchema>;

export const getLicense = () => {
  // if module is group run only takke the first object
  return fetchApi<LicenseInfo>({
    path: license,
  });
};
