import React from "react";

import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import DropdownField, { DropdownOption } from "@/components/fields/DropdownField";

type NormalizedChartProps = {
  id: string;
  isLoading: boolean;
  xAxes: FossilyticsChartAxis[];
  yAxes: FossilyticsChartAxis[];
  series: FossilyticsChartSeries[];
  dropDownOption: DropdownOption[];
  onChangeDropdownOption: (v: string) => void;
  selectedOption: string;
};

const NormalizedChart = ({ id, isLoading, xAxes, yAxes, selectedOption, series, dropDownOption, onChangeDropdownOption }: NormalizedChartProps) => {
  return (
    <div style={{ height: "90%" }}>
      <FossilyticsChart id={id} isLoading={isLoading} title={""} xAxes={xAxes} yAxes={yAxes} series={series} />
      <div style={{ width: "200px", margin: "10px auto", textAlign: "center" }}>
        <DropdownField
          label="Selected x-axis"
          options={dropDownOption}
          selectedKey={String(selectedOption)}
          onChange={(v) => {
            if (v !== undefined) {
              onChangeDropdownOption(v);
            }
          }}
        />
      </div>
    </div>
  );
};

export default NormalizedChart;
