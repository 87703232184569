import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CellChange, DateCell, NumberCell, Row } from "@silevis/reactgrid";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";

import {
  TahkCsgStateResponse,
  ForecastEvent,
  TahkAnalysisCurveModel,
  TahkAnalysisCurveData,
  TahkCsgForecastState,
  TahkCsgForecastResponse,
  TahkCsgForecastPayload,
  postExportTahkCsgForecast,
} from "@/models/tahk";
import { FluidType, Interval, ModuleIdentity } from "@/models/Generic";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { CsvData } from "@/features/app/app.types";

import { FossilyticsChartSeries } from "@/components/FossilyticsChart";
import { tableHeaderStyle } from "@/components/CustomTable";

import dictionary from "@/constants/dictionary";

import { camelToSnakeCase, formatToScientific } from "@/utils/general";
import { transformRowToString } from "@/utils/csvProcessing";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";

import { cumulativeSeriesKey, dataSeriesKey, seriesKey } from "../../constants/grid";
import { dataTableNotation, getDataTableHeader, restructureForecast } from "../../utils";
import { ContractRateEnum, FlowPressureTypeEnum } from "@/models/InputGeneric";
import { calculateTahkCsgForecast, validateTahkCsgForecast } from "@/constants/apiUrl";
import { usePolling } from "@/utils/apiFetcher";
import { PollHelper } from "../../context/TahkCsgContext";
import { saveBlob } from "@/util";
import { parseErrorThrown } from "@/utils/errorHandling";

export type TahkCsgForecastProps = {
  tahkCsgState?: TahkCsgStateResponse | null;
  tabIndex: number;
  isLoading: boolean;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  analysisIdentity?: ModuleIdentity;
  setCsvData: (csvData?: CsvData[]) => void;
  isTest?: boolean;
  fluidType: FluidType;
} & PollHelper;

const safeTahkDictionary: {
  [key: string]: string;
} = dictionary.tahk;

const useTahkCsgForecast = ({
  isTest = false,
  setApiError,
  setCsvData,
  tahkCsgState,
  isLoading,
  setTahkCsgState,
  analysisIdentity,
  tabIndex,
  setIsLoading,
  setPollStatus,
  setProgress,
  apiError,
  fluidType,
}: TahkCsgForecastProps) => {
  const { palette } = useTheme();

  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);
  const [selectedDataTableLayer, setSelectedDataTableLayer] = useState<string>("total");
  const [loadingState, setLoadingState] = useState(false);
  const [forecastCalculation, setForecastCalculation] = useState<TahkCsgForecastResponse | undefined | null>(tahkCsgState?.results);
  const inputs = tahkCsgState?.inputs;

  const { createPoll, canCancelPoll, onCancelPoll } = usePolling({
    setApiError,
    setErrorValidation: setErrorInputValidation,
    setLoadingState: (val) => {
      setIsLoading(val);
    },
    setProgressStatus: (val) => {
      setProgress(val.progress ?? null);
      setPollStatus(val.pollStatus);
    },
    apiError,
  });

  const haveInitialize = useRef(false);
  // make sure the whole flowing table is filled before calculating
  const canCalculateForecast = useMemo(() => {
    if (!tahkCsgState?.forecast) return false;
    let status = tahkCsgState.forecast.flowing_schedule.length > 0;
    for (let index = 0; index < tahkCsgState?.forecast.flowing_schedule.length; index++) {
      const item = tahkCsgState?.forecast.flowing_schedule[index];
      if ((!item.date || !item.pressure) && Object.values(item).filter((val) => !!val).length > 0) {
        status = false;
        break;
      }
    }

    return status;
  }, [tahkCsgState?.forecast]);

  const calculateForecast = useCallback(
    async (forecast: TahkCsgForecastState) => {
      if (inputs && analysisIdentity && tahkCsgState.forecast) {
        try {
          const payload = {
            data_options: {
              analysis_identity: analysisIdentity,
            },
            options: {
              inputs,
              forecast,
            },
          };
          const calculation = await createPoll<TahkCsgForecastResponse, TahkCsgForecastPayload>({
            path: calculateTahkCsgForecast(payload.data_options.analysis_identity.project_id),
            body: payload,
            type: "post",
          });

          if (calculation.task_result) setForecastCalculation(calculation.task_result);
        } catch (error: any) {
          console.log(error);
        } finally {
          setLoadingState(false);
        }
      }
    },
    [analysisIdentity, createPoll, inputs, tahkCsgState?.forecast]
  );

  const onValidateForecast = useCallback(async () => {
    if (inputs && analysisIdentity && tahkCsgState?.forecast) {
      try {
        setLoadingState(true);
        const restructuredForecast = restructureForecast(tahkCsgState.forecast.flowing_schedule);
        haveInitialize.current = true;
        const payload = {
          data_options: {
            analysis_identity: analysisIdentity,
          },
          options: {
            inputs,
            forecast: {
              smart_fill: tahkCsgState.forecast.smart_fill,
              flowing_schedule: restructuredForecast,
            },
          },
        };

        const validated = await createPoll<TahkCsgForecastState, TahkCsgForecastPayload>({
          path: validateTahkCsgForecast(payload.data_options.analysis_identity.project_id),
          body: payload,
          type: "post",
        });
        if (validated.task_result) {
          if (!_.isEqual(validated, tahkCsgState.forecast)) {
            setTahkCsgState((prev) => {
              if (!prev || !validated.task_result) return prev;
              return {
                ...prev,
                forecast: validated.task_result,
              };
            });
            calculateForecast(validated.task_result);
          }
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  }, [analysisIdentity, calculateForecast, createPoll, inputs, setTahkCsgState, tahkCsgState]);

  useEffect(() => {
    if (haveInitialize.current || tabIndex !== 3) return;
    if (tahkCsgState?.results) {
      haveInitialize.current = true;
      setForecastCalculation(tahkCsgState.results);
    } else if (!forecastCalculation && !isTest) {
      onValidateForecast();
    }
  }, [forecastCalculation, isTest, onValidateForecast, tabIndex, tahkCsgState?.results]);

  // reset the calculation if user change something on the input
  // we want to hide the calculation, because it can be misleading
  useEffect(() => {
    if (haveInitialize.current && tabIndex !== 3) {
      setForecastCalculation(undefined);
    }
  }, [tabIndex, tahkCsgState?.inputs]);

  const dataTableLayerOption = useMemo(() => {
    const layerLength = forecastCalculation?.data_table?.length ?? 1;
    // manually append total from all layer
    return [
      {
        key: "total",
        text: dictionary.tahk.total,
      },
      ...Array.from(Array(layerLength - 1).keys()).map((_, index) => {
        return {
          key: String(index),
          text: `${dictionary.tahk.layer} ${index + 1}`,
        };
      }),
    ];
  }, [forecastCalculation?.data_table?.length]);

  const flowingScheduleHeader = useMemo(() => {
    return {
      date: dictionary.koldunCsg.date,
      pressure: dictionary.koldunCsg[inputs?.wellbore_inputs?.selected_flow_pressure_type ?? FlowPressureTypeEnum.BottomHolePressure],
      rate: dictionary.koldunCsg[inputs?.wellbore_inputs?.contract_rate_mode ?? ContractRateEnum.WaterContractRate],
    };
  }, [inputs?.wellbore_inputs?.contract_rate_mode, inputs?.wellbore_inputs?.selected_flow_pressure_type]);

  const flowingPressureCol = Object.keys(flowingScheduleHeader).map((columnId, index) => {
    return { columnId: camelToSnakeCase(columnId), width: index === 0 ? 70 : 128 };
  });

  const flowingPressureRow: Row<any>[] = useMemo(() => {
    if (!inputs || !tahkCsgState.forecast) return [];
    const concatedRow = [
      ...tahkCsgState.forecast.flowing_schedule,
      ...Array.from(Array(100).keys()).map(() => ({
        date: "",
        pressure: null,
        rate: null,
      })),
    ];

    return [
      {
        rowId: "header",
        cells: Object.values(flowingScheduleHeader).map((header) => {
          return {
            type: "header",
            text: header,
            style: {
              ...tableHeaderStyle,
              fontSize: 16,
              padding: 0,
            },
          };
        }),
        height: 60,
      },
      ...concatedRow.map((row, index) => {
        const background = tahkCsgState.forecast.smart_fill ? palette.grey[100] : "#fff";
        return {
          rowId: index,
          cells: [
            {
              type: "date",
              date: row.date ? new Date(row.date) : undefined,
              style: {
                background,
              },
              nonEditable: tahkCsgState.forecast.smart_fill,
              hideZero: true,
            },
            {
              type: "number",
              value: row.pressure,
              style: {
                background,
              },
              nonEditable: tahkCsgState.forecast.smart_fill,
            },
            {
              type: "number",
              value: row.rate,
              style: {
                background,
              },
              nonEditable: tahkCsgState.forecast.smart_fill,
            },
          ],
        };
      }),
    ];
  }, [flowingScheduleHeader, inputs, palette.grey, tahkCsgState?.forecast]);

  const onChangeSmartFill = useCallback(() => {
    setTahkCsgState((prev) => {
      if (!prev?.forecast) return prev;

      return {
        ...prev,
        forecast: {
          ...prev.forecast,
          smart_fill: !prev.forecast.smart_fill,
        },
      };
    });
  }, [setTahkCsgState]);

  const handleFlowingPressureCellChange = useCallback(
    (changes: CellChange[]) => {
      if (!inputs || !tahkCsgState.forecast) return;
      const newFlowingPressure = [...tahkCsgState.forecast.flowing_schedule] as any[];

      changes.forEach((change) => {
        const numberCell = change.newCell as NumberCell;
        const idx = change.rowId as number;
        const colIdx = change.columnId as keyof ForecastEvent;

        if (!newFlowingPressure[idx]) {
          newFlowingPressure[idx] = {
            date: "",
            pressure: 0,
            rate: 0,
          };
        }
        if (colIdx === "date") {
          const dateCell = change.newCell as DateCell;
          newFlowingPressure[idx][colIdx] = convertDateToUtcTimeZoneIsoString(new Date(dateCell.date ?? 0)) ?? "";
        } else if (isNaN(numberCell.value) || Number(numberCell.value) < 0) newFlowingPressure[idx][colIdx] = null;
        else if (numberCell.value === 0) newFlowingPressure[idx][colIdx] = 0;
        else newFlowingPressure[idx][colIdx] = numberCell.value;
      });

      setTahkCsgState((prev) => {
        if (!prev?.forecast) return prev;

        return {
          ...prev,
          forecast: {
            ...prev.forecast,
            flowing_schedule: newFlowingPressure,
          },
        };
      });
    },
    [inputs, setTahkCsgState, tahkCsgState?.forecast]
  );

  const chartXAxes = useMemo(() => {
    return [{ name: dictionary.tahk.days, type: "time", color: palette.grey[900] }];
  }, [palette.grey]);

  const chartYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.pressure} ${dictionary.tableUnits.pressure}`,
        type: "value",
        color: palette.grey[900],
        min: 0,
        position: "left",
        offset: 80,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tableUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tableUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 0,
        nameGap: 50,
      },
    ];
  }, [palette.error.main, palette.grey, palette.info.main]);

  const cumulativeChartYAxes = useMemo(() => {
    return [
      {
        name: `${dictionary.tahk.gasRate} ${dictionary.tableUnits.gasRate}`,
        type: "value",
        color: palette.error.main,
        min: 0,
        position: "left",
        offset: 0,
        nameGap: 30,
      },
      {
        name: `${dictionary.tahk.waterRate} ${dictionary.tableUnits.waterRate}`,
        type: "value",
        color: palette.info.main,
        min: 0,
        position: "right",
        offset: 0,
        nameGap: 50,
      },
    ];
  }, [palette.error.main, palette.info.main]);

  const getSeriesColor = useCallback(
    (key: string) => {
      if (key.toLowerCase().includes("gas")) return palette.error.main;
      if (key.toLowerCase().includes("water")) return palette.info.main;

      return palette.common.black;
    },
    [palette]
  );

  const getYAxisIndex = (key: string) => {
    if (key.toLowerCase().includes("gas")) return 1;
    if (key.toLowerCase().includes("water")) return 2;
    return 0;
  };

  const series = useMemo(() => {
    if (!forecastCalculation) return [];
    const curveModel: FossilyticsChartSeries[] = [];
    seriesKey.forEach((key) => {
      if (forecastCalculation.tahk_analysis_curve_model[camelToSnakeCase(key) as keyof TahkAnalysisCurveModel]) {
        curveModel.push({
          name: `${dictionary.tahk.model} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: forecastCalculation.tahk_analysis_curve_model?.dates
            ? forecastCalculation.tahk_analysis_curve_model?.dates?.map((d, i) => [
                d,
                forecastCalculation.tahk_analysis_curve_model?.[camelToSnakeCase(key) as keyof TahkAnalysisCurveModel]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key),
          hideSymbol: true,
          lineWidth: 2,
        });
      }
    });
    const curveData: FossilyticsChartSeries[] = [];
    dataSeriesKey.forEach((key) => {
      if (forecastCalculation.tahk_analysis_curve_data[camelToSnakeCase(key) as keyof TahkAnalysisCurveData]) {
        curveModel.push({
          name: `${dictionary.tahk.data} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: forecastCalculation.tahk_analysis_curve_data?.dates
            ? forecastCalculation.tahk_analysis_curve_data?.dates?.map((d, i) => [
                d,
                forecastCalculation.tahk_analysis_curve_data?.[camelToSnakeCase(key) as keyof TahkAnalysisCurveData]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key),
        });
      }
    });
    return [...curveModel, ...curveData];
  }, [forecastCalculation, getSeriesColor]);

  const cumulativeSeries = useMemo(() => {
    if (!forecastCalculation) return [];
    const curveModel: FossilyticsChartSeries[] = [];
    cumulativeSeriesKey.forEach((key) => {
      if (forecastCalculation.tahk_analysis_curve_model[camelToSnakeCase(key) as keyof TahkAnalysisCurveModel]) {
        curveModel.push({
          name: `${dictionary.tahk.model} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: forecastCalculation.tahk_analysis_curve_model?.dates
            ? forecastCalculation.tahk_analysis_curve_model?.dates?.map((d, i) => [
                d,
                forecastCalculation.tahk_analysis_curve_model?.[camelToSnakeCase(key) as keyof TahkAnalysisCurveModel]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key) - 1,
          hideSymbol: true,
          lineWidth: 2,
        });
      }
    });
    const curveData: FossilyticsChartSeries[] = [];
    cumulativeSeriesKey.forEach((key) => {
      if (forecastCalculation.tahk_analysis_curve_data[camelToSnakeCase(key) as keyof TahkAnalysisCurveData]) {
        curveModel.push({
          name: `${dictionary.tahk.data} ${safeTahkDictionary[key]}`,
          type: "line",
          color: getSeriesColor(key),
          data: forecastCalculation.tahk_analysis_curve_data?.dates
            ? forecastCalculation.tahk_analysis_curve_data?.dates?.map((d, i) => [
                d,
                forecastCalculation.tahk_analysis_curve_data?.[camelToSnakeCase(key) as keyof TahkAnalysisCurveData]?.[i] ?? 0,
              ])
            : [],
          yAxisIndex: getYAxisIndex(key) - 1,
        });
      }
    });
    return [...curveModel, ...curveData];
  }, [forecastCalculation, getSeriesColor]);

  const generateRows = useCallback((dataTable: TahkCsgForecastResponse["data_table"], indexData: number, isTotal?: boolean) => {
    return [
      ...dataTable[indexData].dates.map((_, index) => {
        const safeParam = dataTable[indexData] as { [key: string]: any };
        return {
          rowId: index,
          cells: Object.keys(isTotal ? dictionary.tahkTotalDataTable : dictionary.tahkDataTable).map((key) => {
            const val = safeParam?.[camelToSnakeCase(key)][index] ?? "-";
            return {
              type: "text",
              text: formatToScientific(val),

              style: { display: "flex", justifyContent: "center", zIndex: 0 },
            };
          }),
        };
      }),
    ];
  }, []);

  const dataTableRows: Row<any>[] = useMemo(() => {
    if (!forecastCalculation) return [];
    if (selectedDataTableLayer === "total") {
      return [
        getDataTableHeader(dictionary.tahkTotalDataTable),
        dataTableNotation(dictionary.tahkTotalDataTable),
        ...generateRows(forecastCalculation.data_table, forecastCalculation.data_table.length - 1, true),
      ];
    }
    return [
      getDataTableHeader(dictionary.tahkDataTable),
      dataTableNotation(dictionary.tahkDataTable),
      ...generateRows(forecastCalculation.data_table, Number(selectedDataTableLayer)),
    ];
  }, [forecastCalculation, generateRows, selectedDataTableLayer]);

  const flowingInputError = useMemo(() => {
    if (errorInputValidation.length > 0) {
      const flowingErr = errorInputValidation.find((err) => err.loc.includes("flowing_schedule"));

      if (flowingErr) return flowingErr.msg;
    }
    return "";
  }, [errorInputValidation]);

  const loadingStateAll = isLoading || loadingState;
  const transformDataTableToCsv = useCallback(() => {
    if (!analysisIdentity) return;
    const { data_set_ids } = analysisIdentity;

    const tableCsv = {
      fileName: `${data_set_ids.join(", ")} Analysis Tahk Table.csv`,
      data: transformRowToString(dataTableRows),
      isTableCsv: true,
    };
    setCsvData([tableCsv]);
  }, [analysisIdentity, dataTableRows, setCsvData]);

  useEffect(() => {
    if (analysisIdentity && !loadingStateAll && dataTableRows.length > 0) {
      transformDataTableToCsv();
    }
  }, [analysisIdentity, dataTableRows.length, loadingStateAll, transformDataTableToCsv]);

  const onClickExport = useCallback(
    async (interval: Interval) => {
      try {
        if (!analysisIdentity) return;

        setIsLoading(true);
        const res = await postExportTahkCsgForecast({
          projectId: analysisIdentity.project_id,
          body: analysisIdentity,
          fluid: fluidType,
          interval,
        });

        if (res.data) {
          const fileName = `${res.headers["content-disposition"].split("filename=")[1]}`;
          saveBlob(res.data, fileName);
        }
      } catch (error: any) {
        console.log(error);

        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [analysisIdentity, apiError, fluidType, setApiError, setIsLoading]
  );

  return {
    loadingState: loadingStateAll,
    handleFlowingPressureCellChange,
    flowingPressureRow,
    flowingPressureCol,
    chartYAxes,
    chartXAxes,
    series,
    dataTableLayerOption,
    selectedDataTableLayer,
    setSelectedDataTableLayer,
    dataTableRows,
    cumulativeSeries,
    cumulativeChartYAxes,
    onValidateForecast,
    flowingInputError,
    onChangeSmartFill,
    canCalculateForecast,
    forecastCalculation,
    canCancelPoll,
    onCancelPoll,
    onClickExport,
  };
};

export default useTahkCsgForecast;
