import { useMemo } from "react";
import { DateTime } from "luxon";

import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import DialogMui from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";

import { useProjectSettings, useProjectSettingState } from "@/ProjectSettingsContextV3";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";
import { DialogEnum } from "../types";
import { getIdFromActiveKey } from "../utils";
import routeUrlMap from "@/constants/routeUrl";
import dictionary from "@/constants/dictionary";
import useThemeStyling from "@/utils/useThemeStyling";
import InputField from "@/components/fields/InputField";

interface ProjectSettingsDialogProps {
  setActiveDialog: (show: DialogEnum | undefined) => void;
  isLoading: boolean;
  selectedKey?: string;
}

const ProjectSettingsDialog = ({ setActiveDialog, isLoading, selectedKey }: ProjectSettingsDialogProps) => {
  const { projectId } = getIdFromActiveKey(selectedKey ?? "");
  const { palette } = useThemeStyling();

  const { saveProjectSettings } = useProjectSettings();

  const [forecastStartDate, setForecastStartDate] = useProjectSettingState<string | null>("forecast_start_date", projectId, null);
  const [forecastEndDate, setForecastEndDate] = useProjectSettingState<string | null>("forecast_end_date", projectId, null);

  const [minArpsExponent, setMinArpsExponent] = useProjectSettingState<number>("spad_decline_setting.min_arps_exponent", projectId, 0);
  const [maxArpsExponent, setMaxArpsExponent] = useProjectSettingState<number>("spad_decline_setting.max_arps_exponent", projectId, 0);
  const [lowCasePercentile, setLowCasePercentile] = useProjectSettingState<number>("spad_decline_setting.low_case_percentile", projectId, 0);
  const [midCasePercentile, setMidCasePercentile] = useProjectSettingState<number>("spad_decline_setting.mid_case_percentile", projectId, 0);
  const [highCasePercentile, setHighCasePercentile] = useProjectSettingState<number>("spad_decline_setting.high_case_percentile", projectId, 0);

  const isKoldunCSg = window.location.pathname === `/modules/${routeUrlMap.csgV2}`;

  const handleStartDateChange = (v: Date | undefined) => {
    const dateStr = convertDateToUtcTimeZoneIsoString(v) ?? null;
    setForecastStartDate(dateStr);
  };

  const handleEndDateChange = (v: Date | undefined) => {
    const dateStr = convertDateToUtcTimeZoneIsoString(v) ?? null;
    setForecastEndDate(dateStr);
  };

  const handleSave = async () => {
    try {
      // Build updated settings object
      const updatedSettings = {
        forecast_start_date: forecastStartDate,
        forecast_end_date: forecastEndDate,
        spad_decline_setting: {
          min_arps_exponent: minArpsExponent,
          max_arps_exponent: maxArpsExponent,
          low_case_percentile: lowCasePercentile,
          mid_case_percentile: midCasePercentile,
          high_case_percentile: highCasePercentile,
        },
      };

      // Save to backend
      await saveProjectSettings(projectId, updatedSettings);

      setActiveDialog(undefined);
    } catch (error: any) {
      console.error("Error occurred while saving in Project Setting:", error);
    }
  };

  const onCancelDialog = () => {
    if (isKoldunCSg) return;
    setActiveDialog(undefined);
  };

  const saveButtonDisabled = useMemo(() => {
    if (isKoldunCSg) {
      return isLoading || !forecastEndDate;
    }

    return isLoading;
  }, [isLoading, forecastEndDate, isKoldunCSg]);

  const errorList = useMemo(() => {
    const list = [];

    if (forecastStartDate && forecastEndDate) {
      if (DateTime.fromISO(forecastStartDate) > DateTime.fromISO(forecastEndDate))
        list.push({
          msg: "Forecast start date must be before end date",
          loc: ["body", "startDate"],
          type: "validation",
          ctx: "",
        });
    }
    return list;
  }, [forecastStartDate, forecastEndDate]);

  return (
    <DialogMui open keepMounted onClose={onCancelDialog}>
      <DialogTitle className="primaryColor" style={{ textAlign: "left" }}>
        Forecast Setting
      </DialogTitle>
      <DialogContent style={{ minWidth: "350px" }}>
        {isKoldunCSg && !forecastEndDate && <div style={{ color: palette.error.main }}>{dictionary.errorMessage.forecastEndDate}</div>}

        {/* Forecast Settings */}
        <div style={{ display: "flex", flexFlow: "row", alignItems: "end" }}>
          <div style={{ flexGrow: 1, display: "flex" }}>
            <InputField
              dataTestId="forecast-start-date"
              outerStyle={{ width: "100%" }}
              type="date"
              value={forecastStartDate ?? ""}
              label="Forecast start date"
              debounceDelay={100}
              onChange={(val) => handleStartDateChange(new Date(String(val)))}
              errors={errorList}
              keyField="startDate"
            />
          </div>
          <IconButton onClick={() => handleStartDateChange(undefined)} style={{ marginBottom: 6 }} aria-label="delete">
            <CloseIcon />
          </IconButton>
        </div>

        <div style={{ display: "flex", flexFlow: "row", alignItems: "end" }}>
          <div style={{ flexGrow: 1, display: "flex" }}>
            <InputField
              dataTestId="forecast-end-date"
              outerStyle={{ width: "100%" }}
              type="date"
              value={forecastEndDate ?? ""}
              label="Forecast end date"
              debounceDelay={100}
              onChange={(val) => handleEndDateChange(new Date(String(val)))}
            />
          </div>
          <IconButton onClick={() => handleEndDateChange(undefined)} style={{ marginBottom: 6 }} aria-label="delete">
            <CloseIcon />
          </IconButton>
        </div>
        <br />

        {/* Spad Decline Smart Fit Settings */}
        <Typography variant="h6" className="primaryColor" style={{ textAlign: "left" }}>
          Spad Decline Smart Fit Setting
        </Typography>

        <InputField
          label="Minimum Arps Exponent"
          dataTestId="spad-min-arps-exponent"
          value={minArpsExponent}
          required={true}
          min={0}
          max={1}
          onChange={(val) => setMinArpsExponent(Number(val))}
          type="float"
          errors={errorList}
          keyField="data.spad_decline_setting.min_arps_exponent"
        />

        <InputField
          label="Maximum Arps Exponent"
          dataTestId="spad-max-arps-exponent"
          value={maxArpsExponent}
          required={true}
          min={0}
          max={1}
          onChange={(val) => setMaxArpsExponent(Number(val))}
          type="float"
          errors={errorList}
          keyField="data.spad_decline_setting.max_arps_exponent"
        />

        <InputField
          label="Low Case Percentile"
          dataTestId="spad-low-case-percentile"
          value={lowCasePercentile}
          required={true}
          min={0}
          max={100}
          onChange={(val) => setLowCasePercentile(Number(val))}
          type="float"
          errors={errorList}
          keyField="data.spad_decline_setting.low_case_percentile"
        />

        <InputField
          label="Mid Case Percentile"
          dataTestId="spad-mid-case-percentile"
          value={midCasePercentile}
          required={true}
          min={0}
          max={100}
          onChange={(val) => setMidCasePercentile(Number(val))}
          type="float"
          errors={errorList}
          keyField="data.spad_decline_setting.mid_case_percentile"
        />

        <InputField
          label="High Case Percentile"
          dataTestId="spad-high-case-percentile"
          value={highCasePercentile}
          required={true}
          min={0}
          max={100}
          onChange={(val) => setHighCasePercentile(Number(val))}
          type="float"
          errors={errorList}
          keyField="data.spad_decline_setting.high_case_percentile"
        />
      </DialogContent>

      <DialogActions>
        <Button
          data-testid="save-project-settings"
          style={{ color: "white", marginTop: 10, marginBottom: 10 }}
          variant="contained"
          disabled={saveButtonDisabled || errorList.length > 0}
          onClick={handleSave}
        >
          Save
        </Button>

        <Button disabled={isLoading || isKoldunCSg} onClick={onCancelDialog} variant="outlined">
          Cancel
        </Button>
      </DialogActions>
    </DialogMui>
  );
};

export default ProjectSettingsDialog;
