import { useAutoFmbState } from "../../context/AutoFmbContext";
import useAutoFmbForecast from "../../hooks/useAutoFmbForecast";
import ForecastChartFmb from "./ForecastChart";
import ForecastParameter from "./ForecastParameter";

const AutoFmbForecast = () => {
  const {
    autoFmbState,
    setAutoFmbState,
    analysisIdentity,
    tabIndex,
    isLoading,
    setApiError,
    apiError,
    setIsLoading,
    setProgress,
    setPollStatus,
    setAutoFmbForecastCalculation,
    autoFmbForecastCalculation,
  } = useAutoFmbState();

  const { onCalculateForecastFmb, flowingPressureRow, onTableCellChange, updateForecastFmb, errorInputValidation, summaryTableData } =
    useAutoFmbForecast({
      autoFmbState,
      setAutoFmbState,
      analysisIdentity,
      tabIndex,
      isLoading,
      setApiError,
      apiError,
      setIsLoading,
      setProgress,
      setPollStatus,
      setAutoFmbForecastCalculation,
      autoFmbForecastCalculation,
    });

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "480px 2fr",
        gridGap: 20,
        padding: "1em",
      }}
    >
      <ForecastParameter
        isLoading={isLoading}
        forecastFmbState={autoFmbState?.forecast}
        onChangeParameter={updateForecastFmb}
        summaryTableData={summaryTableData}
        errorInputValidation={errorInputValidation}
        onTableCellChange={onTableCellChange}
        parameterTableRow={flowingPressureRow}
        onClickCalculate={onCalculateForecastFmb}
      />

      <ForecastChartFmb autoFmbState={autoFmbState} isLoading={isLoading} autoFmbForecastCalculation={autoFmbForecastCalculation} />
    </div>
  );
};

export default AutoFmbForecast;
