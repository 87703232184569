import { z } from "zod";
import { ModuleId, DataSetType } from "@/model";

export const DataSetSchema = z.object({
  id: z.string(),
  name: z.string(),
  well_id: z.string(),
  type: z.nativeEnum(DataSetType),
  fields_present: z.array(z.string()),
  modules_available: z.array(
    z.nativeEnum(ModuleId).catch((item) => {
      return ModuleId.extra;
    })
  ),
  tags: z.array(z.string()),
});

const TagsSchema = z.record(z.string(), z.record(z.string(), z.array(z.string())));

const missingDatasetSchema = z.object({
  id: z.string(),
  name: z.string(),
  well_id: z.string(),
});

export type MissingDataSet = z.infer<typeof missingDatasetSchema>;

export const DataSetsResponseSchema = z.object({
  tags: TagsSchema,
  datasets: z.array(DataSetSchema),
  missing_datasets: missingDatasetSchema.array(),
});

export type DataSetState = z.infer<typeof DataSetSchema>;
export type DataSetsResponse = z.infer<typeof DataSetsResponseSchema>;
