import { Dispatch, useCallback, useMemo } from "react";
import IconButton from "@mui/material/IconButton";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { useAppStore } from "@/features/app";
import { Group } from "../model";
import InputField from "./fields/InputField";
import useThemeStyling from "@/utils/useThemeStyling";
import styled from "@emotion/styled";

interface TreeViewCreateGroupsInputProps {
  parentGroup?: Group;
  value: string[];
  onChange: Dispatch<string[]>;
  name: string;
  setName: Dispatch<React.SetStateAction<string>>;
}

const StyledItemContainer = styled.div`
  &:hover {
    background-color: antiquewhite;
  }
`;
function TreeViewCreateGroupsInput({ parentGroup, value, onChange, name, setName }: Readonly<TreeViewCreateGroupsInputProps>) {
  const isLoading = useAppStore((state) => state.isLoading);
  const { palette } = useThemeStyling();
  const items = useMemo(
    () =>
      value.map((v) => ({
        key: v,
        name: parentGroup ? `${parentGroup.name} / ${v}` : v,
      })),
    [parentGroup, value]
  );

  const isNameValid = useMemo(
    () => (value.length === 1 || !value.includes(name)) && !parentGroup?.groups?.some((g) => g.name === name),
    [parentGroup, value, name]
  );

  const addName = useCallback(() => {
    onChange([...value, name]);
    setName("");
  }, [onChange, value, name, setName]);

  const removeName = useCallback(
    (n: string) => {
      if (!value.includes(n)) return;
      onChange(value.filter((v) => v !== n));
    },
    [onChange, value]
  );

  return (
    <div>
      {items.map((item) => {
        return (
          <StyledItemContainer key={item.key} style={{ display: "flex", flexDirection: "row", alignItems: "center", padding: 5 }}>
            <span style={{ display: "flex", flexGrow: 1, fontSize: 12, color: palette.grey[500] }}>{item.name}</span>
            <IconButton onClick={() => removeName(item.key)} disabled={isLoading} aria-label="delete">
              <DeleteOutlinedIcon className="primaryColor" />
            </IconButton>
          </StyledItemContainer>
        );
      })}

      <InputField
        label={parentGroup ? "Subgroup name" : "Group name"}
        disabled={isLoading}
        keyField="name"
        type="text"
        errors={!isNameValid ? [{ msg: "Cannot create duplicate groups.", loc: ["body", "name"], type: "type_error", ctx: "" }] : []}
        onKeyDown={(event) => event.key === "Enter" && isNameValid && addName()}
        value={name}
        debounceDelay={10}
        onChange={(v) => setName(String(v ?? ""))}
      />
      <span style={{ fontSize: 10 }}>Tip: Use the Enter key to create groups quickly.</span>
    </div>
  );
}

export default TreeViewCreateGroupsInput;
