import { defineParametersScheme, gasPvtScheme, langmuirIsothermScheme, oilPvtScheme, waterPvtScheme } from "@/models/InputGeneric";
import { z, object, number, boolean } from "zod";

// wellbore model
const wellboreScheme = object({
  use_wellbore_model: boolean(),
  tubing_diameter: number(),
  tubing_roughness: number(),
  tubing_depth: number(),
  well_angle: number(),
  wellhead_temperature: number(),
});

export type WellboreModel = z.infer<typeof wellboreScheme>;

export const autoRtaInputGridScheme = object({
  defined_parameters: defineParametersScheme,
  gas_pvt: gasPvtScheme,
  water_pvt: waterPvtScheme,
  oil_pvt: oilPvtScheme,
  langmuir_isotherm: langmuirIsothermScheme,
  wellbore_model: wellboreScheme,
});

export type AutoRtaInputGrid = z.infer<typeof autoRtaInputGridScheme>;
