import { AFA_WELL_TYPE } from "@/components/TreeView/constants";
import { DataSet, DataSetType, Group, isDataSet, Project } from "@/model";
import { FluidType } from "@/models/Generic";
import { useMemo, useState } from "react";

export type UseGenericDatasetInfoProps = {
  group?: Group;
  project?: Project;
  selectedDataSets: DataSet | DataSet[] | undefined;
};

// return generic information usually required by modules
const useGenericDatasetInfo = ({ selectedDataSets, group, project }: UseGenericDatasetInfoProps) => {
  const [tabIndex, setTabIndex] = useState(0);

  const dataSets = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets.id];
    return selectedDataSets?.map((dataSet) => dataSet.id) ?? [];
  }, [selectedDataSets]);

  // only support oil and gas at the moment
  const fluidType = useMemo(() => {
    if (!selectedDataSets) return FluidType.gas;

    let wellType = "";

    let selectedTags: string[] = [];

    if (isDataSet(selectedDataSets)) {
      selectedTags = selectedDataSets.tags;
    } else {
      selectedTags = selectedDataSets?.[0].tags;
    }

    selectedTags = selectedTags.filter((tag) => tag.includes(AFA_WELL_TYPE));

    if (selectedTags.length > 0) wellType = selectedTags[0].split(":")[1];

    return wellType === DataSetType.GAS_WELL ? FluidType.gas : FluidType.oil;
  }, [selectedDataSets]);

  const analysisIdentity = useMemo(() => {
    return {
      data_set_ids: dataSets,
      group_id: group?.id ?? "",
      project_id: project?.id ?? "",
    };
  }, [dataSets, group?.id, project?.id]);

  return {
    analysisIdentity,
    dataSets,
    fluidType,
    tabIndex,
    setTabIndex,
  };
};

export default useGenericDatasetInfo;
