import React, { useMemo } from "react";
import chroma from "chroma-js";

import { DataSet } from "../../../../model";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "../../../../components/FossilyticsChart";
import FossilyticsGrid, { FossilyticsGridColumn, FossilyticsGridColumnGroup } from "../../../../components/FossilyticsGrid";
import { ModuleTahkForecastOutput } from "./model";
import useThemeStyling from "@/utils/useThemeStyling";
import LoadingIndicator from "@/components/LoadingIndicator";
import CustomCard from "@/components/Card";
import Tabs from "@/components/Tabs";

interface ModuleTahkForecastGasOilOutputViewProps {
  isLoading: boolean;
  dataSet: DataSet;
  output: ModuleTahkForecastOutput | undefined;
}

export function ModuleTahkForecastGasOilOutputView({ isLoading, output }: Readonly<ModuleTahkForecastGasOilOutputViewProps>) {
  const { palette } = useThemeStyling();

  const profilesTableGroups = useMemo<FossilyticsGridColumnGroup[]>(() => {
    if (!output) return [];
    return [
      { header: "", colspan: 1 },
      ...output.rates_mmscf_d.map((_, i) => ({
        header: `Profile ${i + 1}`,
        colspan: 2,
      })),
    ];
  }, [output]);

  const profilesTableColumns = useMemo<FossilyticsGridColumn[]>(() => {
    if (!output) return [];
    return [
      { key: "day", type: "number" as "number", header: "Day", width: 50, editable: false },
      ...output.rates_mmscf_d.flatMap((p, i) => [
        {
          key: `profile-${i}-q`,
          type: "number" as "number",
          header: "Rate (MMscf/d)",
          width: 125,
          editable: false,
        },
        {
          key: `profile-${i}-Q`,
          type: "number" as "number",
          header: "Cum. prod (MMscf)",
          width: 125,
          editable: false,
        },
      ]),
    ];
  }, [output]);

  const profilesTableItems = useMemo<number[][]>(() => {
    if (!output) return [];
    return output.days.map((d, j) => [d, ...output.rates_mmscf_d.flatMap((rates, i) => [rates[j], output.cumes_mmscf[i][j]])]);
  }, [output]);

  const profilesXAxes = useMemo<FossilyticsChartAxis[]>(() => [{ name: "Days", type: "value", color: palette.customColor.black, min: 1 }], [palette]);
  const profilesYAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Gas rate (MMscf/day)", type: "value", color: palette.customColor.black }],
    [palette]
  );

  const profilesSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      output
        ? output.rates_mmscf_d.map((p, i) => ({
            name: `Profile ${i + 1}`,
            type: "line",
            hideSymbol: true,
            lineWidth: !output.indices || output.indices.indexOf(i) >= 0 ? 3 : 1,
            color: !output.indices || output.indices.indexOf(i) >= 0 ? palette.customColor.red : chroma(palette.customColor.red).alpha(0.2).hex(),
            z: !output.indices || output.indices.indexOf(i) >= 0 ? 2 : 1,
            data: output.days.map((d, j) => [d, p[j]]),
          }))
        : [],
    [palette, output]
  );

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{"Profiles"}</span>,
        key: "Profiles",
        content: (
          <div
            style={{
              height: 600,
            }}
            className="chartWrapper"
          >
            <FossilyticsChart
              id="tahk_profiles"
              isLoading={isLoading}
              hideLegend
              xAxes={profilesXAxes}
              yAxes={profilesYAxes}
              series={profilesSeries}
            />
          </div>
        ),
      },
      {
        label: <span>{"Profiles table"}</span>,
        key: "Profiles table",
        content: isLoading ? (
          <div className="center-flex h100">
            <LoadingIndicator />
          </div>
        ) : (
          <div
            style={{
              height: 600,
            }}
            className="chartWrapper"
          >
            <FossilyticsGrid columnGroups={profilesTableGroups} columns={profilesTableColumns} data={profilesTableItems} />
          </div>
        ),
      },
    ];
  }, [isLoading, profilesSeries, profilesTableColumns, profilesTableGroups, profilesTableItems, profilesXAxes, profilesYAxes]);

  return (
    <CustomCard style={{ margin: "1em" }}>
      <Tabs tabList={tabList} />
    </CustomCard>
  );
}

export default ModuleTahkForecastGasOilOutputView;
