import { Auth, Hub } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";

import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import DialogMui from "@mui/material/Dialog";
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import { TenantConfigs } from "./tenants";

// remove this package later replace with regex
import emailAddresses from "email-addresses";
import { ReactComponent as LogoVert } from "./logo-vert.svg";
import NewPasswordField from "./components/NewPasswordField";
import { useAppStore } from "./features/app";

import useThemeStyling from "./utils/useThemeStyling";
import CustomCard from "./components/Card";
import LoadingIndicator from "./components/LoadingIndicator";
import InputField from "./components/fields/InputField";

function SignIn() {
  const { tenant, setTenant, user, setUser } = useAppStore(
    (state) => ({
      tenant: state.tenant,
      setTenant: state.setTenant,
      user: state.user,
      setUser: state.setUser,
    }),
    shallow
  );

  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [emailValid, setEmailValid] = useState(false);
  const [newPassword, setNewPassword] = useState<string>();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [code, setCode] = useState<string>();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<string>();

  const signIn = useCallback(async () => {
    if (!username) return;

    setIsLoading(true);
    try {
      const user = await Auth.signIn(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(user);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [password, setUser, username]);

  const completeNewPasswordChallenge = useCallback(async () => {
    if (!newPassword) return;
    try {
      await Auth.completeNewPassword(
        user, // the Cognito User Object
        newPassword // the new password
      );
    } catch (e) {
      console.error(e);
      setError(e);
    }
  }, [user, newPassword]);

  const sendPasswordResetCode = useCallback(async () => {
    if (!username) return;

    setIsLoading(true);
    try {
      setForgotPassword(true);
      await Auth.forgotPassword(username);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [username]);

  const completePasswordReset = useCallback(async () => {
    if (!username || !code || !newPassword) return;

    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (e) {
      console.error(e);
    } finally {
      setSuccess("Password reset! Login now.");
      setPassword(newPassword);
      setIsLoading(false);
      setForgotPassword(false);
    }
  }, [username, code, newPassword]);

  const getUser = useCallback(async () => {
    setIsLoading(true);
    try {
      return await Auth.currentAuthenticatedUser();
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          setError(data);
          setIsLoading(false);
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
  }, [getUser, setUser]);

  useEffect(() => {
    const emailInfo: any = emailAddresses.parseOneAddress(username || "");
    if (!emailInfo) {
      setEmailValid(false);
      return;
    }
    setEmailValid(true);

    // Find tenant based on email address
    setTenant(Object.keys(TenantConfigs).find((k) => TenantConfigs[k].domain === emailInfo.domain));
  }, [username, setTenant]);

  const { palette } = useThemeStyling();

  const allowFederatedSignIn = emailValid && tenant && TenantConfigs[tenant]?.idpEnabled;
  const allowNormalSignIn = emailValid && username && password;

  const enterToSignin = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === "Enter") {
      return allowNormalSignIn ? signIn() : undefined;
    }
  };

  return (
    <div style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CustomCard
        style={{
          display: "flex",
          flexDirection: "column",
          width: 300,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LogoVert />
          <h1 style={{ fontSize: "40px", fontWeight: 600, color: palette.primary.main, marginBottom: 0, marginTop: 0 }}>predico</h1>
          <h2 className="noMarginVer">Advanced Flow Analytics</h2>
        </div>

        <div>
          <InputField
            required
            disabled={isLoading}
            label="Username"
            type="text"
            debounceDelay={10}
            id="sign-in-username"
            value={username}
            onChange={(val) => {
              setUsername(String(val));
            }}
          />

          <InputField
            required
            disabled={Boolean(allowFederatedSignIn) || isLoading}
            label="Password"
            type="password"
            id="sign-in-password"
            debounceDelay={10}
            value={password}
            onChange={(val) => {
              setPassword(String(val));
            }}
            onKeyDown={(event) => enterToSignin(event)}
          />
        </div>

        {/* Forgot password button */}
        <Button
          style={{
            justifyContent: "start",
            textTransform: "capitalize",
          }}
          disabled={!(emailValid && username) || isLoading}
          onClick={sendPasswordResetCode}
          variant="text"
        >
          Forgot password?
        </Button>

        {/* Choose correct sign-in button based on tenant */}
        {allowFederatedSignIn ? (
          <Button
            onClick={(e) => {
              e.preventDefault();
              Auth.federatedSignIn({ customProvider: "IDP" });
            }}
            style={{ color: "white", width: "100%", marginTop: 10, marginBottom: 10 }}
            variant="contained"
          >
            Sign In with {TenantConfigs[tenant]?.name}
          </Button>
        ) : (
          <Button
            id="sign-in-button"
            disabled={!allowNormalSignIn || isLoading}
            onClick={() => (allowNormalSignIn ? signIn() : undefined)}
            style={{ color: "white", width: "100%", marginTop: 10, marginBottom: 10 }}
            variant="contained"
          >
            Sign In{tenant && TenantConfigs[tenant] ? ` to ${TenantConfigs[tenant]?.name}` : ""}
          </Button>
        )}

        {/* Loading spinner */}
        {isLoading ? <LoadingIndicator /> : undefined}

        {/* New password challenge dialog */}
        <DialogMui
          open={!(!user || user.challengeName !== "NEW_PASSWORD_REQUIRED")}
          keepMounted
          onClose={() => {
            setUser(undefined);
            setIsLoading(false);
          }}
        >
          <DialogTitle className="primaryColor">Set new password</DialogTitle>
          <DialogContent
            style={{
              width: 350,
            }}
          >
            <DialogContentText>A new password is required for this account. Please enter a new password below.</DialogContentText>
            <NewPasswordField onChange={setNewPassword} />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ color: "white", marginTop: 10, marginBottom: 10 }}
              variant="contained"
              disabled={!newPassword}
              onClick={completeNewPasswordChallenge}
            >
              Update
            </Button>

            <Button
              onClick={() => {
                setUser(undefined);
                setIsLoading(false);
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogMui>

        {/* Forgot password dialog */}

        <DialogMui
          open={forgotPassword}
          keepMounted
          onClose={() => {
            setForgotPassword(false);
          }}
        >
          <DialogTitle className="primaryColor">Reset password</DialogTitle>
          <DialogContent
            style={{
              width: 350,
            }}
          >
            <DialogContentText>
              A confirmation code has been sent to your verified email address. Please enter the code and a new password below.
            </DialogContentText>

            <InputField
              required
              disabled={isLoading}
              preffix={<LockOutlinedIcon fontSize="small" />}
              label="Code"
              type="text"
              id="sign-in-code"
              value={code}
              onChange={(val) => {
                setCode(String(val));
              }}
            />

            <NewPasswordField onChange={setNewPassword} />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ color: "white", marginTop: 10, marginBottom: 10 }}
              variant="contained"
              disabled={!code || !newPassword}
              onClick={completePasswordReset}
            >
              Update
            </Button>

            <Button
              onClick={() => {
                setForgotPassword(false);
                setUser(undefined);
                setIsLoading(false);
                setCode("");
                setNewPassword("");
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogMui>

        {/* Display error messages */}
        {error ? (
          <Alert severity="error" onClose={() => setError(null)}>
            {error?.message}
          </Alert>
        ) : undefined}

        {/* Display success messages */}
        {success ? (
          <Alert severity="success" onClose={() => setSuccess(undefined)}>
            {success}
          </Alert>
        ) : undefined}
      </CustomCard>
    </div>
  );
}

export default SignIn;
