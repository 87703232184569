import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

import { UseModelComponentInputProps } from "../../../hooks/KoldunCsgContext";
import useGasPvt from "../../../hooks/input/useGasPvt";

const GasPvt = (props: UseModelComponentInputProps) => {
  const { gasPvtColumns, gasPvtRows, onChangeGasPvt } = useGasPvt(props);

  return (
    <div
      style={{
        padding: 10,
        marginTop: 20,
      }}
      data-testid="gas-pvt-table-container"
    >
      <ReactGridTable
        data-testid="gas-pvt-table"
        viewWidth={950}
        viewHeight={360}
        columns={gasPvtColumns}
        rows={gasPvtRows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onChangeGasPvt}
      />
    </div>
  );
};

export default GasPvt;
