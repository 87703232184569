import DropdownField from "@/components/fields/DropdownField";
import useTahkCsgLangmuirIsotherm, { TahkCsgLangmuirIsothermProps } from "../../../hooks/input/charts/useTahkCsgLangmuirIsotherm";
import dictionary from "@/constants/dictionary";
import { mapEnumToDropdown } from "@/utils/general";
import { TahkCsgLangmuirIsothermEnum } from "@/models/tahk";
import CustomTable from "@/components/CustomTable";
import Tabs from "@/components/Tabs";
import { useMemo } from "react";
import useLayeredChart, { Layer } from "../../../hooks/input/useLayeredChart";
import FossilyticsChart from "@/components/FossilyticsChart";
import { langmuirDataTableCols } from "../../../constants/grid";
import styled from "@emotion/styled";

const LangmuirIsothermContainer = styled.div`
  .ms-Dropdown-container {
    width: 200px;
  }
`;
const LangmuirIsotherm = (props: TahkCsgLangmuirIsothermProps) => {
  const { onChangeDropdownInput, rows, columns, onCellsChanged, dataTableRows, selectedLayer, setSelectedLayer, layerOption } =
    useTahkCsgLangmuirIsotherm(props);
  const { loadingState, inputs } = props;

  const {
    series: gasContentSeries,
    xAxis: gasContentXAxis,
    yAxis: gasContentYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "gas_content",
      labelKey: "gasContent",
    },

    chartType: "langmuir_charts",
  });

  const {
    series: desoprtionSeries,
    xAxis: desoprtionXAxis,
    yAxis: desoprtionYAxis,
  } = useLayeredChart({
    layers: props.layers as Layer[],
    xAxis: {
      key: "pressure",
      labelKey: "pressure",
    },
    yAxis: {
      key: "desorption_compressibility",
      labelKey: "desorptionCompressibility",
    },

    chartType: "langmuir_charts",
  });

  const tableHeight = 500 + layerOption.length * 5;

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.tahk.gasContent}</span>,
        key: "gas-content",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart
              id="gas-content"
              isLoading={props.loadingState}
              xAxes={gasContentXAxis}
              yAxes={gasContentYAxis}
              series={gasContentSeries}
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            C<sub>d</sub>
          </span>
        ),
        key: "Cd",
        content: (
          <div
            style={{
              height: tableHeight,
            }}
            className="chartWrapper chartWidth"
          >
            <FossilyticsChart
              id="desorption-compressibility"
              isLoading={props.loadingState}
              xAxes={desoprtionXAxis}
              yAxes={desoprtionYAxis}
              series={desoprtionSeries}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.dataTable}</span>,
        key: "Data table",
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            className="chartWrapper"
          >
            <DropdownField
              label={dictionary.tahk.layer}
              options={layerOption}
              disabled={props.loadingState}
              selectedKey={String(selectedLayer)}
              onChange={(v) => {
                setSelectedLayer(Number(v));
              }}
            />
            <CustomTable
              style={{
                display: "flex",
              }}
              rows={dataTableRows}
              columns={langmuirDataTableCols}
              isLoading={loadingState}
            />
          </div>
        ),
      },
    ];
  }, [
    dataTableRows,
    desoprtionSeries,
    desoprtionXAxis,
    desoprtionYAxis,
    gasContentSeries,
    gasContentXAxis,
    gasContentYAxis,
    layerOption,
    loadingState,
    props.loadingState,
    selectedLayer,
    setSelectedLayer,
    tableHeight,
  ]);

  return (
    <LangmuirIsothermContainer>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center", width: 1000, marginBottom: 20, flexDirection: "column", overflowY: "auto" }}>
          <DropdownField
            label={dictionary.tahk.selectInputs}
            options={mapEnumToDropdown(TahkCsgLangmuirIsothermEnum)}
            disabled={loadingState}
            selectedKey={inputs?.layers[0].selected_langmuir_inputs ?? ""}
            onChange={(v) => {
              onChangeDropdownInput(v as TahkCsgLangmuirIsothermEnum);
            }}
          />

          <CustomTable
            rows={rows}
            columns={columns}
            isLoading={props.loadingState}
            enableRangeSelection
            enableColumnSelection
            stickyTopRows={2}
            onCellsChanged={onCellsChanged}
          />
        </div>
      </div>

      <Tabs tabList={tabList} />
    </LangmuirIsothermContainer>
  );
};

export default LangmuirIsotherm;
