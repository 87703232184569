import { ModuleKoldunMcsimGasOilOptions, ModuleKoldunMcsimResProp } from "../../../model";
import InputField from "@/components/fields/InputField";
import CustomCard from "@/components/Card";

const horizontalSelectFieldsFirstColumn = [
  {
    label: "Permeability(X-axis)",
    suffix: "md",
    valueKey: "permeability_xaxis_md",
  },
  {
    label: "Permeability(Y-axis)",
    suffix: "md",
    valueKey: "permeability_yaxis_md",
  },
  {
    label: "Permeability(Z-axis)",
    suffix: "md",
    valueKey: "permeability_zaxis_md",
  },
  {
    label: "Horizontal well center position (X-axis)",
    suffix: "ft",
    valueKey: "well_position_xaxis_ft",
  },
  {
    label: "Horizontal well center position (Y-axis)",
    suffix: "ft",
    valueKey: "well_position_yaxis_ft",
  },
  {
    label: "Horizontal well center position (Z-axis)",
    suffix: "ft",
    valueKey: "well_position_zaxis_ft",
  },
];

const horizontalSelectFieldsSecondColumn = [
  {
    label: "Reservoir Width (X-axis)",
    suffix: "ft",
    valueKey: "res_width_xaxis_ft",
  },
  {
    label: "Reservoir Length (Y-axis)",
    suffix: "ft",
    valueKey: "res_length_yaxis_ft",
  },
  {
    label: "Net Pay",
    suffix: "ft",
    valueKey: "res_height_zaxis_ft",
  },
  {
    label: "Horizontal Section Length (Y-axis)",
    suffix: "ft",
    valueKey: "horizontal_well_length_yaxis_ft",
  },
];

interface HorizontalSelectInputsProps {
  value: ModuleKoldunMcsimGasOilOptions;
  onChange: (val: ModuleKoldunMcsimGasOilOptions) => void;
}

const HorizontalSelectInputs = ({ value, onChange }: HorizontalSelectInputsProps) => {
  if (!value) return <></>;

  return (
    <CustomCard>
      <h4 className="primaryColor noMarginVer">Horizontal Select</h4>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "250px 250px",
          gridGap: 20,
        }}
      >
        <div>
          {horizontalSelectFieldsFirstColumn.map((field) => (
            <InputField
              key={field.valueKey}
              label={field.label}
              suffix={field.suffix}
              value={value.res_prop[field.valueKey as keyof ModuleKoldunMcsimResProp]}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      res_prop: {
                        ...value.res_prop,
                        [field.valueKey]: v,
                      },
                    })
                  : null
              }
            />
          ))}
        </div>
        <div>
          {horizontalSelectFieldsSecondColumn.map((field) => (
            <InputField
              key={field.valueKey}
              label={field.label}
              suffix={field.suffix}
              value={value.res_prop[field.valueKey as keyof ModuleKoldunMcsimResProp]}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      res_prop: {
                        ...value.res_prop,
                        [field.valueKey]: v,
                      },
                    })
                  : null
              }
            />
          ))}
        </div>
      </div>
    </CustomCard>
  );
};

export default HorizontalSelectInputs;
