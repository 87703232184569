import React, { useMemo } from "react";

import { Container, UpperSection } from "./style";

import useTahkCsgInput from "../../hooks/input/useTahkCsgInput";
import { useTahkCsgState } from "../../context/TahkCsgContext";
import ReservoirInput from "./ReservoirInput";
import SummaryCard from "./SummaryCard";
import ModelComponents from "./ModelComponents";

const TahkCsgInput = () => {
  const {
    tabIndex,
    isLoading,
    tahkCsgState,
    setTahkCsgState,
    setApiError,
    analysisIdentity,
    setIsLoading,
    setPollStatus,
    setProgress,
    apiError,
    loadingDependency,
  } = useTahkCsgState();

  const [expandReservoirInput, setExpandReservoirInput] = React.useState(false);

  const inputs = useMemo(() => {
    return tahkCsgState?.inputs;
  }, [tahkCsgState?.inputs]);

  const { loadingState, errorInputValidation, tahkCsgInputCalculation, layerError } = useTahkCsgInput({
    tahkCsgState,
    tabIndex,
    isLoading,
    setTahkCsgState,
    setApiError,
    analysisIdentity,
    setIsLoading,
    setPollStatus,
    setProgress,
    apiError,
    loadingDependency,
  });

  return (
    <Container className="noScrollbar">
      <UpperSection expanded={expandReservoirInput}>
        {tabIndex === 1 && (
          <ReservoirInput
            inputs={inputs}
            layerError={layerError}
            loadingState={loadingState}
            errorInputValidation={errorInputValidation}
            setTahkCsgState={setTahkCsgState}
            setExpandReservoirInput={setExpandReservoirInput}
            expandReservoirInput={expandReservoirInput}
          />
        )}

        {!expandReservoirInput && <SummaryCard isLoading={loadingState} layers={tahkCsgInputCalculation?.layers} />}
      </UpperSection>

      <ModelComponents
        tabIndex={tabIndex}
        layers={tahkCsgInputCalculation?.layers}
        loadingState={isLoading || loadingState}
        inputs={inputs}
        setTahkCsgState={setTahkCsgState}
        errorInputValidation={errorInputValidation}
      />
    </Container>
  );
};

export default TahkCsgInput;
