import React from "react";

// utils
import useAutoRtaForecast, { AutoRtaForecastProps } from "../../hooks/useAutoRtaForecast";

// component
import AutoRtaForecastSetting from "./AutoRtaForecastSetting";
import AutoRtaForecastCalculation from "./AutoRtaForecastCalculation";

const AutoRtaForecastContainer = (props: AutoRtaForecastProps) => {
  const {
    errorInputValidation,
    forecastInputState,
    onChangeForecastInput,
    onChangeForecastInputTable,
    forecastEventsRow,
    forecastCalculationDataTableRow,
    forecastXAxes,
    forecastYAxes,
    forecastCalcSeries,
    cumulativeGasChartSeries,
    cumulativeGasYAxes,
    loadForecast,
    onClickCalculateForecast,
    autoRtaForecastParameter,
    forecastCalculationDataTableCol,
    fieldDataRow,
    fieldDataCol,
    fluidType,
    canCancelPoll,
    onCancelPoll,
  } = useAutoRtaForecast();

  const loadingState = loadForecast || props.isLoading;

  // don't display anything if no forecastInputState
  if (!forecastInputState) return <></>;

  return (
    <div style={{ height: "93%", display: "grid", gridTemplateColumns: "440px 1fr", gridGap: 20, padding: "1em" }}>
      <AutoRtaForecastSetting
        onChangeInput={onChangeForecastInput}
        forecastInput={forecastInputState}
        onChangeTableInput={onChangeForecastInputTable}
        errorInputValidation={errorInputValidation}
        forecastEventsRow={forecastEventsRow}
        autoRtaForecastParameter={autoRtaForecastParameter}
        isLoading={loadingState}
        onClickValidate={onClickCalculateForecast}
        fluidType={fluidType}
        canCancelPoll={canCancelPoll}
        onCancelPoll={onCancelPoll}
      />
      <AutoRtaForecastCalculation
        forecastCalculationDataTableCol={forecastCalculationDataTableCol}
        dataTableRow={forecastCalculationDataTableRow}
        fieldDataCol={fieldDataCol}
        fieldDataRow={fieldDataRow}
        forecastXAxes={forecastXAxes}
        forecastYAxes={forecastYAxes}
        isLoading={loadingState}
        forecastCalcSeries={forecastCalcSeries}
        cumulativeGasChartSeries={cumulativeGasChartSeries}
        cumulativeGasYAxes={cumulativeGasYAxes}
        fluidType={fluidType}
      />
    </div>
  );
};

export default AutoRtaForecastContainer;
