import { fetchApi } from "@/utils/apiFetcher";
import { batchTask } from "@/constants/apiUrl";
import { BatchTask } from "..";

export const getBatchTasks = () => {
  // if module is group run only takke the first object
  return fetchApi<BatchTask[]>({
    path: batchTask,
    queryStringParameters: {
      in_progress_only: "true",
    },
  });
};
