import _ from "lodash";
import { Language, ThemeMode } from "./types";

export const defaultUnit = {
  oil: {
    economicCutoff: "STB/D",
    initialRate: "bbl/D",
    initialDeclineRate: "1/D",
    initialDay: "D",
  },
  gas: {
    economicCutoff: "MMscf/D",
    initialRate: "MMscf/D",
    initialDeclineRate: "1/D",
    initialDay: "D",
  },
  water: {},
};

export const defaultUserPreference = {
  unitPreference: _.cloneDeep(defaultUnit),
  setUnitPreference: () => {},
  setLanguage: () => {},
  language: Language.English,
  theme: {},
  setMode: () => {},
  mode: ThemeMode.light,
};
