import * as Sentry from "@sentry/react";

export const captureException = (payload: any) => {
  if (process.env.NODE_ENV !== "development") {
    let restructuredErrorInfo = { ...payload };
    if (payload.code) {
      restructuredErrorInfo = {
        code: payload.code,
        message: payload.message,
      };
    }
    Sentry.setExtra("errObj", JSON.stringify(restructuredErrorInfo));
    Sentry.captureException(new Error(payload));
  } else {
    console.log(payload);
  }
};

// this part will be used for error api fecther since throwing and error will trigger capture exception already
export const setExtraErrException = (payload: any) => {
  if (process.env.NODE_ENV !== "development") {
    let restructuredErrorInfo = { ...payload };
    if (payload.code) {
      restructuredErrorInfo = {
        code: payload.code,
        message: payload.message,
      };
    }
    Sentry.setExtra("errObj", JSON.stringify(restructuredErrorInfo));
  } else {
    console.log(payload);
  }
};
