import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import dictionary from "@/constants/dictionary";
import { DataSet, isDataSet } from "@/model";
import { Row } from "@silevis/reactgrid";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const tagColumn = [
  {
    columnId: "key",
    width: 200,
  },
  {
    columnId: "val",
    width: 200,
  },
];

type SortState = {
  // if true mean sort alphanumerically
  // select either one will reset the other one ( only 1 active sort )
  key: boolean;
  value: boolean;
};

const defaultSortState = {
  key: true,
  value: false,
};

const sortKeyValPair = (list: string[], sortState: SortState) => {
  let sortedList = list;

  if (sortState.key || sortState.value) {
    sortedList = [...list]; // Create a shallow copy of the list

    sortedList.sort((a, b) => {
      const objA = a.split(":");
      const objB = b.split(":");

      if (sortState.key) {
        return objA[0].localeCompare(objB[0]);
      }

      return objA[1].localeCompare(objB[1]);
    });
  }

  return sortedList;
};
type UseTagListProps = {
  dataSets?: DataSet[];
  selectedDataSets?: DataSet[] | DataSet;
};
const useTagList = ({ dataSets, selectedDataSets }: UseTagListProps) => {
  const param = useParams();

  const { generateTagTableRow } = useTagTable();

  const selectedDataSetsConverted = useMemo(() => {
    if (isDataSet(selectedDataSets)) return [selectedDataSets];
    return selectedDataSets ?? [];
  }, [selectedDataSets]);

  const selectedWells = useMemo(() => {
    if (!dataSets) return [];
    if (param?.dataSetId) {
      return dataSets?.filter((well) => well.id === param?.dataSetId) ?? [];
    }
    return dataSets.filter((well) => selectedDataSetsConverted.findIndex((selectedWell) => selectedWell.id === well.id) > -1);
  }, [dataSets, param?.dataSetId, selectedDataSetsConverted]);

  const tagTableList = useMemo(() => {
    if (selectedWells.length <= 0) return [];
    return selectedWells.map((well) => {
      return {
        tagTableRow: generateTagTableRow(well),
        well,
      };
    });
  }, [generateTagTableRow, selectedWells]);

  return { selectedWells, tagTableList };
};

const useTagTable = () => {
  const [sortState, setSortState] = useState<SortState>(defaultSortState);
  const generateTagTableRow = useCallback(
    (well: DataSet): Row<any>[] => {
      const header = {
        rowId: "header",
        cells: [
          {
            type: "custom",
            text: `${dictionary.dataView.key}`,
            style: tableHeaderStyle,
            nonEditable: true,
            onClick: () => {
              setSortState((prev) => {
                return {
                  value: false,
                  key: !prev.key,
                };
              });
            },
          },
          {
            type: "custom",
            text: `${dictionary.dataView.value}`,
            style: tableHeaderStyle,
            nonEditable: true,
            onClick: () => {
              setSortState((prev) => {
                return {
                  key: false,
                  value: !prev.value,
                };
              });
            },
          },
        ],
        height: 50,
      };

      if (!well.tags) return [header];
      let keyList = sortKeyValPair(well.tags, sortState);

      return [
        header,
        ...keyList.map((keyVal, index) => {
          const [key, val] = keyVal.split(":");

          return {
            rowId: index,
            height: 30,
            cells: [
              {
                type: "text",
                text: key,
                style: tableCellStyle,
              },
              {
                type: "text",
                text: val,
                style: tableCellStyle,
              },
            ],
          };
        }),
      ];
    },
    [sortState]
  );

  return {
    generateTagTableRow,
  };
};

export default useTagList;
