import { createContext, useContext, useMemo, useState } from "react";
import { PaletteMode } from "@mui/material";

import { Language, ThemeMode, UnitSelection, UserPreferenceProviderProps, UserPreferenceState } from "./types";
import { defaultUnit, defaultUserPreference } from "./constants";
import { getMuiTheme } from "@/features/themes/mui-theme";

const UserPreferenceContext = createContext<UserPreferenceState>(defaultUserPreference);

const UserPreferenceProvider = ({ children }: UserPreferenceProviderProps) => {
  const [unitPreference, setUnitPreference] = useState<UnitSelection>(defaultUnit);
  const [language, setLanguage] = useState<Language>(Language.English);
  const [mode, setMode] = useState<PaletteMode>(ThemeMode.light);

  const theme = useMemo(() => getMuiTheme(mode), [mode]);

  const state = useMemo(() => {
    return { unitPreference, setUnitPreference, language, setLanguage, theme, setMode, mode };
  }, [unitPreference, language, theme, mode]);

  return <UserPreferenceContext.Provider value={state}>{children}</UserPreferenceContext.Provider>;
};

export function useUserPreference() {
  return useContext(UserPreferenceContext);
}

export default UserPreferenceProvider;
