import { ZMethod } from "@/model";

import InputField from "@/components/fields/InputField";
import DropdownField from "@/components/fields/DropdownField";

import { THEORETICAL_HELP_LINKS } from "@/constants/helpLinkUrl";
import { ModuleGazPzPvtOptions } from "./model";
import { generateWikiLink } from "@/features/help-link/help-link.utils";

interface ModuleGazPzInputGridProps {
  value: ModuleGazPzPvtOptions | undefined;
  onChange: (value: ModuleGazPzPvtOptions) => void;
}

const zMethodOptions = [
  { key: ZMethod.DPR, text: "DPR (default)" },
  { key: ZMethod.BEGGS_BRILL, text: "Beggs/Brill" },
  { key: ZMethod.PAPAY, text: "Papay (simple)" },
  { key: ZMethod.DRANCHUCK_ABU_KASSEM, text: "Dranchuck/Abu-Kassem" },
];

const ModuleGazPzInputGrid = ({ value, onChange }: ModuleGazPzInputGridProps) => {
  if (!value) {
    return null;
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: 20,
      }}
    >
      <div>
        <InputField
          label="Initial pressure"
          suffix="psia"
          required
          value={value.initial_pressure_psia ?? null}
          onChange={(v) =>
            onChange({
              ...value,
              initial_pressure_psia: Number(v),
            })
          }
        />
        <InputField
          label="Formation temperature"
          suffix="°F"
          value={value.formation_temp_of}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  formation_temp_of: Number(v),
                })
              : null
          }
        />
      </div>

      <div>
        <InputField
          label="Gas gravity"
          suffix="g"
          value={value.gas_gravity}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  gas_gravity: Number(v),
                })
              : null
          }
        />
        <InputField
          label="N₂"
          suffix="%"
          min={0}
          max={100}
          value={value.n2_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  n2_perc: Number(v),
                })
              : null
          }
        />
        <InputField
          label="CO₂"
          suffix="%"
          min={0}
          max={100}
          value={value.co2_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  co2_perc: Number(v),
                })
              : null
          }
        />
        <InputField
          label="H₂S"
          suffix="%"
          min={0}
          max={100}
          value={value.h2s_perc}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  h2s_perc: Number(v),
                })
              : null
          }
        />
      </div>

      <div>
        <DropdownField
          style={{
            marginTop: 0,
          }}
          helpUrl={generateWikiLink(THEORETICAL_HELP_LINKS.gasDeviationFactor)}
          label="Z Method"
          options={zMethodOptions}
          selectedKey={value.z_method}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  z_method: v as ZMethod,
                })
              : null
          }
        />
      </div>
    </div>
  );
};

export default ModuleGazPzInputGrid;
