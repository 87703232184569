import CustomTable from "@/components/CustomTable";
import DropdownField from "@/components/fields/DropdownField";
import CustomCard from "@/components/Card";
import CustomButton from "@/components/Button";

import dictionary from "@/constants/dictionary";
import { mapEnumToDropdown } from "@/utils/general";

import { FluidType } from "@/models/Generic";

import { useUserPreference } from "./context";
import useUnitPref, { unitColumn } from "./hooks/useUnitPref";
import { PaletteMode } from "@mui/material";
import { ThemeMode } from "./context/types";

const UserPreference = () => {
  const { unitPreference, setUnitPreference, setMode, mode } = useUserPreference();
  const { selectedFluid, setSelectedFluid, dataTableRows, onChangeUnit } = useUnitPref({
    unitPreference,
    setUnitPreference,
  });

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: 20,
          marginTop: 20,
        }}
      >
        <CustomCard>
          <h4 className="primaryColor noMarginVer">{dictionary.userPreference.unitPreference}</h4>

          <DropdownField
            label={dictionary.userPreference.fluidType}
            options={mapEnumToDropdown(FluidType)}
            selectedKey={selectedFluid}
            onChange={(val) => setSelectedFluid(val as FluidType)}
          />

          <CustomTable
            style={{ height: 300, overflow: "auto" }}
            onCellsChanged={onChangeUnit}
            rows={dataTableRows}
            columns={unitColumn}
            stickyTopRows={1}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
            }}
          >
            <CustomButton
              style={{
                marginBottom: 15,
                marginTop: 20,
                marginRight: 20,
              }}
              onClick={() => {
                console.log("export setting");
              }}
            >
              {dictionary.userPreference.export}
            </CustomButton>
            <CustomButton
              style={{
                marginBottom: 15,
                marginTop: 20,
                marginRight: 20,
              }}
              onClick={() => {
                console.log("import setting");
              }}
            >
              {dictionary.userPreference.import}
            </CustomButton>
          </div>
        </CustomCard>

        <CustomCard>
          <h4 className="primaryColor noMarginVer">{dictionary.userPreference.userPreference}</h4>
          <DropdownField
            label={dictionary.userPreference.mode}
            options={mapEnumToDropdown(ThemeMode)}
            selectedKey={mode}
            onChange={(val) => setMode(val as PaletteMode)}
          />
        </CustomCard>
      </div>
      <CustomButton
        style={{
          marginBottom: 15,
          marginTop: 20,
          float: "right",
        }}
        onClick={() => {
          console.log("save setting");
        }}
      >
        {dictionary.userPreference.save}
      </CustomButton>
    </div>
  );
};

export default UserPreference;
