import styled from "@emotion/styled";

export const Container = styled.div`
  padding: 1em;
  overflow: scroll;

  display: grid;
  grid-template-columns: 380px 1fr;
  grid-gap: 20px;
  height: 95%;

  h4 {
    margin: 0px;
  }
`;
