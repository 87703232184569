import AnalysisParameter from "./AnalysisParameter";
import AnalysisChartFmb from "./AnalysisChart";

import { useAutoFmbState } from "../../context/AutoFmbContext";
import useAutoFmbAnalysis from "../../hooks/useAutoFmbAnalysis";

const AutoFmbAnalysis = () => {
  const {
    isLoading,
    apiError,
    autoFmbState,
    setAutoFmbState,
    analysisIdentity,
    tabIndex,
    setApiError,
    setIsLoading,
    setPollStatus,
    setProgress,
    setAutoFmbAnalysisCalculation,
    autoFmbAnalysisCalculation,
    setAutoFmbForecastCalculation,
  } = useAutoFmbState();

  const {
    errorInputValidation,
    updateAnalysisFmb,
    onCalculateAnalysisFmb,
    summaryTableData,
    onTableCellChange,
    parameterTableRow,
    onPointTableCellChange,
    pzPointTableRow,
    onDragChartHandlebar,
  } = useAutoFmbAnalysis({
    setApiError,
    setAutoFmbState,
    tabIndex,
    analysisIdentity,
    apiError,
    autoFmbState,
    setIsLoading,
    setPollStatus,
    setProgress,
    setAutoFmbAnalysisCalculation,
    autoFmbAnalysisCalculation,
    setAutoFmbForecastCalculation,
  });

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "480px 1fr",
        gridGap: 20,
        padding: "1em",
      }}
    >
      <AnalysisParameter
        parameters={autoFmbState?.analysis}
        errorInputValidation={errorInputValidation}
        isLoading={isLoading}
        onChangeParameter={updateAnalysisFmb}
        onClickCalculate={onCalculateAnalysisFmb}
        summaryTableData={summaryTableData}
        onTableCellChange={onTableCellChange}
        parameterTableRow={parameterTableRow}
        onPointTableCellChange={onPointTableCellChange}
        pzPointTableRow={pzPointTableRow}
      />
      <AnalysisChartFmb
        autoFmbState={autoFmbState}
        onDragChartHandlebar={onDragChartHandlebar}
        analysisResult={autoFmbAnalysisCalculation}
        isLoading={isLoading}
        tabIndex={tabIndex}
      />
    </div>
  );
};

export default AutoFmbAnalysis;
