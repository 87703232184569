import { spadDeclineAnalysisChartDrag } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { SpadDeclineChartDrag, SpadDeclineType, SpadDeclinePayload } from "../State";
import { HandlebarCoordinateItem } from "@/models/Generic";

type SpadDeclineChartDragPayload = SpadDeclinePayload & {
  handlebar: HandlebarCoordinateItem;
};

export const postDragSpadDecline = (body: SpadDeclineChartDragPayload, type: SpadDeclineType, projectId: string) => {
  return fetchApi<SpadDeclineChartDrag, SpadDeclineChartDragPayload>({
    path: spadDeclineAnalysisChartDrag(type, projectId),
    body,
    type: "post",
  });
};
