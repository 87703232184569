import { useMemo } from "react";

import Tabs from "@/components/Tabs";
import FossilyticsChart from "@/components/FossilyticsChart";
import CustomCard from "@/components/Card";
import DropdownField from "@/components/fields/DropdownField";
import CustomTable from "@/components/CustomTable";

import dictionary from "@/constants/dictionary";
import { AutoFmbState, ForecastFmbResultResponse } from "@/models/autoFmb";
import { capitalizeFirstLetter } from "@/utils/general";

import { caseKey } from "../../constants";
import useAutoFmbForecastResult from "../../hooks/useAutoFmbForecastResult";

type ForecastChartFmbProps = {
  isLoading: boolean;
  autoFmbForecastCalculation?: ForecastFmbResultResponse;
  autoFmbState?: AutoFmbState;
};

const ForecastChartFmb = ({ isLoading, autoFmbForecastCalculation, autoFmbState }: ForecastChartFmbProps) => {
  const {
    setSelectedCase,
    chartAxes,
    forecastCalcSeries,
    cumulativeChartAxes,
    cumulativeChartSeries,
    forecastCalculationDataTableCol,
    forecastCalculationDataTableRow,
    fieldDataCol,
    fieldDataRow,
    selectedCase,
  } = useAutoFmbForecastResult({
    autoFmbForecastCalculation,
    autoFmbState,
  });

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.genericChart.chart}</span>,
        key: dictionary.genericChart.chart,
        content: (
          <div
            style={{
              height: 600,
            }}
          >
            <FossilyticsChart
              id={"fmb_chart"}
              key={"fmb_chart"}
              isLoading={isLoading}
              xAxes={chartAxes.xAxes}
              yAxes={chartAxes.yAxes}
              series={forecastCalcSeries}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.genericChart.cumulativeGasChart}</span>,
        key: dictionary.genericChart.cumulativeGasChart,
        content: (
          <div
            style={{
              height: 600,
            }}
          >
            <FossilyticsChart
              id={"pz_chart"}
              key={"pz_chart"}
              isLoading={isLoading}
              xAxes={cumulativeChartAxes.xAxes}
              yAxes={cumulativeChartAxes.yAxes}
              series={cumulativeChartSeries}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.genericChart.modelDataTable}</span>,
        key: "Data table",
        content: (
          <div>
            <DropdownField
              label={dictionary.fmb.caseOption}
              options={caseKey.map((key) => ({
                key,
                text: capitalizeFirstLetter(key),
              }))}
              selectedKey={selectedCase}
              onChange={(v) => setSelectedCase(v)}
              disabled={isLoading}
            />
            <CustomTable rows={forecastCalculationDataTableRow} columns={forecastCalculationDataTableCol} isLoading={isLoading} />
          </div>
        ),
      },
      {
        label: <span>{dictionary.genericChart.measuredDataTable}</span>,
        key: "Measured Data table",
        content: (
          <div>
            <CustomTable rows={fieldDataRow} columns={fieldDataCol} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [
    chartAxes.xAxes,
    chartAxes.yAxes,
    cumulativeChartAxes.xAxes,
    cumulativeChartAxes.yAxes,
    cumulativeChartSeries,
    fieldDataCol,
    fieldDataRow,
    forecastCalcSeries,
    forecastCalculationDataTableCol,
    forecastCalculationDataTableRow,
    isLoading,
    selectedCase,
    setSelectedCase,
  ]);

  return (
    <CustomCard
      style={{
        opacity: isLoading || !autoFmbForecastCalculation ? 0.5 : 1,
        position: "relative",
        display: "flex",
        height: "100%",
      }}
    >
      {!autoFmbForecastCalculation && <div className="overlay-section" />}

      <Tabs tabList={tabList} />
    </CustomCard>
  );
};

export default ForecastChartFmb;
