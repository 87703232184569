import React, { useMemo } from "react";

import ProjectSettingsDialog from "./ProjectSettingsDialog";

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import DialogMui from "@mui/material/Dialog";

import GroupDialog from "./GroupDialogV2";
import ProjectDialog from "./ProjectDialogV2";
import { useTreeViewState } from "../hooks/TreeViewContextV2";
import { getIdFromActiveKey } from "../utils";
import { DialogEnum } from "../types";

const DialogSection = () => {
  const {
    isLoading,
    selectedKey,
    copyFromSelected,
    setCopyFromSelected,
    createNewProject,
    deleteProject,
    editExistingProject,

    createNewGroups,
    editExistingGroup,
    selectedGroup,
    setNewGroupsNames,
    newGroupsNames,
    removeDataSets,
    activeDialog,
    setActiveDialog,
    mappedItemKeyTotal,
    deleteGroup,
    dataSets,
  } = useTreeViewState();

  const safeSelectedKey = useMemo(() => {
    if (selectedKey) return selectedKey;
    return "";
  }, [selectedKey]);

  const { groupIds, dataSet } = getIdFromActiveKey(safeSelectedKey);

  const datasetName = useMemo(() => {
    return dataSet.map((id) => {
      const item = dataSets?.find((data) => data.id === id);
      return item?.name ?? id;
    });
  }, [dataSet, dataSets]);

  return (
    <>
      {/* Create project dialog */}
      <ProjectDialog
        selectedKey={safeSelectedKey}
        copyFromSelected={copyFromSelected}
        setCopyFromSelected={setCopyFromSelected}
        isLoading={isLoading}
        createNewProject={createNewProject}
        deleteProject={deleteProject}
        editExistingProject={editExistingProject}
        setActiveDialog={setActiveDialog}
        activeDialog={activeDialog}
      />

      {activeDialog === DialogEnum.PROJECT_SETTING && (
        <ProjectSettingsDialog setActiveDialog={setActiveDialog} isLoading={isLoading} selectedKey={safeSelectedKey} />
      )}

      {/* Create groups dialog */}
      {safeSelectedKey && (
        <GroupDialog
          selectedKey={safeSelectedKey}
          isLoading={isLoading}
          createNewGroups={createNewGroups}
          editExistingGroup={editExistingGroup}
          selectedGroup={selectedGroup}
          setNewGroupsNames={setNewGroupsNames}
          newGroupsNames={newGroupsNames}
          setActiveDialog={setActiveDialog}
          activeDialog={activeDialog}
          deleteGroup={deleteGroup}
        />
      )}

      {/* Remove data sets dialog */}
      <DialogMui
        open={activeDialog === DialogEnum.REMOVE_DATASET}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{`Remove Wells`}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>Warning: This operation cannot be undone!</DialogContentText>
          <DialogContentText style={{ color: "black", marginTop: 5 }}>
            Removing well&nbsp; "{datasetName.join(", ")}"&nbsp; from group&nbsp; "
            {selectedKey && mappedItemKeyTotal[groupIds[groupIds.length - 1]]?.name}" .`
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={isLoading}
            onClick={removeDataSets}
            data-testid="remove-well-btn"
          >
            Remove
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>
    </>
  );
};

export default DialogSection;
