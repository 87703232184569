import { useMemo } from "react";

import Tabs from "@/components/Tabs";
import dictionary from "@/constants/dictionary";
import useThemeStyling from "@/utils/useThemeStyling";

import { useKoldunCsgState } from "../../hooks/KoldunCsgContext";
import LoadingIndicator from "@/components/LoadingIndicator";
import useKoldunCsgForecastChart from "../../hooks/forecast/useKoldunCsgForecastChart";
import FossilyticsChart from "@/components/FossilyticsChart";
import DropdownField from "@/components/fields/DropdownField";
import CustomTable from "@/components/CustomTable";

const ForecastChartSection = () => {
  const { state, loadingState, koldunCsgCalculation } = useKoldunCsgState();
  const {
    chartXAxes,
    chartYAxes,
    dataTableCol,
    dataTableLayerOption,
    simulation,
    dataTableRows,
    setSimulation,
    cumulativeSeries,
    rateSeries,
    rateLegend,
    cumulativeLegend,
    chartCumulativeYAxes,
  } = useKoldunCsgForecastChart({
    koldunCsgCalculation,
  });

  const { elevatedCard } = useThemeStyling();

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.koldunCsg.rates}</span>,
        key: dictionary.koldunCsg.rates,
        content:
          loadingState || !state ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <div
              style={{
                height: 600,
              }}
              className="chartWrapper"
              data-testid="forecast-koldun-csg-rates-chart-container"
            >
              <FossilyticsChart
                data-testid="forecast-koldun-csg-rates-chart"
                key={"forecast_koldun_csg_rates"}
                id="forecast_koldun_csg_rates"
                isLoading={loadingState}
                xAxes={chartXAxes}
                yAxes={chartYAxes}
                series={rateSeries}
                legends={rateLegend}
              />
            </div>
          ),
      },
      {
        label: <span>{dictionary.koldunCsg.cumulativeProduction}</span>,
        key: dictionary.koldunCsg.cumulativeProduction,
        content:
          loadingState || !state ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <div
              style={{
                height: 600,
              }}
              className="chartWrapper"
              data-testid="forecast-koldun-csg-cumulative-chart-container"
            >
              <FossilyticsChart
                data-testid="forecast-koldun-csg-cumulative-chart"
                key={"forecast_koldun_csg_cumulative"}
                id="forecast_koldun_csg_cumulative"
                isLoading={loadingState}
                xAxes={chartXAxes}
                yAxes={chartCumulativeYAxes}
                series={cumulativeSeries}
                legends={cumulativeLegend}
              />
            </div>
          ),
      },
      {
        label: <span>{dictionary.koldunCsg.dataTable}</span>,
        key: dictionary.koldunCsg.dataTable,
        content:
          loadingState || !state ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <div>
              <DropdownField
                data-testid="select-simulation-dropdown"
                label={dictionary.koldunCsg.seleectSimulation}
                options={dataTableLayerOption}
                selectedKey={String(simulation)}
                onChange={(val) => setSimulation(val)}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  overflowX: "scroll",
                  height: 900,
                }}
              >
                <CustomTable
                  data-testid="forecast-koldun-csg-data-table"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "absolute",
                  }}
                  rows={dataTableRows}
                  columns={dataTableCol}
                  isLoading={loadingState}
                  enableRangeSelection
                  enableColumnSelection
                  stickyTopRows={2}
                />
              </div>
            </div>
          ),
      },
    ];
  }, [
    chartCumulativeYAxes,
    loadingState,
    state,
    chartXAxes,
    chartYAxes,
    rateSeries,
    rateLegend,
    cumulativeSeries,
    cumulativeLegend,
    dataTableLayerOption,
    simulation,
    dataTableRows,
    dataTableCol,
    setSimulation,
  ]);

  return (
    <div
      style={{
        ...elevatedCard,
      }}
    >
      <Tabs tabList={tabList} />
    </div>
  );
};

export default ForecastChartSection;
