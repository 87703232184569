import { useCallback, useMemo } from "react";
import { useTheme } from "@mui/material/styles";

import { tableHeaderStyle } from "@/components/CustomTable";
import dictionary from "@/constants/dictionary";

import { CellChange, DateCell, NumberCell } from "@silevis/reactgrid";
import { ForecastEvent, KoldunCsgApiState } from "@/models/koldunV2";
import { convertDateToUtcTimeZoneIsoString } from "@/utils/dateTime";
import { ContractRateEnum, FlowPressureTypeEnum } from "@/models/InputGeneric";

type UseKoldunCsgForecastInputType = {
  state?: KoldunCsgApiState;
  setKoldunCsgState: React.Dispatch<React.SetStateAction<KoldunCsgApiState | undefined>>;
};

const useKoldunCsgForecastInput = ({ state, setKoldunCsgState }: UseKoldunCsgForecastInputType) => {
  const { palette } = useTheme();

  const flowingScheduleHeader = useMemo(() => {
    return {
      date: dictionary.koldunCsg.date,
      flowing_pressure: dictionary.koldunCsg[state?.forecast?.selected_flow_pressure_type ?? FlowPressureTypeEnum.BottomHolePressure],
      rate: dictionary.koldunCsg[state?.forecast?.contract_rate_mode ?? ContractRateEnum.WaterContractRate],
    };
  }, [state?.forecast?.contract_rate_mode, state?.forecast?.selected_flow_pressure_type]);

  const flowingPressureCol = Object.keys(flowingScheduleHeader).map((columnId, index) => {
    return { columnId: columnId, width: index === 0 ? 100 : 145 };
  });

  const forecastEventsRow = useMemo(() => {
    if (!state?.forecast?.forecast_events) return [];
    const concatedRow = [
      ...state.forecast.forecast_events,
      ...Array.from(Array(100).keys()).map(() => ({
        date: 0,
        flowing_pressure: 0,
        rate: null,
      })),
    ];

    return [
      {
        rowId: "header",
        cells: Object.values(flowingScheduleHeader).map((header) => {
          return {
            type: "header",
            text: header,
            style: tableHeaderStyle,
          };
        }),
        height: 50,
      },
      ...concatedRow.map((row, index) => {
        const background = index === 0 ? "#edebe9" : palette.primary.light;

        return {
          rowId: index,
          cells: [
            {
              type: "date",
              date: row.date ? new Date(row.date) : undefined,
              style: {
                background,
              },
              hideZero: true,
              nonEditable: index === 0,
            },
            {
              type: "number",
              value: row.flowing_pressure,
              hideZero: true,
            },
            {
              type: "number",
              value: row.rate,
              hideZero: true,
            },
          ],
        };
      }),
    ];
  }, [flowingScheduleHeader, palette.primary.light, state?.forecast?.forecast_events]);

  const handleFlowingPressureCellChange = useCallback(
    (changes: CellChange[]) => {
      if (!state?.forecast) return;
      const newFlowingPressure = [...state.forecast.forecast_events] as any[];

      changes.forEach((change) => {
        const numberCell = change.newCell as NumberCell;
        const idx = change.rowId as number;
        const colIdx = change.columnId as keyof ForecastEvent;

        if (!newFlowingPressure[idx]) {
          newFlowingPressure[idx] = {
            date: "",
            flowing_pressure: 0,
            rate: null,
          };
        }
        if (colIdx === "date") {
          const dateCell = change.newCell as DateCell;
          newFlowingPressure[idx][colIdx] = convertDateToUtcTimeZoneIsoString(new Date(dateCell.date ?? 0)) ?? "";
          if (newFlowingPressure[idx][colIdx] && !newFlowingPressure[idx].flowing_pressure)
            newFlowingPressure[idx].flowing_pressure = newFlowingPressure[idx - 1].flowing_pressure;
        } else if (isNaN(numberCell.value) || Number(numberCell.value) < 0) newFlowingPressure[idx][colIdx] = null;
        else if (numberCell.value === 0) newFlowingPressure[idx][colIdx] = 0;
        else newFlowingPressure[idx][colIdx] = numberCell.value;

        if (Object.values(newFlowingPressure[idx]).filter((val) => !!val).length === 0) newFlowingPressure.splice(idx, 1);
      });

      setKoldunCsgState((prev) => {
        if (!prev) return prev;

        return {
          ...prev,
          forecast: {
            ...prev.forecast,
            forecast_events: newFlowingPressure,
          },
        };
      });
    },
    [setKoldunCsgState, state?.forecast]
  );

  return {
    forecastEventsRow,
    flowingPressureCol,
    handleFlowingPressureCellChange,
  };
};

export default useKoldunCsgForecastInput;
