import { useMemo } from "react";
import useThemeStyling from "./useThemeStyling";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

type UseChartInfoProps = {
  xAxesLabel: string;
  yAxesLabel: string;
  chartPlot?: any[];
  seriesName: string;
  seriesColor?: string;
};

// this is to generate many standard chart with similar value
const useChartInfo = ({ xAxesLabel, yAxesLabel, chartPlot = [], seriesName, seriesColor }: UseChartInfoProps) => {
  const { palette } = useThemeStyling();

  const xAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: xAxesLabel,
        type: "value",
        color: palette.customColor.black,
      },
    ],
    [palette.customColor.black, xAxesLabel]
  );

  const yAxes = useMemo<FossilyticsChartAxis[]>(
    () => [
      {
        name: yAxesLabel,
        type: "value",
        color: palette.customColor.black,
        show: true,
      },
    ],
    [palette.customColor.black, yAxesLabel]
  );

  const series = useMemo<FossilyticsChartSeries[]>(
    () => [
      {
        name: seriesName,
        type: "line",
        hideSymbol: true,
        color: seriesColor ?? palette.customColor.orangeLighter,
        lineWidth: 2,
        z: 2,
        data: chartPlot,
      },
    ],
    [chartPlot, palette.customColor.orangeLighter, seriesName, seriesColor]
  );

  return {
    xAxes,
    yAxes,
    series,
  };
};

export default useChartInfo;
