import dictionary from "@/constants/dictionary";
import {
  gasPvtBase,
  gasPvtWidthBase,
  geomechanicBase,
  relativePermeabilityBase,
  relativePermeabilityBaseWidth,
  waterPvtBase,
  wellInputAreaBase,
} from "@/constants/gridTable";
import { TableHeader } from "@/models/Generic";
import { Column } from "@silevis/reactgrid";

export const reservoirInputRowsHeader: { [key: string]: string } = {
  layer: dictionary.tahk.layer,
  initialPressure: dictionary.tahk.initialPressure,
  formationTemperature: dictionary.tahk.formationTemperature,
  netPay: dictionary.tahk.netPay,
  layerDepth: dictionary.tahk.layerDepth,

  porosity: dictionary.tahk.porosity,
  selectedFormationCompressibilityCorrelation: dictionary.tahk.selectedFormationCompressibilityCorrelation,
  formationCompressibility: dictionary.tahk.formationCompressibility,
  initialWaterSaturation: dictionary.tahk.initialWaterSaturation,
  reservoirArea: dictionary.tahk.reservoirArea,
  reservoirWidth: dictionary.tahk.reservoirWidth,
  reservoirLength: dictionary.tahk.reservoirLength,
  permeabilityXAxis: dictionary.tahk.permeabilityXAxis,
  permeabilityYAxis: dictionary.tahk.permeabilityYAxis,
  permeabilityZAxis: dictionary.tahk.permeabilityZAxis,
};
export const reservoirInputRowsHeaderWidth: { [key: string]: number } = {
  layer: 50,
  initialPressure: 110,
  formationTemperature: 110,
  netPay: 100,
  layerDepth: 110,
  porosity: 100,
  selectedFormationCompressibilityCorrelation: 110,
  formationCompressibility: 130,
  initialWaterSaturation: 110,
  reservoirArea: 60,
  reservoirWidth: 60,
  reservoirLength: 60,
  permeabilityXAxis: 110,
  permeabilityYAxis: 110,
  permeabilityZAxis: 110,
};

export const tableHeaderStyle = {
  background: "#fbdcb9",
  display: "flex",
  justifyContent: "center",
  fontWeight: "600",
  border: {
    bottom: {
      width: "0px",
    },
  },
  textOverflow: "ellipsis",
  whiteSpace: "break-spaces",
  textAlign: "center",
  padding: 10,
};

export const tableHeaderNotationStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  border: {
    bottom: {
      width: "3px",
    },
  },
};

export const tableCellStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
};

export const summaryCardTableHeader = [
  {
    key: "parameter",
    label: dictionary.tahk.parameter,
  },
  {
    key: "value",
    label: dictionary.tahk.value,
  },
  {
    key: "unit",
    label: dictionary.tahk.units,
  },
];

export const gasPvtHeader: { [key: string]: string } = {
  layer: dictionary.tahk.layer,
  ...gasPvtBase,
};

export const gasPvtWidth: { [key: string]: number } = {
  layer: 50,
  ...gasPvtWidthBase,
};

export const waterPvtHeader = {
  layer: dictionary.tahk.layer,
  ...waterPvtBase,
};

export const dataTableHeaderStyles = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  fontWeight: "bold",
  zIndex: 1,
};

export const headerDataTableUnitStyles = { background: "#fff", display: "flex", justifyContent: "center", zIndex: 1 };

export const gasPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
] as Column[];

export const waterPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
] as Column[];

export const relativePermeabilityHeader: {
  [key: string]: {
    label: string;
    sub?: string;
  };
} = {
  layer: {
    label: dictionary.tahk.layer,
  },
  ...relativePermeabilityBase,
};

export const relativePermeabilityWidth: { [key: string]: number } = {
  layer: 50,
  ...relativePermeabilityBaseWidth,
};

export const areaWellInputHeader = {
  layer: dictionary.tahk.layer,
  ...wellInputAreaBase,
  skin: dictionary.tahk.skin,
};

export const dimensionWellInputHeader = {
  layer: dictionary.tahk.layer,
  wellboreRadius: dictionary.tahk.wellboreRadius,
  selectedWellType: dictionary.tahk.selectedWellType,
  fractureHalfLength: dictionary.tahk.fractureHalfLength,
  fractureConductivity: dictionary.tahk.fractureConductivity,
  horizontalLength: dictionary.tahk.horizontalLength,
  wellPositionXaxis: dictionary.tahk.wellPositionXAxis,
  wellPositionYaxis: dictionary.tahk.wellPositionYAxis,
  wellPositionZaxis: dictionary.tahk.wellPositionZAxis,
  selectedSkinModel: dictionary.tahk.selectedSkinModel,
  skin: dictionary.tahk.skin,
};

export const flowingPressureHeader = {
  date: dictionary.tahk.date,
  pressure: dictionary.tahk.flowingPressure,
  rate: dictionary.tahk.rate,
};

export const geomechanicsHeader = {
  layer: dictionary.tahk.layer,
  ...geomechanicBase,
};

export const langmuirIsothermVolumetricHeader: { [key: string]: string } = {
  layer: dictionary.tahk.layer,
  desorptionPressure: dictionary.tahk.desorptionPressure,
  langmuirPressure: dictionary.tahk.langmuirPressure,
  langmuirVolume: dictionary.tahk.langmuirVolume,
  rockDensity: dictionary.tahk.rockDensity,
};

export const additionalLangmuirIsothermVolumetricHeader: { [key: string]: string } = {
  absorbedGas: dictionary.tahk.absorbedGas,
  freeGas: dictionary.tahk.freeGas,
  ogip: dictionary.tahk.ogip,
};

export const langmuirIsothermOGIPHeader: { [key: string]: string } = {
  layer: dictionary.tahk.layer,
  gasContentSaturation: dictionary.tahk.underSaturation,
  initialGasContent: dictionary.tahk.initialGasContent,
  langmuirPressure: dictionary.tahk.langmuirPressure,
  ogip: dictionary.tahk.ogip,
};

export const additionalLangmuirIsothermOGIPHeader: { [key: string]: string } = {
  langmuirVolume: dictionary.tahk.langmuirVolume,
  desorptionPressure: dictionary.tahk.desorptionPressure,
  formationDensity: dictionary.tahk.formationDensity,
  absorbedGas: dictionary.tahk.absorbedGas,
  freeGas: dictionary.tahk.freeGas,
};

export const langmuirHeaderWithLink = ["langmuirPressure", "langmuirVolume"];
export const langmuirHeaderWithSub = ["langmuirPressure", "langmuirVolume"];

export const langmuirIsothermDataTable = {
  pressure: { name: "Pressure", unit: "(psia)", sub: "" },
  gas_content: { name: "Gas Content", unit: "(scf/ton)", sub: "" },
  desorption_compressibility: { name: "C", unit: "(psi⁻¹)", sub: "d" },
};

export const langmuirDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "gas_content", width: 80, justifyContent: "center" },
  { columnId: "desorption_compressibility", width: 80, justifyContent: "center" },
] as Column[];

export const getDataTableColumn = (table: TableHeader) =>
  Object.keys(table).map((key) => {
    return {
      columnId: key,
      width: 80,
      justifyContent: "center",
    };
  });

export const seriesKey = ["gasRate", "waterRate", "bottomholePressure"];
export const dataSeriesKey = ["gasRate", "waterRate", "casingPressure", "tubingPressure", "bottomholePressure"];

export const cumulativeSeriesKey = ["cumulativeGasProduction", "cumulativeWaterProduction"];
