import { useState } from "react";
import { DialogEnum } from "../types";

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Checkbox as CheckboxMui } from "@mui/material";
import DialogMui from "@mui/material/Dialog";

import InputField from "@/components/fields/InputField";

interface ProjectDialogProps {
  selectedKey: string | undefined;
  copyFromSelected: boolean;
  setCopyFromSelected: (copy: boolean) => void;
  isLoading: boolean;
  createNewProject: (name: string) => Promise<void> | void;
  deleteProject: () => Promise<void> | void;
  editExistingProject: (name: string) => Promise<void> | void;

  activeDialog?: DialogEnum;
  setActiveDialog: (val: DialogEnum | undefined) => void;
}

const ProjectDialog = ({
  selectedKey,
  copyFromSelected,
  setCopyFromSelected,
  isLoading,
  createNewProject,
  deleteProject,
  editExistingProject,
  activeDialog,
  setActiveDialog,
}: ProjectDialogProps) => {
  const [name, setName] = useState("");

  return (
    <>
      {/* new project dialog */}
      <DialogMui
        open={activeDialog === DialogEnum.NEW_PROJECT}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{"New Project"}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>
            Please enter a name for the new project. You can optionally copy groups and settings from the currently selected project.
          </DialogContentText>
          <InputField
            label="New project name"
            required
            placeholder="Enter a project name..."
            disabled={isLoading}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                createNewProject(name);
                setName("");
              }
            }}
            debounceDelay={10}
            value={name}
            type="text"
            onChange={(v) => setName(String(v ?? ""))}
          />
          {selectedKey && (
            <FormControlLabel
              control={
                <CheckboxMui
                  onChange={(e) => {
                    setCopyFromSelected(e.target.checked);
                  }}
                  checked={copyFromSelected}
                  size="small"
                  data-testid="Copy selected project"
                />
              }
              disabled={isLoading}
              label={"Copy selected project"}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="create-project"
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={isLoading || !name}
            onClick={() => {
              createNewProject(name);
              setName("");
            }}
          >
            Create
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>

      {/* edit project dialog */}
      <DialogMui
        open={activeDialog === DialogEnum.EDIT_PROJECT}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{"Edit Project"}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>You can update the project name below. Click save when you are done with this project. </DialogContentText>
          <InputField
            label="New project name"
            required
            placeholder="Enter a project name..."
            disabled={isLoading}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                editExistingProject(name);
                setName("");
              }
            }}
            debounceDelay={10}
            value={name}
            onChange={(v) => setName(String(v ?? ""))}
            type="text"
          />

          <span style={{ fontSize: 10 }}>Tip: Use the Enter key to save changes.</span>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={!name}
            onClick={() => {
              editExistingProject(name);
              setName("");
            }}
          >
            Save
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>

      <DialogMui
        open={activeDialog === DialogEnum.DELETE_PROJECT}
        keepMounted
        onClose={() => {
          setActiveDialog(undefined);
        }}
      >
        <DialogTitle className="primaryColor">{"Delete Project"}</DialogTitle>
        <DialogContent
          style={{
            maxWidth: 350,
          }}
        >
          <DialogContentText>Warning: This operation cannot be undone!</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "white", marginTop: 10, marginBottom: 10 }}
            variant="contained"
            disabled={isLoading}
            onClick={() => {
              deleteProject();
            }}
          >
            Delete
          </Button>

          <Button
            onClick={() => {
              setActiveDialog(undefined);
            }}
            disabled={isLoading}
            variant="outlined"
          >
            Cancel
          </Button>
        </DialogActions>
      </DialogMui>
    </>
  );
};

export default ProjectDialog;
