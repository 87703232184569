import { initializeRullon } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { RulonGroupRunResponse } from "../State";

export const postInitializeGroupRun = (projectId: string, data_set_ids: string[]) => {
  return fetchApi<RulonGroupRunResponse[]>({
    path: initializeRullon(projectId),
    body: {
      data_set_ids,
    },
    type: "post",
  });
};
