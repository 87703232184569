import { z, object, number, nativeEnum, string } from "zod";
import { PvtChartScheme, SummaryCardScheme } from "../Generic";
import {
  ContractRateEnum,
  FlowPressureTypeEnum,
  FormationCompressibilityCorrelationEnum,
  SkinModelEnum,
  ViscosityCorrelationEnum,
  WaterCompressibilityCorrelation,
  WellInputEnum,
  WaterViscosityCorrelation,
  WellboreModelEnum,
  relativePermeabilityScheme,
  matrixShrinkageScheme,
} from "../InputGeneric";
import { forecastEventScheme } from "./forecast";

const langmuirTableVolumetric = z.object({
  absorbed_gas: z.number(),
  free_gas: z.number(),
  ogip: z.number(),
});

const langmuirTableOgip = z.object({
  absorbed_gas: z.number(),
  free_gas: z.number(),
  langmuir_volume: z.number(),
  desorption_pressure: z.number(),
  formation_density: z.number(),
});
const langmuirTablescheme = langmuirTableOgip.or(langmuirTableVolumetric);

export type LangmuirTable = z.infer<typeof langmuirTablescheme>;

const tahkCsgPvtChartScheme = object({
  compressibility_factor: number().array(),
}).merge(PvtChartScheme);

const tahkCsgLangmuirChartScheme = object({
  pressure: number().array(),
  gas_content: number().array(),
  desorption_compressibility: number().array(),
});
const tahkCsgRelPermChartScheme = object({
  water_saturation: number().array(),
  gas_relative_permeability: number().array(),
  water_relative_permeability: number().array(),
});

const tahkCsgInputLayerChartsScheme = object({
  gas_pvt_charts: tahkCsgPvtChartScheme,
  water_pvt_charts: PvtChartScheme,
  langmuir_charts: tahkCsgLangmuirChartScheme,
  rel_perm_charts: tahkCsgRelPermChartScheme,
  summary_card: SummaryCardScheme.array(),
  langmuir_table: langmuirTablescheme,
});

export type TahkCsgInputLayerCharts = z.infer<typeof tahkCsgInputLayerChartsScheme>;

// Request
export enum TahkCsgSelectedGeometryEnum {
  SpecifyArea = "SpecifyArea",
  SpecifyDimension = "SpecifyDimension",
}

// WELLBORE

const TahkCsgWellboreParameter = object({
  pump_depth: number().nullable(),
  minimum_pump_head: number().nullable(),
  pump_draw_down_rate: number().nullable(),
  initial_liquid_level: number().nullable(),
});

const TahkCsgWellboreInput = object({
  contract_rate_mode: nativeEnum(ContractRateEnum),
  number_of_days: number(),
  selected_flow_pressure_type: nativeEnum(FlowPressureTypeEnum),
  parameters: TahkCsgWellboreParameter,
  selected_wellbore_model: nativeEnum(WellboreModelEnum),
});

// POWER LAW
const TahkCsgPowerLawSkin = object({
  permeability_a: number().nullable(),
  permeability_b: number().nullable(),
  skin_a: number().nullable(),
  skin_b: number().nullable(),
});

// Tahk csg input layer

const tahkCsgReservoirParamScheme = object({
  initial_pressure: number(),
  formation_temperature: number(),
  net_pay: number(),
  layer_depth: number(),
  porosity: number(),
  selected_formation_compressibility_correlation: nativeEnum(FormationCompressibilityCorrelationEnum),
  formation_compressibility: number(),
  initial_water_saturation: number(),
  permeability_x_axis: number(),
  permeability_y_axis: number(),
  permeability_z_axis: number(),
});

const tahkCsgReservoirParamAreaScheme = object({
  reservoir_area: number(),
}).merge(tahkCsgReservoirParamScheme);

const tahkCsgReservoirParamDimensionScheme = object({
  reservoir_width: number(),
  reservoir_length: number(),
}).merge(tahkCsgReservoirParamScheme);

// GAS PVT
export enum TahkCsgZCorrelationEnum {
  DranchukPurvisRobinson = "DranchukPurvisRobinson",
  BeggsBrill = "BeggsBrill",
  Papay = "Papay",
  DranchukAbouKassem = "DranchukAbouKassem",
}

const tahkCsgPvtScheme = object({
  carbon_dioxide: number(),
  hydrogen_sulphide: number(),
  nitrogen: number(),
  selected_gas_viscosity_correlation: nativeEnum(ViscosityCorrelationEnum),
  selected_z_correlation: nativeEnum(TahkCsgZCorrelationEnum),
  specific_gravity: number(),
});

// WATER PVT

const tahkCsgWaterPvtScheme = object({
  salinity: number(),
  selected_water_compressibility_correlation: nativeEnum(WaterCompressibilityCorrelation),
  selected_water_viscosity_correlation: nativeEnum(WaterViscosityCorrelation),
});

// LANGMUIR ISOTHERM
export enum TahkCsgLangmuirIsothermEnum {
  Ogip = "Ogip",
  Volumetric = "Volumetric",
}

const tahkCsgLangmuirIsothermVolumetricScheme = object({
  desorption_pressure: number(),
  langmuir_pressure: number(),
  langmuir_volume: number(),
  rock_density: number(),
});

const tahkCsgLangmuirIsothermOgipScheme = object({
  gas_content_saturation: number(),
  initial_gas_content: number(),
  langmuir_pressure: number(),
  ogip: number(),
});

const TahkCsgVerticalWellParameter = object({
  selected_skin_model: nativeEnum(SkinModelEnum),
  skin: number(),
});

const TahkCsgVerticalFracturedWellParameter = object({
  fracture_half_length: number(),
  fracture_conductivity: number(),
  selected_skin_model: nativeEnum(SkinModelEnum),
  skin: number(),
});

const TahkCsgHorizontalWellParameter = object({
  horizontal_length: number(),
  selected_skin_model: nativeEnum(SkinModelEnum),
  skin: number(),
});

const tahkCsgWellInputBaseScheme = object({
  wellbore_radius: number(),
  selected_well_type: nativeEnum(WellInputEnum),
  well_parameters: TahkCsgHorizontalWellParameter.or(TahkCsgVerticalFracturedWellParameter).or(TahkCsgVerticalWellParameter),
});

export type TahkCsgWellInputBase = z.infer<typeof tahkCsgWellInputBaseScheme>;

const TahkCsgWellInputSpecifyWellPosition = object({
  well_position_xaxis: number(),
  well_position_yaxis: number(),
  well_position_zaxis: number(),
}).merge(tahkCsgWellInputBaseScheme);

const tahkCsgInputLayerScheme = object({
  reservoir_parameters: tahkCsgReservoirParamAreaScheme.or(tahkCsgReservoirParamDimensionScheme),
  gas_pvt: tahkCsgPvtScheme,
  water_pvt: tahkCsgWaterPvtScheme,
  selected_langmuir_inputs: nativeEnum(TahkCsgLangmuirIsothermEnum),
  langmuir_isotherm: tahkCsgLangmuirIsothermOgipScheme.or(tahkCsgLangmuirIsothermVolumetricScheme),
  relative_permeability: relativePermeabilityScheme,
  well_inputs: TahkCsgWellInputSpecifyWellPosition.or(tahkCsgWellInputBaseScheme),
  matrix_shrinkage: matrixShrinkageScheme,
});

export type TahkCsgInputLayer = z.infer<typeof tahkCsgInputLayerScheme>;

export const tahkCsgInputStateScheme = object({
  number_of_layers: number(),
  selected_geometry: nativeEnum(TahkCsgSelectedGeometryEnum),
  layers: tahkCsgInputLayerScheme.array(),
  wellbore_inputs: TahkCsgWellboreInput,
  flowing_pressure_table: forecastEventScheme.array(),
  power_law_skin: TahkCsgPowerLawSkin,
});
export type TahkCsgInputState = z.infer<typeof tahkCsgInputStateScheme>;

export const TahkCsgInputResponseScheme = object({
  layers: tahkCsgInputLayerChartsScheme.array(),
});

export type TahkCsgInputResponse = z.infer<typeof TahkCsgInputResponseScheme>;

export const messagesSchema = z.object({
  level: number().optional(),
  loc: string().or(number()).array(),
  message: string(),
});

export type WarningMessage = z.infer<typeof messagesSchema>;
