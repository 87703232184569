import { z, object, string, number, record } from "zod";
import { chartSerieScheme } from "../Generic";

export * from "./apiFetcher/calculate";
export * from "./apiFetcher/initialize";
export * from "./apiFetcher/preview";

export const dataOptionScheme = record(string(), number().nullable());

export type DataOption = z.infer<typeof dataOptionScheme>;

export const dataviewChartOption = object({
  label: string(),
  axis: number(),
});

const dataviewPayloadScheme = object({
  data_set_ids: string().array(),
  data_option: dataOptionScheme,
  chart_option: dataviewChartOption.array(),
});

export type DataviewPayload = z.infer<typeof dataviewPayloadScheme>;

export const dataviewInitializeScheme = object({
  data_option: dataOptionScheme,
  chart_option: dataviewChartOption.array(),
  data_result: chartSerieScheme,
}).strict();

export type DataviewInitializeState = z.infer<typeof dataviewInitializeScheme>;
