import React, { useMemo } from "react";

import FossilyticsChart from "@/components/FossilyticsChart";
import Tabs from "@/components/Tabs";
import CustomDataTable from "@/components/CustomTable";

import { GasPvtDataTable } from "@/models/InputGeneric";
import useGasPvt from "../hooks/useGasPvt";

export type GasPvtFossilyticsChartProps = {
  gasPvtCalculation?: GasPvtDataTable;
  isLoading: boolean;
};

const GasPvtChart = ({ gasPvtCalculation, isLoading }: GasPvtFossilyticsChartProps) => {
  const { gasPvtZ, gasPvtMu, gasPvtPg, gasPvtBg, gasPvtCg, gasPvtDataTableCols, gasPvtDataTableRows } = useGasPvt({
    gasPvtCalculation,
  });

  const tabList = useMemo(() => {
    return [
      {
        label: <span>Z</span>,
        key: "Z",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart id="gas_pvt_z" isLoading={isLoading} xAxes={gasPvtZ.xAxes} yAxes={gasPvtZ.yAxes} series={gasPvtZ.series} hideLegend />
          </div>
        ),
      },
      {
        label: (
          <span>
            μ<sub>{"g"}</sub>
          </span>
        ),
        key: "μg",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart id="gas_pvt_mg" isLoading={false} xAxes={gasPvtMu.xAxes} yAxes={gasPvtMu.yAxes} series={gasPvtMu.series} hideLegend />
          </div>
        ),
      },
      {
        label: (
          <span>
            p<sub>{"g"}</sub>
          </span>
        ),
        key: "pg",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="gas_pvt_pg"
              isLoading={isLoading}
              xAxes={gasPvtPg.xAxes}
              yAxes={gasPvtPg.yAxes}
              series={gasPvtPg.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span>
            B<sub>{"g"}</sub>
          </span>
        ),
        key: "Bg",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart id="gas_pvt_bg" isLoading={false} xAxes={gasPvtBg.xAxes} yAxes={gasPvtBg.yAxes} series={gasPvtBg.series} hideLegend />
          </div>
        ),
      },
      {
        label: (
          <span>
            c<sub>{"g"}</sub>
          </span>
        ),
        key: "Cg",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart id="gas_pvt_cg" isLoading={false} xAxes={gasPvtCg.xAxes} yAxes={gasPvtCg.yAxes} series={gasPvtCg.series} hideLegend />
          </div>
        ),
      },
      {
        label: <span>Data Table</span>,
        key: "Data table",
        content: (
          <div className="chartWrapper">
            <CustomDataTable rows={gasPvtDataTableRows} columns={gasPvtDataTableCols} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [gasPvtBg, gasPvtCg, gasPvtDataTableCols, gasPvtDataTableRows, gasPvtMu, gasPvtPg, gasPvtZ, isLoading]);

  return <Tabs centered tabList={tabList} />;
};

export default GasPvtChart;
