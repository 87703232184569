import React, { useMemo, useState } from "react";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { CellChange, Column, ReactGrid, Row } from "@silevis/reactgrid";

import useThemeStyling from "@/utils/useThemeStyling";
import dictionary from "@/constants/dictionary";

import useTahkCsgForecast from "../../hooks/forecast/useTahkCsgForecast";
import { useTahkCsgState } from "../../context/TahkCsgContext";

import Tabs from "@/components/Tabs";
import LoadingIndicator from "@/components/LoadingIndicator";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import CustomTable from "@/components/CustomTable";
import { TahkCsgStateResponse } from "@/models/tahk";
import DropdownField, { DropdownOption } from "@/components/fields/DropdownField";

import { Container } from "./style";
import { getDataTableColumn } from "../../constants/grid";
import CustomCard from "@/components/Card";
import IntervalButton from "@/components/IntervalButton";
import { Interval } from "@/models/Generic";

type ForecastContainerProps = {
  handleFlowingPressureCellChange: (changes: CellChange[]) => void;
  flowingPressureRow: Row<any>[];
  flowingPressureCol: Column[];
  loadingState: boolean;
  chartYAxes: FossilyticsChartAxis[];
  chartXAxes: FossilyticsChartAxis[];
  series: FossilyticsChartSeries[];
  dataTableLayerOption: DropdownOption[];
  selectedDataTableLayer: string;
  setSelectedDataTableLayer: (key: string) => void;
  dataTableRows: Row[];
  cumulativeSeries: FossilyticsChartSeries[];
  cumulativeChartYAxes: FossilyticsChartAxis[];
  data: boolean;
  isTest?: boolean;
  flowingInputError: string;
  onChangeSmartFill: () => void;
  onValidateForecast: () => Promise<void>;
  canCalculateForecast: boolean;
  tahkCsgState: TahkCsgStateResponse | null | undefined;
  canCancelPoll: boolean;
  onCancelPoll: () => {};
  onClickExport: (interval: Interval) => Promise<void>;
};

export const ForecastContainer = ({
  handleFlowingPressureCellChange,
  flowingPressureRow,
  flowingPressureCol,
  loadingState,
  chartYAxes,
  chartXAxes,
  series,
  dataTableLayerOption,
  selectedDataTableLayer,
  setSelectedDataTableLayer,
  dataTableRows,
  cumulativeSeries,
  cumulativeChartYAxes,
  data,
  isTest,
  flowingInputError,
  onChangeSmartFill,
  onValidateForecast,
  canCalculateForecast,
  tahkCsgState,
  canCancelPoll,
  onCancelPoll,
  onClickExport,
}: ForecastContainerProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const { palette } = useThemeStyling();

  const tabList = useMemo(() => {
    return [
      {
        label: <span>{dictionary.dataView.combinedRates}</span>,
        key: dictionary.dataView.combinedRates,
        content:
          loadingState || isTest ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <div
              style={{
                height: 600,
              }}
            >
              <FossilyticsChart
                key={"forecast_rates_tahk_csg"}
                id="forecast_rates_tahk_csg"
                isLoading={loadingState}
                xAxes={chartXAxes}
                yAxes={chartYAxes}
                series={series}
              />
            </div>
          ),
      },
      {
        label: <span>{dictionary.dataView.cumulativeChart}</span>,
        key: dictionary.dataView.cumulativeChart,
        content:
          loadingState || activeTab !== 1 || isTest ? (
            <div className="center-flex h100">
              <LoadingIndicator />
            </div>
          ) : (
            <div
              style={{
                height: 600,
              }}
              className="chartWrapper"
            >
              <FossilyticsChart
                key={"forecast_cumulative_chart_tahk_csg"}
                id="forecast_cumulative_chart_tahk_csg"
                isLoading={loadingState}
                xAxes={chartXAxes}
                yAxes={cumulativeChartYAxes}
                series={cumulativeSeries}
              />
            </div>
          ),
      },
      {
        label: <span>{dictionary.dataTable}</span>,
        key: dictionary.dataTable,
        content: loadingState ? (
          <div className="center-flex h100">
            <LoadingIndicator />
          </div>
        ) : (
          <div
            style={{
              height: 500,
              paddingRight: 30,
            }}
          >
            <DropdownField
              label={dictionary.tahk.layer}
              options={dataTableLayerOption}
              disabled={loadingState}
              selectedKey={selectedDataTableLayer}
              onChange={(v) => {
                setSelectedDataTableLayer(String(v));
              }}
            />

            <CustomTable
              style={{
                display: "flex",
                height: 500,
              }}
              rows={dataTableRows}
              columns={getDataTableColumn(selectedDataTableLayer === "total" ? dictionary.tahkTotalDataTable : dictionary.tahkDataTable)}
              stickyTopRows={2}
              isLoading={loadingState}
            />
          </div>
        ),
      },
    ];
  }, [
    activeTab,
    chartXAxes,
    chartYAxes,
    cumulativeChartYAxes,
    cumulativeSeries,
    dataTableLayerOption,
    dataTableRows,
    isTest,
    loadingState,
    selectedDataTableLayer,
    series,
    setSelectedDataTableLayer,
  ]);

  return (
    <Container>
      <CustomCard style={{ overflow: "auto" }}>
        <h4
          style={{
            color: palette.primary.main,
          }}
        >
          {dictionary.tahk.forecastFlowSchedule}
        </h4>

        <span style={{ color: "red" }}>{flowingInputError}</span>
        <FormControlLabel
          control={
            tahkCsgState?.forecast ? (
              <Checkbox
                onChange={() => {
                  onChangeSmartFill();
                }}
                checked={tahkCsgState?.forecast.smart_fill}
                size="small"
              />
            ) : (
              <></>
            )
          }
          label={dictionary.tahk.smartFill}
        />
        <div style={{ blockSize: 550, overflowY: "auto", marginBlockStart: 5, width: 350, marginTop: 15, marginBottom: 15, height: "60vh" }}>
          <ReactGrid
            columns={flowingPressureCol}
            rows={flowingPressureRow}
            enableRangeSelection
            enableColumnSelection
            enableFullWidthHeader
            stickyTopRows={1}
            onCellsChanged={handleFlowingPressureCellChange}
          />
        </div>
        {!loadingState && !data && !flowingInputError && !canCalculateForecast && !tahkCsgState?.forecast.smart_fill && (
          <div>{dictionary.tahk.forecastFlowingSchedule}</div>
        )}
        <Button
          onClick={(e) => {
            e.preventDefault();
            canCancelPoll ? onCancelPoll() : onValidateForecast();
          }}
          disabled={!canCancelPoll ? loadingState || (!canCalculateForecast && !tahkCsgState?.forecast.smart_fill) : false}
          style={{ color: "white", display: "flex", width: "100%", fontWeight: 600, marginTop: 20, marginBottom: 20 }}
          variant="contained"
          id={"calculate-forecast"}
        >
          {canCancelPoll ? dictionary.genericButton.cancel : dictionary.tahk.calculateForecast}
        </Button>

        <IntervalButton
          label={dictionary.rulon.export}
          loading={loadingState}
          disabled={loadingState || !data}
          onClickButton={(option) => {
            onClickExport(option);
          }}
        />
      </CustomCard>

      <CustomCard style={{ position: "relative" }}>
        <Tabs id="forecast-tab-list" onClickItem={(index) => setActiveTab(index)} tabList={tabList} />
      </CustomCard>
    </Container>
  );
};

const ForecastWrapper = () => {
  const {
    setCsvData,
    tabIndex,
    isLoading,
    tahkCsgState,
    setTahkCsgState,
    analysisIdentity,
    setApiError,
    setIsLoading,
    setPollStatus,
    setProgress,
    apiError,
    fluidType,
  } = useTahkCsgState();

  const {
    handleFlowingPressureCellChange,
    flowingPressureRow,
    flowingPressureCol,
    loadingState,
    chartYAxes,
    chartXAxes,
    series,
    dataTableLayerOption,
    selectedDataTableLayer,
    setSelectedDataTableLayer,
    dataTableRows,
    cumulativeSeries,
    cumulativeChartYAxes,
    flowingInputError,
    onChangeSmartFill,
    canCalculateForecast,
    onValidateForecast,
    forecastCalculation,
    canCancelPoll,
    onCancelPoll,
    onClickExport,
  } = useTahkCsgForecast({
    isLoading,
    tabIndex,
    tahkCsgState,
    setTahkCsgState,
    analysisIdentity,
    setCsvData,
    setApiError,
    setIsLoading,
    setPollStatus,
    setProgress,
    apiError,
    fluidType,
  });

  return (
    <ForecastContainer
      {...{
        onClickExport,
        handleFlowingPressureCellChange,
        flowingPressureRow,
        flowingPressureCol,
        loadingState,
        chartYAxes,
        chartXAxes,
        series,
        dataTableLayerOption,
        selectedDataTableLayer,
        setSelectedDataTableLayer,
        dataTableRows,
        cumulativeSeries,
        cumulativeChartYAxes,
        flowingInputError,
        onValidateForecast,
        onChangeSmartFill,
        canCalculateForecast,
        tahkCsgState,
        data: !!forecastCalculation,
        canCancelPoll,
        onCancelPoll,
      }}
    />
  );
};
export default ForecastWrapper;
