import { ModuleId } from "@/model";

export type HelpLinks = {
  [key: string]: HelpLink;
};

export type HelpLink = {
  name: string;
  code: string;
};

type HelpLinkDictionary = {
  [key: string]: string;
};

export const userManualDom = "https://predico.scrollhelp.site/afaum/";

const input: HelpLinkDictionary = {
  wellboreInput: `${userManualDom}pump-model-wellbore`,
  isotherm: `${userManualDom}isotherm`,
  formationCompressibility: `${userManualDom}rock-formation-compressibility`,
  porosity: `${userManualDom}porosity`,
  netPay: `${userManualDom}net-pay`,
  permeability: `${userManualDom}csg-permeability-and-permeability-anisotropy`,
  csgPermeability: `${userManualDom}csg-permeability-and-permeability-anisotropy`,
  gasPvt: `${userManualDom}gas-pvt-correlations`,
  permeabilityXAxis: `${userManualDom}relative-permeability`,
  permeabilityYAxis: `${userManualDom}relative-permeability`,
  permeabilityZAxis: `${userManualDom}relative-permeability`,
  coalDensity: `${userManualDom}coal-density`,
  underSaturation: `${userManualDom}undersaturation-dewatering`,
  specificGravity: `${userManualDom}gas-specific-gravity`,
  zCorrelation: `${userManualDom}gas-deviation-factor`,
  gasViscosityCorrelation: `${userManualDom}gas-viscosity`,
  isothermalCompressibility: `${userManualDom}isothermal-compressibility`,
  relativePermeability: `${userManualDom}relative-permeability`,
  connateWaterSaturation: `${userManualDom}irreducible-water`,
  pssModel: `${userManualDom}pss-models`,
  waterSaturation: `${userManualDom}fluid-saturations`,
  oilGravityAPI: `${userManualDom}oil-api`,
  wellboreSkin: `${userManualDom}wellbore-skin-and-formation-damage`,
  productionConstraints: `${userManualDom}production-constraints`,
  cleatCompressibility: `${userManualDom}cleat-compressibility`,
  horizontalWellModels: `${userManualDom}horizontal-well-models`,
  geomechanics: `${userManualDom}geomechanics`,
  productivityIndexPI: `${userManualDom}productivity-index-pi`,
  cleatPorosity: `${userManualDom}cleat-porosity`,

  langmuirPressure: `${userManualDom}isotherm`,
  langmuirVolume: `${userManualDom}isotherm`,
  rockDensity: `${userManualDom}coal-density`,
  populationSize: `${userManualDom}population-size`,
  maxIterationNumber: `${userManualDom}maximum-iteration-number`,

  transientAnalysis: `${userManualDom}fmb-and-rate-transient-analysis-rta`,
  transientModel: `${userManualDom}transient-models-flow-regimes`,
  dualPorosity: `${userManualDom}dual-porosity`,

  //This 2 is for tahk/forecast/csg originally
  selectedZCorrelation: `${userManualDom}gas-deviation-factor`,
  selectedGasViscosityCorrelation: `${userManualDom}gas-viscosity`,
};

// ------------------------ Kuldon CSG help links ------------------------
const reservoirInput: HelpLinkDictionary = {
  measure: `${userManualDom}coal-measure-vs-coal-layer`, // Declare here to prevent other measures shows helper link
  formation_compressibility: input.cleatCompressibility, //THIS NEED TO ADJUST FOR FORMATION
  permeability_vertical: input.csgPermeability,
};

const gasPvt: HelpLinkDictionary = {
  specificGravity: input.specificGravity,
  selectedZCorrelation: input.zCorrelation,
  selectedGasViscosityCorrelation: input.gasViscosityCorrelation,
};

const waterPvt: HelpLinkDictionary = {
  selectedWaterCompressibilityCorrelation: input.isothermalCompressibility,
};

const relativePermeability: HelpLinkDictionary = {
  selectedRelativePermeabilityCorrelation: input.relativePermeability,
  connateWaterSaturation: input.connateWaterSaturation,
};

const wellInput: HelpLinkDictionary = {
  selectedWellType: input.pssModel,
  horizontalLength: input.horizontalWellModels,
  skinValue: input.wellboreSkin,
};

const geoMechanics: HelpLinkDictionary = {
  selectedGeomechanicModel: input.geomechanics,
  poissonsRatio: input.geomechanics,
  langmuirVolumeStrain: input.geomechanics,
  youngModule: input.geomechanics,
  maximumProductivityMultiplier: input.productivityIndexPI,
};

const MonteCarloInput: HelpLinkDictionary = {
  permeabilityHorizontalDistribution: input.csgPermeability,
  netPayDistribution: input.netPay,
  initialWaterSaturationDistribution: input.waterSaturation, //sw distribution
  porosityDistribution: input.cleatPorosity,
  langmuirPressureDistribution: input.isotherm,
  langmuirVolumeDistribution: input.isotherm,
  desorptionPressureDistribution: input.isotherm,
  rockDensityDistribution: input.coalDensity, //coal density
};

const koldunCsgForecast: HelpLinkDictionary = {
  contractRateMode: input.productionConstraints,
  selectedWellboreModel: input.wellboreInput,
};

const koldunCsgHelpLinks = {
  reservoirInput,
  gasPvt,
  waterPvt,
  relativePermeability,
  wellInput,
  geoMechanics,
  MonteCarloInput,
  koldunCsgForecast,
};

//----------------------------Gaz autoRTA helpLinks----------------------------------

const defineParameters: HelpLinkDictionary = {
  netPay: input.netPay,
  porosity: input.porosity,
  waterSaturation: input.waterSaturation,
  formationCompressibility: input.formationCompressibility,
};

const modelComponents: HelpLinkDictionary = {
  //Gas PVT
  specificGravity: input.specificGravity,
  gasViscosityCorrelation: input.gasViscosityCorrelation,
  zCorrelation: input.zCorrelation,

  //Oil PVT
  oilGravityAPI: input.oilGravityAPI,

  //Langmuir Isotherm
  desorptionPressure: input.isotherm,
  VI: input.isotherm,
  PI: input.isotherm,
  rockDensity: input.rockDensity,
};

const transientAnalysis: HelpLinkDictionary = {
  transientAnalysis: input.transientAnalysis,
  transientModel: input.transientModel,
  skin: input.wellboreSkin,
  permeability: `${userManualDom}permeability`, // "permeability" is already delcared in Input (here delcare manaully)
  dualPorosityModel: input.dualPorosity,
};

const gasAutoRta = { defineParameters, modelComponents, transientAnalysis };

//------------------------------------------------------------------------------------

//ALL HELP LINKS
const helpLinkUrl = {
  input,
  koldunCsgHelpLinks,
  gasAutoRta,
  wellImport: `${userManualDom}import-wells-from-csv`,
  projectImport: `${userManualDom}import-projects`,
  errorCode: `${userManualDom}error-messages#`,
  machineLearning: `${userManualDom}tsar-machine-learning-m-l-capability`,
};

export const MODULE_SPECIFIC_LINKS: ModuleId[] = [ModuleId.KOLDUN_MCSIM_CSG];

export const OPERATIONAL_HELP_LINKS: HelpLinks = {
  index: {
    name: "Operational Manual",
    code: "user-manual",
  },
  [ModuleId.KOLDUN_MCSIM_CSG]: {
    name: "KOLDUN: CSG Monte-Carlo",
    code: "koldun-csg-monte-carlo",
  },
};

export const THEORETICAL_HELP_LINKS: HelpLinks = {
  index: {
    name: "Theory & Reference Manual",
    code: "theory-reference-manual",
  },
  pssModels: {
    name: "PSS Models",
    code: "pss-models",
  },
  gasDeviationFactor: {
    name: "Gas Deviation Factor",
    code: "gas-deviation-factor",
  },
  gasViscosity: {
    name: "Gas Viscosity",
    code: "gas-viscosity",
  },
  gasPvtCorrelations: {
    name: "Gas PVT Correlations",
    code: "gas-pvt-correlations",
  },
  csgWellSpacingAndCompletions: {
    name: "CSG Well Spacing & Completions",
    code: "csg-well-spacing-completions",
  },
  relativePermeability: {
    name: "Relative Permeability",
    code: "relative-permeability",
  },
  isotherm: {
    name: "Isotherm",
    code: "isotherm",
  },
  coalDensity: {
    name: "Coal Density",
    code: "coal-density",
  },
  cleatPorosity: {
    name: "Cleat Porosity",
    code: "cleat-porosity",
  },
  wellboreSkinAndFormationDamage: {
    name: "Wellbore Skin and Formation Damage",
    code: "wellbore-skin-and-formation-damage",
  },
};

export default helpLinkUrl;
