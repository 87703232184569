import { useMemo } from "react";

import InputField from "@/components/fields/InputField";
import DropdownField from "@/components/fields/DropdownField";
import helpLinkUrl from "@/constants/helpLinkUrl";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { mapEnumToDropdown } from "@/utils/general";
import { DefinedParameters, FormationCompressibilityCorrelation } from "@/models/InputGeneric";
import dictionary from "@/constants/dictionary";
import CustomCard from "@/components/Card";
import useThemeStyling from "@/utils/useThemeStyling";

export interface DefinedParamProps {
  definedParameters?: DefinedParameters;
  onChange: (v: number | string | undefined, key: keyof DefinedParameters) => void;
  isLoading: boolean;
  validationError: ErrorValidationDetail[];
}

const DefinedParameter = ({ definedParameters, onChange, isLoading, validationError }: DefinedParamProps) => {
  const { palette } = useThemeStyling();

  const shouldRenderFormationCompressibilityInput = useMemo(() => {
    if (!definedParameters) return false;
    return (
      definedParameters.selected_formation_compressibility_correlation === FormationCompressibilityCorrelation.Constant ||
      definedParameters.selected_formation_compressibility_correlation === FormationCompressibilityCorrelation.DobryninLaurent ||
      definedParameters.selected_formation_compressibility_correlation === FormationCompressibilityCorrelation.DobryninWang
    );
  }, [definedParameters]);

  return (
    <CustomCard>
      <h3 style={{ color: palette.primary.main, margin: 0 }}>{dictionary.inputPage.definedParameters}</h3>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          opacity: isLoading ? 0.5 : 1,
          gridGap: 20,
        }}
      >
        <div>
          <InputField
            dataTestId="defined_parameters.initial_pressure"
            label={dictionary.inputPage.initialPressure}
            suffix="psia"
            debounceDelay={1000}
            value={definedParameters?.initial_pressure}
            onChange={(v) => onChange(v, "initial_pressure")}
            disabled={isLoading}
            errors={validationError}
            keyField="inputs.defined_parameters.initial_pressure"
          />
          <InputField
            label={dictionary.inputPage.formationTemperature}
            suffix="°F"
            debounceDelay={1000}
            value={definedParameters?.formation_temperature}
            onChange={(v) => onChange(v, "formation_temperature")}
            disabled={isLoading}
            errors={validationError}
            keyField="inputs.defined_parameters.formation_temperature"
            dataTestId="defined_parameters.formation_temperature"
          />
          <InputField
            label={dictionary.inputPage.waterSaturation}
            suffix="%"
            min={0}
            max={100}
            debounceDelay={1000}
            value={definedParameters?.initial_water_saturation}
            onChange={(v) => onChange(v, "initial_water_saturation")}
            disabled={isLoading}
            errors={validationError}
            keyField="inputs.defined_parameters.initial_water_saturation"
            dataTestId="defined_parameters.initial_water_saturation"
            helpUrl={helpLinkUrl.gasAutoRta.defineParameters["waterSaturation"]}
          />
          <InputField
            label={dictionary.inputPage.porosity}
            suffix="%"
            debounceDelay={1000}
            value={definedParameters?.porosity}
            onChange={(v) => onChange(v, "porosity")}
            disabled={isLoading}
            errors={validationError}
            keyField="inputs.defined_parameters.porosity"
            dataTestId="defined_parameters.porosity"
            helpUrl={helpLinkUrl.gasAutoRta.defineParameters["porosity"]}
          />
        </div>
        <div>
          <InputField
            label={dictionary.inputPage.wellboreRadius}
            suffix="ft"
            debounceDelay={1000}
            value={definedParameters?.wellbore_radius}
            onChange={(v) => onChange(v, "wellbore_radius")}
            disabled={isLoading}
            errors={validationError}
            keyField="inputs.defined_parameters.wellbore_radius"
            dataTestId="defined_parameters.wellbore_radius"
          />
          <InputField
            label={dictionary.inputPage.netPay}
            suffix="ft"
            debounceDelay={1000}
            value={definedParameters?.net_pay}
            onChange={(v) => onChange(v, "net_pay")}
            disabled={isLoading}
            errors={validationError}
            keyField="inputs.defined_parameters.net_pay"
            dataTestId="defined_parameters.net_pay"
            helpUrl={helpLinkUrl.gasAutoRta.defineParameters["netPay"]}
          />
          <DropdownField
            label={dictionary.inputPage.formationCompressibility}
            options={mapEnumToDropdown(FormationCompressibilityCorrelation)}
            selectedKey={definedParameters?.selected_formation_compressibility_correlation}
            onChange={(v) => onChange(v, "selected_formation_compressibility_correlation")}
            disabled={isLoading}
            errors={validationError}
            keyField="inputs.defined_parameters.selected_formation_compressibility_correlation"
            dataTestId="defined_parameters.selected_formation_compressibility_correlation"
            helpUrl={helpLinkUrl.gasAutoRta.defineParameters["formationCompressibility"]}
          />
          {shouldRenderFormationCompressibilityInput && (
            <InputField
              label=""
              suffix="1/psia"
              debounceDelay={1000}
              value={definedParameters?.formation_compressibility}
              onChange={(v) => onChange(v, "formation_compressibility")}
              disabled={isLoading}
              errors={validationError}
              keyField="inputs.defined_parameters.formation_compressibility"
              dataTestId="defined_parameters.formation_compressibility"
              helpUrl={helpLinkUrl.gasAutoRta.defineParameters["formationCompressibility"]}
            />
          )}
        </div>
      </div>
    </CustomCard>
  );
};

export default DefinedParameter;
