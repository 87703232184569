import styled from "@emotion/styled";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
  },
}));

export const Container = styled.div`
  padding-top: 15px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  transform: none !important;

  .header-container {
    display: flex;
    flex-direction: row;
  }

  .nav-section {
    border-right: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .drag-handle {
    width: 5px;
    cursor: col-resize;
    background-color: transparent;
    flex-shrink: 0;
    position: relative;
  }

  .drag-handle::before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
    width: 1.5px;
    height: 100%;
    background-color: rgb(243, 242, 241);
    transition: background-color 0.2s;
  }

  .drag-handle:hover::before {
    background-color: #ddd;
    width: 5px;
  }
`;

export const IconContainer = styled.div<{
  bgColor: string;
}>`
  background-color: ${(props) => props.bgColor};
  padding: 5px;
  justify-content: center;
  display: flex;
  border-radius: 3px;
  cursor: pointer;
`;

export const ExpandableContainer = styled.div<{
  showAll: boolean;
  maxWidth?: number;
}>`
  width: ${(props) => (props.showAll ? (props.maxWidth ? `${props.maxWidth}px` : "280px") : "0px")};
  display: ${(props) => (props.showAll ? "flex" : "none")};
  flex-direction: column;

  .MuiCheckbox-sizeSmall {
    padding: 5px;
    padding-left: 10px;
  }
  .well-list {
    height: 80vh;
  }

  .project-list {
    overflow-y: auto;
    height: 87vh;
  }

  .well-container {
    padding-left: 8px;
    width: 97%;
  }

  .spinner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
