import Button from "@mui/material/Button";
import { CSSProperties, ReactNode } from "react";

type CustomButtonProps = {
  children: ReactNode;
  onClick: () => void;
  variant?: "text" | "contained" | "outlined";
  style?: CSSProperties;
};
const CustomButton = ({ children, onClick, variant = "contained", style }: CustomButtonProps) => {
  return (
    <Button
      style={{
        color: "white",
        width: 100,
        fontWeight: "bold",
        ...style,
      }}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};
export default CustomButton;
