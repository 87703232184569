import { ModuleId } from "@/model";

const routeUrlMap: {
  [key: string | ModuleId]: string;
} = {
  settings: "settings",
  modules: "modules",
  [ModuleId.GAZ_FMB]: "gaz/fmb",
  [ModuleId.GAZ_PZ]: "gaz/pz",
  [ModuleId.GAZ_AUTORTA]: "gaz/autoRTA",
  [ModuleId.SPAD_DECLINE_GAS]: "spad/decline/gas",
  [ModuleId.SPAD_DECLINE_OIL]: "spad/decline/oil",
  [ModuleId.SPAD_WOR]: "spad/wor",
  [ModuleId.TAHK_FORECAST_GAS]: "tahk/forecast/gas",
  [ModuleId.TAHK_FORECAST_OIL]: "tahk/forecast/oil",
  [ModuleId.TAHK_FORECAST_CSG]: "tahk/forecast/csg",
  [ModuleId.KOLDUN_MCSIM_GAS]: "koldun/mcsim/gas",
  [ModuleId.KOLDUN_MCSIM_OIL]: "koldun/mcsim/oil",
  [ModuleId.KOLDUN_MCSIM_CSG]: "koldun/mcsim/csg",
  [ModuleId.GILDRO_HYDRATE]: "gildro/hydrate",
  [ModuleId.ROLLUP]: "rollup",
  [ModuleId.MASLO_AUTORTA]: "oil/autoRTA",
  [ModuleId.MACHINE_LEARNING]: "ml",

  // not yet available
  [ModuleId.GILDRO_PVT]: "gildro/pvt",

  // import well
  importWells: "import/wells",

  // import projects
  importProjects: "import/projects",

  [ModuleId.RULON]: "rulon/groupRun",
  [ModuleId.RULON_GAS]: "rulon/rollup/gas",
  [ModuleId.RULON_OIL]: "rulon/rollup/oil",
  csgV2: "koldun/csg",

  importTools: "import/:url/:option",

  dataPreview: "dataPreview/:dataSetId",
};

export default routeUrlMap;
