import CustomCard from "@/components/Card";
import CustomTable from "@/components/CustomTable";
import LoadingIndicator from "@/components/LoadingIndicator";
import dictionary from "@/constants/dictionary";
import { Button } from "@mui/material";
import { Column, Row } from "@silevis/reactgrid";

type GroupRunProgressProps = {
  onCancelPoll: () => void;
  canCancel: boolean;
  rows: Row[];
  columns: Column[];
  groupRunFinished: boolean;
  onClickOk: () => void;
};

const GroupRunProgress = ({ canCancel, groupRunFinished, rows, onCancelPoll, columns, onClickOk }: GroupRunProgressProps) => {
  return (
    <CustomCard
      style={{
        padding: "1em",
        margin: "1em",
      }}
    >
      {rows.length === 1 && <LoadingIndicator />}
      <div>
        <CustomTable
          style={{
            display: "flex",
            justifyContent: "center",
            height: 600,
          }}
          rows={rows}
          columns={columns}
          enableRangeSelection
          enableColumnSelection
          stickyTopRows={1}
        />
      </div>

      <div style={{ float: "right" }}>
        <Button
          style={{
            marginRight: 20,
          }}
          variant="outlined"
          onClick={onClickOk}
          disabled={!groupRunFinished}
        >
          {dictionary.genericButton.ok}
        </Button>

        <Button
          onClick={() => {
            onCancelPoll();
          }}
          disabled={!canCancel}
          style={{ color: "white", marginTop: 10, marginBottom: 10 }}
          variant="contained"
        >
          {dictionary.genericButton.cancel}
        </Button>
      </div>
    </CustomCard>
  );
};

export default GroupRunProgress;
