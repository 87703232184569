import { useCallback, useMemo, useState } from "react";
import { CellChange, Column, DropdownCell, Row } from "@silevis/reactgrid";

import { FluidType } from "@/models/Generic";
import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { capitalizeFirstLetter, convertCamelCaseToReadable, mapArrayToDropdownReactGrid } from "@/utils/general";
import unitDictionary from "@/constants/units";

import { UnitSelection } from "../context/types";

type useUnitPrefProps = {
  unitPreference: UnitSelection;
  setUnitPreference: React.Dispatch<React.SetStateAction<UnitSelection>>;
};

export const unitColumn = [
  { columnId: "label", width: 200 },
  { columnId: "unit", width: 200, justifyContent: "center" },
] as Column[];

const useUnitPref = ({ unitPreference, setUnitPreference }: useUnitPrefProps) => {
  const [selectedFluid, setSelectedFluid] = useState(FluidType.oil);
  const [activeDropdown, setActiveDropdown] = useState("");

  const dataTableRows = useMemo<Row<any>[]>(() => {
    const headerRow: Row<any> = {
      rowId: "header",
      cells: unitColumn.map((item) => ({
        type: "text",
        text: capitalizeFirstLetter(String(item.columnId)),
        style: tableHeaderStyle,
        nonEditable: true,
      })),
      height: 30,
    };
    const safeTable: { [key: string]: string } = unitPreference[selectedFluid];
    return [
      headerRow,
      ...Object.keys(safeTable).map((item) => ({
        rowId: item,
        cells: [
          { type: "text", text: convertCamelCaseToReadable(capitalizeFirstLetter(String(item))), style: tableCellStyle, nonEditable: true },
          {
            type: "dropdown",
            selectedValue: safeTable[item] ?? undefined,
            values: mapArrayToDropdownReactGrid(unitDictionary[selectedFluid][item]),
            style: tableCellStyle,
            isOpen: activeDropdown === item,
          },
        ],
      })),
    ];
  }, [activeDropdown, selectedFluid, unitPreference]);

  const onChangeUnit = useCallback(
    (changes: CellChange[]) => {
      const change = changes[0];
      let { rowId, newCell, previousCell } = change as CellChange<any>;
      const prevCell = previousCell as DropdownCell;
      const dropDownNewCell = newCell as DropdownCell;
      rowId = rowId as string;

      if (prevCell.isOpen !== dropDownNewCell.isOpen || activeDropdown !== rowId) {
        if (dropDownNewCell.isOpen) {
          setActiveDropdown(rowId);
        } else {
          setActiveDropdown("");
        }
      }
      if (dropDownNewCell.isOpen) return;
      if (prevCell.selectedValue !== dropDownNewCell.selectedValue) {
        setUnitPreference((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            [selectedFluid]: {
              ...prev[selectedFluid],
              [rowId]: dropDownNewCell.selectedValue ?? "",
            },
          };
        });
      }
    },
    [activeDropdown, selectedFluid, setUnitPreference]
  );
  return { selectedFluid, setSelectedFluid, dataTableRows, onChangeUnit };
};

export default useUnitPref;
