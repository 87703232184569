import { ModuleKoldunMcsimGasOilOptions } from "@/modules/koldun/mcsim/gasoil/ModuleKoldunMcsimGasOilContent/model";
import InputField from "@/components/fields/InputField";
import { TankType } from "@/model";

import { TankTypeOptions, formationCompressibilityOptions, gasViscMethodOptions, wtrViscMethodOptions, zMethodOptions } from "./model";
import DropdownField from "@/components/fields/DropdownField";

const fossilyticsFields = [
  {
    label: "Gas gravity",
    suffix: "dim",
    valueKey: "gas_gravity",
  },
  {
    label: "N₂",
    suffix: "%",
    min: 0,
    max: 100,
    valueKey: "n2_perc",
  },
  {
    label: "CO₂",
    suffix: "%",
    min: 0,
    max: 100,
    valueKey: "co2_perc",
  },
  {
    label: "H₂S",
    suffix: "%",
    min: 0,
    max: 100,
    valueKey: "h2s_perc",
  },
];

const dropdownFields = [
  {
    label: "Z Method",
    options: zMethodOptions,
    valueKey: "z_method",
  },
  {
    label: "Water viscosity method",
    options: wtrViscMethodOptions,
    valueKey: "water_viscosity_method",
  },
  {
    label: "Gas viscosity method",
    options: gasViscMethodOptions,
    valueKey: "gas_viscosity_method",
  },
];

interface BaseInputsProps {
  value: ModuleKoldunMcsimGasOilOptions;
  onChange: (val: ModuleKoldunMcsimGasOilOptions) => void;
  module?: string;
}

const BaseInputs = ({ value, onChange, module }: BaseInputsProps) => {
  const getFieldValue = (obj: any, field: any) => {
    if (field.additionalKeys) {
      return `${obj[field.valueKey]}-${obj[field.additionalKeys[0]]}`;
    }
    return obj[field.valueKey];
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: 20,
      }}
    >
      <div>
        <DropdownField
          ariaLabel="koldun_well_type"
          label="Well type"
          options={TankTypeOptions}
          selectedKey={value.tank_type}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  tank_type: v as TankType,
                })
              : null
          }
        />
        {module === "koldun_gas" ? null : (
          <InputField
            label="Area"
            suffix="acre"
            value={value.area_acres}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    area_acres: Number(v),
                  })
                : null
            }
          />
        )}
        <InputField
          label="Wellbore radius"
          suffix="ft"
          value={value.wellbore_radius_ft}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  wellbore_radius_ft: Number(v),
                })
              : null
          }
        />
      </div>

      <div>
        {fossilyticsFields.map((field) => (
          <InputField
            key={field.valueKey}
            label={field.label}
            suffix={field.suffix}
            min={field.min}
            max={field.max}
            value={value[field.valueKey as keyof ModuleKoldunMcsimGasOilOptions] as number}
            onChange={(v) =>
              v !== undefined
                ? onChange({
                    ...value,
                    [field.valueKey]: Number(v),
                  })
                : null
            }
          />
        ))}
      </div>

      <div>
        <DropdownField
          ariaLabel="koldun_formation_compressibility_method"
          label="Formation compressibility method"
          options={formationCompressibilityOptions}
          selectedKey={`${value.form_compressibility_method}-${value.rock_type}`}
          onChange={(v) => {
            const data = formationCompressibilityOptions.filter((key) => key.key === v);
            if (data?.[0]?.data) {
              onChange({
                ...value,
                ...data[0].data,
              });
            }
          }}
        />

        {dropdownFields.map((field) => (
          <DropdownField
            ariaLabel={`koldun_${field.valueKey}`}
            key={field.valueKey}
            label={field.label}
            options={field.options}
            selectedKey={getFieldValue(value, field)}
            onChange={(v) => {
              console.log(v, field);
              onChange({
                ...value,
                [field.valueKey]: v,
              });
            }}
          />
        ))}

        <InputField
          label="Formation temperature"
          suffix="°F"
          value={value.form_temp_of}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  form_temp_of: Number(v),
                })
              : null
          }
        />

        <InputField
          label="Water Salinity "
          suffix="PPM"
          value={value.salinity_ppm}
          onChange={(v) =>
            v !== undefined
              ? onChange({
                  ...value,
                  salinity_ppm: Number(v),
                })
              : null
          }
        />
      </div>
    </div>
  );
};

export default BaseInputs;
