import React, { useCallback, useMemo, useState } from "react";

import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import dictionary from "@/constants/dictionary";

import { ProjectStructureErrorCode, PromiseResult, PromiseResultDetail, postProjectStructure, ResultStatus } from "@/models/wells";
import helpLinkUrl from "@/constants/helpLinkUrl";
import IndividualItem from "../shared/components/IndividualItem";
import { Container, VisuallyHiddenInput } from "../shared/style";
import CustomTable, { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import { mapArrayToDropdownReactGrid } from "@/utils/general";
import { useTreeViewState } from "@/components/TreeView/hooks/TreeViewContextV2";
import CustomCard from "@/components/Card";

const enumColumnHeader = ["well", "group", "project"];

const getColumnIndexWithAlphabets = (csvString: string) => {
  const rows = csvString.split("\n");
  const headers = rows[0].split(",");
  const columnIndicesWithAlphabets = [];

  for (let i = 0; i < headers.length; i++) {
    let columnHasAlphabets = true;
    for (let j = 1; j < rows.length; j++) {
      const columns = rows[j].split(",");
      if (columns.length > i && !/[a-zA-Z]/.test(columns[i]) && columns[i]) {
        columnHasAlphabets = false;
        break;
      }
    }
    if (columnHasAlphabets) {
      columnIndicesWithAlphabets.push(i);
    }
  }
  return columnIndicesWithAlphabets;
};

const ImportProjectStructure = () => {
  const { refreshProjects } = useTreeViewState();

  // which row is the dropdown opened
  // will use row id + 1, to differentiate if there's no dropdown open
  const [isDropdownOpened, setIsDropdownOpened] = useState<number>(0);

  const [file, setFile] = useState<File>();
  const [res, setRes] = useState<PromiseResult<ProjectStructureErrorCode> | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  // csv text excluding header
  const [csvText, setCsvText] = useState<string | undefined>();
  const [csvHeaderText, setCsvHeaderText] = useState<string[] | undefined>();

  const [selectedField, setSelectedField] = useState<any[]>([undefined, undefined, undefined]);

  const handleOnChange = async (e: any) => {
    const selectedFile = e.target.files[0];
    const text = await selectedFile?.text();
    const splitted = text.split("\n");
    const header = splitted[0].split(",");

    setRes(undefined);
    setFile(selectedFile);

    if (header.length < 1) {
      setRes({
        code: 400,
        detail: {
          code: "validate_error",
          message: dictionary.wellImport.minimumColumnsError,
        },
        status: "rejected",
      });
      setCsvHeaderText(undefined);
      setCsvText(undefined);
    } else {
      setCsvHeaderText(header);
      setCsvText(splitted.slice(1).join("\n"));
    }
  };

  const handleOnSubmit = useCallback(async () => {
    try {
      setIsLoading(true);

      if (csvText && csvHeaderText && file) {
        const newHeader = [...csvHeaderText];
        // adjust header text
        selectedField.forEach((field, index) => {
          const headerIndex = newHeader.indexOf(field);
          newHeader[headerIndex] = enumColumnHeader[index];
        });
        const newText = [newHeader.join(","), csvText].join("\n");

        const newFile = new File([newText], file.name, {
          type: `data:text/csv;charset=utf-8`,
        });

        const res = await postProjectStructure({ file: newFile });
        const safeRes = res as unknown as {
          code: number;
          detail: PromiseResultDetail<ProjectStructureErrorCode>;
        };
        const reMappedResult = {
          code: safeRes?.code,
          detail: safeRes?.detail,
          status: "fulfilled" as ResultStatus,
        };
        setRes(reMappedResult);
        await refreshProjects();
      }
    } catch (error: any) {
      if (error.code === 400) {
        setRes({
          code: error.code,
          detail: error.detail,
          status: "rejected" as ResultStatus,
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [csvHeaderText, csvText, file, refreshProjects, selectedField]);

  const renderRightButton = () => {
    if (res) {
      if (res.status === "rejected") {
        return <></>;
      }
    }
    return (
      <Button
        onClick={(e) => {
          e.preventDefault();
          handleOnSubmit();
        }}
        disabled={!file || isLoading || !selectedField[0]}
        style={{ color: "white", marginLeft: 20 }}
        variant="contained"
      >
        {dictionary.wellImport.submit}
      </Button>
    );
  };

  const columns = [
    {
      columnId: "target",
      width: 250,
    },
    {
      columnId: "field",
      width: 250,
    },
  ];

  const filteredHeader = useMemo(() => {
    if (!csvHeaderText || !csvText) return [];

    const columnIndexes = getColumnIndexWithAlphabets(csvText);
    let filtered = csvHeaderText.filter((header, index) => columnIndexes.indexOf(index) !== -1 && selectedField.indexOf(header) === -1);
    return ["-", ...filtered];
  }, [csvHeaderText, selectedField, csvText]);

  const rows: Row<any>[] = useMemo(() => {
    if (!csvHeaderText) return [];
    const option = mapArrayToDropdownReactGrid(filteredHeader);
    return [
      {
        rowId: "header",
        cells: [
          {
            type: "header",
            text: "Target Fields",
            style: tableHeaderStyle,
          },
          {
            type: "header",
            text: "Input Column",
            style: tableHeaderStyle,
          },
        ],
        height: 50,
      },
      ...enumColumnHeader.map((header, index) => {
        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "text",
              text: header,
            },
            {
              type: "dropdown",
              text: header,
              selectedValue: selectedField[index],
              values: option,
              style: tableCellStyle,
              isOpen: isDropdownOpened === index + 1,
            },
          ],
        };
      }),
    ];
  }, [csvHeaderText, filteredHeader, isDropdownOpened, selectedField]);

  const onCellChange = useCallback(
    (changes: CellChange[]) => {
      if (!csvHeaderText) return;
      const updatedRows: any[] = [...selectedField];

      for (const element of changes) {
        const change = element;
        let { rowId, newCell, columnId, previousCell } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;

        const newDropdown = dropDownNewCell.isOpen ? rowId + 1 : 0;
        if (prevCell.isOpen !== dropDownNewCell.isOpen && newDropdown !== isDropdownOpened) {
          setIsDropdownOpened(dropDownNewCell.isOpen ? rowId + 1 : 0);
          const newVal = String(dropDownNewCell.selectedValue);
          if (prevCell.selectedValue !== dropDownNewCell.selectedValue && newVal) {
            updatedRows[rowId] = dropDownNewCell.selectedValue === "-" ? undefined : dropDownNewCell.selectedValue;
            setSelectedField([...updatedRows]);
          }
        }
      }
    },
    [csvHeaderText, isDropdownOpened, selectedField]
  );

  return (
    <Container>
      <CustomCard style={{ width: 700, maxHeight: "60vh" }}>
        <h3>
          <a target="_blank" rel="noreferrer" href={helpLinkUrl.projectImport}>
            {dictionary.nav.importProjects} <InfoOutlinedIcon fontSize="small" />
          </a>
        </h3>

        <div className="list-container">
          <div>{file && !res && <IndividualItem key={file.name} name={file.name} />}</div>
          {res && (
            <div>
              <IndividualItem
                success={res.status === "fulfilled" ? "Project imported succesfully" : undefined}
                error={res.detail?.message}
                key={file?.name}
                name={file?.name ?? ""}
              />
            </div>
          )}

          {csvHeaderText && (
            <CustomTable
              style={{
                display: "flex",
                height: 250,
              }}
              onCellsChanged={onCellChange}
              columns={columns}
              rows={rows}
            />
          )}
        </div>

        <div className="button-container">
          <Button
            disabled={isLoading}
            style={{ color: "white" }}
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            {dictionary.wellImport.choose}
            <VisuallyHiddenInput
              onClick={(e: any) => {
                e.target.value = null;
              }}
              onChange={handleOnChange}
              type="file"
              accept=".csv"
            />
          </Button>
          {renderRightButton()}
        </div>
      </CustomCard>
    </Container>
  );
};

export default ImportProjectStructure;
