import { CSSProperties, ReactNode } from "react";
import { Card } from "@mui/material";
import styled from "@emotion/styled";

type CustomCardProps = {
  children: ReactNode;
  style?: CSSProperties;
};

const StyledCard = styled(Card)`
  scrollbar-width: thin;
`;
const CustomCard = ({ children, style }: CustomCardProps) => {
  return (
    <StyledCard style={style} sx={{ padding: 2 }}>
      {children}
    </StyledCard>
  );
};

export default CustomCard;
