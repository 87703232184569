import React from "react";
import SsidChartOutlinedIcon from "@mui/icons-material/SsidChartOutlined";

import { worAnalyses } from "./model";
import styled from "@emotion/styled";

interface ModuleSpadWorAnalysesSelectProps {
  selectedAnalyses: string[];
  onAnalysesUpdate: (analyses: string[]) => void;
}

type SelectOptionItemProps = {
  onClick: () => void;
  checked: boolean;
  id: string;
  label: string;
};

const SelectOptionInput = styled.input`
  position: absolute;
  margin: 0px;
  width: 20px;
  top: 5px;
  height: 20px;
  right: 5px;
  ${(props) => `visibility: ${props.checked ? "visible" : "hidden"}`}
`;

const SelectOptionLabel = styled.label<{ checked?: boolean }>`
  display: flex;
  cursor: pointer;
  margin: 0px;
  position: relative;
  vertical-align: top;
  min-height: 20px;
  box-sizing: content-box;
  padding-top: 25px;
  text-align: center;
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  ${(props) => props.checked && `border: 1px solid  rgb(255, 130, 0);`}
`;

const SelectOptionContainer = styled.div`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  min-height: 26px;
  border: none;
  position: relative;
  margin: 0px 4px 4px 0px;
  padding-left: 0px;
  background-color: rgb(243, 242, 241);
  height: 100%;

  // customize radio button color and position
  input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    position: relative;
    background-color: #d1d3d1;
    content: "";
    display: inline-block;
    visibility: hidden;
  }

  input[type="radio"]:checked:after {
    width: 12px;
    top: 4px;
    right: -4px;
    height: 12px;
    position: relative;
    background-color: rgb(255, 130, 0);
    content: "";
    display: inline-block;
    visibility: visible;
  }
`;

const SelectOptionItem = ({ onClick, checked, id, label }: SelectOptionItemProps) => {
  return (
    <SelectOptionContainer onClick={onClick}>
      <SelectOptionInput id={id} type="radio" name={label} checked={checked} />
      <SelectOptionLabel checked={checked}>
        <SsidChartOutlinedIcon
          fontSize="large"
          style={{
            paddingLeft: 30,
            paddingRight: 30,
          }}
        />
        <span
          style={{
            marginBottom: 10,
            fontSize: 16,
          }}
        >
          {label}
        </span>
      </SelectOptionLabel>
    </SelectOptionContainer>
  );
};

function ModuleSpadWorAnalysesSelect({ selectedAnalyses, onAnalysesUpdate }: Readonly<ModuleSpadWorAnalysesSelectProps>) {
  const onOptionChange = React.useCallback(
    (optionKey: string) => {
      const i = selectedAnalyses.indexOf(optionKey);
      let value;
      if (i >= 0) {
        value = [...selectedAnalyses.slice(0, i), ...selectedAnalyses.slice(i + 1)];
      } else {
        value = [...selectedAnalyses, optionKey].sort((a, b) => worAnalyses.indexOf(a) - worAnalyses.indexOf(b));
      }
      onAnalysesUpdate(value);
    },
    [onAnalysesUpdate, selectedAnalyses]
  );

  return (
    <div>
      <h4 className="noMarginVer">Analyses</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 10,
          marginTop: 10,
        }}
      >
        {worAnalyses.map((analysis) => {
          return (
            <SelectOptionItem
              checked={selectedAnalyses ? selectedAnalyses.indexOf(analysis) >= 0 : false}
              label={analysis}
              id={`id-select-${analysis}`}
              onClick={() => onOptionChange(analysis)}
              key={analysis}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ModuleSpadWorAnalysesSelect;
