import { useCallback, useMemo } from "react";
import { CellChange, Row } from "@silevis/reactgrid";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";

import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import dictionary from "@/constants/dictionary";
import { KoldunCsgApiState, KoldunMeasure } from "@/models/koldunV2";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { checkMatrixLimitsAndCholeskyCondition } from "@/utils/matrixValidator";

type useDependencyMatrixProps = {
  measures?: KoldunMeasure[];
  selectedLayer: number;
  loadingState: boolean;
  matrixKeys: string[];
  setKoldunCsgState: React.Dispatch<React.SetStateAction<KoldunCsgApiState | undefined>>;
  setValidationError: React.Dispatch<React.SetStateAction<ErrorValidationDetail[]>>;
};

const useDependencyMatrix = ({
  matrixKeys,
  measures,
  selectedLayer,
  setKoldunCsgState,
  loadingState,
  setValidationError,
}: useDependencyMatrixProps) => {
  const { palette } = useTheme();

  const dependencyMatrixColumns = ["", ...matrixKeys].map((columnId, index) => {
    return { columnId, width: index === 0 ? 155 : 100 };
  });

  const measure = useMemo(() => {
    return measures?.[selectedLayer];
  }, [selectedLayer, measures]);

  const dependencyMatrixRow: Row<any>[] = useMemo(() => {
    if (!measure) return [];
    return [
      {
        rowId: "header",
        cells: ["", ...matrixKeys].map((header) => {
          const style = { ...tableHeaderStyle };
          style.background = "#fff";
          return {
            type: "custom",
            text: dictionary.koldunCsg[header] ?? "",
            style,
          };
        }),
        height: 80,
      },
      ...matrixKeys.map((outerKey, outerIndex) => {
        const style = { ...tableHeaderStyle };
        style.background = "#fff";
        return {
          rowId: outerKey,
          height: 45,
          cells: [
            {
              type: "text",
              text: dictionary.koldunCsg[outerKey] ?? "",
              nonEditable: true,
              style,
            },
            ...matrixKeys.map((key, innerIndex) => {
              const itemIndex = `${outerKey}:${key}`;
              const item = measure.dependency_matrix_values[itemIndex];
              const notFound = !measure.dependency_matrix_values.hasOwnProperty(itemIndex);
              let textValue = notFound ? "" : String(item);

              const style = { ...tableCellStyle };

              if (notFound) {
                style.background = palette.primary.light;
              }
              if (outerIndex === innerIndex) {
                style.background = palette.primary.main;
                textValue = "1";
              }

              return {
                type: "text",
                text: textValue,
                style,
                nonEditable: notFound || loadingState,
              };
            }),
          ],
        };
      }),
    ];
  }, [measure, palette, loadingState, matrixKeys]);

  const onChangeDependencyMatrix = useCallback(
    (changes: CellChange[]) => {
      if (!measures) return;
      const updatedRows = _.cloneDeep(measures);

      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell } = change as CellChange<any>;

        rowId = rowId as number;
        columnId = columnId as string;

        updatedRows[selectedLayer].dependency_matrix_values[`${rowId}:${columnId}`] = newCell.value ?? newCell.text ?? 0;
      }

      try {
        checkMatrixLimitsAndCholeskyCondition(updatedRows[selectedLayer].dependency_matrix_values);
        setValidationError([]);
        setKoldunCsgState((prev) => {
          if (!prev) return prev;
          return {
            ...prev,
            inputs: {
              ...prev.inputs,
              measures: updatedRows,
            },
          };
        });
      } catch (error: any) {
        setValidationError((prev) => {
          const previousErrors = prev || [];
          return [
            ...previousErrors.filter((errValidation) => !errValidation.loc.includes("dependency_matrix")),
            {
              ctx: null,
              loc: ["dependency_matrix"],
              msg: error.message,
              type: "value_error",
            },
          ];
        });

        setTimeout(() => {
          setValidationError((prev) => {
            const previousErrors = prev || [];
            return [...previousErrors.filter((errValidation) => !errValidation.loc.includes("dependency_matrix"))];
          });
        }, 5000);
      }
    },
    [measures, setKoldunCsgState, selectedLayer, setValidationError]
  );

  return {
    onChangeDependencyMatrix,
    dependencyMatrixRow,
    dependencyMatrixColumns,
  };
};
export default useDependencyMatrix;
