import { Row } from "@silevis/reactgrid";

import dictionary from "@/constants/dictionary";
import { tableHeaderStyle } from "@/components/CustomTable";

const safeSpadDictionary: any = dictionary.spadDecline;
const safeSpadParamDictionary: any = dictionary.spadDeclineParams;

export const generateHeaderSpad = (list: string[]): Row<any> => {
  return {
    rowId: "header",
    cells: list.map((header, index) => {
      let text = header;
      if (index === 0) text = dictionary.rulon.data_set_id;
      else if (text.includes("operationalForecast") || text.includes("profileForecast")) {
        const section = header.split(".");
        return {
          type: "custom",
          text: `${safeSpadDictionary[section[0]]} - ${safeSpadDictionary[section[1]]} - ${safeSpadParamDictionary[section[2]].label}`,
          style: tableHeaderStyle,
          sub: safeSpadParamDictionary[section[2]].sub ?? "",
        };
      } else text = safeSpadDictionary[header];
      return {
        type: "text",
        text,
        style: tableHeaderStyle,
      };
    }),
    height: 50,
  };
};
