import React, { useCallback, useMemo, useRef, useState } from "react";
import _ from "lodash";

import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tabs from "@/components/Tabs";
import LinearProgress from "@mui/material/LinearProgress";

import dictionary from "@/constants/dictionary";

import { PromiseResultDetail, ResultState, WellErrorCode, postDataSet } from "@/models/wells";
import helpLinkUrl from "@/constants/helpLinkUrl";
import IndividualItem from "../shared/components/IndividualItem";
import { Container, VisuallyHiddenInput } from "../shared/style";
import { useTreeViewState } from "@/components/TreeView/hooks/TreeViewContextV2";
import CustomCard from "@/components/Card";

const MAX_FETCH = 10;

const ImportWell = () => {
  const { refreshDataSets } = useTreeViewState();

  const [files, setFiles] = useState<File[]>([]);
  const [res, setRes] = useState<ResultState<WellErrorCode>>({});
  const [isLoading, setIsLoading] = useState(false);

  const [isOverrideActive, setIsOverrideActive] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const [progressPercentage, setProgressPercentage] = useState(0);

  const totalFiles = useRef<number>(0);

  const handleOnChange = (e: any) => {
    setRes([]);
    setFiles(Array.from(e.target.files));
    setIsOverrideActive(false);
  };

  const batchFetch = useCallback(async (payloadList: any[]) => {
    let totalRes: any[] = [];
    let clonedPromises = _.cloneDeep(payloadList);

    while (clonedPromises.length > 0) {
      try {
        const promises = clonedPromises.slice(0, MAX_FETCH).map((payload) => postDataSet(payload));
        const allRes = await Promise.allSettled(promises);

        totalRes = totalRes.concat(allRes);
        clonedPromises.splice(0, MAX_FETCH);

        const percentage: number = ((totalFiles.current - clonedPromises.length) / totalFiles.current) * 100;

        setProgressPercentage(percentage);
      } catch (error) {
        // don't need fetch err handling because we are using promise settled which will all go as total res
        console.log(error);
      }
    }

    return {
      totalRes,
    };
  }, []);

  const handleOnSubmit = useCallback(
    async (override: boolean) => {
      try {
        setIsLoading(true);
        setProgressPercentage(0);
        if (!override) setRes([]);

        if (files) {
          totalFiles.current = 0;
          const overrideFileIndex: number[] = [];
          const filelistToFetch: any[] = [];

          if (override) {
            const overrideFiles = files.filter((_, index) => {
              if (res[index].status === "rejected" && res[index].detail.code === "file_exists") {
                overrideFileIndex.push(index);
                return true;
              }
              return false;
            });

            overrideFiles.forEach((file) => {
              filelistToFetch.push({ file: file, override });
            });
          } else {
            files.forEach((file) => {
              filelistToFetch.push({ file, override });
            });
          }
          totalFiles.current = filelistToFetch.length;

          const { totalRes } = await batchFetch(filelistToFetch);

          const reMappedResult: ResultState<WellErrorCode> = {};
          if (override) {
            setRes((prev) => {
              if (!prev) return prev;
              totalRes.forEach((currRes, index) => {
                const safeRes = currRes as {
                  reason: {
                    code: number;
                    detail: PromiseResultDetail<WellErrorCode>;
                  };
                  status: "rejected" | "fulfilled";
                };

                prev[overrideFileIndex[index]] = {
                  code: safeRes.reason?.code,
                  detail: safeRes.reason?.detail,
                  status: safeRes.status,
                };
              });
              return { ...prev };
            });
          } else {
            totalRes.forEach((res, index) => {
              const safeRes = res as {
                reason: {
                  code: number;
                  detail: PromiseResultDetail<WellErrorCode>;
                };
                status: "rejected" | "fulfilled";
              };
              reMappedResult[index] = {
                code: safeRes.reason?.code,
                detail: safeRes.reason?.detail,
                status: safeRes.status,
              };
            });
            refreshDataSets();
            setRes(reMappedResult);
          }
        }
      } catch (error: any) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
        setActiveTab(0);
        setIsOverrideActive(false);
      }
    },
    [batchFetch, files, refreshDataSets, res]
  );

  const resultAvailable = Object.keys(res).length > 0;

  const tabList = useMemo(() => {
    if (!resultAvailable || !res) return [];
    const successFiles = files
      .map((_, index) => {
        if (res[index].status === "fulfilled") return String(index);
        return null;
      })
      .filter((comp) => !!comp) as string[];
    const overrideFiles = files
      .map((_, index) => {
        if (res[index].status === "rejected" && res[index].detail?.code === "file_exists") return String(index);
        return null;
      })
      .filter((comp) => !!comp) as string[];
    const failedFiles = files
      .map((_, index) => {
        if (res[index].status === "rejected" && res[index].detail?.code !== "file_exists") return String(index);
        return null;
      })
      .filter((comp) => !!comp) as string[];

    const tabs = [];
    if (successFiles.length > 0)
      tabs.push({
        label: <span>{dictionary.wellImport.successFiles}</span>,
        key: "success",
        content: (
          <div className="files-list-container">
            {successFiles.map((fileIndex) => {
              return <IndividualItem key={files[Number(fileIndex)].name} name={files[Number(fileIndex)].name} />;
            })}
          </div>
        ),
      });
    if (failedFiles.length > 0)
      tabs.push({
        label: <span>{dictionary.wellImport.failedTitle}</span>,
        key: "failed",
        content: (
          <div className="files-list-container">
            {failedFiles.map((fileIndex) => {
              return (
                <IndividualItem
                  error={res[Number(fileIndex)].detail?.message}
                  key={files[Number(fileIndex)].name}
                  name={files[Number(fileIndex)].name}
                />
              );
            })}
          </div>
        ),
      });
    if (overrideFiles.length > 0)
      tabs.push({
        label: <span>{dictionary.wellImport.overrideFile}</span>,
        key: "override",
        content: (
          <div className="files-list-container">
            {overrideFiles.map((fileIndex) => {
              return (
                <IndividualItem
                  error={res[Number(fileIndex)].detail?.message}
                  key={files[Number(fileIndex)].name}
                  name={files[Number(fileIndex)].name}
                />
              );
            })}
          </div>
        ),
      });

    return tabs;
  }, [files, res, resultAvailable]);

  const onlyOverrideTabActive = useMemo(() => {
    return tabList.filter((tab) => tab.key === "override").length === 1 && tabList.length === 1;
  }, [tabList]);

  const onlySuccessTabActive = useMemo(() => {
    return tabList.filter((tab) => tab.key === "success").length === 1 && tabList.length === 1;
  }, [tabList]);

  return (
    <Container>
      <CustomCard style={{ width: 700, maxHeight: "60vh" }}>
        <h3>
          <a target="_blank" rel="noreferrer" href={helpLinkUrl.wellImport}>
            {dictionary.nav.importWells} <InfoOutlinedIcon fontSize="small" />
          </a>
        </h3>

        <div className="list-container">
          {isLoading && (
            <LinearProgress
              style={{
                margin: "1em",
              }}
              variant="determinate"
              value={progressPercentage}
            />
          )}

          <div>
            {!resultAvailable &&
              files?.map((file) => {
                return <IndividualItem key={file.name} name={file.name} />;
              })}
          </div>

          {resultAvailable && (
            <div>
              <Tabs
                onClickItem={(item) => {
                  setActiveTab(item);
                  setIsOverrideActive(tabList[item].key === "override");
                }}
                customActiveTab={activeTab}
                tabList={tabList}
              />
            </div>
          )}
        </div>
        <div className="button-container">
          {!onlySuccessTabActive && (
            <Button
              disabled={isLoading}
              style={{ color: "white" }}
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              {dictionary.wellImport.choose}(s)
              <VisuallyHiddenInput onChange={handleOnChange} multiple type="file" accept=".csv" />
            </Button>
          )}

          {onlySuccessTabActive ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "/modules";
              }}
              style={{ color: "white", marginLeft: 20 }}
              variant="contained"
            >
              {dictionary.wellImport.ok}
            </Button>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                handleOnSubmit(isOverrideActive || onlyOverrideTabActive);
              }}
              disabled={files.length === 0 || isLoading || (resultAvailable && !isOverrideActive && !onlyOverrideTabActive)}
              style={{ color: "white", marginLeft: 20 }}
              variant="contained"
            >
              {isOverrideActive || onlyOverrideTabActive ? dictionary.wellImport.overrideFile : dictionary.wellImport.submit}
            </Button>
          )}
        </div>
      </CustomCard>
    </Container>
  );
};

export default ImportWell;
