import React, { useMemo } from "react";
import { Column, Row } from "@silevis/reactgrid";

// component
import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";
import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";

// constant
import dictionary from "@/constants/dictionary";
import LoadingIndicator from "@/components/LoadingIndicator";
import Tabs from "@/components/Tabs";
import { FluidType } from "@/models/Generic";
import CustomCard from "@/components/Card";

export type AutoRtaForecastCalculationProps = {
  isLoading: boolean;
  dataTableRow: Row[];
  forecastXAxes: FossilyticsChartAxis[];
  forecastYAxes: FossilyticsChartAxis[];
  cumulativeGasYAxes: FossilyticsChartAxis[];
  forecastCalcSeries: FossilyticsChartSeries[];
  cumulativeGasChartSeries: FossilyticsChartSeries[];
  forecastCalculationDataTableCol: Column[];

  fieldDataCol: Column[];
  fieldDataRow: Row[];

  fluidType: FluidType;
};

const AutoRtaForecastCalculation = ({
  isLoading,
  dataTableRow,
  forecastXAxes,
  forecastYAxes,
  forecastCalcSeries,
  cumulativeGasChartSeries,
  cumulativeGasYAxes,
  forecastCalculationDataTableCol,
  fieldDataCol,
  fieldDataRow,
  fluidType,
}: AutoRtaForecastCalculationProps) => {
  const tabList = useMemo(() => {
    return [
      {
        label: <span>Chart</span>,
        key: "chart",
        content: (
          <div
            style={{
              height: 600,
            }}
            className="chartWrapper"
          >
            <FossilyticsChart
              id="auto-rta-forecast-chart"
              isLoading={isLoading}
              xAxes={forecastXAxes}
              yAxes={forecastYAxes}
              series={forecastCalcSeries}
            />
          </div>
        ),
      },
      {
        label: <span>{`Cumulative ${fluidType} Chart`}</span>,
        key: `Cumulative ${fluidType} Chart`,
        content: (
          <div
            style={{
              height: 600,
            }}
            className="chartWrapper"
          >
            <FossilyticsChart
              id="auto-rta-forecast-cumulative-gas-chart"
              isLoading={isLoading}
              xAxes={forecastXAxes}
              yAxes={cumulativeGasYAxes}
              series={cumulativeGasChartSeries}
            />
          </div>
        ),
      },
      {
        label: <span>{dictionary.modelDataTable}</span>,
        key: dictionary.modelDataTable,
        content: (
          <div
            style={{
              height: 600,
              paddingTop: "1em",
            }}
            className="chartWrapper"
          >
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <ReactGridTable
                viewWidth={"100%"}
                viewHeight={600}
                columns={forecastCalculationDataTableCol}
                rows={dataTableRow}
                enableRangeSelection
                enableColumnSelection
                stickyTopRows={2}
              />
            )}
          </div>
        ),
      },
      {
        label: <span>{dictionary.autoRta.measuredDataTable}</span>,
        key: dictionary.autoRta.measuredDataTable,
        content: (
          <div
            style={{
              height: 600,
              paddingTop: "1em",
            }}
            className="chartWrapper"
          >
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <ReactGridTable
                viewWidth={"100%"}
                viewHeight={600}
                columns={fieldDataCol}
                rows={fieldDataRow}
                enableRangeSelection
                enableColumnSelection
                stickyTopRows={2}
              />
            )}
          </div>
        ),
      },
    ];
  }, [
    cumulativeGasChartSeries,
    cumulativeGasYAxes,
    dataTableRow,
    fieldDataCol,
    fieldDataRow,
    forecastCalcSeries,
    forecastCalculationDataTableCol,
    forecastXAxes,
    forecastYAxes,
    isLoading,
    fluidType,
  ]);

  const haveCalculate = useMemo(() => cumulativeGasChartSeries.length !== 0, [cumulativeGasChartSeries]);
  return (
    <CustomCard
      style={{
        display: "flex",
        height: "100%",
        opacity: !haveCalculate ? 0.5 : 1,
        position: "relative",
      }}
    >
      {!haveCalculate && <div className="overlay" />}

      <Tabs tabList={tabList} />
    </CustomCard>
  );
};

export default AutoRtaForecastCalculation;
