import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";

import { InputApiStateScheme, postValidateInput, TahkCsgInputResponse, TahkCsgInputState, TahkCsgStateResponse, WarningMessage } from "@/models/tahk";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { ModuleIdentity } from "@/models/Generic";
import { PollHelper } from "../../context/TahkCsgContext";
import { parseErrorThrown } from "@/utils/errorHandling";

type TahkCsgInputProps = {
  tabIndex: number;
  isLoading: boolean;
  setTahkCsgState: React.Dispatch<React.SetStateAction<TahkCsgStateResponse | null | undefined>>;
  tahkCsgState?: TahkCsgStateResponse | null;
  analysisIdentity?: ModuleIdentity;
  loadingDependency: boolean;
} & PollHelper;

const useTahkCsgInput = ({
  tabIndex,
  isLoading,
  setTahkCsgState,
  setApiError,
  tahkCsgState,
  analysisIdentity,
  setIsLoading,
  apiError,
  loadingDependency,
}: TahkCsgInputProps) => {
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);

  const lastInputRef = useRef<TahkCsgInputState>();
  const [calculationResult, setCalculationResult] = useState<TahkCsgInputResponse | null>();
  const [warningMessages, setWarningMessages] = useState<WarningMessage[]>([]);

  const lastPayloadRef = useRef<any>();
  const isUpdatedFromBE = useRef<boolean>(false);

  useEffect(() => {
    if (tahkCsgState?.inputs && !_.isEqual(tahkCsgState.inputs, lastInputRef.current)) {
      setErrorInputValidation([]);
      lastInputRef.current = { ...tahkCsgState?.inputs };
    }
  }, [tahkCsgState?.inputs]);

  const inputs = useMemo(() => {
    if (tahkCsgState?.inputs) {
      return tahkCsgState.inputs;
    }
    return undefined;
  }, [tahkCsgState?.inputs]);

  const analysis = useMemo(() => {
    return tahkCsgState?.analysis;
  }, [tahkCsgState?.analysis]);

  const onCalculateInput = useCallback(async () => {
    if (!inputs || !analysis || !analysisIdentity) return;

    try {
      const payload = {
        options: {
          input_options: inputs,
          analysis_options: analysis,
        },
        data_options: {
          analysis_identity: analysisIdentity,
        },
      };
      if (_.isEqual(lastPayloadRef.current, payload)) return;
      lastPayloadRef.current = _.cloneDeep(payload);

      if (isUpdatedFromBE.current) {
        isUpdatedFromBE.current = false;
        return;
      }
      setIsLoading(true);
      setWarningMessages([]);
      const res = await postValidateInput(payload);

      const data = res?.data;
      if (data) {
        const parsed = InputApiStateScheme.parse(data);
        const inputData = parsed.inputs;
        const inputEqual = _.isEqual(inputs, inputData.input_options);
        const analysisEqual = _.isEqual(analysis, inputData.analysis_options);

        if (!analysisEqual || !inputEqual) {
          setTahkCsgState((prev) => {
            if (!prev) return prev;
            const newState = { ...prev };
            if (!analysisEqual) {
              newState.analysis = inputData.analysis_options ?? prev?.analysis;
            }
            if (!inputEqual) {
              newState.inputs = inputData.input_options ?? prev?.inputs;
            }
            return newState;
          });
          isUpdatedFromBE.current = true;
        }
        if (!_.isEqual(parsed.calculation, calculationResult)) setCalculationResult(parsed.calculation);
        if (parsed.messages) setWarningMessages(parsed.messages);
      }
    } catch (error) {
      console.log(error);
      parseErrorThrown({
        error,
        setApiError,
        setValidationError: setErrorInputValidation,
        apiError,
      });
    } finally {
      setIsLoading(false);
    }
  }, [analysis, analysisIdentity, calculationResult, inputs, apiError, setApiError, setIsLoading, setTahkCsgState]);

  useEffect(() => {
    if (!!inputs && tabIndex === 1 && !!analysis && !!analysisIdentity && !loadingDependency && !isLoading) {
      onCalculateInput();
    }
  }, [analysis, analysisIdentity, inputs, loadingDependency, onCalculateInput, tabIndex, isLoading]);

  const loadingState = useMemo(() => {
    return isLoading;
  }, [isLoading]);

  const tahkCsgInputCalculation = useMemo(() => {
    return calculationResult;
  }, [calculationResult]);

  const layerError = useMemo(() => {
    const totalWarning: string[] = [];

    warningMessages.forEach((warn) => {
      if (warn.loc.includes("layers")) totalWarning.push(warn.message);
    });

    errorInputValidation.forEach((warn) => {
      totalWarning.push(warn.msg);
    });

    if (totalWarning.length === 0) return [];

    return totalWarning;
  }, [errorInputValidation, warningMessages]);

  return {
    loadingState,
    errorInputValidation,
    tahkCsgInputCalculation,
    layerError,
  };
};

export default useTahkCsgInput;
