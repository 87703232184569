import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import _ from "lodash";

import useGenericDatasetInfo, { UseGenericDatasetInfoProps } from "@/utils/useGenericModuleInfo";
import { parseErrorThrown } from "@/utils/errorHandling";

import { FluidType, ModuleIdentity } from "@/models/Generic";
import { ApiError } from "@/models/APIGeneric";
import { AnalysisFmbResult, AutoFmbState, autoFmbStateScheme, ForecastFmbResultResponse, postInitializeFmb } from "@/models/autoFmb";

import { defaultAutoFmbState } from "../constants";

export type ApiHelper = {
  setApiError: (error?: ApiError) => void;
  apiError?: ApiError;
  setIsLoading: (isLoading: boolean) => void;
  setProgress: (progress: number | null) => void;
  setPollStatus: (status?: string) => void;
};

type AutoFmbContextState = {
  tabIndex: number;
  dataSets: string[];
  isLoading: boolean;
  analysisIdentity?: ModuleIdentity;
  fluidType: FluidType;
  setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  autoFmbState?: AutoFmbState;
  setAutoFmbState: React.Dispatch<React.SetStateAction<AutoFmbState | undefined>>;
  setAutoFmbForecastCalculation: React.Dispatch<React.SetStateAction<ForecastFmbResultResponse | undefined>>;
  autoFmbForecastCalculation?: ForecastFmbResultResponse;
  autoFmbAnalysisCalculation?: AnalysisFmbResult;
  setAutoFmbAnalysisCalculation: React.Dispatch<React.SetStateAction<AnalysisFmbResult | undefined>>;
} & ApiHelper;

type AutoFmbProps = {
  children: React.ReactNode;
  isLoading: boolean;
} & UseGenericDatasetInfoProps &
  ApiHelper;

export type SummaryFmb = {
  parameter: string | number;
  low: string | number;
  mid: string | number;
  high: string | number;
  units: string | number;
}[];

const AutoFmbContext = createContext<AutoFmbContextState>(defaultAutoFmbState);

const AutoFmbProvider = ({
  project,
  group,
  isLoading,
  selectedDataSets,
  children,
  setApiError,
  setIsLoading,
  apiError,
  setProgress,
  setPollStatus,
}: Readonly<AutoFmbProps>) => {
  const { analysisIdentity, dataSets, fluidType, setTabIndex, tabIndex } = useGenericDatasetInfo({
    selectedDataSets,
    group,
    project,
  });

  const [autoFmbState, setAutoFmbState] = useState<AutoFmbState | undefined>();
  const [latestDataSets, setLatestDataSets] = useState<string[]>([]);

  const [autoFmbForecastCalculation, setAutoFmbForecastCalculation] = useState<ForecastFmbResultResponse>();
  const [autoFmbAnalysisCalculation, setAutoFmbAnalysisCalculation] = useState<AnalysisFmbResult>();

  const client = useQueryClient();

  const { isLoading: isLoadingInitialize, isFetching } = useQuery({
    queryKey: ["initialize-auto-fmb", dataSets, analysisIdentity.project_id],
    queryFn: async () => {
      return postInitializeFmb(analysisIdentity.project_id, fluidType, {
        data_set_ids: dataSets,
      });
    },
    select(data) {
      try {
        if (data?.data && !autoFmbState) {
          const parsed = autoFmbStateScheme.parse(data.data);
          setAutoFmbState(parsed);
          setLatestDataSets(dataSets);
        }
      } catch (error: any) {
        console.log(error.issues);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: dataSets && dataSets.length > 0 && !!analysisIdentity.project_id && tabIndex > 0,
  });

  useEffect(() => {
    if (latestDataSets.length > 0 && !_.isEqual(latestDataSets, dataSets)) {
      setAutoFmbState(undefined);
      client?.invalidateQueries();
    }
  }, [client, dataSets, latestDataSets]);

  const state = useMemo(() => {
    return {
      tabIndex,
      analysisIdentity,
      dataSets,
      isLoading: isLoading || isLoadingInitialize || isFetching,
      fluidType,
      setTabIndex,
      setApiError,
      setIsLoading,
      apiError,
      autoFmbState,
      setAutoFmbState,
      setProgress,
      setPollStatus,
      autoFmbForecastCalculation,
      setAutoFmbForecastCalculation,
      autoFmbAnalysisCalculation,
      setAutoFmbAnalysisCalculation,
    };
  }, [
    tabIndex,
    analysisIdentity,
    dataSets,
    isLoading,
    isLoadingInitialize,
    isFetching,
    fluidType,
    setTabIndex,
    setApiError,
    setIsLoading,
    apiError,
    autoFmbState,
    setProgress,
    setPollStatus,
    autoFmbForecastCalculation,
    setAutoFmbForecastCalculation,
    autoFmbAnalysisCalculation,
    setAutoFmbAnalysisCalculation,
  ]);

  return <AutoFmbContext.Provider value={state}>{children}</AutoFmbContext.Provider>;
};

export function useAutoFmbState() {
  return useContext(AutoFmbContext);
}

export default AutoFmbProvider;
