import { API } from "aws-amplify";
import { GenericApiResponse, GenericApiParam, CustomError } from "./type";
import { APIName } from "@/constants/apiUrl";
import { safeTimeStampToLS } from "@/utils/session";
import { setExtraErrException } from "../errorMonitoring";

export const fetchApi = async <Response, Request = any, QueryParams = any>({
  path,
  config,
  queryStringParameters,
  responseType,
  body,
  type = "get",
}: GenericApiParam<Request, QueryParams>): Promise<GenericApiResponse<Response>> => {
  try {
    if (!path.toLowerCase().includes("/session")) safeTimeStampToLS();
    const response = await API[type](APIName, path, {
      queryStringParameters,
      body,
      responseType,
      response: true,
      ...config,
    });

    return {
      data: response.data as Response,
      headers: response?.headers,
    };
  } catch (err: any) {
    setExtraErrException(err);
    // indicate the error comes from BE
    if (err.response)
      throw new CustomError({
        code: err.response.data?.code ?? err.response.status,
        detail: err.response.data?.detail,
        message: err.response.data.message,
        severity: err.response.data.severity,
      });

    // if something went wrong need to send this as default for displaying error
    throw new CustomError({
      code: 0,
      message: "",
      severity: "error",
    });
  }
};
