import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import InputField from "@/components/fields/InputField";

import { ModelComponentsParamProps } from "../../hooks/AutoRtaContext";

const WellboreModelContainer = ({ value, isLoading, updateInputGridValue, validationError }: ModelComponentsParamProps) => {
  const wellboreModelCheckBox = value?.wellbore_model.use_wellbore_model;

  return (
    <div style={{ marginTop: 30, width: 300 }}>
      {value?.wellbore_model && (
        <FormControlLabel
          control={
            <Checkbox
              id="use-wellbore-model"
              onChange={(e) => {
                updateInputGridValue(e.target.checked, "use_wellbore_model", "wellbore_model");
              }}
              defaultChecked
              size="small"
              checked={wellboreModelCheckBox}
              disabled={isLoading}
            />
          }
          label="Use Wellbore Model"
        />
      )}

      {wellboreModelCheckBox && (
        <div>
          <div className="equal-size">
            <InputField
              label="Tubing diameter"
              suffix="in"
              debounceDelay={1000}
              value={value?.wellbore_model?.tubing_diameter}
              onChange={(v) => updateInputGridValue(v, "tubing_diameter", "wellbore_model")}
              disabled={isLoading}
              errors={validationError}
              keyField="inputs.wellbore_model.tubing_diameter"
            />
            <InputField
              label="Tubing roughness"
              suffix="in"
              debounceDelay={1000}
              value={value?.wellbore_model?.tubing_roughness}
              onChange={(v) => updateInputGridValue(v, "tubing_roughness", "wellbore_model")}
              disabled={isLoading}
              errors={validationError}
              keyField="inputs.wellbore_model.tubing_roughness"
            />
            <InputField
              label="Tubing depth"
              suffix="ft"
              debounceDelay={1000}
              value={value?.wellbore_model?.tubing_depth}
              onChange={(v) => updateInputGridValue(v, "tubing_depth", "wellbore_model")}
              disabled={isLoading}
              errors={validationError}
              keyField="inputs.wellbore_model.tubing_depth"
            />
            <InputField
              label="Wellhead temperature"
              suffix="°F"
              debounceDelay={1000}
              value={value?.wellbore_model?.wellhead_temperature}
              onChange={(v) => updateInputGridValue(v, "wellhead_temperature", "wellbore_model")}
              disabled={isLoading}
              errors={validationError}
              keyField="inputs.wellbore_model.wellhead_temperature"
            />
            <InputField
              label="Well angle"
              suffix="°"
              debounceDelay={1000}
              value={value?.wellbore_model?.well_angle}
              onChange={(v) => updateInputGridValue(v, "well_angle", "wellbore_model")}
              disabled={isLoading}
              errors={validationError}
              keyField="inputs.wellbore_model.well_angle"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WellboreModelContainer;
