import { useState, useRef } from "react";
import { Menu, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { Interval } from "@/models/Generic";
import dictionary from "@/constants/dictionary";

const exportOption = [Interval.daily, Interval.monthly, Interval.yearly];

type IntervalButtonProps = {
  loading: boolean;
  label: string;
  onClickButton: (option: Interval) => void;
  disabled?: boolean;
};

const IntervalButton = ({ loading, onClickButton, label, disabled }: IntervalButtonProps) => {
  const ref = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  return (
    <div ref={ref}>
      <Button
        style={{
          color: "white",
          width: "100%",
          fontWeight: "bold",
        }}
        variant="contained"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        disabled={loading || disabled}
      >
        <div style={{ marginLeft: "auto", paddingLeft: 23 }}>{label}</div>
        <KeyboardArrowDownIcon style={{ marginLeft: "auto" }} />
      </Button>
      <div>
        <Menu
          slotProps={{
            paper: {
              style: {
                width: ref?.current?.offsetWidth ?? 400,
              },
            },
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={() => setAnchorEl(null)}
        >
          {exportOption.map((option: Interval) => {
            return (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  onClickButton(option);
                }}
                key={dictionary.spadDecline[option]}
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {label} {dictionary.spadDecline[option]}
                </div>
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    </div>
  );
};

export default IntervalButton;
