const unitDictionary: {
  [fluid: string]: {
    [key: string]: string[];
  };
} = {
  oil: {
    economicCutoff: ["STB/D", "ABcd/E"],
    initialRate: ["bbl/D", "abc/D"],
    initialDeclineRate: ["1/D"],
    initialDay: ["D"],
  },
  gas: {
    economicCutoff: ["MMscf/D", "ABcd/E"],
    initialRate: ["MMscf/D", "abc/D"],
    initialDeclineRate: ["1/D"],
    initialDay: ["D"],
  },
  water: {},
};

export default unitDictionary;
