import { FluidType } from "@/models/Generic";

export const defaultTahkCsgState = {
  tabIndex: 0,
  onChangeTab: () => {},
  setTahkCsgState: () => {},
  setApiError: () => {},
  setCsvData: () => {},
  dataSets: [],
  isLoading: false,
  tahkCsgState: null,

  setPollStatus: () => {},
  setProgress: () => {},

  setIsLoading: () => {},
  pollRequest: () => {},

  loadingDependency: false,
  setLoadingDependency: () => {},
  fluidType: FluidType.gas,
};
