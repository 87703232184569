import React, { useMemo } from "react";
import { shallow } from "zustand/shallow";

import { useAppStore } from "@/features/app";

import ModuleDataView from "../../ModuleDataView";
import ModulePage from "../../ModulePage";

import ModuleSpadWorAnalysesSelect from "./ModuleSpadWorAnalysesSelect";
import FossilyticsChart from "../../../components/FossilyticsChart";
import FossilyticsGrid from "../../../components/FossilyticsGrid";

// hooks
import useModuleData from "./hooks/useModuleData";
import useSpadWorData, { axisTypeOptions, AxisType } from "./hooks/useSpadWorData";

import InputField from "@/components/fields/InputField";
import CustomCard from "@/components/Card";
import DropdownField from "@/components/fields/DropdownField";
import Tabs from "@/components/Tabs";

const ModuleSpadWor = () => {
  const { selectedDataSets, isLoading, postRequest, setCsvData } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      postRequest: state.postRequest,
      setCsvData: state.setCsvData,
    }),
    shallow
  );

  const { moduleFields } = useModuleData();

  const {
    dataSet,
    cleaned,
    analyses,
    validTarget,
    reservesTableGroups,
    reservesTableCols,
    reservesTableData,
    worAxisType,
    target,
    combinedChartProps,
    mappedAnalyses,
    currentTab,

    setCleaned,
    setWorAxisType,
    setAnalyses,
    setTarget,
    setCurrentTab,
  } = useSpadWorData({
    selectedDataSets,
    postRequest,
    setCsvData,
  });

  const tabList = useMemo(() => {
    const result = [
      ...mappedAnalyses.map((analysis) => {
        return {
          label: <span>{analysis.key}</span>,
          key: analysis.key,
          content: (
            <div
              style={{
                height: 700,
              }}
              className="chartWrapper"
            >
              <FossilyticsChart id={`spad_wor_${analysis.key}`} isLoading={isLoading} {...analysis} />
            </div>
          ),
        };
      }),

      {
        label: <span>Combined Chart</span>,
        key: "Combined Chart",
        content: (
          <div
            style={{
              height: 700,
            }}
            className="chartWrapper"
          >
            {analyses && analyses.length > 0 && <FossilyticsChart id="spad_wor_combined" isLoading={isLoading} {...combinedChartProps} />}
          </div>
        ),
      },
    ];
    if (validTarget) {
      result.splice(1, 0, {
        label: <span>{"Analysis Summary"}</span>,
        key: "Analysis Summary",
        content: (
          <div
            style={{
              height: 700,
            }}
            className="chartWrapper"
          >
            <FossilyticsGrid columnGroups={reservesTableGroups} columns={reservesTableCols} data={reservesTableData} />
          </div>
        ),
      });
    }
    return result;
  }, [analyses, combinedChartProps, isLoading, mappedAnalyses, reservesTableCols, reservesTableData, reservesTableGroups, validTarget]);

  return (
    <ModulePage
      title={"SPAD: Water Oil Ratio"}
      tabIndex={currentTab}
      onTabChange={setCurrentTab}
      tabs={
        dataSet && [
          {
            headerText: "Data View",
            itemIcon: "ScatterChart",
            disabled: false,
            canSaveAsImg: true,
            content: (
              <ModuleDataView
                isActive={currentTab === 0}
                moduleFields={moduleFields}
                showAtmosphericCorrection={false}
                showCombinedFirst
                onDataCleaned={setCleaned}
              />
            ),
          },
          {
            headerText: "Analyses",
            itemIcon: "LineChart",
            disabled: !cleaned,
            canSaveAsImg: true,
            content: (
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "350px 1fr",
                  gridGap: 20,
                  padding: "1em",
                }}
              >
                <CustomCard>
                  <h4 className="primaryColor noMarginVer">Inputs</h4>

                  <ModuleSpadWorAnalysesSelect selectedAnalyses={analyses ?? []} onAnalysesUpdate={setAnalyses} />

                  {analyses && analyses.indexOf("WOR") >= 0 ? (
                    <DropdownField
                      label="WOR vertical axis type"
                      selectedKey={worAxisType}
                      onChange={(o) => (o !== undefined ? setWorAxisType(o as AxisType) : null)}
                      options={axisTypeOptions}
                    />
                  ) : null}

                  <InputField
                    label="Target water cutoff"
                    suffix="%"
                    value={target ? target * 100 : null}
                    onChange={(v) => setTarget(v ? Number(v) / 100 : 0)}
                  />
                </CustomCard>

                <CustomCard>
                  <Tabs tabList={tabList} />
                </CustomCard>
              </div>
            ),
          },
        ]
      }
    />
  );
};

export default ModuleSpadWor;
