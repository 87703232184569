import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";

import { ModuleTahkForecastGasOilOutputView } from "./ModuleTahkForecastGasOilOutputView";
import ModulePage from "../../../ModulePage";
import { useSettingState } from "../../../../SettingsState";
import ModuleTahkForecastGasOilInputGridTab from "./ModuleTahkForecastGasOilInputGridTab/ModuleTahkForecastGasOilInputGridTab";
import {
  DataSet,
  FormationCompressibility,
  GasViscosityMethod,
  RockType,
  TankType,
  WaterViscosityMethod,
  ZMethod,
  isDataSet,
} from "../../../../model";
import { ModuleTahkForecastGasOilOptions, ModuleTahkForecastOutput, ModuleTahkForecastUncertainParamDistribution } from "./model";
import { useAppStore } from "@/features/app";

const DEFAULT_NUM_DAYS = 300;

export const getLastValidDataIndex = (inputs?: [number, number, number, number][], num_days?: number) => {
  if (inputs && num_days != null) {
    for (let i = 1; i < inputs.length; i++) {
      if (inputs[i][3] === -1) {
        return i - 1;
      }
      if (inputs[i][0] >= inputs[i - 1][0] || inputs[i][0] > num_days || inputs[i][2] === -1) {
        return i;
      }
    }
  }
  return 0;
};

interface ModuleTahkForecastGasOilContentProps {
  title: string;
  module: string;
}

function ModuleTahkForecastGasOilContent({ title, module }: Readonly<ModuleTahkForecastGasOilContentProps>) {
  const { selectedDataSets, isLoading, pollRequest, setApiError } = useAppStore(
    (state) => ({
      selectedDataSets: state.selectedDataSets,
      isLoading: state.isLoading,
      pollRequest: state.pollRequest,
      setApiError: state.setApiError,
    }),
    shallow
  );

  const [dataSet, setDataSet] = useState<DataSet>();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [inputChanged, setInputChanged] = useState(false);

  const handleInputNotChanged = useCallback(() => {
    setInputChanged(true);
  }, []);

  useEffect(() => {
    setDataSet(isDataSet(selectedDataSets) ? selectedDataSets : undefined);
    setCurrentTab((prev) => (prev === 1 ? 0 : prev));
  }, [selectedDataSets]);

  const [options, setOptions] = useSettingState<ModuleTahkForecastGasOilOptions>(`${module}_options`, false, {
    default: {
      //BaseInputs
      tank_type: TankType.Vertical,
      area_acres: 90,
      wellbore_radius_ft: 0.3,

      gas_gravity: 0.65,
      n2_perc: 0,
      co2_perc: 0,
      h2s_perc: 0,

      form_compressibility_method: FormationCompressibility.HALL,
      z_method: ZMethod.DPR,
      z_correlation: "INTERP",
      water_viscosity_method: WaterViscosityMethod.MCCAIN,
      gas_viscosity_method: GasViscosityMethod.GONZALES_EAKIN,
      form_temp_of: 158,
      salinity_ppm: 0,

      rock_type: RockType.SANDSTONE,

      num_simulations: 1,
      ogip_mmscf: { distribution: ModuleTahkForecastUncertainParamDistribution.fixed, value: 4000 },
      water_sat_perc: { distribution: ModuleTahkForecastUncertainParamDistribution.fixed, value: 20 },
      porosity_perc: { distribution: ModuleTahkForecastUncertainParamDistribution.fixed, value: 0.5 },
      permeability_md: { distribution: ModuleTahkForecastUncertainParamDistribution.fixed, value: 50 },
      net_pay_ft: { distribution: ModuleTahkForecastUncertainParamDistribution.fixed, value: 50 },
      initial_pressure_psia: { distribution: ModuleTahkForecastUncertainParamDistribution.fixed, value: 2500 },

      //CSG specific options

      res_prop: {
        permeability_xaxis_md: 5,
        permeability_yaxis_md: 5,
        permeability_zaxis_md: 5,
        well_position_xaxis_ft: 990,
        well_position_yaxis_ft: 990,
        well_position_zaxis_ft: 25,
        res_width_xaxis_ft: 1980,
        res_length_yaxis_ft: 1980,
        res_height_zaxis_ft: 50,
        horizontal_well_length_yaxis_ft: 1200,
      },

      num_days: DEFAULT_NUM_DAYS,

      // Days, Flowing BH pressures, Skins, contract_rate_mmscf_d
      inputs: [[1, 0, -1, -1], ...Array.from(Array(DEFAULT_NUM_DAYS - 1).keys()).map(() => [0, 0, -1, -1] as [number, number, number, number])],
    },
  });

  const [output, setOutput] = useState<ModuleTahkForecastOutput>();

  useEffect(() => {
    if (!dataSet || !options || currentTab !== 1) return;

    setOutput(undefined);
    (async () => {
      try {
        const lastValidInputIndex = getLastValidDataIndex(options.inputs, options.num_days);
        const response = await pollRequest(
          "/modules/koldun/mcsim/calculate",
          {
            data_set_id: dataSet.id,
          },
          {
            ...options,
            inputs: options.inputs.slice(0, lastValidInputIndex + 1),
          }
        );

        setOutput(response);
      } catch (error: any) {
        const errorRes = error.response?.data;
        if (error.response.status === 422 && errorRes?.detail?.length > 0) {
          setApiError({
            message: errorRes.detail?.[0].msg,
          });
        }
        console.error(error);
      }
    })();
  }, [dataSet, currentTab, pollRequest, options, setApiError]);

  return (
    <ModulePage
      title={title}
      tabIndex={currentTab}
      onTabChange={setCurrentTab}
      tabs={
        dataSet && [
          {
            headerText: "Input Grid",
            itemIcon: "InputField",
            disabled: isLoading,
            disableHideSidebar: true,
            content:
              currentTab === 0 ? (
                <ModuleTahkForecastGasOilInputGridTab
                  value={options}
                  onChange={setOptions}
                  module={module}
                  isLoading={isLoading}
                  handleInputNotChanged={handleInputNotChanged}
                />
              ) : (
                <></>
              ),
          },
          {
            headerText: "Forecast",
            itemIcon: "LineChart",
            disabled: isLoading || !options || !inputChanged,
            disableHideSidebar: true,
            canSaveAsImg: true,
            content: <ModuleTahkForecastGasOilOutputView isLoading={isLoading} dataSet={dataSet} output={output} />,
          },
        ]
      }
    />
  );
}

export default ModuleTahkForecastGasOilContent;
