import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { ContractRateEnum, FlowPressureTypeEnum, WellboreModelEnum } from "@/models/InputGeneric";

import DropdownField from "@/components/fields/DropdownField";
import InputField from "@/components/fields/InputField";
import LoadingIndicator from "@/components/LoadingIndicator";

import dictionary from "@/constants/dictionary";
import helpLinkUrl from "@/constants/helpLinkUrl";

import { mapEnumToDropdown } from "@/utils/general";

import useTahkCsgWellboreInput, { TahkCsgWellboreInputProps } from "../../../hooks/input/charts/useTahkCsgWellboreInput";

type WellboreInputProps = {
  errorInputValidation: ErrorValidationDetail[];
} & TahkCsgWellboreInputProps;

const WellboreInput = (props: WellboreInputProps) => {
  const { loadingState, inputs, errorInputValidation } = props;

  const { onChangeWellboreInput, onChangeWellboreInputParameter } = useTahkCsgWellboreInput(props);

  return (
    <div
      style={{
        width: 250,
      }}
    >
      {inputs?.wellbore_inputs ? (
        <>
          <DropdownField
            label={dictionary.tahk.contractRate}
            options={mapEnumToDropdown(ContractRateEnum)}
            disabled={loadingState}
            selectedKey={inputs?.wellbore_inputs.contract_rate_mode}
            onChange={(v) => {
              onChangeWellboreInput(v, "contract_rate_mode");
            }}
            errors={errorInputValidation}
            keyField="input_options.wellbore_inputs.contract_rate_mode"
          />

          <DropdownField
            label={dictionary.tahk.flowPressureType}
            options={mapEnumToDropdown(FlowPressureTypeEnum)}
            disabled={loadingState}
            selectedKey={inputs?.wellbore_inputs.selected_flow_pressure_type}
            onChange={(v) => {
              onChangeWellboreInput(v, "selected_flow_pressure_type");
            }}
            errors={errorInputValidation}
            keyField="input_options.wellbore_inputs.selected_flow_pressure_type"
            helpUrl={helpLinkUrl.input.wellboreInput}
          />
          <DropdownField
            label={dictionary.tahk.wellboreModel}
            options={mapEnumToDropdown(WellboreModelEnum)}
            disabled={loadingState}
            selectedKey={inputs?.wellbore_inputs.selected_wellbore_model}
            onChange={(v) => {
              onChangeWellboreInput(v, "selected_wellbore_model");
            }}
            errors={errorInputValidation}
            keyField="input_options.wellbore_inputs.selected_wellbore_model"
          />

          {inputs?.wellbore_inputs.selected_wellbore_model === WellboreModelEnum.PumpModel && (
            <>
              <InputField
                keyField="inputs.wellbore_inputs.parameters.pump_depth"
                label={dictionary.tahk.pumpDepth}
                disabled={loadingState}
                value={inputs?.wellbore_inputs.parameters.pump_depth}
                onChange={(v) => onChangeWellboreInputParameter(v, "pump_depth")}
                min={1}
                suffix="ft"
                errors={errorInputValidation}
                helpUrl={helpLinkUrl.input.wellboreInput}
                styles={{
                  root: {
                    width: 250,
                  },
                }}
              />
              <InputField
                keyField="inputs.wellbore_inputs.parameters.minimum_pump_head"
                label={dictionary.tahk.minPumpHead}
                disabled={loadingState}
                value={inputs?.wellbore_inputs.parameters.minimum_pump_head}
                onChange={(v) => onChangeWellboreInputParameter(v, "minimum_pump_head")}
                min={1}
                suffix="ft"
                errors={errorInputValidation}
                helpUrl={helpLinkUrl.input.wellboreInput}
                styles={{
                  root: {
                    width: 250,
                  },
                }}
              />
              <InputField
                keyField="inputs.wellbore_inputs.parameters.initial_liquid_level"
                label={dictionary.tahk.initialLiquidLevel}
                disabled={loadingState}
                value={inputs?.wellbore_inputs.parameters.initial_liquid_level}
                onChange={(v) => onChangeWellboreInputParameter(v, "initial_liquid_level")}
                min={1}
                suffix="ft"
                errors={errorInputValidation}
                styles={{
                  root: {
                    width: 250,
                  },
                }}
              />
              <InputField
                keyField="inputs.wellbore_inputs.parameters.pump_draw_down_rate"
                label={dictionary.tahk.pumpDownRate}
                disabled={loadingState}
                value={inputs?.wellbore_inputs.parameters.pump_draw_down_rate}
                onChange={(v) => onChangeWellboreInputParameter(v, "pump_draw_down_rate")}
                min={1}
                suffix="ft/month"
                errors={errorInputValidation}
                helpUrl={helpLinkUrl.input.wellboreInput}
                styles={{
                  root: {
                    width: 250,
                  },
                }}
              />
            </>
          )}
        </>
      ) : (
        <LoadingIndicator />
      )}
    </div>
  );
};

export default WellboreInput;
