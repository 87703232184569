import React, { useMemo } from "react";

import FossilyticsChart, { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import FossilyticsGrid, { FossilyticsGridColumn } from "@/components/FossilyticsGrid";

import { ModuleGildroHydrateOutput } from "./model";
import { DataSet } from "@/model";
import { transpose } from "@/util";
import useThemeStyling from "@/utils/useThemeStyling";
import LoadingIndicator from "@/components/LoadingIndicator";
import Tabs from "@/components/Tabs";

const dataTableColumns = [
  { key: "depths_ft", type: "number", header: "Depth (ft)" },
  { key: "pressures_psia", type: "number", header: "Pressure (psia)", width: 110 },
  { key: "temperatures_of", type: "number", header: "Temperature (°F)", width: 110 },
] as FossilyticsGridColumn[];

interface ModuleGildroHydrateOutputViewProps {
  isLoading: boolean;
  dataSet: DataSet;
  output: ModuleGildroHydrateOutput | undefined;
}

function ModuleGildroHydrateOutputView({ isLoading, dataSet, output }: ModuleGildroHydrateOutputViewProps) {
  const { palette } = useThemeStyling();

  const pressAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Pressure (psia)", type: "value", color: palette.customColor.black, min: 0 }],
    [palette]
  );

  const pressSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      output
        ? output.cases.map((c, i) => ({
            id: `p${i}`,
            name: "",
            type: "line",
            hideSymbol: true,
            color: palette.primary.main,
            data: c.pressures_psia.map((p, i) => [p, output.depths_ft[i]]),
            markPointData: c.indices.map((i) => [c.pressures_psia[i], output.depths_ft[i]]),
          }))
        : [],
    [palette, output]
  );

  const tempAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: "Temperature (°F)", type: "value", color: palette.customColor.black, min: 0 }],
    [palette]
  );

  const tempSeries = useMemo<FossilyticsChartSeries[]>(
    () =>
      output
        ? output.cases.map((c, i) => ({
            id: `t${i}`,
            name: "",
            type: "line",
            hideSymbol: true,
            color: palette.primary.main,
            data: c.temps_of.map((t, i) => [t, output.depths_ft[i]]),
          }))
        : [],
    [palette, output]
  );

  const yAxes = useMemo<FossilyticsChartAxis[]>(() => {
    return [{ name: "Depth (ft)", type: "value", color: palette.customColor.black, min: 0, inverse: true }];
  }, [palette]);

  const tabList = useMemo(() => {
    return [
      {
        label: <span>Pressure</span>,
        key: "Pressure",
        content: (
          <div
            style={{
              height: 600,
              paddingTop: "1em",
            }}
            className="chartWrapper"
          >
            <FossilyticsChart id="gildro_hydrate_pressure" isLoading={isLoading} xAxes={pressAxes} yAxes={yAxes} series={pressSeries} />
          </div>
        ),
      },
      {
        label: <span>Temperature</span>,
        key: "Temperature",
        content: (
          <div
            style={{
              height: 600,
              paddingTop: "1em",
            }}
            className="chartWrapper"
          >
            <FossilyticsChart id="gildro_hydrate_temp" isLoading={isLoading} xAxes={tempAxes} yAxes={yAxes} series={tempSeries} />
          </div>
        ),
      },
      {
        label: <span>Data table</span>,
        key: "Data table",
        content: (
          <div
            style={{
              height: 600,
              paddingTop: "1em",
            }}
            className="chartWrapper"
          >
            {isLoading ? (
              <LoadingIndicator />
            ) : (
              <div>
                {output?.cases.map((c, index) => (
                  <FossilyticsGrid
                    key={`${c.indices}-${index + 1}-output`}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      overflowY: "auto",
                    }}
                    columns={dataTableColumns}
                    data={transpose([output.depths_ft, c.pressures_psia, c.temps_of])}
                  />
                ))}
              </div>
            )}
          </div>
        ),
      },
    ];
  }, [isLoading, output?.cases, output?.depths_ft, pressAxes, pressSeries, tempAxes, tempSeries, yAxes]);

  return (
    <div>
      <Tabs tabList={tabList} />
    </div>
  );
}

export default ModuleGildroHydrateOutputView;
