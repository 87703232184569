import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

import { ApiError } from "@/models/APIGeneric";
import { parseErrorThrown } from "@/utils/errorHandling";
import { AnalysisFmbResult, AutoFmbState, ForecastFmbResultResponse, postCalculateInputFmb } from "@/models/autoFmb";
import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { FluidType, ModuleIdentity } from "@/models/Generic";
import { InputGridCalculation, inputGridCalculationScheme } from "@/models/InputGeneric";

type useAutoFmbInputProps = {
  setApiError: (err?: ApiError) => void;
  apiError?: ApiError;
  autoFmbState?: AutoFmbState;
  setAutoFmbState: React.Dispatch<React.SetStateAction<AutoFmbState | undefined>>;
  analysisIdentity?: ModuleIdentity;
  tabIndex: number;
  fluidType: FluidType;
  setAutoFmbAnalysisCalculation: React.Dispatch<React.SetStateAction<AnalysisFmbResult | undefined>>;
  setAutoFmbForecastCalculation: React.Dispatch<React.SetStateAction<ForecastFmbResultResponse | undefined>>;
};

const useAutoFmbInput = ({
  apiError,
  setApiError,
  autoFmbState,
  setAutoFmbState,
  analysisIdentity,
  tabIndex,
  fluidType,
  setAutoFmbAnalysisCalculation,
  setAutoFmbForecastCalculation,
}: useAutoFmbInputProps) => {
  const [errorInputValidation, setErrorInputValidation] = useState<ErrorValidationDetail[]>([]);
  const [autoFmbInputCalculation, setAutoFmbInputCalculation] = useState<InputGridCalculation>();

  const { isFetching, isLoading: isLoadingCalculation } = useQuery({
    queryKey: ["input-calculation-fmb", autoFmbState?.inputs, analysisIdentity],
    queryFn: async () => {
      if (autoFmbState?.inputs && analysisIdentity?.data_set_ids && analysisIdentity?.project_id) {
        return postCalculateInputFmb(analysisIdentity?.project_id, fluidType, {
          data_set_ids: analysisIdentity?.data_set_ids ?? [],
          inputs: autoFmbState.inputs,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled:
      tabIndex === 1 &&
      !!autoFmbState?.inputs &&
      !!analysisIdentity?.project_id &&
      !apiError &&
      analysisIdentity?.data_set_ids &&
      analysisIdentity?.data_set_ids.length > 0,
    select(data) {
      try {
        if (data?.data) {
          const parsed = inputGridCalculationScheme.parse(data.data);

          if (!_.isEqual(parsed, autoFmbInputCalculation)) {
            setAutoFmbAnalysisCalculation(undefined);
            setAutoFmbForecastCalculation(undefined);
            setAutoFmbInputCalculation(parsed);
          }
        }
      } catch (error: any) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
          setValidationError: setErrorInputValidation,
        });
      }
      return data?.data;
    },
  });

  const updateInputFmb = useCallback(
    (value: any, key: string, objectKey: string) => {
      if (String(value) === "undefined") return;
      setErrorInputValidation([]);
      setApiError();
      setAutoFmbAnalysisCalculation(undefined);
      setAutoFmbForecastCalculation(undefined);
      setAutoFmbState((prev) => {
        const prevInputs: any = _.cloneDeep(prev);
        if (!prevInputs?.inputs?.[objectKey]) return prevInputs;
        prevInputs.inputs[objectKey] = {
          ...prevInputs.inputs[objectKey],
          [key]: value,
        };
        return prevInputs;
      });
    },
    [setApiError, setAutoFmbState, setAutoFmbAnalysisCalculation, setAutoFmbForecastCalculation]
  );

  const loadingState = isLoadingCalculation || isFetching;

  return { updateInputFmb, errorInputValidation, autoFmbInputCalculation, loadingState };
};

export default useAutoFmbInput;
