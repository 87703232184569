import ReactGridTable from "@/components/ReactGridTable/ReactGridTable";

import { UseModelComponentInputProps } from "../../../hooks/KoldunCsgContext";
import useRelativePermeability from "../../../hooks/input/useRelativePermeability";

const RelativePermeability = (props: UseModelComponentInputProps) => {
  const { relativePermeabilityRows, relativePermeabilityColumns, onChangeRelativePermeabilityCell } = useRelativePermeability(props);
  return (
    <div
      style={{
        padding: 10,
        marginTop: 20,
      }}
      data-testid="relative-permeability-table-container"
    >
      <ReactGridTable
        data-testid="relative-permeability-table"
        innerStyle={{
          display: "flex",
        }}
        viewWidth={"100%"}
        viewHeight={360}
        columns={relativePermeabilityColumns}
        rows={relativePermeabilityRows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        onCellsChanged={onChangeRelativePermeabilityCell}
      />
    </div>
  );
};

export default RelativePermeability;
