import { tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { FluidType } from "@/models/Generic";
import { Column } from "@silevis/reactgrid";

export const forecastEventColumn = [
  { columnId: "dates", width: 90 },
  { columnId: "flowing_rate", width: 80 },
  { columnId: "flowing_pressure", width: 100 },
  { columnId: "bound_condition_is_rate", width: 120 },
] as Column[];

export const autoRtaForecastEventHeader = {
  rowId: "header",
  height: 50,
  cells: [
    {
      type: "header",
      text: "Date",
      style: tableHeaderStyle,
    },
    {
      type: "header",
      text: "Flowing rate",
      style: tableHeaderStyle,
    },
    {
      type: "header",
      text: "Flowing Pressure",
      style: tableHeaderStyle,
    },
    {
      type: "header",
      text: "Controlled value",
      style: tableHeaderStyle,
    },
  ],
};

export const autoRtaForecastEventNotation = (fluidType: FluidType) => ({
  rowId: "notation",
  height: 30,
  cells: [
    {
      type: "header",
      text: "",
      style: tableHeaderNotationStyle,
    },
    {
      type: "header",
      text: fluidType === FluidType.gas ? "MMscf/D" : "STB/D",
      style: tableHeaderNotationStyle,
    },
    {
      type: "header",
      text: "psia",
      style: tableHeaderNotationStyle,
    },
    {
      type: "header",
      text: "",
      style: tableHeaderNotationStyle,
    },
  ],
});

export const forecastGasDataTableHeader = [
  "dates",
  "flowing_pressure",
  "dry_gas_rate",
  "dry_cumulative_gas",
  "recombined_gas_rate",
  "recombined_cumulative_gas",
  "pseudo_time",
  "average_pseudo_pressure",
  "average_pressure",
  "average_viscosity",
  "average_total_compressibility",
];

export const forecastGasDataTableNotation = ["", "psia", "MMscf/D", "MMscf", "MMscf/D", "MMscf", "", "psia", "psia", "cP", "psi⁻¹"];
export const forecastOilDataTableHeader = ["dates", "flowing_pressure", "oil_rate", "cumulative_oil"];
export const forecastOilDataTableNotation = ["", "psia", "bbl/D", "bbl"];

export const fieldGasDataTableHeader = [
  "dates",
  "flowing_pressure",
  "dry_gas_rate",
  "dry_cumulative_gas",
  "recombined_gas_rate",
  "recombined_cumulative_gas",
];
export const fieldGasDataTableNotation = ["", "psia", "MMscf/D", "MMscf", "MMscf/D", "MMscf"];

export const fieldOilDataTableHeader = ["dates", "flowing_pressure", "oil_rate", "cumulative_oil"];
export const fieldOilDataTableNotation = ["", "psia", "bbl/D", "bbl"];

export const normalizedPressureXaxisOptions = [
  { key: "wellbore_radius_normalized_time", text: "tDrw" },
  { key: "area_normalized_time", text: "tDA" },
  { key: "decline_time", text: "tDd" },
];

export const analysisDataColumns = [
  { columnId: "wellbore_radius_normalized_time" },
  { columnId: "area_normalized_time" },
  { columnId: "decline_time" },
  { columnId: "beta_derivative" },
  { columnId: "bourdet_derivative" },
  { columnId: "chow_pressure_group" },
  { columnId: "normalized_pressure" },
  { columnId: "normalized_pressure_integral" },
  { columnId: "normalized_pressure_integral_derivative" },
  { columnId: "decline_rate" },
  { columnId: "decline_rate_integral" },
  { columnId: "decline_rate_integral_derivative" },
  { columnId: "normalized_rate" },
] as Column[];

export const analysisTableHeader: Record<string, string> = {
  wellbore_radius_normalized_time: "tDrw",
  area_normalized_time: "tDA",
  decline_time: "tDd",
  beta_derivative: "pβd",
  bourdet_derivative: "pD'",
  chow_pressure_group: "CPG",
  normalized_pressure: "pD",
  normalized_pressure_integral: "pDi",
  normalized_pressure_integral_derivative: "pDid",
  decline_rate: "qDd",
  decline_rate_integral: "qDdi",
  decline_rate_integral_derivative: "qDdid",
  normalized_rate: "qD",
};

export const autoRtaDefaultState = {
  autoRtaState: null,
  setAutoRtaState: () => {},
  isLoading: false,
  setApiError: () => {},
  setValidationError: () => {},
  fluidType: FluidType.gas,
  validationError: [],

  setIsLoading: () => {},
  setProgress: () => {},
  setPollStatus: () => {},

  analysisCalculation: undefined,
  setAnalysisCalculation: () => {},
  autoRtaForecastCalculation: undefined,
  setAutoRtaForecastCalculation: () => {},
};

export const headerDataTableUnitStyles = { background: "#fff", display: "flex", justifyContent: "center", zIndex: 1 };

export const dataTableColumns = [
  { key: "parameter", label: "Parameter" },
  { key: "value", label: "Value" },
  { key: "units", label: "Units" },
];

export const cleaningSmoothingTypeOptions = [
  { key: "interp", text: "Interpolation" },
  { key: "movavg", text: "Moving average" },
];
