import React, { useCallback } from "react";
import InputField from "@/components/fields/InputField";
import DropdownField from "@/components/fields/DropdownField";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { ModuleGazPzPvtAbnormalOptions } from "./model";
import { FormationCompressibility, RockType } from "../../../model";

const formationCompressibilityOptions = [
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.SANDSTONE}`,
    text: "Newman Sandstone",
    data: {
      formation_compressibility_psi1: FormationCompressibility.NEWMAN,
      formation_rock_type: RockType.SANDSTONE,
    },
  },
  {
    key: `${FormationCompressibility.NEWMAN}-${RockType.LIMESTONE}`,
    text: "Newman Limestone",
    data: {
      formation_compressibility_psi1: FormationCompressibility.NEWMAN,
      formation_rock_type: RockType.LIMESTONE,
    },
  },
  {
    key: `${FormationCompressibility.HALL}-${RockType.SANDSTONE}`,
    text: "Hall General",
    data: {
      formation_compressibility_psi1: FormationCompressibility.HALL,
      formation_rock_type: RockType.SANDSTONE,
    },
  },
];

interface ModuleGazPzAbnormalInputGridProps {
  value: ModuleGazPzPvtAbnormalOptions | undefined;
  onChange: (value: ModuleGazPzPvtAbnormalOptions | undefined) => void;
}

function ModuleGazPzAbnormalInputGrid({ value, onChange }: Readonly<ModuleGazPzAbnormalInputGridProps>) {
  const setDefault = useCallback(() => {
    onChange({
      water_sat_perc: 30,
      formation_compressibility_psi1: FormationCompressibility.NEWMAN,
      formation_rock_type: RockType.SANDSTONE,
    });
  }, [onChange]);

  return (
    <div>
      <FormControlLabel
        control={<Checkbox onChange={(e) => (e.target.checked ? setDefault() : onChange(undefined))} checked={!!value} size="small" />}
        label="Abnormally pressured reservoir"
        disabled
      />

      {value ? (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gridGap: 20,
          }}
        >
          <div>
            <InputField
              label="Water saturation"
              suffix="%"
              min={0}
              max={100}
              value={value.water_sat_perc}
              onChange={(v) =>
                v !== undefined
                  ? onChange({
                      ...value,
                      water_sat_perc: Number(v),
                    })
                  : null
              }
            />
            <InputField
              label="Water compressibility override"
              suffix="psi⁻¹"
              value={value.water_compressibility_override}
              onChange={(v) =>
                onChange({
                  ...value,
                  water_compressibility_override: Number(v),
                })
              }
            />
          </div>

          <div>
            <InputField
              label="Porosity"
              suffix="%"
              min={0}
              max={100}
              disabled={!!value.formation_compressibility_override}
              value={value.porosity_perc}
              onChange={(v) =>
                onChange({
                  ...value,
                  porosity_perc: Number(v),
                })
              }
            />
            <DropdownField
              label="Formation compressibility method"
              options={formationCompressibilityOptions}
              disabled={!!value.formation_compressibility_override}
              selectedKey={`${value.formation_compressibility_psi1}-${value.formation_rock_type}`}
              onChange={(v) => {
                const data = formationCompressibilityOptions.filter((key) => key.key === v);
                if (data?.[0]?.data) {
                  onChange({
                    ...value,
                    ...data[0].data,
                  });
                }
              }}
            />
          </div>

          <div>
            <InputField
              label="Formation compressibility override"
              suffix="psi⁻¹"
              disabled={!!value.porosity_perc}
              value={value.formation_compressibility_override}
              onChange={(v) =>
                onChange({
                  ...value,
                  formation_compressibility_override: Number(v),
                })
              }
            />
          </div>
        </div>
      ) : undefined}
    </div>
  );
}

export default ModuleGazPzAbnormalInputGrid;
