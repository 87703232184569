import { exportTahkCsgForecast } from "@/constants/apiUrl";

import { fetchApi } from "@/utils/apiFetcher";
import { FluidType, ModuleIdentity, Interval } from "@/models/Generic";

type ExportPayload = {
  projectId: string;
  fluid: FluidType;
  body: ModuleIdentity;
  interval: Interval;
};

// csg currently doesnt need fluid, but the api contract have fluid, so will put it for now
export const postExportTahkCsgForecast = ({ body, interval, projectId }: ExportPayload) => {
  return fetchApi<Blob>({
    path: exportTahkCsgForecast(projectId),
    body,
    type: "post",
    responseType: "blob",
    queryStringParameters: {
      interval,
    },
  });
};
