import styled from "@emotion/styled";

export const Container = styled.div`
  padding: 1em;

  display: grid;
  grid-template-columns: 420px 1fr;
  grid-gap: 20px;
  height: 100%;
  overflow: auto;
`;
