import { useEffect, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "./fields/InputField";

interface NewPasswordFieldRequirementProps {
  pass: boolean;
  text: string;
}

function NewPasswordFieldRequirement({ pass, text }: NewPasswordFieldRequirementProps) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {pass ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
      <span>{text}</span>
    </div>
  );
}

interface NewPasswordFieldProps {
  onChange: (password?: string) => void;
}

function NewPasswordField({ onChange }: NewPasswordFieldProps) {
  const [password, setPassword] = useState<string>("");
  const [lengthRqmt, setLengthRqmt] = useState<boolean>(false);
  const [numberRqmt, setNumberRqmt] = useState<boolean>(false);
  const [specialRqmt, setSpecialRqmt] = useState<boolean>(false);
  const [upperRqmt, setUpperRqmt] = useState<boolean>(false);
  const [lowerRqmt, setLowerRqmt] = useState<boolean>(false);

  useEffect(() => {
    const hasValidLength = password.length >= 8;
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[$^*.[\]{}()?\-"!@#%&/\\,<>':;|_~`+=]/.test(password);
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);

    setLengthRqmt(hasValidLength);
    setNumberRqmt(hasNumber);
    setSpecialRqmt(hasSpecialCharacter);
    setUpperRqmt(hasUpperCase);
    setLowerRqmt(hasLowerCase);

    if (password && hasValidLength && hasNumber && hasSpecialCharacter && hasUpperCase && hasLowerCase) {
      onChange(password);
    } else {
      onChange(undefined);
    }
  }, [onChange, password]);

  const handlePasswordChange = (newValue?: string | number) => {
    setPassword(String(newValue) || "");
  };

  return (
    <>
      <InputField
        required
        label="New Password"
        type="password"
        id="new-password"
        dataTestId="new-password"
        value={password}
        onChange={handlePasswordChange}
        debounceDelay={0}
      />

      <div style={{ padding: 10 }}>
        <NewPasswordFieldRequirement pass={lengthRqmt} text="Minimum 8 characters" />
        <NewPasswordFieldRequirement pass={numberRqmt} text="Contains at least 1 number" />
        <NewPasswordFieldRequirement pass={specialRqmt} text="Contains at least 1 special character" />
        <NewPasswordFieldRequirement pass={upperRqmt} text="Contains at least 1 uppercase letter" />
        <NewPasswordFieldRequirement pass={lowerRqmt} text="Contains at least 1 lowercase letter" />
      </div>
    </>
  );
}

export default NewPasswordField;
