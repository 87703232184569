import { useQuery } from "@tanstack/react-query";
import { useCallback, useMemo, useRef, useState } from "react";
import { CellChange, DropdownCell, Row } from "@silevis/reactgrid";
import { useParams } from "react-router-dom";

import { dataviewInitializeScheme, DataviewInitializeState, getDataPreview } from "@/models/dataview";
import { ApiError } from "@/models/APIGeneric";

import { parseErrorThrown } from "@/utils/errorHandling";
import useThemeStyling from "@/utils/useThemeStyling";

import { tableCellStyle, tableHeaderStyle } from "@/components/CustomTable";
import { FossilyticsChartAxis, FossilyticsChartSeries } from "@/components/FossilyticsChart";
import dictionary from "@/constants/dictionary";
import _ from "lodash";

type UseDataviewProps = {
  setApiError: (err?: ApiError) => void;
  apiError?: ApiError;
};

const useDataview = ({ apiError, setApiError }: UseDataviewProps) => {
  const [stateOption, setStateOption] = useState<DataviewInitializeState>();
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);

  const lastDataSet = useRef<string>();
  const { palette } = useThemeStyling();

  const param = useParams();

  const { isLoading: isLoadingPreview, isFetching: isFetchingPreview } = useQuery({
    queryKey: ["preview-dataset", param?.dataSetId],
    queryFn: async () => {
      return getDataPreview(param?.dataSetId ?? "");
    },
    select(data) {
      try {
        if (data?.data) {
          const parsed = dataviewInitializeScheme.parse(data.data);
          if (!_.isEqual(parsed, stateOption) && param?.dataSetId !== lastDataSet.current) {
            lastDataSet.current = param?.dataSetId;
            setStateOption(parsed);
          }
        }
        return data?.data;
      } catch (error: any) {
        console.log(error);
        parseErrorThrown({
          error,
          setApiError,
          apiError,
        });
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!param?.dataSetId,
  });

  const loadingState = isLoadingPreview || isFetchingPreview;

  const dataOptionColumn = useMemo(() => {
    return [
      { columnId: "axis", width: 150 },
      { columnId: "data", width: 180 },
    ];
  }, []);

  const dataOptionRows: Row<any>[] = useMemo(() => {
    const header = [
      {
        rowId: "header",
        cells: [
          {
            type: "custom",
            text: dictionary.dataView.axis,
            style: tableHeaderStyle,
          },
          {
            type: "custom",
            text: dictionary.dataView.data,
            style: tableHeaderStyle,
          },
        ],
        height: 50,
      },
    ];
    if (!stateOption) return header;

    const chartRows = stateOption?.chart_option.map((option, index) => {
      return {
        rowId: index,
        height: 30,
        cells: [
          {
            type: "dropdown",
            selectedValue: String(option.axis),
            values: Array.from(Array(4).keys()).map((index) => ({
              label: String(index + 1),
              value: String(index + 1),
            })),
            style: tableCellStyle,
            isOpen: !!(activeDropdown && index + 1 === activeDropdown),
            nonEditable: loadingState,
          },
          {
            type: "text",
            text: option.label,
            nonEditable: true,
            style: tableCellStyle,
          },
        ],
      };
    });

    return [...header, ...chartRows];
  }, [activeDropdown, loadingState, stateOption]);

  const onCellsChanged = useCallback(
    (changes: CellChange[]) => {
      if (!stateOption?.chart_option) return;
      const updatedDataOption: any = [...stateOption.chart_option];
      let haveChanged = false;
      for (const element of changes) {
        const change = element;
        let { rowId, columnId, newCell, previousCell } = change as CellChange<any>;
        const prevCell = previousCell as DropdownCell;
        const dropDownNewCell = newCell as DropdownCell;

        rowId = rowId as number;
        columnId = columnId as string;
        if (prevCell.isOpen !== dropDownNewCell.isOpen || rowId !== Number(activeDropdown) + 1) {
          if (dropDownNewCell.isOpen) {
            setActiveDropdown(rowId + 1);
          } else {
            setActiveDropdown(null);
          }
        }
        if (dropDownNewCell.isOpen) return;
        if (String(prevCell.selectedValue) !== dropDownNewCell.selectedValue) {
          updatedDataOption[rowId].axis = Number(dropDownNewCell.selectedValue);
          haveChanged = true;
        }
      }

      if (haveChanged) {
        setStateOption((prev) => {
          if (!prev?.chart_option) return prev;
          return {
            ...prev,
            chart_option: _.cloneDeep(updatedDataOption),
          };
        });
      }
    },
    [activeDropdown, stateOption?.chart_option]
  );

  const getColor = useCallback(
    (name: string) => {
      const lowercaseName = name.toLocaleLowerCase();
      if (lowercaseName.includes("oil")) return palette.customColor.green;
      if (lowercaseName.includes("water")) return palette.customColor.blue;
      if (lowercaseName.includes("pressure")) return palette.common.black;
      return palette.customColor.red;
    },
    [palette.common.black, palette.customColor.blue, palette.customColor.green, palette.customColor.red]
  );

  const yAxes = useMemo<FossilyticsChartAxis[]>(() => {
    let result: FossilyticsChartAxis[] = [];
    if (!stateOption) return result;

    _.cloneDeep(stateOption?.chart_option ?? [])
      .sort((a, b) => Number(a.axis) - Number(b.axis))
      .forEach((item) => {
        const currentSerieUnit = stateOption.data_result?.series.filter((serie) => serie.label === item.label)[0];

        if (currentSerieUnit) {
          const position = Number(item.axis) > 2 ? "right" : "left";

          if (result[item.axis - 1]) {
            const prevName = result[item.axis - 1].name.split(", ");
            result[item.axis - 1] = {
              ...result[item.axis - 1],
              name: prevName[prevName.length - 1] === currentSerieUnit.unit ? currentSerieUnit.unit : "",
              color: getColor(currentSerieUnit.label),
            };
          } else {
            result[item.axis - 1] = {
              name: currentSerieUnit.unit ?? "",
              type: "value",
              color: getColor(currentSerieUnit.label),
              position,
              nameGap: 30,
              offset: 0,
              axis: item.axis ?? 0,
              data: item.axis,
            };
          }
        }
      });

    result = result.filter((res) => !!res);

    const totalPosLeft = result.filter((res) => res.position === "left").length;
    const totalPosRight = result.length - totalPosLeft;

    if (totalPosLeft > 1) {
      result[result.findIndex((res) => res.position === "left")].offset = 75;
    }
    if (totalPosRight > 1) {
      // @ts-ignore
      result[result?.findLastIndex((res: FossilyticsChartAxis) => res.position === "right")].offset = 75;
    }
    return result;
  }, [getColor, stateOption]);

  const xAxes = useMemo<FossilyticsChartAxis[]>(
    () => [{ name: `Date`, type: "time", color: palette.customColor.black }],
    [palette.customColor.black]
  );

  const series = useMemo<FossilyticsChartSeries[]>(() => {
    if (yAxes.length === 0 || !stateOption?.chart_option) return [];
    return stateOption.data_result.series.map((serie) => {
      const chartAxis = stateOption.chart_option.find((option) => option.label === serie.label);
      const axis = yAxes.findIndex((axes) => axes.data === chartAxis?.axis);

      return {
        name: serie.label,
        type: "line",
        color: getColor(serie.label),
        z: 0,
        data: stateOption.data_result.dates.map((date, index) => [new Date(date), serie.data[index]]),
        yAxisIndex: axis < 0 ? 0 : axis,
      };
    });
  }, [getColor, stateOption?.chart_option, stateOption?.data_result.dates, stateOption?.data_result.series, yAxes]);

  const dataColumns = useMemo(() => {
    if (!stateOption?.data_result?.series) return [];
    return [
      { columnId: "date", width: 200 },
      ...stateOption.data_result.series.map((item) => {
        return {
          columnId: item.label,
          width: 200,
        };
      }),
    ];
  }, [stateOption?.data_result.series]);

  const dataRows: Row<any>[] = useMemo(() => {
    if (!stateOption?.data_result?.series || !stateOption?.data_result?.dates) return [];

    return [
      {
        rowId: "header",
        cells: [
          {
            type: "text",
            text: `${dictionary.dataView.date}`,
            style: tableHeaderStyle,
            nonEditable: true,
          },
          ...stateOption.data_result.series.map((item) => {
            return {
              type: "text",
              text: `${item.label} (${item.unit})`,
              style: tableHeaderStyle,
            };
          }),
        ],
        height: 50,
      },
      ...stateOption.data_result.dates.map((date, index) => {
        return {
          rowId: index,
          height: 30,
          cells: [
            {
              type: "text",
              text: `${new Date(date).toLocaleDateString()}`,
              style: tableCellStyle,
            },
            ...stateOption.data_result.series.map((item) => {
              return {
                type: "text",
                text: `${item.data[index]}`,
                style: tableCellStyle,
              };
            }),
          ],
        };
      }),
    ];
  }, [stateOption?.data_result.dates, stateOption?.data_result.series]);

  return {
    loadingState,
    dataOptionColumn,
    dataOptionRows,
    onCellsChanged,
    yAxes,
    xAxes,
    series,
    dataRows,
    dataColumns,
  };
};

export default useDataview;
