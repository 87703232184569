import { Button } from "@mui/material";

import DropdownField from "@/components/fields/DropdownField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import InputField from "@/components/fields/InputField";
import LoadingIndicator from "@/components/LoadingIndicator";

import useThemeStyling from "@/utils/useThemeStyling";
import { formatNumber } from "@/util";
import { mapEnumToDropdown } from "@/utils/general";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { AutoRtaAnalysis, DualPorosityModel, TransientModel, TransientModelBoundaryConditions } from "@/models/gaz/autoRta";
import { SummaryCard } from "@/models/Generic";

import { dataTableColumns } from "../../constants";
import dictionary from "@/constants/dictionary";
import SummaryTable from "@/components/SummaryTable";
import CustomCard from "@/components/Card";
import helpLinkUrl from "@/constants/helpLinkUrl";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export type AnalysisSettingProps = {
  onChangeInput: (value: any, firstKey: string, secKey?: string, thirdKey?: string, fourthKey?: string) => void;
  analysisInput: AutoRtaAnalysis;
  isLoading: boolean;
  errorInputValidation?: ErrorValidationDetail[];
  analysisSummaryCardParameter?: SummaryCard[];
  onClickCalculateAnalysis: () => void;
  canCancelPoll: boolean;
  onCancelPoll: () => {};
};

const AnalysisSetting = ({
  onChangeInput,
  analysisInput,
  isLoading,
  analysisSummaryCardParameter = [],
  errorInputValidation,
  onClickCalculateAnalysis,
  canCancelPoll,
  onCancelPoll,
}: AnalysisSettingProps) => {
  const { palette } = useThemeStyling();

  const disabledInput = analysisInput.smart_fit || isLoading;

  const renderDrainageOption = () => {
    switch (analysisInput.transient_flow_model.section_transient_model_type.selected_transient_model) {
      case TransientModel.Radial:
        return (
          <InputField
            label="Drainage Radius"
            suffix={`ft`}
            debounceDelay={20}
            disabled={disabledInput}
            value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_radial.drainage_radius}
            onChange={(v) => {
              onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_radial", "drainage_radius");
            }}
            keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_radial.drainage_radius"
            errors={errorInputValidation}
          />
        );
      case TransientModel.Linear:
        return (
          <>
            <InputField
              label="Drainage Length"
              suffix={`ft`}
              debounceDelay={20}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_length}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_linear", "drainage_length");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_length"
              errors={errorInputValidation}
            />
            <InputField
              label="Drainage Width"
              suffix={`ft`}
              debounceDelay={20}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_width}
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_linear", "drainage_width");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_linear.drainage_width"
              errors={errorInputValidation}
            />
          </>
        );
      case TransientModel.UniformFluxFracture:
        return (
          <>
            <InputField
              label="Drainage Radius"
              suffix={`ft`}
              debounceDelay={20}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_uniform_flux_fracture.drainage_radius}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_uniform_flux_fracture", "drainage_radius");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_uniform_flux_fracture.drainage_radius"
              errors={errorInputValidation}
            />
            <InputField
              label="Fracture Half Length"
              suffix={`ft`}
              debounceDelay={20}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_uniform_flux_fracture.fracture_half_length}
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_uniform_flux_fracture",
                  "fracture_half_length"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_uniform_flux_fracture.fracture_half_length"
              errors={errorInputValidation}
            />
          </>
        );
      case TransientModel.FiniteConductivityFracture:
        return (
          <>
            <InputField
              label="Drainage Radius"
              suffix={`ft`}
              debounceDelay={20}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_finite_conductivity_fracture.drainage_radius}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_finite_conductivity_fracture",
                  "drainage_radius"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_finite_conductivity_fracture.drainage_radius"
              errors={errorInputValidation}
            />
            <InputField
              label="Fracture Half Length"
              suffix={`ft`}
              debounceDelay={20}
              value={
                analysisInput.transient_flow_model.section_transient_model_type.transient_model_finite_conductivity_fracture.fracture_half_length
              }
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_finite_conductivity_fracture",
                  "fracture_half_length"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_finite_conductivity_fracture.fracture_half_length"
              errors={errorInputValidation}
            />
            <InputField
              label="Dimensionless Fracture Conductivity"
              debounceDelay={20}
              suffix="Dim"
              value={
                analysisInput.transient_flow_model.section_transient_model_type.transient_model_finite_conductivity_fracture
                  .dimensionless_fracture_conductivity
              }
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_finite_conductivity_fracture",
                  "dimensionless_fracture_conductivity"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_finite_conductivity_fracture.dimensionless_fracture_conductivity"
              errors={errorInputValidation}
            />
          </>
        );
      case TransientModel.MultiFractureHorizontalRadialReservoir:
        return (
          <>
            <InputField
              label="Drainage Radius"
              suffix={`ft`}
              debounceDelay={20}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_multi_fracture_horizontal_radial.drainage_radius}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_multi_fracture_horizontal_radial",
                  "drainage_radius"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_multi_fracture_horizontal_radial.drainage_radius"
              errors={errorInputValidation}
            />
            <InputField
              label="Fracture Half Length"
              suffix={`ft`}
              debounceDelay={20}
              value={
                analysisInput.transient_flow_model.section_transient_model_type.transient_model_multi_fracture_horizontal_radial.fracture_half_length
              }
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_multi_fracture_horizontal_radial",
                  "fracture_half_length"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_multi_fracture_horizontal_radial.fracture_half_length"
              errors={errorInputValidation}
            />
            <InputField
              label="Horizontal Length"
              debounceDelay={20}
              suffix="ft"
              value={
                analysisInput.transient_flow_model.section_transient_model_type.transient_model_multi_fracture_horizontal_radial.horizontal_length
              }
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_multi_fracture_horizontal_radial",
                  "horizontal_length"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_multi_fracture_horizontal_radial.horizontal_length"
              errors={errorInputValidation}
            />
            <InputField
              label="Number Fractures"
              debounceDelay={20}
              value={
                analysisInput.transient_flow_model.section_transient_model_type.transient_model_multi_fracture_horizontal_radial.number_fractures
              }
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_multi_fracture_horizontal_radial",
                  "number_fractures"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_multi_fracture_horizontal_radial.number_fractures"
              errors={errorInputValidation}
            />
          </>
        );

      default:
        return (
          <>
            <InputField
              label="Drainage Radius"
              suffix={`ft`}
              debounceDelay={100}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_composite_radial.drainage_radius}
              disabled={disabledInput}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_composite_radial", "drainage_radius");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_composite_radial.drainage_radius"
              errors={errorInputValidation}
            />

            <InputField
              label="Inner region Radius"
              suffix={`ft`}
              debounceDelay={100}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_radius}
              onChange={(v) => {
                onChangeInput(v, "transient_flow_model", "section_transient_model_type", "transient_model_composite_radial", "inner_region_radius");
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_radius"
              errors={errorInputValidation}
            />

            <InputField
              label="Inner region Permeability"
              suffix={`ft`}
              debounceDelay={100}
              disabled={disabledInput}
              value={analysisInput.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_permeability}
              onChange={(v) => {
                onChangeInput(
                  v,
                  "transient_flow_model",
                  "section_transient_model_type",
                  "transient_model_composite_radial",
                  "inner_region_permeability"
                );
              }}
              keyField="analysis.transient_flow_model.section_transient_model_type.transient_model_composite_radial.inner_region_permeability"
              errors={errorInputValidation}
            />
          </>
        );
    }
  };

  return (
    <CustomCard
      style={{
        height: "100%",
        marginBottom: 20,
        overflow: "auto",
      }}
    >
      <h3 style={{ color: palette.primary.main, margin: 0 }}>
        <a
          target="_blank"
          rel="noreferrer"
          href={helpLinkUrl.gasAutoRta.transientAnalysis["transientAnalysis"]}
          style={{
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Transient Analysis
          <InfoOutlinedIcon fontSize="small" />
        </a>
      </h3>

      <FormControlLabel
        control={
          analysisInput ? (
            <Checkbox
              onChange={(e) => {
                onChangeInput(e.target.checked, "smart_fit");
              }}
              checked={analysisInput.smart_fit}
              size="small"
              disabled={isLoading}
            />
          ) : (
            <></>
          )
        }
        label="Smart Fit"
      />

      <DropdownField
        label="Model"
        options={mapEnumToDropdown(TransientModel)}
        selectedKey={analysisInput.transient_flow_model.section_transient_model_type.selected_transient_model}
        disabled={disabledInput}
        onChange={(v) => {
          onChangeInput(v, "transient_flow_model", "section_transient_model_type", "selected_transient_model");
        }}
        errors={errorInputValidation}
        keyField="analysis.transient_flow_model.section_transient_model_type.selected_transient_model"
        helpUrl={helpLinkUrl.gasAutoRta.transientAnalysis["transientModel"]}
      />

      {renderDrainageOption()}

      <DropdownField
        label="Boundary Condition"
        options={mapEnumToDropdown(TransientModelBoundaryConditions)}
        selectedKey={analysisInput.transient_flow_model.selected_boundary_condition}
        disabled={disabledInput}
        onChange={(v) => {
          onChangeInput(v, "transient_flow_model", "selected_boundary_condition");
        }}
        errors={errorInputValidation}
        keyField="analysis.transient_flow_model.selected_boundary_condition"
      />

      <InputField
        label="Skin"
        suffix="Dim"
        debounceDelay={100}
        disabled={disabledInput}
        value={analysisInput.transient_flow_model.skin}
        onChange={(v) => {
          onChangeInput(v, "transient_flow_model", "skin");
        }}
        keyField="analysis.transient_flow_model.skin"
        errors={errorInputValidation}
        helpUrl={helpLinkUrl.gasAutoRta.transientAnalysis["skin"]}
      />

      <InputField
        label="Permeability"
        suffix="mD"
        debounceDelay={100}
        value={analysisInput.transient_flow_model.permeability}
        disabled={disabledInput}
        onChange={(v) => {
          onChangeInput(v, "transient_flow_model", "permeability");
        }}
        keyField="analysis.transient_flow_model.permeability"
        errors={errorInputValidation}
        helpUrl={helpLinkUrl.gasAutoRta.transientAnalysis["permeability"]}
      />

      <DropdownField
        label="Dual Porosity Model"
        options={mapEnumToDropdown(DualPorosityModel)}
        selectedKey={analysisInput.transient_flow_model.section_dual_porosity.selected_dual_porosity_model}
        disabled={disabledInput}
        onChange={(v) => {
          onChangeInput(v, "transient_flow_model", "section_dual_porosity", "selected_dual_porosity_model");
        }}
        errors={errorInputValidation}
        keyField="analysis.transient_flow_model.section_dual_porosity.selected_dual_porosity_model"
        helpUrl={helpLinkUrl.gasAutoRta.transientAnalysis["dualPorosityModel"]}
      />
      {analysisInput.transient_flow_model.section_dual_porosity.selected_dual_porosity_model !== "NoModel" && (
        <>
          <InputField
            label="Storage Capacity Ratio"
            suffix="dim"
            debounceDelay={100}
            disabled={disabledInput}
            value={analysisInput.transient_flow_model.section_dual_porosity.storage_capacity_ratio}
            onChange={(v) => {
              onChangeInput(v, "transient_flow_model", "section_dual_porosity", "storage_capacity_ratio");
            }}
            keyField="analysis.transient_flow_model.section_dual_porosity.storage_capacity_ratio"
            errors={errorInputValidation}
          />

          <InputField
            label="Inter-Porosity Flow Parameter"
            suffix="Dim"
            debounceDelay={100}
            disabled={disabledInput}
            value={analysisInput.transient_flow_model.section_dual_porosity.interpososity_flow_parameter}
            onChange={(v) => {
              onChangeInput(v, "transient_flow_model", "section_dual_porosity", "interpososity_flow_parameter");
            }}
            keyField="analysis.transient_flow_model.section_dual_porosity.interpososity_flow_parameter"
            errors={errorInputValidation}
          />
        </>
      )}

      <Button
        onClick={(e) => {
          e.preventDefault();
          canCancelPoll ? onCancelPoll() : onClickCalculateAnalysis();
        }}
        disabled={!canCancelPoll ? isLoading : false}
        style={{ color: "white", marginTop: 20, width: "100%" }}
        variant="contained"
      >
        {canCancelPoll ? dictionary.genericButton.cancel : dictionary.autoRta.calculateAnalysis}
      </Button>
      <div style={{ marginTop: 30, width: 400 }}>
        <div style={{ color: palette.primary.main }}>Summary</div>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <SummaryTable
            rows={analysisSummaryCardParameter.map((item: any, i) => {
              return {
                parameter: item.parameter,
                value: formatNumber(item.value),
                units: item.unit,
              };
            })}
            headers={dataTableColumns}
          />
        )}
      </div>
    </CustomCard>
  );
};

export default AnalysisSetting;
