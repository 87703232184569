import { CSSProperties } from "react";
import Latex from "react-latex-next";

import styled from "@emotion/styled";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import { Cell, CellTemplate, Column, ReactGrid, Row, Compatible, Uncertain, getCellProperty, ReactGridProps } from "@silevis/reactgrid";
import LoadingIndicator from "@/components/LoadingIndicator";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import "./index.css";
import { colorPalette } from "@/features/themes/mui-theme/mui-theme";

const StyledLink = styled.a`
  color: black;
  text-decoration: none;

  display: block;
  align-items: center;

  label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 0px;
  }

  svg {
    margin-left: 3px;
    width: 14px;
    height: 14px;
  }
  &:hover {
    svg {
      color: ${colorPalette.primary.main};
    }
    label {
      color: ${colorPalette.primary.main};
    }
  }
`;

export type CustomTableProps = {
  dataTestId?: string;
  columns: Column[];
  rows: Row[];
  isLoading?: boolean;
  style?: CSSProperties;
  noScrollable?: boolean;
  [key: string]: any;
} & ReactGridProps;

export interface CustomCell extends Cell {
  type: "custom";
  text: string;
  sub?: string;
  link?: string;
  onClick?: () => void;
  isLatex?: boolean;
}

// generic table header
export const tableHeaderStyle = {
  background: "#fbdcb9",
  display: "flex",
  justifyContent: "center",
  fontWeight: "600",
  border: {
    bottom: {
      width: "0px",
    },
  },
  textOverflow: "ellipsis",
  whiteSpace: "break-spaces",
  textAlign: "center",
  padding: 10,
};

export const mergedHeaderLeft = {
  ...tableHeaderStyle,
  border: {
    bottom: {
      width: "0px",
    },
    right: {
      width: "0px",
    },
  },
  justifyContent: "flex-end",
};

export const mergedHeaderRight = {
  ...tableHeaderStyle,
  border: {
    bottom: {
      width: "0px",
    },
  },
  justifyContent: "flex-start",
  paddingLeft: "0px",
};

// generic table notation
export const tableHeaderNotationStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  border: {
    bottom: {
      width: "3px",
    },
  },
};

// generic table cell style
export const tableCellStyle = {
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
};

export const tableCellStyleDisabled = {
  background: "#e1dfdd",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
};

// custom class so we can put in subscript in table header
export class CustomCellTemplate implements CellTemplate<CustomCell> {
  getCompatibleCell(uncertainCell: Uncertain<CustomCell>): Compatible<CustomCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }
  render(cell: Compatible<CustomCell>): React.ReactNode {
    if (cell.isLatex) {
      return <Latex>${cell.text}$</Latex>;
    }

    if (cell.link) {
      return (
        <StyledLink href={cell.link} target="_blank" rel="noreferrer">
          <label>
            {cell.text} <sub>{cell.sub}</sub>
          </label>

          <InfoOutlinedIcon fontSize="small" />
        </StyledLink>
      );
    }

    if (cell.onClick) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignContent: "center",
          }}
          onKeyDown={() => {
            /* no-op*/
          }}
          onClick={cell.onClick}
        >
          {cell.text}
          <SwapVertIcon
            style={{
              float: "right",
            }}
            fontSize="small"
          />
        </div>
      );
    }

    return (
      <div>
        {cell.text}
        <sub>{cell.sub}</sub>
      </div>
    );
  }
}

const CustomDataTable = ({ columns, rows, isLoading, style, noScrollable, className, dataTestId, ...props }: CustomTableProps) => {
  if (isLoading) return <LoadingIndicator />;

  if (noScrollable) {
    return (
      <div data-testid={dataTestId}>
        <ReactGrid
          data-testid={dataTestId}
          customCellTemplates={{ custom: new CustomCellTemplate() }}
          columns={columns}
          rows={rows}
          enableRangeSelection
          enableColumnSelection
          stickyTopRows={2}
          {...props}
        />
      </div>
    );
  }

  return (
    <div
      data-testid={dataTestId}
      style={{
        overflow: "auto",
        maxHeight: 500,
        marginTop: 20,
        ...style,
      }}
      className={`noScrollbar ${className} `}
    >
      <ReactGrid
        customCellTemplates={{ custom: new CustomCellTemplate() }}
        columns={columns}
        rows={rows}
        enableRangeSelection
        enableColumnSelection
        stickyTopRows={2}
        {...props}
      />
    </div>
  );
};

export default CustomDataTable;
