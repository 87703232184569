import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { GasPvt, GasPvtDataTable } from "@/models/InputGeneric";

import InputSectionGasPvt from "./components/InputSectionGasPvt";
import ChartSectionGasPvt from "./components/ChartSectionGasPvt";

export type InputGasPvtProps = {
  gasPvtInput?: GasPvt;
  isLoading: boolean;
  updateInputValue: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
  gasPvtCalculation?: GasPvtDataTable;
};

const InputGasPvt = ({ isLoading, updateInputValue, validationError, gasPvtCalculation, gasPvtInput }: InputGasPvtProps) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 3fr",
        gridGap: 20,
      }}
    >
      <InputSectionGasPvt validationError={validationError} gasPvtInput={gasPvtInput} isLoading={isLoading} updateInputValue={updateInputValue} />
      <ChartSectionGasPvt gasPvtCalculation={gasPvtCalculation} isLoading={isLoading} />
    </div>
  );
};

export default InputGasPvt;
