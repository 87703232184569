import { object, z } from "zod";
import { messagesSchema, TahkCsgInputResponseScheme } from "../input";
import { TahkCsgDataOption, TahkCsgValidationState, TahkCsgValidationStateScheme } from "../State";

import { fetchApi } from "@/utils/apiFetcher";
import { validateTahkCsgInput } from "@/constants/apiUrl";

export type ValidateInputPayload = {
  options: TahkCsgValidationState;
} & TahkCsgDataOption;

export const InputApiStateScheme = object({
  inputs: TahkCsgValidationStateScheme,
  calculation: TahkCsgInputResponseScheme,
  messages: messagesSchema.array().nullable(),
});

export type InputApiState = z.infer<typeof InputApiStateScheme>;

export const postValidateInput = (payload: ValidateInputPayload) => {
  return fetchApi<InputApiState>({
    path: validateTahkCsgInput(payload.data_options.analysis_identity.project_id),
    body: payload,
    type: "post",
  });
};
