import React, { useMemo } from "react";

import InputField from "@/components/fields/InputField";
import FossilyticsChart from "@/components/FossilyticsChart";
import CustomDataTable from "@/components/CustomTable";
import Tabs from "@/components/Tabs";
import DropdownField from "@/components/fields/DropdownField";

import { ErrorValidationDetail } from "@/models/ErrorInputValidation";
import { OilDensityCorrelation, OilViscosityCorrelation, SolutionGasOilCorrelation, OilPvt, OilPvtDataTable } from "@/models/InputGeneric";
import { mapEnumToDropdown } from "@/utils/general";
import dictionary from "@/constants/dictionary";
import useOilPvt from "./hooks/useOilPvt";
import helpLinkUrl from "@/constants/helpLinkUrl";

type InputOilPvtProps = {
  oilPvtInput?: OilPvt;
  isLoading: boolean;
  updateInputValue: (val: any, key: string, objectKey: string) => void;
  validationError: ErrorValidationDetail[];
  oilPvtCalculation?: OilPvtDataTable;
};

const InputOilPvt = ({ isLoading, updateInputValue, validationError, oilPvtCalculation, oilPvtInput }: InputOilPvtProps) => {
  const { oilPvtBo, oilPvtMu, oilPvtpo, oilPvtRs, oilPvtSigma, oilPvtCo, oilPvtDataTableColumns, oilPvtDataTableRows } = useOilPvt({
    oilPvtCalculation,
  });

  const tabList = useMemo(() => {
    return [
      {
        label: (
          <span style={{ fontSize: 16 }}>
            μ<sub>{"o"}</sub>
          </span>
        ),
        key: "μo",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="oil_pvt_mo"
              isLoading={isLoading}
              xAxes={oilPvtMu.xAxes}
              yAxes={oilPvtMu.yAxes}
              series={oilPvtMu.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            p<sub>{"o"}</sub>
          </span>
        ),
        key: "po",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="Oil_pvt_po"
              isLoading={isLoading}
              xAxes={oilPvtpo.xAxes}
              yAxes={oilPvtpo.yAxes}
              series={oilPvtpo.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            B<sub>{"o"}</sub>
          </span>
        ),
        key: "Bo",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="oil_pvt_bo"
              isLoading={isLoading}
              xAxes={oilPvtBo.xAxes}
              yAxes={oilPvtBo.yAxes}
              series={oilPvtBo.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            R<sub>{"s"}</sub>
          </span>
        ),
        key: "Ro",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="Oil_pvt_po"
              isLoading={isLoading}
              xAxes={oilPvtRs.xAxes}
              yAxes={oilPvtRs.yAxes}
              series={oilPvtRs.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            c<sub>{"o"}</sub>
          </span>
        ),
        key: "co",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart id="Oil_pvt_co" isLoading={false} xAxes={oilPvtCo.xAxes} yAxes={oilPvtCo.yAxes} series={oilPvtCo.series} hideLegend />
          </div>
        ),
      },
      {
        label: (
          <span style={{ fontSize: 16 }}>
            σ<sub>{"o"}</sub>
          </span>
        ),
        key: "oo",
        content: (
          <div className="chartWrapper">
            <FossilyticsChart
              id="Oil_pvt_sigma"
              isLoading={false}
              xAxes={oilPvtSigma.xAxes}
              yAxes={oilPvtSigma.yAxes}
              series={oilPvtSigma.series}
              hideLegend
            />
          </div>
        ),
      },
      {
        label: <span style={{ fontSize: 16 }}>Data Table</span>,
        key: "data table oil",
        content: (
          <div className="chartWrapper">
            <CustomDataTable rows={oilPvtDataTableRows} columns={oilPvtDataTableColumns} isLoading={isLoading} />
          </div>
        ),
      },
    ];
  }, [isLoading, oilPvtBo, oilPvtCo, oilPvtDataTableColumns, oilPvtDataTableRows, oilPvtMu, oilPvtRs, oilPvtSigma, oilPvtpo]);

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 3fr",
        gridGap: 20,
      }}
    >
      <div>
        <InputField
          label={dictionary.inputPage.oilGravityApi}
          suffix="°"
          debounceDelay={1000}
          value={oilPvtInput?.oil_gravity_api}
          onChange={(v) => updateInputValue(v, "oil_gravity_api", "oil_pvt")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.oil_pvt.oil_gravity_api"
          helpUrl={helpLinkUrl.gasAutoRta.modelComponents["oilGravityAPI"]}
        />
        <InputField
          label={dictionary.inputPage.oilBubblePressure}
          suffix="psia"
          debounceDelay={1000}
          value={oilPvtInput?.bubble_pressure}
          onChange={(v) => updateInputValue(v, "bubble_pressure", "oil_pvt")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.oil_pvt.bubble_pressure"
        />
        <InputField
          label={dictionary.inputPage.solutionGasSpecificGravity}
          suffix="dim"
          debounceDelay={1000}
          value={oilPvtInput?.solution_gas_specific_gravity}
          onChange={(v) => updateInputValue(v, "solution_gas_specific_gravity", "oil_pvt")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.oil_pvt.solution_gas_specific_gravity"
        />
        <DropdownField
          label={dictionary.inputPage.oilDensityCorrelation}
          options={mapEnumToDropdown(OilDensityCorrelation)}
          selectedKey={oilPvtInput?.selected_oil_density_correlation}
          onChange={(v) => updateInputValue(v, "selected_oil_density_correlation", "oil_pvt")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.oil_pvt.selected_oil_density_correlation"
        />
        <DropdownField
          label={dictionary.inputPage.oilViscosityCorrelation}
          options={mapEnumToDropdown(OilViscosityCorrelation)}
          selectedKey={oilPvtInput?.selected_oil_viscosity_correlation}
          onChange={(v) => updateInputValue(v, "selected_oil_viscosity_correlation", "oil_pvt")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.oil_pvt.selected_oil_viscosity_correlation"
        />
        <DropdownField
          label={dictionary.inputPage.solutionRatioCorrelation}
          options={mapEnumToDropdown(SolutionGasOilCorrelation)}
          selectedKey={oilPvtInput?.selected_solution_gas_oil_correlation}
          onChange={(v) => updateInputValue(v, "selected_solution_gas_oil_correlation", "oil_pvt")}
          disabled={isLoading}
          errors={validationError}
          keyField="inputs.oil_pvt.selected_solution_gas_oil_correlation"
        />
      </div>
      <Tabs tabList={tabList} centered />
    </div>
  );
};

export default InputOilPvt;
