import { Column, Row } from "@silevis/reactgrid";
import { useMemo } from "react";

import { WaterPvtDataTable } from "@/models/InputGeneric";
import { PvtTableHeaderMap } from "@/models/Generic";
import { CustomCell, tableHeaderNotationStyle, tableHeaderStyle } from "@/components/CustomTable";
import { formatToScientific } from "@/utils/general";
import dictionary from "@/constants/dictionary";
import useChartInfo from "@/utils/useChartInfo";
import useThemeStyling from "@/utils/useThemeStyling";

const waterPvtHeaderUnitMapping: { [key: string]: PvtTableHeaderMap } = {
  pressure: { name: "Pressure", unit: "(psia)" },
  viscosity: { name: "μ", unit: "cP", sub: "w" },
  density: { name: `p`, unit: "lbm/ft³", sub: "w" },
  form_vol_factor: { name: "B", unit: "(rb/stb)", sub: "w" },
  compressibility: { name: "c", unit: "(psi⁻¹)", sub: "w" },
};

const waterPvtDataTableCols = [
  { columnId: "pressure", width: 80 },
  { columnId: "compressibility", width: 80, justifyContent: "center" },
  { columnId: "density", width: 80, justifyContent: "center" },
  { columnId: "form_vol_factor", width: 80, justifyContent: "center" },
  { columnId: "viscosity", width: 80, justifyContent: "center" },
] as Column[];

type UseWaterPvtProps = {
  waterPvtCalculation?: WaterPvtDataTable;
};

const useWaterPvt = ({ waterPvtCalculation }: UseWaterPvtProps) => {
  const { palette } = useThemeStyling();
  const waterPvtDataTableRows = useMemo<Row[]>(() => {
    if (!waterPvtCalculation) return [];

    const headerRow: Row<CustomCell> = {
      rowId: "header",
      cells: Object.values(waterPvtHeaderUnitMapping).map(({ name, sub }) => ({
        type: "custom",
        text: name,
        style: tableHeaderStyle,
        sub,
      })),
    };

    const unitsRow: Row = {
      rowId: "units",
      cells: Object.values(waterPvtHeaderUnitMapping).map(({ unit }) => ({
        type: "header",
        text: unit,
        style: tableHeaderNotationStyle,
      })),
    };

    return [
      headerRow,
      unitsRow,
      ...waterPvtCalculation.density.map((_, j) => ({
        rowId: j,
        cells: Object.keys(waterPvtHeaderUnitMapping).map((key) => {
          return {
            type: "text",
            text: formatToScientific(waterPvtCalculation[key as keyof WaterPvtDataTable][j]),
            style: { display: "flex", justifyContent: "center" },
            nonEditable: true,
          };
        }),
      })),
    ] as Row[];
  }, [waterPvtCalculation]);

  const waterPvtMu = useChartInfo({
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.viscosity,
    seriesName: dictionary.inputPage.waterMu,
    chartPlot: waterPvtCalculation ? waterPvtCalculation.pressure.map((p, j) => [p, waterPvtCalculation.viscosity[j]]) : [],
    seriesColor: palette.customColor.blueLight,
  });
  const waterPvtPw = useChartInfo({
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.density,
    seriesName: dictionary.inputPage.waterPw,
    chartPlot: waterPvtCalculation ? waterPvtCalculation.pressure.map((p, j) => [p, waterPvtCalculation.density[j]]) : [],
    seriesColor: palette.customColor.blueLight,
  });
  const waterPvtBw = useChartInfo({
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.formVolFactorLiquid,
    seriesName: dictionary.inputPage.waterBw,
    seriesColor: palette.customColor.blueLight,
    chartPlot: waterPvtCalculation ? waterPvtCalculation.pressure.map((p, j) => [p, waterPvtCalculation.form_vol_factor[j]]) : [],
  });
  const waterPvtCw = useChartInfo({
    xAxesLabel: dictionary.inputPage.pressure,
    yAxesLabel: dictionary.inputPage.compressibility,
    seriesName: dictionary.inputPage.waterCw,
    seriesColor: palette.customColor.blueLight,
    chartPlot: waterPvtCalculation ? waterPvtCalculation.pressure.map((p, j) => [p, waterPvtCalculation.compressibility[j]]) : [],
  });

  return {
    waterPvtDataTableRows,
    waterPvtDataTableCols,
    waterPvtMu,
    waterPvtPw,
    waterPvtBw,
    waterPvtCw,
  };
};

export default useWaterPvt;
